/* eslint-disable react/no-unstable-nested-components */
import React, { useEffect, useState } from "react";
import { Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import MaterialTable from "material-table";
import { useParams } from "react-router-dom";
import useSWR from "swr";
import TableLoadingOverlay from "../../components/MaterialTableLoadingOverlay";
import { getClientSpecificTransform, postClientSpecificMapping, editClientMapping, deleteClientSpecificMapping, getStandardHeaders } from "../../utils/api";

export function DisplayClientSpecificMapping() {
  const { client, studyFileType, id } = useParams();
  const study = studyFileType.split(":")[0];
  const headerLookup = {};
  const [data, setData] = useState([]);
  const [dropDownData, setDropdownData] = useState([]);
  const [isOpen, setIsOpen] = useState({ status: false, message: "", severity: "" });
  const { data: transformMapping, error: transformMappingError, mutate } = useSWR(`/lms/api/v1/transform/client-specific-transform/${id}`, () => getClientSpecificTransform(id));
  const { data: standardHeaders, error: standardHeadersError } = useSWR(`/lms/api/v1/transform/standard-header/${study}`, () => getStandardHeaders(study));
  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }
  useEffect(() => {
    if (transformMapping) {
      transformMapping.sort((a, b) => (a.standard_header_name > b.standard_header_name ? 1 : -1));
      setData(transformMapping);
    }
    if (standardHeaders) {
      standardHeaders.standard_headers.sort((a, b) => (a.standard_header_name > b.standard_header_name ? 1 : -1));
      setDropdownData(standardHeaders.standard_headers);
    }
  }, [transformMapping, standardHeaders]);
  /* eslint-disable*/
  if (dropDownData.length > 0) {
    dropDownData.map((row) => headerLookup[row.standard_header_name] = row.standard_header_name?.toString());
  }
  if (transformMappingError) return <div>Error loading client specific transforms...</div>;
  if (standardHeadersError) return <div>Error loading standard header transforms...</div>;
  const columns = [
    { title: "File Header Mapping", field: "header_name" },
    { title: "Standard Header Mapping", field: "standard_header_name", lookup: headerLookup },
    { title: "Required", field: "is_required", type: "boolean" },
  ];
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setIsOpen((prev) => ({ ...prev, status: false, message: "", severity: "" }));
  };
  return (
    <div>
      {transformMapping ? (
        <MaterialTable
          columns={columns}
          options={{
            headerStyle: {
              backgroundColor: "#30455c",
            },
            paging: true,
            pageSize: 20,
            pageSizeOptions: [],
            emptyRowsWhenPaging: false,
          }}
          data={data}
          title={`Header Mapping for ${client} - ${studyFileType}`}
          editable={{
            onRowAdd: async (newData) => {
              const rowToAdd = {
                preprocessing_file_type: parseInt(id, 10),
                header_name: newData.header_name.toUpperCase(),
                standard_header_name: newData.standard_header_name,
                is_required: !!newData?.is_required,
              };
              if (data.filter(row => row.header_name === rowToAdd.header_name).length === 0) {
                await postClientSpecificMapping(rowToAdd);
                mutate();
              } else {
                setIsOpen((prev) => ({
                  ...prev, status: true, message: "Header name already mapped!", severity: "error",
                }));
              }
            },
            onRowUpdate: async (newData, oldData) => {
              const rowToUpdate = {
                preprocessing_file_type: oldData.preprocessing_file_type,
                header_name: newData.header_name.toUpperCase(),
                standard_header_name: newData.standard_header_name,
                is_required: !!newData?.is_required
              }
              await editClientMapping(oldData.id, rowToUpdate);
              mutate();
            },
            onRowDelete: async (oldData) => {
              await deleteClientSpecificMapping(oldData.id);
              mutate();
              setIsOpen((prev) => ({
                ...prev, status: true, message: "Transform Type deleted successfully", severity: "success",
              }));
            },
          }}
        />
      ) : <TableLoadingOverlay />
      }
      {isOpen.status && (
        <Snackbar
          open={isOpen}
          onClose={() => setIsOpen((prev) => ({ ...prev, status: false }))}
          autoHideDuration={4000}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        >
          <Alert onClose={handleClose} severity={isOpen.severity}>
            {isOpen.message}
          </Alert>
        </Snackbar>
      )}
    </div>
  );
}
