import React from "react";
import { Chip, Typography } from "@material-ui/core";
import { useSelector } from "react-redux";

import useClientConfiguration from "../../hooks/useClientConfiguration";
import useFormData from "../../hooks/useFormData";
import * as SharedDefinitions from "../../utils/SharedDefinitions";
import BaseForm from "./common/BaseForm";

const ColumnOptions = [
  "MF / ANN",
  "ZIP_CODE",
  "STATE",
  "QUARTER",
  "FDS_BROKER_ID",
  "BROKER_NAME",
  "BROAD_FUND_CATEGORY",
  "FUND_CATEGORY",
  "CUSIP",
  "CLIENT_SALES",
  "GLOBAL_SALES",
  "TERRITORY",
  "REBALANCE",
  "CITY",
  "CHANNEL",
];

const uiSchema = {
  lock: {
    "ui:help": "Leave unselected for Live",
  },
  line_of_business: {
    "ui:help": "Leave unselected for both",
  },
};

export default function AnnDashboardForm({ children, studyType, client, setTaskConfig, submitHandler, formDataProp, displayHeader }) {
  const formOptions = useSelector((state) => state.formOptions);

  // In the future formData should be fetched and pre-populated for saved jobs
  const [formData, setFormData] = useFormData(setTaskConfig, formDataProp);

  useClientConfiguration(setFormData, studyType, client);

  const annDashboardSchema = {
    title: "ANN Dashboard Form",
    description: "ANN Dashboard",
    type: "object",
    required: [
      "start_quarter",
      "end_quarter",
      "annuity_types",
      "metric_display_type",
      "num_past_quarters",
      "num_rolling_quarters",
      "territory_settings",
    ],
    properties: {
      start_quarter: {
        type: "string",
        title: "Start Quarter",
        enum: formOptions.quarters,
      },
      end_quarter: {
        type: "string",
        title: "End Quarter",
        enum: formOptions.quarters,
      },
      lock: {
        type: "string",
        title: "Lock (Data Set)",
        enum: formOptions.locksANN,
      },
      annuity_types: SharedDefinitions.annuityTypes,
      va_product_type: SharedDefinitions.vaProductType,
      production_cycle: {
        type: "string",
        title: "Production Cycle",
        enum: formOptions.quarters,
      },
      include_mf_data: {
        type: "boolean",
        title: "Include MF Data",
      },
      num_past_quarters: {
        type: "integer",
        title: "Number of Past Quarters",
      },
      num_rolling_quarters: {
        type: "integer",
        title: "Number of Rolling Quarters",
      },
      metric_display_type: {
        type: "string",
        title: "Metric Display Type (Previously named Cell Contents)",
        oneOf: [
          {
            const: "client_gross_sales",
            title: "Client Gross Sales",
          },
          {
            const: "client_rank",
            title: "Client Market Share Rank",
          },
          {
            const: "market_share",
            title: "Market Share",
          },
          {
            const: "total_opportunity",
            title: "Total Opportunity",
          },
        ],
      },
      disable_phantom: {
        type: "boolean",
        title: "Disable Phantom Exclusions",
      },
      separate_client_columns: {
        type: "boolean",
        title: "Separate client columns",
      },
      include_indirect_sales: {
        type: "boolean",
        title: "Include Indirect sales",
      },
      use_va_alternate_broker_names: {
        type: "boolean",
        title: "Use VA Alternate Broker Names",
      },
      initial_sales_only: {
        type: "boolean",
        title: "Initial Only Sales Deposit Type Types (Leave both this and below blank to keep including both)",
      },
      subsequent_sales_only: {
        type: "boolean",
        title: "Subsequent Only Sales Deposit Type (Leave both this and above blank to keep including both)",
      },
      sales_channels_brokers_only: {
        type: "boolean",
        title: "Sales Channels Brokers Only",
      },
      key_brokers_id: {
        type: "string",
        title: "Key Brokers ID",
      },
      territory_settings: {
        type: "object",
        title: "Territories",
        required: ["territory_mode"],
        properties: {
          territory_mode: {
            type: "string",
            title: "Territory Mode",
            enum: ["combined", "split"],
            default: "combined",
          },
        },
        dependencies: {
          territory_mode: SharedDefinitions.getTerritoryModeVA(formOptions, client),
        },
      },
      broker_zip_exclusions_settings: {
        type: "object",
        title: "Broker/Zip Exclusions Settings",
        properties: {
          fia_exclusion_settings: {
            type: "object",
            title: "FIA Broker/Zip Exclusions",
            properties: SharedDefinitions.brokerZipExclusions,
          },
          va_exclusion_settings: {
            type: "object",
            title: "VA Broker/Zip Exclusions",
            properties: SharedDefinitions.brokerZipExclusions,
          },
          mf_exclusion_settings: {
            type: "object",
            title: "MF Broker/Zip Exclusions",
            properties: SharedDefinitions.brokerZipExclusions,
          },
        },
      },
      verified_address_exclusions: SharedDefinitions.verifiedAddressExclusions,
      generate_raw_data_file: {
        type: "boolean",
        title: "Generate Raw Data File",
      },
      raw_data_excluded_fields: {
        type: "array",
        title: "Raw Data File Excluded Fields",
        items: {
          type: "string",
          enum: ColumnOptions,
        },
      },
      raw_data_include_channel_field: {
        type: "boolean",
        title: "Include Channel in Raw Data File",
        items: {
          type: "string",
          enum: ColumnOptions,
        },
      },
      raw_data_include_address_field: {
        type: "boolean",
        title: "Include Addresses in Raw Data File",
      },
      raw_data_include_month_field: {
        type: "boolean",
        title: "Include Month in Raw Data File",
      },
    },
  };

  return (
    <div>
      <div style={{ display: "flex", alignItems: "center" }}>
        <Typography variant="subtitle2" style={{ marginRight: "8px", marginBottom: "16px" }}>Task Name :</Typography>
        <Chip style={{ marginBottom: "16px" }} variant="outlined" label={displayHeader(["start_quarter", "end_quarter", "annuity_types"])} />
      </div>
      <BaseForm
        schema={annDashboardSchema}
        uiSchema={uiSchema}
        formData={formData}
        submitHandler={submitHandler}
        setFormData={setFormData}
      >
        {children}
      </BaseForm>
    </div>
  );
}
