import React, { useState } from "react";
import { Chip, useTheme } from "@material-ui/core";
import DoneIcon from "@material-ui/icons/Done";

export default function TerritoryChip({ label, index, getTagProps, defaultTerr, handleChipClick }) {
  const theme = useTheme();

  const [hover, setHover] = useState(false);

  const isDefault = index === defaultTerr;

  return (
    <Chip
      label={hover ? "Set as default" : label}
      style={isDefault ? theme.common.chip.defaultTerritoryChip : theme.common.chip.territoryChip}
      icon={isDefault ? <DoneIcon /> : null}
      onMouseOver={() => setHover(true)}
      onMouseOut={() => setHover(false)}
      onClick={() => handleChipClick(index)}
      {...getTagProps({ index })}
    />
  );
}
