import React from "react";
import { Chip, makeStyles } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { setFilters } from "../../../state";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "left",
    flexWrap: "wrap",
    listStyle: "none",
    padding: theme.spacing(0.5),
    margin: 0,
  },
  chip: {
    margin: theme.spacing(0.5),
  },
}));

export default function FilterChip() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const filters = useSelector(state => state.filters.available);

  const handleDelete = (chipToDelete) => () => {
    if (Array.isArray(filters[chipToDelete])) {
      dispatch(setFilters({ value: [], key: chipToDelete }));
    } else {
      dispatch(setFilters({ value: null, key: chipToDelete }));
    }
  };

  return (
    <ul className={classes.root}>
      {Object.keys(filters).map((key) => ((filters[key] && !(Array.isArray(filters[key]) && !filters[key].length))
        ? (
          <li key={key}>
            <Chip
              label={(Array.isArray(filters[key]) ? `${key}: ${filters[key].map(obj => obj.label)}` : `${key}: ${filters[key]}`)}
              onDelete={handleDelete(key)}
              className={classes.chip}
            />
          </li>
        )
        : null
      ))}
    </ul>
  );
}
