import React from "react";
import { Button, Tooltip } from "@material-ui/core";
import moment from "moment-timezone";
import { Link } from "react-router-dom";
import StatusColor from "./StatusColor";

export default function Shape({ taskDetails }) {
  const { id, status, ranBy, date, workflowId } = taskDetails;
  return (
    <div style={{ textAlign: "center" }}>
      <Tooltip
        title={(status === "NOT_ATTEMPTED" || status === "NOT_EXPECTED")
          ? status.replace("_", " ")
          : (
            <div>
              {`Status : ${status}`}
              <br />
              {`Run By : ${ranBy}`}
              <br />
              {`Date : ${moment(moment.parseZone(date).local()).format("MMM Do YYYY, h:mm:ss a")}`}
            </div>
          )}
      >
        <Button
          variant="text"
          component={Link}
          // eslint-disable-next-line no-nested-ternary
          to={(status === "NOT_ATTEMPTED" || status === "NOT_EXPECTED") ? "#" : workflowId ? `/details/${workflowId}/${id}` : `/details/${id}`}
          target={(status === "NOT_ATTEMPTED" || status === "NOT_EXPECTED") ? "" : "_blank"}
          style={{ textAlign: "center" }}
        >
          <StatusColor status={status?.toUpperCase()} />
        </Button>
      </Tooltip>
    </div>
  );
}
