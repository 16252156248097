import React from "react";
import { Button, Chip, Link, makeStyles, Tooltip, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import _ from "lodash";
import moment from "moment-timezone";

import { objectToArray, userDisplay } from "./utils";

const useStyles = makeStyles((theme) => ({
  typography: {
    padding: theme.spacing(2),
  },
}));

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
    minWidth: 500,
  },
}))(Tooltip);

function getMaxes(data, criteria) {
  const maxesPerUser = data?.map(job => objectToArray(job?.stats)?.sort((a, b) => b[criteria] - a[criteria])?.find(x => x !== undefined));
  return maxesPerUser?.sort((a, b) => b[criteria] - a[criteria])?.find(x => x !== undefined);
}

export default function JobSummaryClient({ client, data, users, user, setUser }) {
  const classes = useStyles();

  const numberOfUsersInJob = data?.length;
  const firstUserJob = data?.find(x => x !== undefined);
  const userJobsWithEmptyRows = data?.filter(job => objectToArray(job?.stats).find(stat => stat?.records === 0));
  const longestQuery = getMaxes(data, "seconds");
  const biggestQuery = getMaxes(data, "records");
  // eslint-disable-next-line prefer-spread
  const uniqueQueriesWithEmptyRows = _.uniqBy([].concat.apply([], userJobsWithEmptyRows?.map(job => objectToArray(job?.stats).filter(stat => stat?.records === 0))), "key");

  return (
    <>
      <Typography className={classes.title} color="textSecondary" gutterBottom>
        Latest Job Summary
      </Typography>
      <Typography className={classes.title} color="textSecondary" gutterBottom>
        See Job:
        {" "}
        <Link href={`/details/${firstUserJob?.task_id}`} color="inherit">
          {firstUserJob?.task_id}
        </Link>
      </Typography>
      <Typography variant="h5">
        The latest
        {" "}
        <b>{client}</b>
        {" "}
        prewarming job on
        {" "}
        <b>{moment(firstUserJob?.created_at).format("MMMM Do YYYY, h:mm:ss a")}</b>
        {" "}
        ran for
        {" "}
        <b>{numberOfUsersInJob}</b>
        {" "}
        active users
        {" "}
        . Out of that number,
        {" "}
        <HtmlTooltip
          interactive
          title={(
            <>
              <Typography color="inherit">Users</Typography>
              <Typography className={classes.typography}>{userJobsWithEmptyRows?.map(u => <Chip label={userDisplay(u?.user_id, users)} color={u?.user_id === user ? "primary" : ""} onClick={() => setUser(u?.user_id)} />)}</Typography>
            </>
          )}
        >
          <Button color="primary"><b>{userJobsWithEmptyRows?.length}</b></Button>
        </HtmlTooltip>
        {" "}
        users returned responses with zero results for these
        {" "}
        <HtmlTooltip
          interactive
          title={(
            <>
              <Typography color="inherit">Queries</Typography>
              <Typography className={classes.typography}>{uniqueQueriesWithEmptyRows?.map(o => o?.key).map(q => <Chip label={q} />)}</Typography>
            </>
          )}
        >
          <Button color="primary"><b>{uniqueQueriesWithEmptyRows?.length}</b></Button>
        </HtmlTooltip>
        {" "}
        different queries.
        The longest running query
        {" "}
        <b>{longestQuery?.key}</b>
        {" "}
        took
        {" "}
        <b>{Math.round(longestQuery?.seconds)}</b>
        {" "}
        seconds to complete.
        The most records returned were
        {" "}
        <b>{biggestQuery?.records}</b>
        {" "}
        records from
        {" "}
        <b>{biggestQuery?.key}</b>
      </Typography>
    </>
  );
}
