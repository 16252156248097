import React from "react";
import { Chip, useTheme } from "@material-ui/core";

export default function GroupsChip({ group }) {
  const theme = useTheme();

  function getStyle(groupString) {
    if (groupString === "everyone") {
      return theme.common.chip.successChip;
    }
    if (groupString.includes("misight")) {
      return theme.common.chip.runningChip;
    }
    if (groupString.includes("simfund")) {
      return theme.common.chip.draftChip;
    }

    return theme.common.chip.baseChip;
  }

  return (
    <Chip
      size="small"
      label={group}
      style={{ ...getStyle(group), marginRight: "1px" }}
    />
  );
}
