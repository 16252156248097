import React, { useState } from "react";
import { Card, CardContent, FormControl, Box, Chip, makeStyles, Button, TextField, Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import CircularProgress from "../../../components/Spinner";
import usePageTitle from "../../../hooks/usePageTitle";
import { API } from "../../../utils/api";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const ErrorDisplay = () => (
  <>
    Input must contain
    <ul style={{ margin: "0" }}>
      <li>Alphanumeric characters including  _ </li>
      <li>Must not start with number or  _ </li>
    </ul>
    <strong style={{ color: "green" }}> Valid Example : WEEKEND_WORKFLOW_0422</strong>
  </>
);

export default function CreateWorkflow() {
  // --- Task Update ---
  const [name, setName] = useState("");
  const alphanumericPattern = /^[a-zA-Z][a-zA-Z0-9_]*$/;
  const [inputError, setInputError] = useState("");
  const handleInputChange = (event) => {
    setInputError();
    setName(event.target.value.toUpperCase());
  };
  const [notification, setNotification] = useState({
    error: false,
    success: false,
  });
  const [spinner, setSpinner] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const useStyles = makeStyles(() => ({
    fullWidthChip: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
    },
  }));
  const classes = useStyles();
  const handleSubmit = (event) => {
    event.preventDefault();
    setSpinner(true);
    if (!alphanumericPattern.test(name)) {
      // handle valid input
      setInputError(<ErrorDisplay />);
      setSpinner(false);
    } else {
      // handle invalid input
      setInputError("");
      API.request({ url: "/workflows", method: "POST", data: { workflow_name: name } }).then((response) => {
        if (response.status === 201) {
          setNotification(prev => ({ ...prev, success: true, error: false }));
          setTimeout(() => {
            setSpinner(false);
            window.location.href = "/create";
          }, 2500);
        }
      }, (e) => {
        setErrorMessage(e.response.data.message);
        setSpinner(false);
        setNotification(prev => ({ ...prev, success: false, error: true }));
        setTimeout(() => {
          setNotification(prev => ({ ...prev, success: false, error: false }));
        }, 2500);
        return null;
      });
    }
  };

  usePageTitle("Create Workflow");
  return (
    <Card style={{ marginBottom: "1em" }}>

      <CardContent>

        <Chip
          className={classes.fullWidthChip}
          size="large"
          style={{ fontSize: "large", color: "white", backgroundColor: "#7A8C9F", padding: " 0.5rem 1rem" }}
          label="Create Workflow By Workflow Name"
        />

        <Box display="flex" justifyContent="space-between" p={2}>
          <form onSubmit={handleSubmit}>
            <FormControl fullWidth margin="normal" variant="outlined">
              <TextField
                autoFocus
                error={inputError}
                helperText={inputError}
                label="Enter Workflow Name"
                value={name}
                onChange={handleInputChange}
              />
            </FormControl>

            <Button
              style={{ width: "5%" }}
              onClick={handleSubmit}
              disabled={!name || spinner}
              color="primary"
            >
              Save
            </Button>
            {spinner ? <CircularProgress style={{ width: "40px", height: "20px" }} /> : null}

          </form>
          <Snackbar
            open={notification.success || notification.error}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            autoHideDuration={4000}
          >
            <Alert severity={notification.success ? "success" : "error"}>
              {notification.success ? "Workflow created" : errorMessage}
            </Alert>
          </Snackbar>
        </Box>

      </CardContent>

    </Card>
  );
}
