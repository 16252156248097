import React from "react";
import {
  Box,
} from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import { useFormikContext } from "formik";
import { useSelector } from "react-redux";
import * as Yup from "yup";

import LMSSelect from "../../../components/input/Formik/base/LMSSelect";
import LMSSwitch from "../../../components/input/Formik/base/LMSSwitch";
import LMSTextField from "../../../components/input/Formik/base/LMSTextField";
import { getOptionsByClient } from "../../../utils/SharedDefinitions";
import { convertArrayToOptions } from "../../../utils/dataTransformations";

export const PrimaryInfoSchema = {
  full_name: Yup.string().required("Required"),
  short_name: Yup.string().required("Required"),
  territory_map: Yup.string(),
};

export default function PrimaryInfo({
  clientMnemonic,
  disabled,
  children,
  study,
}) {
  const { values } = useFormikContext();

  const formOptions = useSelector(state => state.formOptions);
  const formIsLoading = useSelector(state => state.application.formIsLoading);
  const LMSIsLoading = useSelector(state => state.application.LMSIsLoading);
  const legacyClient = formOptions.clients?.find((o) => o.id.toUpperCase() === clientMnemonic?.toUpperCase());
  const territories = study === "ann" ? convertArrayToOptions(
    getOptionsByClient(formOptions.ddTerritoriesVA, legacyClient?.id),
  ).concat(convertArrayToOptions(
    getOptionsByClient(formOptions.ddTerritoriesFIA, legacyClient?.id),
  )) : convertArrayToOptions(
    getOptionsByClient(formOptions[`ddTerritories${study.toUpperCase()}`], legacyClient?.id),
  );
  const channels = convertArrayToOptions(
    getOptionsByClient(formOptions[`ddChannels${study.toUpperCase()}`], legacyClient?.id),
  );

  const fieldsOptions = (
    <>
      <Box
        display="flex"
        justifyContent="space-around"
        p={2}
      >
        {formIsLoading || LMSIsLoading
          ? (
            <>
              <Skeleton style={{ width: "30%", margin: "0.5em" }} />
              <Skeleton style={{ width: "30%", margin: "0.5em" }} />
            </>
          )
          : (
            <>
              <LMSSelect
                name="territory_map"
                label="Territory Map"
                options={territories}
                disabled={!clientMnemonic}
                formControlProps={{
                  style: { width: "30%" },
                }}
              />
              <LMSSelect
                name="channel_map"
                label="Channel Map"
                options={channels}
                disabled={!clientMnemonic}
                formControlProps={{
                  style: { width: "30%" },
                }}
              />
            </>
          )}
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        p={2}
      >
        <LMSSwitch
          label="Use Consortium Exclusions"
          name="use_consortium_exclusions"
        />
      </Box>
    </>
  );

  const studySpecificFieldsMap = {
    ann: (
      fieldsOptions
    ),
    mf: (
      fieldsOptions
    ),
    sma: (
      fieldsOptions
    ),
  };

  return (
    <div
      style={{
        pointerEvents: disabled ? "none" : "all",
      }}
    >
      <Box
        display="flex"
        justifyContent="space-around"
        p={2}
      >
        {children}
        <LMSTextField
          label="Profile Full Name *"
          name="full_name"
          style={{ width: "20%" }}
          helperText="Name of the Client Profile"
        />
        <LMSTextField
          label="Profile Short Name *"
          name="short_name"
          style={{ width: "20%" }}
          helperText="Abbreviation"
        />
      </Box>
      {studySpecificFieldsMap[values?.study]}
    </div>
  );
}
