import React from "react";
import { Button, makeStyles, Paper, Typography } from "@material-ui/core";
import Home from "@material-ui/icons/Home";

const useStyles = makeStyles((theme) => ({
  icon: {
    width: 192,
    height: 192,
    color: theme.palette.secondary.main,
  },
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
  },
  paper: {
    backgroundColor: theme.palette.background.default,
    margin: 0,
    height: "calc(100vh - 64px)",
  },
  button: {
    marginTop: 20,
  },
  boldText: {
    color: theme.palette.running.main,
    fontWeight: "bold",
  },
  titleText: {
    background: `-webkit-linear-gradient(${theme.palette.error.main}, ${theme.palette.primary.main})`,
    "-webkit-background-clip": "text",
    "-webkit-text-fill-color": "transparent",
  },
}));

export default function PageNotFound() {
  const classes = useStyles();

  return (
    <Paper className={classes.paper}>
      <div className={classes.container}>
        <Typography variant="h1" className={classes.titleText}>404</Typography>
        <Typography variant="subtitle1">
          {"Sorry ): page "}
          <span className={classes.boldText}>not found</span>
          {" or you do not have "}
          <span className={classes.boldText}>access</span>
          {" to this page"}
        </Typography>
        <Button
          color="primary"
          aria-label="home"
          href="/"
          className={classes.button}
        >
          <Home fontSize="large" />
        </Button>
      </div>
    </Paper>
  );
}
