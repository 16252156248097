import React from "react";
import { useTheme } from "@material-ui/core";

export function getStyle(statusString, theme) {
  switch (statusString) {
  case "SUCCEEDED": {
    return theme.common.chip.successChip;
  }
  case "FAILED": {
    return theme.common.chip.errorChip;
  }
  case "ABORTED": {
    return theme.common.chip.abortedChip;
  }
  case "RUNNING": {
    return theme.common.chip.runningChip;
  }
  case "QUEUED": {
    return theme.common.chip.queuedChip;
  }
  case "RUNNING_QUEUE": {
    return theme.common.chip.runningQueueChip;
  }
  case "NOT_ATTEMPTED": {
    return theme.common.chip.notAttemptedChip;
  }
  default:
    return theme.common.chip.baseChip;
  }
}

export default function StatusColor({ status, children }) {
  const theme = useTheme();
  return (
    <div
      style={{
        width: "40px",
        height: "40px",
        backgroundColor: getStyle(status, theme).backgroundColor,
        borderRadius: "12px",
        boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.5)",
        backgroundImage: status === "NOT_EXPECTED" ? "repeating-linear-gradient(-45deg, grey, transparent 2vh)" : "none",
        alignItems: "start",
      }}
    >
      {children}
    </div>
  );
}
