import React, { useEffect } from "react";
import {
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import Form from "@rjsf/material-ui";
import { useSelector } from "react-redux";

export default function ANNQADashboardForm({
  children,
  taskConfig,
  setTaskConfig,
  submitHandler,
  studyType,
  displayHeader,
  setIsRequired,
}) {
  const productionCycles = useSelector((state) => state.formOptions.productionCycle);

  const onCheckHandler = (e) => {
    setTaskConfig((prevTaskConfig) => ({
      ...prevTaskConfig,
      [e.target.value]: e.target.checked,
    }));
  };

  const onProductionCycleSelect = (e) => {
    setTaskConfig((prevTaskConfig) => ({
      ...prevTaskConfig,
      [e.target.name]: e.target.value,
    }));
  };

  useEffect(() => {
    // If none of the options are selected then reset the production_cycle.
    if (
      taskConfig.sanity_check === false
      && taskConfig.number_comparison === false
    ) {
      setTaskConfig((prevTaskConfig) => {
        // eslint-disable-next-line camelcase
        const { production_cycle, ...newTaskConfig } = prevTaskConfig;
        return newTaskConfig;
      });
    }
  }, [
    taskConfig.sanity_check,
    taskConfig.number_comparison,
    setTaskConfig,
  ]);

  useEffect(() => {
    setTaskConfig((prevConfig) => ({
      ...prevConfig,
      study: studyType,
      production_cycle: taskConfig.production_cycle ?? productionCycles[1],
      sanity_check: taskConfig.sanity_check ?? true,
      number_comparison: taskConfig.number_comparison ?? true,
    }));
    setIsRequired((prevState) => [...prevState, "production_cycle"]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Form schema={{}} onSubmit={submitHandler}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <Typography
          variant="subtitle2"
          style={{ marginRight: "8px", marginBottom: "16px" }}
        >
          Task Name :
        </Typography>
        <Chip
          style={{ marginBottom: "16px" }}
          variant="outlined"
          label={displayHeader(["file_type"])}
        />
      </div>
      <Grid container spacing={2}>
        <Grid item>
          <FormControl fullWidth variant="outlined">
            <InputLabel required htmlFor="inputLabel-production-cycle-select">
              Production Cycle
            </InputLabel>
            <Select
              required
              style={{ width: 250 }}
              value={taskConfig.production_cycle || ""}
              onChange={onProductionCycleSelect}
              label="Production Cycle"
              inputProps={{
                name: "production_cycle",
                id: "inputLabel-production-cycle-select",
              }}
              disabled={
                !taskConfig.sanity_check
                && !taskConfig.number_comparison
              }
            >
              {productionCycles.map((quarter) => (
                <MenuItem key={quarter} value={quarter}>
                  {quarter}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item>
          {(taskConfig.production_cycle !== productionCycles[1] && taskConfig.production_cycle !== "") ? (
            <Alert severity="warning">
              Selected Production Cycle is not the current cycle. Running this
              job will overwrite the data for
              {" "}
              {taskConfig.production_cycle}
              {" "}
              cycle.
            </Alert>
          ) : null}
        </Grid>
      </Grid>
      {/* Sanity Check */}
      <div style={{ height: 50 }}>
        <FormControl margin="normal" variant="outlined">
          <Tooltip title="Sanity Check" placement="right-end">
            <FormControlLabel
              control={(
                <Checkbox
                  value="sanity_check"
                  onChange={onCheckHandler}
                  checked={taskConfig.sanity_check ?? true}
                />
              )}
              label="Sanity Check"
            />
          </Tooltip>
        </FormControl>
      </div>
      {/* Number Comparison */}
      <div style={{ height: 50, marginBottom: 25 }}>
        <div style={{ display: "flex" }}>
          <FormControl margin="normal" variant="outlined">
            <Tooltip title="Number Comparison" placement="right-end">
              <FormControlLabel
                control={(
                  <Checkbox
                    value="number_comparison"
                    onChange={onCheckHandler}
                    checked={taskConfig.number_comparison ?? true}
                  />
                )}
                label="Number Comparison"
              />
            </Tooltip>
          </FormControl>
          {taskConfig.number_comparison ? (
            <Alert
              severity="info"
              style={{ marginTop: "auto", marginBottom: "auto" }}
            >
              Run Territory OTT of clients whose Number Comparison is required.
            </Alert>
          ) : null}
        </div>
      </div>
      {children}
    </Form>
  );
}
