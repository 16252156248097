import React, { useEffect, useState } from "react";
import {
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@material-ui/core";
import usePageTitle from "../hooks/usePageTitle";
import { API } from "../utils/api";

const braavosInfo = Object.freeze({
  sha: process.env.REACT_APP_BUILD_SHA && process.env.REACT_APP_BUILD_SHA.slice(0, 7),
  ref: process.env.REACT_APP_BUILD_REF && process.env.REACT_APP_BUILD_REF.replace("refs/heads/", ""),
  date: process.env.REACT_APP_BUILD_DATE,
});

const useStyles = makeStyles({
  headerCell: {
    fontWeight: "bold",
    borderBottom: "none",
    textTransform: "capitalize",
    backgroundColor: "#f5f5f5",
    borderBottomRightRadius: "8px",
  },
  TableContainer: {
    display: "flex",
    justifyContent: "center",
    maxWidth: 1280,
    margin: "0 auto",
  },
});

export default function VersionsInfo() {
  const classes = useStyles();
  const [versions, setVersions] = useState();

  usePageTitle("Versions");

  useEffect(() => {
    API.request({ url: "/versions", method: "GET" }).then((response) => {
      setVersions(response.data);
    });
  }, []);

  if (!versions) {
    return <div className={classes.TableContainer}>Getting versions...</div>;
  }

  const formatDate = (dateString) => {
    const date = new Date(Date.parse(dateString));
    return date.toLocaleString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      timeZoneName: "short",
    });
  };

  return (
    <TableContainer className={classes.TableContainer} component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Service</TableCell>
            <TableCell>Date</TableCell>
            <TableCell>Description</TableCell>
            <TableCell>Branch</TableCell>
            <TableCell>SHA</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.entries(versions).map(([service, subServices]) => (
            <>
              <TableRow>
                <TableCell className={classes.headerCell}>{service}</TableCell>
              </TableRow>
              {Object.entries(subServices).map(([subService, { date, description, ref, sha }]) => (
                <TableRow>
                  <TableCell>{subService}</TableCell>
                  <TableCell>{formatDate(date)}</TableCell>
                  <TableCell>{description}</TableCell>
                  <TableCell>{ref}</TableCell>
                  <TableCell>{sha}</TableCell>
                </TableRow>
              ))}
            </>
          ))}
          <TableRow>
            <TableCell className={classes.headerCell}>Braavos</TableCell>
            <TableCell>{formatDate(braavosInfo.date)}</TableCell>
            <TableCell>LMS Frontend React App</TableCell>
            <TableCell>{braavosInfo.ref}</TableCell>
            <TableCell>{braavosInfo.sha}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}
