import React, { useEffect } from "react";
import { Typography, Checkbox, FormControl, FormControlLabel } from "@material-ui/core";
import Form from "@rjsf/material-ui";

export default function PublishAthenaTransportDataForm({ children, taskConfig, setTaskConfig, submitHandler, studyType }) {
  useEffect(() => {
    setTaskConfig((preConfig) => ({ ...preConfig, study: studyType }));
  }, [setTaskConfig, studyType]);

  return (
    <Form
      schema={{}}
      onSubmit={submitHandler}
    >
      <Typography variant="h6">MISight Periodic Data Loader</Typography>
      <FormControl component="fieldset" fullWidth margin="normal">
        <FormControlLabel
          value={taskConfig.notify || false}
          control={<Checkbox />}
          label="Notify"
          onChange={(e) => setTaskConfig((prevTaskConfig) => ({ ...prevTaskConfig, notify: e.target.checked }))}
        />
      </FormControl>
      {children}
    </Form>
  );
}
