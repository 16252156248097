import React, { useContext, useEffect } from "react";
import { useFormikContext } from "formik";
import * as Yup from "yup";

import ClientProfileDropdown from "../../../../components/input/Formik/ClientProfileDropdown";
import LMSCheckbox from "../../../../components/input/Formik/base/LMSCheckBox";

import { FormContext } from "../FormContext";

export const IncludeMFDataSchema = {
  mf_client_profile_id: Yup.number().integer()
    .when("include_mf_data", {
      is: true,
      then: Yup.number().integer().required("Field is required"),
    }),
};

export default function IncludeMFData() {
  const { clientId } = useContext(FormContext);
  const { values, setFieldValue } = useFormikContext();

  useEffect(() => {
    if (!values?.include_mf_data) {
      setFieldValue("mf_client_profile_id", undefined);
    }
  }, [values?.include_mf_data, setFieldValue]);

  return (
    <>
      <LMSCheckbox
        name="include_mf_data"
        label="Include MF Data"
      />
      {
        values?.include_mf_data
          ? (
            <ClientProfileDropdown
              clientId={clientId}
              studyType="mf"
              name="mf_client_profile_id"
              label="MF Client Profile *"
            />
          )
          : null
      }
    </>
  );
}
