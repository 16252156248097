import React from "react";
import { Select, MenuItem, InputLabel, FormControl, FormHelperText } from "@material-ui/core";
import { useField } from "formik";

import LMSErrorMessage from "./LMSErrorMessage";

export default function LMSSelect({
  name,
  label,
  formControlProps,
  valueKey = "id",
  optionKey = "label",
  ...props
}) {
  const labelId = label && `inputLabel-${label.replace(" ", "")}`;
  const [field, meta] = useField(name);

  const error = meta.touched && meta.error;

  return (
    <FormControl
      fullWidth
      error={!!error}
      margin="normal"
      variant="outlined"
      {...formControlProps}
    >
      <InputLabel
        id={labelId}
      >
        {label}
      </InputLabel>
      <Select
        label={label}
        labelId={labelId}
        fullWidth
        {...field}
        {...props}
        value={field.value || ""}
      >
        {props.options?.map((o) => (
          <MenuItem key={o[valueKey]} value={o[valueKey]}>
            {o[optionKey]}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText>{props.helperText || ""}</FormHelperText>
      <LMSErrorMessage error={error} />
    </FormControl>
  );
}
