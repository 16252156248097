import React, { useState } from "react";
import { Chip, useTheme } from "@material-ui/core";

export default function UserStatusChip({ status }) {
  const theme = useTheme();

  function getStyle(statusString) {
    switch (statusString) {
    case "ACTIVE": {
      return theme.common.chip.successChip;
    }
    case "PASSWORD_EXPIRED": {
      return theme.common.chip.errorChip;
    }
    case "SUSPENDED": {
      return theme.common.chip.errorChip;
    }
    case "PROVISIONED": {
      return theme.common.chip.runningChip;
    }
    case "RECOVERY": {
      return theme.common.chip.runningChip;
    }
    case "STAGED": {
      return theme.common.chip.draftChip;
    }
    default:
      return theme.common.chip.baseChip;
    }
  }

  function getStatusDescription(statusString) {
    switch (statusString) {
    case "ACTIVE": {
      return "User has access to MISight";
    }
    case "PASSWORD_EXPIRED": {
      return "User's password needs to be changed";
    }
    case "SUSPENDED": {
      return "User was deactivated by our team";
    }
    case "PROVISIONED": {
      return "User was activated but did not create an account";
    }
    case "RECOVERY": {
      return "User account in recovery stage";
    }
    case "STAGED": {
      return "User was created but not activated";
    }
    default:
      return statusString;
    }
  }

  const [hover, setHover] = useState(false);
  const statusDescription = getStatusDescription(status);

  return (
    <Chip
      label={hover ? statusDescription : status}
      style={getStyle(status)}
      onMouseOver={() => setHover(true)}
      onMouseOut={() => setHover(false)}
    />
  );
}
