import React, { useEffect } from "react";
import { Typography } from "@material-ui/core";
import Form from "@rjsf/material-ui";

export default function PublishClientExclusionsForm({ children, setTaskConfig, submitHandler, studyType }) {
  useEffect(() => {
    setTaskConfig((preConfig) => ({ ...preConfig, study: studyType }));
  }, [setTaskConfig, studyType]);

  return (
    <Form
      schema={{}}
      onSubmit={submitHandler}
    >
      <Typography variant="h6">Publish Client Exclusions</Typography>
      <Typography variant="subtitle1" style={{ margin: 15 }}>
        {"This job will publish and update "}
        <b>{`${studyType}`}</b>
        {" client exclusions tables for ALL clients"}
        <br />
        These exclusions tables are used to filter out client specific data in the MISight app.
      </Typography>
      {children}
    </Form>
  );
}
