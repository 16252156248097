import * as React from "react";
import { Button, Tabs, Tab, Typography, Box, makeStyles } from "@material-ui/core";
import { useNavigate } from "react-router-dom";

import usePageTitle from "../../hooks/usePageTitle";
import { lmsTaskTypes } from "../tasks/create_wizard/TaskMapping";
import Exclusions from "./exclusions/Exclusions";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  button: {
    marginRight: theme.spacing(1),
  },
}));

export default function ConsortiumTabs() {
  const classes = useStyles();
  const navigate = useNavigate();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  usePageTitle("Consortium Exclusions");

  const valueToStudy = {
    0: "ann",
  };
  const bulkUploadTask = lmsTaskTypes.find((task) => task.id === "preprocessing_generic_loader" && task.study === valueToStudy[value]);
  const exportTask = lmsTaskTypes.find((task) => task.id === "reporting_generic_exporter" && task.study === valueToStudy[value]);

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider", backgroundColor: "green" }} style={{ marginBottom: 30 }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="ANN" {...a11yProps(0)} />
        </Tabs>
      </Box>
      <Button
        className={classes.button}
        variant="contained"
        color="primary"
        href="/create"
        onClick={() => {
          navigate(
            "/create",
            { state:
              {
                action: "loading",
                studyType: valueToStudy[value],
                taskType: "preprocessing_generic_loader",
                client: "ALL_CLIENTS",
                title: bulkUploadTask.label,
                step: 2,
              },
            },
          );
        }}
      >
        Bulk Upload
      </Button>
      <Button
        variant="contained"
        color="primary"
        href="/create"
        onClick={() => {
          navigate(
            "/create",
            { state:
              {
                action: "loading",
                studyType: valueToStudy[value],
                taskType: "reporting_generic_exporter",
                client: "ALL_CLIENTS",
                title: exportTask.label,
                step: 2,
              },
            },
          );
        }}
      >
        Export
      </Button>
      <TabPanel value={value} index={0}>
        <Exclusions study="ann" />
      </TabPanel>
    </Box>
  );
}
