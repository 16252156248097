import React from "react";
import { Link, MenuItem } from "@material-ui/core";
import { nanoid } from "nanoid";

export default function TaskLogUrls({ logsData }) {
  if (logsData.length) {
    return (
      <>
        {logsData.map((log) => (
          <MenuItem key={nanoid()}>
            <Link href={log.url} color="inherit">
              {`STEP: ${log.step_type} STATUS: ${log.status}`}
            </Link>
          </MenuItem>
        ))}
      </>
    );
  }
  return (
    <MenuItem key="logs" disabled>
      No Logs
    </MenuItem>
  );
}
