import React, { useEffect } from "react";
import { FormControl, Typography } from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Form from "@rjsf/material-ui";
import { useSelector } from "react-redux";

import CSVUpload from "../../components/input/CSVUpload";
import OptionSelect from "../../components/input/OptionSelect";

const tableNames = [
  // MF
  { id: "MORNINGSTAR_FUNDS_STAGE", label: "MORNINGSTAR FUNDS STAGE", study: "mf" },
];

export default function MFMorningstarFundsStage({ children, taskConfig, setTaskConfig, csv, setCsv, submitHandler, studyType, setIsRequired }) {
  const productionCycles = useSelector((state) => state.formOptions.productionCycle);

  useEffect(() => {
    setTaskConfig((preConfig) => ({ ...preConfig, study: studyType, file_type: "secondary_files" }));
    setIsRequired((prevState) => ([...prevState, "table_name", "production_cycle"]));
  }, [setTaskConfig, studyType, setIsRequired]);

  return (
    <Form
      schema={{}}
      onSubmit={submitHandler}
    >
      <Typography variant="h6">
        Morningstar Funds Stage Loader
      </Typography>
      <CSVUpload
        onChange={(update) => {
          setCsv({ attachment: update.csv });
        }}
        value={{ csv, ...taskConfig }}
      />

      <FormControl fullWidth margin="normal">
        <OptionSelect
          required
          style={{ width: 500, marginBottom: 15 }}
          value={taskConfig.table_name || ""}
          onChange={(name) => {
            setTaskConfig((prevTaskConfig) => ({ ...prevTaskConfig, table_name: name, append_replace: taskConfig.append_replace || "" }));
          }}
          options={tableNames.filter((o) => o.study === studyType)}
          label="Table Name"
        />
      </FormControl>

      <FormControl fullWidth variant="outlined">
        <InputLabel required htmlFor="inputLabel-production-cycle-select">Production Cycle</InputLabel>
        <Select
          required
          style={{ width: 500, marginBottom: 30 }}
          value={taskConfig.production_cycle || ""}
          onChange={(e) => {
            setTaskConfig((prevTaskConfig) => ({ ...prevTaskConfig, production_cycle: e.target.value }));
          }}
          label="Production Cycle"
          inputProps={{
            name: "production cycle",
            id: "inputLabel-production-cycle-select",
          }}
        >
          {productionCycles.map((quarter) => (
            <MenuItem key={quarter} value={quarter}>
              {quarter}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {children}
    </Form>
  );
}
