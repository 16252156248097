import React, { useCallback, useEffect, useRef, useState } from "react";
import { Fab, TextField, Typography } from "@material-ui/core";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import PostAddIcon from "@material-ui/icons/PostAdd";
import WarningIcon from "@material-ui/icons/Warning";
import Autocomplete from "@material-ui/lab/Autocomplete";
import OptionSelect from "../../components/input/OptionSelect";
import useTerrClientDescriptions from "../../hooks/useTerrClientDescriptions";
import { API } from "../../utils/api";
import { mapStudyTypeOptions } from "./TerrServiceTerritoryMapsForm";

export default function TerrServiceTerritoryAssignmentForm({ setTaskConfig, setCsv, client, submitHandler, children }) {
  // State
  const [territoryMapVersion, setTerritoryMapVersion] = useState("");
  const [territoryMap, setTerritoryMap] = useState({});
  const [territoryMapOptions, setTerritoryMapOptions] = useState([]);
  const [terrClientDescriptions, setTerrClientDescriptions] = useState({});
  const [fileInput, setFileInput] = useState(undefined);
  const [mapStudyType, setMapStudyType] = useState("");

  // Refs
  const inputRef = useRef();

  const handleFormChange = useCallback(() => {
    setCsv({ infile_s3_path: fileInput });
    setTaskConfig((prevTaskConfig) => ({
      ...prevTaskConfig,
      map_version_id: territoryMapVersion,
    }));
  }, [setCsv, fileInput, setTaskConfig, territoryMapVersion]);

  useTerrClientDescriptions(setTerrClientDescriptions);

  useEffect(() => {
    handleFormChange();
    setTerritoryMapOptions([]); // Reset value
    API.request({ url: `/di/territory/clients/${encodeURIComponent(client)}/maps`, method: "GET" }).then((response) => {
      const data = response.data.filter((d) => d.study === mapStudyType);
      setTerritoryMapOptions(data);
    });
  }, [territoryMapVersion, fileInput, handleFormChange, client, mapStudyType]);

  return (
    <form schema={{}} onSubmit={(e) => { e.preventDefault(); submitHandler(true, e); }} noValidate autoComplete="off" onChange={handleFormChange}>
      <Typography gutterBottom variant="h5" component="h2">
        Territory Assignment
      </Typography>
      <Typography variant="body2" color="textSecondary" component="h3">
        {`Client: ${terrClientDescriptions[client]}`}
      </Typography>
      <br />
      <label htmlFor="dimension-file">
        <input
          required
          style={{ display: "none" }}
          id="dimension-file"
          onChange={(e) => {
            setFileInput(e.target.files[0]);
          }}
          ref={inputRef}
          type="file"
          accept="text/csv,.txt,.xlsx,.xls"
        />
        <Fab
          color={fileInput ? "default" : "primary"}
          size="small"
          component="span"
          aria-label="add"
          variant="extended"
        >
          {fileInput ? <DoneAllIcon /> : <PostAddIcon />}
          {" "}
          {fileInput ? fileInput.name : "dimension_file"}
        </Fab>
        {"  "}
      </label>
      <br />
      <OptionSelect
        required
        style={{ width: "100%", marginBottom: 10 }}
        value={mapStudyType}
        onChange={setMapStudyType}
        options={mapStudyTypeOptions}
        label="Map Study"
      />
      <Autocomplete
        autoComplete="off"
        style={{ width: "100%" }}
        value={territoryMap}
        onChange={(event, newValue) => {
          if (newValue !== null) {
            setTerritoryMapVersion(newValue.published_map_version);
            setTerritoryMap(newValue);
          } else {
            setTerritoryMapVersion("");
            setTerritoryMap({});
          }
        }}
        options={territoryMapOptions}
        autoHighlight
        getOptionLabel={(option) => option.territory_map_name}
        getOptionDisabled={(option) => !option.published_time}
        renderOption={(option) => (
          <>
            <b>{option.territory_map_name}</b>
            {"  "}
            {option.published_time ? <CheckCircleOutlineIcon color="primary" /> : <WarningIcon color="secondary" />}
            {"  "}
            {option.published_time ? `Published: ${option.published_time}` : "Unpublished"}
          </>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Territory Maps"
            variant="outlined"
            inputProps={{
              ...params.inputProps,
              autoComplete: "new-password", // disable autocomplete and autofill from browser
            }}
          />
        )}
      />
      {children}
    </form>
  );
}
