import React, { forwardRef, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  makeStyles,
  Slide,
  Typography,
} from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import Alert from "@material-ui/lab/Alert";

import { API } from "../../../../utils/api";

const useConfirmDialogStyles = makeStyles((theme) => ({
  title: {
    position: "center",
  },
  cancelButton: {
    marginRight: theme.spacing(1),
    color: theme.palette.text.onBackground,
    backgroundColor: theme.palette.primary.light,
  },
  confirmButton: {
    color: theme.palette.text.onBackground,
    backgroundColor: theme.palette.secondary.light,
  },
}));

const Transition = forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

export default function PasswordResetDialog({ open, setOpen, userData, refreshUserFunction }) {
  const classes = useConfirmDialogStyles();

  const [tempPassword, setTempPassword] = useState();

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [passwordCopied, setPasswordCopied] = useState(false);

  const handleClose = () => {
    setError(null);
    if (success) {
      refreshUserFunction();
    }
    setOpen(false);
  };

  function resetPassword() {
    setLoading(true);
    return API.request({ url: `/users/${encodeURIComponent(userData.id)}/resetpassword`, method: "POST" })
      .then((r) => {
        setTempPassword(r.data.temp_password);
        setSuccess("User's password has been reset!");
        setLoading(false);
      }, (e) => { setError(e); setLoading(false); });
  }

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        maxWidth="md"
        fullWidth
      >
        <DialogTitle id="alert-dialog-slide-title" className={classes.title}>
          Confirm
        </DialogTitle>
        {success
          ? (
            <DialogContent dividers>
              <Typography color="textSecondary">
                {"Their new temporary password is: "}
                <b>{tempPassword}</b>
                {passwordCopied
                  ? (
                    <IconButton
                      aria-label="copy"
                      className={classes.margin}
                      size="small"
                      onClick={() => {
                        navigator.clipboard.writeText(tempPassword);
                        setPasswordCopied(true);
                      }}
                    >
                      <CheckIcon />
                    </IconButton>
                  )
                  : (
                    <IconButton
                      aria-label="copy"
                      className={classes.margin}
                      size="small"
                      onClick={() => {
                        navigator.clipboard.writeText(tempPassword);
                        setPasswordCopied(true);
                      }}
                    >
                      <FileCopyIcon />
                    </IconButton>
                  )}
                <br />
                {"Please copy and notify "}
                <b>{userData.email}</b>
                {" of the change."}
                <br />
                You will not be able to see this password anymore after closing this page.
              </Typography>
            </DialogContent>
          )
          : (
            <DialogContent dividers>
              <Typography color="textSecondary">
                {"You are about to "}
                <b>reset</b>
                {" the password for "}
                <b>{userData.email}</b>
                <br />
                {"This will prevent "}
                <b>{userData.first_name}</b>
                {" from logging in with their current password."}
                <br />
                {"A "}
                <b>temporary password</b>
                {" will be displayed on the next screen after confirming."}
                <br />
                Please confirm this action:
              </Typography>
              <br />
            </DialogContent>
          )}
        {loading && <Alert severity="info">Updating Password...</Alert>}
        {error && <Alert severity="error">{`${error}`}</Alert>}
        {success
          ? (
            <div>
              <Alert severity="success">{`${success}`}</Alert>
              <DialogActions>
                <Button onClick={handleClose} className={classes.cancelButton} disabled={loading}>
                  Close
                </Button>
              </DialogActions>
            </div>
          )
          : (
            <DialogActions>
              <Button onClick={handleClose} className={classes.cancelButton} disabled={loading}>
                Cancel
              </Button>
              <Button onClick={resetPassword} className={classes.confirmButton} disabled={loading}>
                Confirm
              </Button>
            </DialogActions>
          )}
      </Dialog>
    </div>
  );
}
