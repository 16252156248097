import React, { useEffect } from "react";
import { Button, Divider, FormControl, FormControlLabel, Grid, Switch, Typography } from "@material-ui/core";
import { nanoid } from "nanoid";

import OptionSelect from "../../../components/input/OptionSelect";
import { studyTypes } from "../../tasks/create_wizard/TaskMapping";
import { roles } from "./Roles";
import TerritoryDropDown from "./TerritoryDropDown";

function modifyResource(array, newResource) {
  const index = array.findIndex(o => o.resource_type === newResource.resource_type && o.resource_value === newResource.resource_value);
  const newArray = array.slice(0);
  newArray[index] = newResource;
  return newArray;
}

function replaceResource(array, oldResource, newResource) {
  const index = array.findIndex(o => o.resource_type === oldResource.resource_type && o.resource_value === oldResource.resource_value);
  const newArray = array.slice(0);
  newArray[index] = newResource;
  return newArray;
}

function deleteResource(array, resource) {
  return array.filter(o => !(o.resource_type === resource.resource_type && o.resource_value === resource.resource_value));
}

export default function ResourcesInputGroup({ clientId, resources, setResources, disabled, userData }) {
  const divisions = resources?.filter(r => r.resource_type === "divisional manager");
  const territories = resources?.filter(r => r.resource_type === "wholesaler");
  const kam = resources?.filter(r => r.resource_type === "key accounts manager");
  const nsm = resources?.filter(r => r.resource_type === "national sales manager");

  const isKeyAccount = kam?.length > 0; // false better than falsy
  const isNationalSales = nsm?.length > 0;

  const resourceStudyTypes = studyTypes.filter(o => !o.internalOnly);
  resourceStudyTypes.push({ id: "ann_opp", label: "ANN Opp Only" });

  useEffect(() => {
    // Ensure that we always have one default across resources no matter what
    if (resources?.length && !resources.some(resource => resource.is_default)) {
      // Locally scoped copies of divisions/territories/kam to prevent infinite loop from setResources
      const divisionsLocal = resources?.filter(r => r.resource_type === "divisional manager");
      const territoriesLocal = resources?.filter(r => r.resource_type === "wholesaler");
      const kamLocal = resources?.filter(r => r.resource_type === "key accounts manager");
      const nsmLocal = resources?.filter(r => r.resource_type === "national sales manager");

      const kamRoles = roles.filter(r => r.territoriesKey === "kam").map(o => o.value);
      const dsmRoles = roles.filter(r => r.territoriesKey === "divisions").map(o => o.value);
      const wholesalerRoles = roles.filter(r => r.territoriesKey === "wholesalers").map(o => o.value);
      const nsmRoles = roles.filter(r => r.territoriesKey === "nsm").map(o => o.value);

      let newResource = { ...resources[0], is_default: true };
      if (kamRoles.includes(userData?.role) && kamLocal?.length > 0) {
        newResource = { ...kamLocal[0], is_default: true };
      } else if (dsmRoles.includes(userData?.role) && divisionsLocal?.length > 0) {
        newResource = { ...divisionsLocal[0], is_default: true };
      } else if (wholesalerRoles.includes(userData?.role) && territoriesLocal?.length > 0) {
        newResource = { ...territoriesLocal[0], is_default: true };
      } else if (nsmRoles.includes(userData?.role) && nsmLocal?.length > 0) {
        newResource = { ...nsmLocal[0], is_default: true };
      }
      setResources(prevResources => modifyResource(prevResources, newResource));
    }
  }, [resources, setResources, disabled, userData]);

  return (
    <>
      <Grid item xs={12}>
        <Typography variant="h6" gutterBottom>
          Resources
        </Typography>
        <Divider />
      </Grid>
      <Grid container direction="column">
        <Typography variant="h6" gutterBottom>
          Key Account Manager
        </Typography>
        <Grid container style={{ alignItems: "center" }}>
          <FormControlLabel
            control={(
              <Switch
                disabled={disabled}
                checked={isKeyAccount}
                onChange={() => {
                  if (isKeyAccount) {
                    setResources(prevResources => deleteResource(prevResources, kam[0]));
                  } else {
                    setResources(prevResources => prevResources.map(resource => ({ ...resource, is_default: false })).concat({ study: "", resource_type: "key accounts manager", resource_value: "Key Accounts Manager", is_default: true }));
                  }
                }}
                name="KeyAccount"
                color="primary"
              />
            )}
            label={`Is ${isKeyAccount ? "" : "Not"} Key Account Manager`}
          />
          {isKeyAccount && (
            <OptionSelect
              value={kam[0]?.study || ""}
              onChange={(v) => setResources(prevResources => modifyResource(prevResources, { ...kam[0], study: v }))}
              options={resourceStudyTypes}
              label="Study"
              disabled={disabled}
              style={{ width: 200 }}
            />
          )}
        </Grid>
      </Grid>
      <Grid container direction="column">
        <Typography variant="h6" gutterBottom>
          National Sales Manager
        </Typography>
        <Grid container style={{ alignItems: "center" }}>
          <FormControlLabel
            control={(
              <Switch
                disabled={disabled}
                checked={isNationalSales}
                onChange={() => {
                  if (isNationalSales) {
                    setResources(prevResources => deleteResource(prevResources, nsm[0]));
                  } else {
                    setResources(prevResources => prevResources.map(resource => ({ ...resource, is_default: false })).concat({ study: "", resource_type: "national sales manager", resource_value: "National Sales Manager", is_default: true }));
                  }
                }}
                name="NationalSales"
                color="primary"
              />
            )}
            label={`Is ${isNationalSales ? "" : "Not"} National Sales Manager`}
          />
          {isNationalSales && (
            <OptionSelect
              value={nsm[0]?.study || ""}
              onChange={(v) => setResources(prevResources => modifyResource(prevResources, { ...nsm[0], study: v }))}
              options={resourceStudyTypes}
              label="Study"
              disabled={disabled}
              style={{ width: 200 }}
            />
          )}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h6" gutterBottom>
          Divisions
        </Typography>
      </Grid>
      <Grid container direction="column">
        {divisions?.map((field) => (
          <Grid key={nanoid()} container direction="row" style={{ justifyContent: "space-around", alignItems: "center" }}>
            <Grid item xs={2}>
              {field.is_default
                ? (
                  <Button
                    disabled
                    variant="outlined"
                    color="primary"
                    onClick={() => setResources(prevResources => modifyResource(prevResources, { ...field, is_default: false }))}
                  >
                    Is default
                  </Button>
                )
                : (
                  <Button
                    disabled={disabled || isKeyAccount}
                    variant="outlined"
                    color="secondary"
                    onClick={() => {
                      setResources(prevResources => prevResources.map(resource => ({ ...resource, is_default: false })));
                      setResources(prevResources => modifyResource(prevResources, { ...field, is_default: true }));
                    }}
                  >
                    Set as default
                  </Button>
                )}
            </Grid>
            <Grid item xs={3}>
              <FormControl margin="normal" fullWidth key={[field]}>
                <OptionSelect
                  value={field.study}
                  onChange={(v) => setResources(prevResources => modifyResource(prevResources, { ...field, study: v }))}
                  options={resourceStudyTypes}
                  label="Study"
                  disabled={disabled}
                />
              </FormControl>
            </Grid>
            <Grid item xs={3}>
              <FormControl margin="normal" fullWidth>
                <TerritoryDropDown
                  multiple={false}
                  study={field.study}
                  clientId={clientId}
                  userRole="Divisional Manager"
                  value={field.resource_value}
                  onChange={(v, terrData) => {
                    // Picking a division also sets the territories for that division
                    setResources(prevResources => replaceResource(prevResources, field, { ...field, resource_value: v }));
                    terrData[v].forEach(terr => {
                      setResources(prevResources => prevResources.concat({ resource_type: "wholesaler", resource_value: terr, study: field.study }));
                    });
                  }}
                  disabled={disabled}
                />
              </FormControl>
            </Grid>
            <Grid item xs={2}>
              <Button
                disabled={disabled}
                variant="outlined"
                color="primary"
                onClick={() => { setResources(prevResources => deleteResource(prevResources, field)); }}
              >
                Remove
              </Button>
            </Grid>
          </Grid>
        ))}
      </Grid>
      <Grid container direction="row" style={{ justifyContent: "space-around", alignItems: "center" }}>
        <Button
          disabled={disabled}
          variant="outlined"
          color="primary"
          onClick={
            () => {
              setResources(prevResources => prevResources.concat({ resource_type: "divisional manager", resource_value: "", study: "" }));
            }
          }
          style={{ width: "20%" }}
        >
          Add
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h6" gutterBottom>
          Territories
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid container direction="column">
          {territories?.map((field) => (
            <Grid key={nanoid()} container direction="row" style={{ justifyContent: "space-around", alignItems: "center" }}>
              <Grid item xs={2}>
                {field.is_default
                  ? (
                    <Button
                      disabled
                      variant="outlined"
                      color="primary"
                    >
                      Is default
                    </Button>
                  )
                  : (
                    <Button
                      disabled={disabled || isKeyAccount}
                      variant="outlined"
                      color="secondary"
                      onClick={() => {
                        setResources(prevResources => prevResources.map(resource => ({ ...resource, is_default: false })));
                        setResources(prevResources => modifyResource(prevResources, { ...field, is_default: true }));
                      }}
                    >
                      Set as default
                    </Button>
                  )}
              </Grid>
              <Grid item xs={3}>
                <FormControl margin="normal" fullWidth key={[field]}>
                  <OptionSelect
                    value={field.study}
                    onChange={(v) => setResources(prevResources => modifyResource(prevResources, { ...field, study: v }))}
                    options={resourceStudyTypes}
                    label="Study"
                    disabled={disabled}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <FormControl margin="normal" fullWidth>
                  <TerritoryDropDown
                    multiple={false}
                    study={field.study}
                    clientId={clientId}
                    userRole="Internal Wholesaler"
                    value={field.resource_value}
                    onChange={(v) => setResources(prevResources => replaceResource(prevResources, field, { ...field, resource_value: v }))}
                    disabled={disabled}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={2}>
                <Button
                  disabled={disabled}
                  variant="outlined"
                  color="primary"
                  onClick={() => { setResources(prevResources => deleteResource(prevResources, field)); }}
                >
                  Remove
                </Button>
              </Grid>
            </Grid>
          ))}
          <Grid container direction="row" style={{ justifyContent: "space-around", alignItems: "center" }}>
            <Button disabled={disabled} style={{ width: "20%" }} variant="outlined" color="primary" onClick={() => setResources(prevResources => prevResources.concat({ resource_type: "wholesaler", resource_value: "", study: "" }))}>
              Add
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
