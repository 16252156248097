import React, { useEffect } from "react";
import { Checkbox, FormControl, FormControlLabel } from "@material-ui/core";
import Form from "@rjsf/material-ui";
import OptionSelect from "../../components/input/OptionSelect";

const syncTypes = [
  { id: "platform_sync", label: "SIS Full Sync" },
  { id: "office_map_sync", label: "SIS Office Map Sync" },
];

export default function SISSyncForm({ children, taskConfig, setTaskConfig, submitHandler, studyType, setIsRequired }) {
  useEffect(() => {
    setTaskConfig((prevTaskConfig) => ({
      ...prevTaskConfig,
      create_ids: taskConfig.create_ids ?? false,
      study: studyType,
    }));
    setIsRequired((prevState) => ([...prevState, "sync"]));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setTaskConfig, studyType, setIsRequired]);

  return (
    <Form
      schema={{}}
      onSubmit={submitHandler}
    >
      <OptionSelect
        required
        style={{ width: 500, marginBottom: -10 }}
        value={taskConfig.sync || ""}
        onChange={(sync) => {
          setTaskConfig((prevTaskConfig) => ({ ...prevTaskConfig, sync }));
        }}
        options={syncTypes}
        label="Select Sync"
      />
      <FormControl component="fieldset" fullWidth margin="normal">
        <FormControlLabel
          control={<Checkbox checked={taskConfig.create_ids} />}
          label="Create IDs"
          onChange={(e) => setTaskConfig((prevTaskConfig) => ({ ...prevTaskConfig, create_ids: e.target.checked }))}
        />
      </FormControl>
      {children}
    </Form>
  );
}
