import React from "react";
import MaterialTable from "material-table";
import useSWR from "swr";
import { getDataRevisions } from "../../../utils/api";

export default function DataRevisions() {
  const { data, error } = useSWR("/datarevisions", getDataRevisions);

  if (error) return <div>Error loading...</div>;

  return (
    <div style={{ width: "100%" }}>
      <MaterialTable
        isLoading={!data}
        columns={[
          { title: "Revision", field: "revision" },
          { title: "Notes", field: "notes" },
          { title: "Created At", field: "created_at", type: "datetime" },
          { title: "Created By", field: "created_by" },
        ]}
        data={data}
        title="Data Revisions"
      />
    </div>
  );
}
