import React, { useEffect } from "react";
import { Chip, Typography } from "@material-ui/core";
import Form from "@rjsf/material-ui";

export default function ClientMaterializedViewFormDD({ children, setTaskConfig, client, submitHandler, studyType, displayHeader }) {
  useEffect(() => {
    setTaskConfig((preConfig) => ({ ...preConfig, study: studyType }));
  }, [setTaskConfig, studyType]);

  return (
    <Form
      schema={{}}
      onSubmit={submitHandler}
    >
      <Typography variant="h4" style={{ backgroundColor: "orange" }}>Discovery Data</Typography>
      <div style={{ display: "flex", alignItems: "center" }}>
        <Typography variant="subtitle2" style={{ marginRight: "8px", marginBottom: "16px" }}>Task Name :</Typography>
        <Chip style={{ marginBottom: "16px" }} variant="outlined" label={displayHeader(["file_type"])} />
      </div>
      <Typography variant="h6">Client Materialized Table View</Typography>
      <Typography variant="subtitle1" style={{ margin: 15 }}>
        {"This job will create or update the "}
        <b>{`${studyType}`}</b>
        {" materialized view with territories for "}
        <b>{`${client}`}</b>
        <br />
        This table view is used to display data for that client in the MISight app.
      </Typography>
      {children}
    </Form>
  );
}
