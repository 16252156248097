import React, { useEffect, useState } from "react";
import { Checkbox, FormControl, FormControlLabel, TextField } from "@material-ui/core";
import Form from "@rjsf/material-ui";
import OptionSelect from "../../components/input/OptionSelect";

const syncTypes = [
  { id: "platform_sync", label: "DD Firm Office Sync" },
  { id: "office_map_sync", label: "DD Office Map Sync" },
  { id: "manual_review_sync", label: "DD Manual Review Sync" },
  { id: "firm_only_sync", label: "DD Firm Only Sync" },
];

export default function DDSyncForm({ children, taskConfig, setTaskConfig, submitHandler, studyType, setIsRequired }) {
  const [syncSpecificIds, setSyncSpecificIds] = useState(false);
  const [firmOfficeIds, setFirmOfficeIds] = useState("");
  useEffect(() => {
    setTaskConfig((prevTaskConfig) => ({ ...prevTaskConfig, study: studyType }));
    setIsRequired((prevState) => ([...prevState, "sync"]));
  }, [setTaskConfig, studyType, setIsRequired]);

  return (
    <Form
      schema={{}}
      onSubmit={submitHandler}
    >
      <FormControl fullWidth margin="normal">
        <OptionSelect
          required
          style={{ width: 500 }}
          value={taskConfig.sync || ""}
          onChange={(sync) => {
            if (sync !== "platform_sync" && "firm_office_ids" in taskConfig) {
              setSyncSpecificIds(false);
              setFirmOfficeIds("");
              /* eslint-disable no-param-reassign */
              delete taskConfig.firm_office_ids;
              /* eslint-disable no-param-reassign */
            }
            setTaskConfig((prevTaskConfig) => ({ ...prevTaskConfig, sync }));
          }}
          options={syncTypes}
          label="Select Sync"
        />
        <br />
        {
          taskConfig.sync === "platform_sync"
            ? (
              <FormControlLabel
                control={
                  (
                    <Checkbox
                      checked={syncSpecificIds}
                      onChange={(e) => {
                        setSyncSpecificIds(e.target.checked);
                        if (!e.target.checked) {
                          setFirmOfficeIds("");
                          /* eslint-disable no-param-reassign */
                          delete taskConfig.firm_office_ids;
                          /* eslint-disable no-param-reassign */
                        }
                      }}
                      inputProps={{ "aria-label": "primary checkbox" }}
                    />
                  )
                }
                label="Sync Specific IDs Only"
              />
            )
            : null
        }
        <br />
        {
          syncSpecificIds && taskConfig.sync === "platform_sync"
            ? (
              <TextField
                multiline
                label="Firm Office IDs (comma separated)"
                value={firmOfficeIds}
                onChange={(e) => {
                  setFirmOfficeIds(e.target.value);
                  setTaskConfig((prevTaskConfig) => ({ ...prevTaskConfig, firm_office_ids: e.target.value.split(",").map(o => parseInt(o, 10)) }));
                }}
                required
                error={firmOfficeIds.length > 0 && !firmOfficeIds.split(",").every(o => !Number.isNaN(parseInt(o, 10)))}
                helperText={firmOfficeIds.length > 0 && !firmOfficeIds.split(",").every(o => !Number.isNaN(parseInt(o, 10))) ? "Please enter comma separated numbers" : ""}
              />
            )
            : null
        }
      </FormControl>
      {children}
    </Form>
  );
}
