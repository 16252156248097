import React, { useCallback, useState } from "react";
import { AppBar, Box, IconButton, Tab, Tabs, Typography, makeStyles } from "@material-ui/core";
import { NavigateNext as NavigateNextIcon, NavigateBefore as NavigateBeforeIcon } from "@material-ui/icons";
import clsx from "clsx";

import TabPanel, { a11yProps } from "../../../../components/TabPanel";
import SearchBar from "../../../../components/input/SearchBar";
import usePageTitle from "../../../../hooks/usePageTitle";
import MisightProfileList from "./MisightProfileList";

const useStyles = makeStyles((theme) => ({
  appTopBar: {
    backgroundColor: theme.palette.background.nav,
    zIndex: theme.zIndex.drawer - 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: 50,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
}));
export default function MisightClientProfileGroupingConfig({ drawerOpen }) {
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const [search, setSearch] = useState("");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleSearch = useCallback((val) => {
    setSearch(val);
  }, []);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(1);
  usePageTitle("Misight Profiles");

  return (
    <Box sx={{ width: "100%" }}>
      <AppBar
        component="div"
        position="fixed"
        indicatorColor="primary"
        className={clsx(classes.appTopBar, {
          [classes.appBarShift]: drawerOpen,
        })}
        style={{
          width: "unset",
          marginTop: "64px",
          marginLeft: drawerOpen ? 180 : 50,
          left: 0,
          borderTop: "20px solid white",
          borderLeft: "50px solid white",
          borderRight: drawerOpen ? "0px solid white" : "none",
          backgroundColor: "white",
        }}
      >
        <Box
          display="flex"
          justifyContent="space-between"
        >
          <Box sx={{ borderBottom: 1, borderColor: "divider", backgroundColor: "green" }} style={{ marginBottom: 30 }}>
            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
              <Tab label="ANN" {...a11yProps(0)} style={{ color: "black", border: "1px solid black" }} />
              <Tab label="MF" {...a11yProps(1)} style={{ color: "black", border: "1px solid black" }} />
            </Tabs>
          </Box>
          <SearchBar
            onChange={handleSearch}
            value={search}
            name="misight-profile-list"
            formControlProps={{ style: { width: "50%" } }}
          />
          <Box
            display="flex"
            style={{ marginRight: 10 }}
          >
            <IconButton onClick={() => setPage(page - 1)} disabled={page === 0} style={{ marginBottom: 15 }}>
              <NavigateBeforeIcon />
            </IconButton>
            <Typography color="primary" style={{ marginTop: 20 }}>
              {`Page ${page + 1} of ${Math.ceil(count / 8)}`}
            </Typography>
            <IconButton onClick={() => setPage(page + 1)} disabled={page >= Math.ceil(count / 8) - 1} style={{ marginBottom: 15 }}>
              <NavigateNextIcon />
            </IconButton>

          </Box>
        </Box>

      </AppBar>
      <div style={{ marginTop: 80 }}>
        <TabPanel value={value} index={0}>
          <MisightProfileList study="ann" search={search} page={page} setCount={setCount} setPage={setPage} />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <MisightProfileList study="mf" search={search} page={page} setCount={setCount} setPage={setPage} />
        </TabPanel>

      </div>
    </Box>
  );
}
