import React, { useMemo, useState } from "react";
import { Box, Button, Card, CardContent, Tab, Tabs } from "@material-ui/core";

import HeaderTransformLogs from "./HeaderTransformLogs";

export default function TransformLog() {
  const [selectedTab, setSelectedTab] = useState("Header Transfrom");
  const [resetFilter, setResetFilter] = useState(false);
  const tabContents = useMemo(() => {
    if (selectedTab === "Header Transfrom") {
      return <HeaderTransformLogs resetFilter={resetFilter} setResetFilter={setResetFilter} required={false} api="/lms/api/v1/transform/header_mapping_change_logs" />;
    }
    return <HeaderTransformLogs resetFilter={resetFilter} setResetFilter={setResetFilter} required api="/lms/api/v1/transform/is_required_change_logs" />;
  }, [selectedTab, resetFilter]);

  return (
    <Card>
      <CardContent>
        <Box style={{ width: "100%" }}>
          <Tabs
            value={selectedTab}
            onChange={(e, tabIndex) => {
              setSelectedTab(tabIndex);
            }}
            textColor="secondary"
            indicatorColor="secondary"
            aria-label="secondary tabs example"
          >
            <Tab
              value="Header Transfrom"
              label="History"
              iconPosition="end"
            />
            <Tab
              value="required"
              label="Is required History"
              iconPosition="end"
            />
            <Button style={{ top: 0, right: 0, position: "absolute" }} color="primary" variant="contained" onClick={() => setResetFilter(true)}>Reset Filters</Button>
          </Tabs>
        </Box>
        <Box style={{ width: "100%", marginLeft: 10 }}>{tabContents}</Box>
      </CardContent>
    </Card>
  );
}
