import React, { useState } from "react";
import { Grid } from "@material-ui/core";

import _ from "lodash";
import useSWR from "swr";
import OptionSelect from "../../../components/input/OptionSelect";
import usePageTitle from "../../../hooks/usePageTitle";
import { fetcher, customRetry } from "../../../utils/swr";
import GpsR2Distribution from "./GpsR2Distribution";
import GpsR2Trend from "./GpsR2Trend";
import GpsStats from "./GpsStats";

export default function GrowthPotentialTab() {
  const { data: RawGpScoreMetrics } = useSWR("lms/api/v1/ann/gpscoremetrics/ann_gpscoremetrics", fetcher, customRetry);
  const [client, setClient] = useState("*");
  const [product, setProduct] = useState("*");

  usePageTitle("LMS - ANN: Growth Potential");

  // Create a list of all clients
  const allClients = _.chain(RawGpScoreMetrics).map("client").uniq().value()
    .map(c => ({ label: c, id: c }));
  allClients.unshift({ label: "All", id: "*" });

  // Create a list of all Products
  const allProducts = _.chain(RawGpScoreMetrics).map("prod_type").uniq().value()
    .map(c => ({ label: c, id: c }));
  allProducts.unshift({ label: "All", id: "*" });

  // Cleanup the data
  let GpScoreMetrics = _.sortBy(RawGpScoreMetrics, ["run_on"])?.map(r => {
    const cleaned = { ...r };

    const timestamp = (new Date(cleaned.run_on));
    timestamp.setMinutes(0, 0, 0);

    cleaned.timestamp = timestamp.valueOf();

    cleaned.factor_wt_norm_client_business_size = cleaned.factor_wt_norm_client_business_size.toFixed(2);
    cleaned.factor_wt_norm_opp_size = cleaned.factor_wt_norm_opp_size.toFixed(2);
    cleaned.factor_wt_norm_opp_size_growth = cleaned.factor_wt_norm_opp_size_growth.toFixed(2);
    cleaned.factor_wt_norm_ticket_size = cleaned.factor_wt_norm_ticket_size.toFixed(2);
    cleaned.factor_wt_prod_growth = cleaned.factor_wt_prod_growth.toFixed(2);

    cleaned.r2 = Math.max((cleaned.r2 * 1e2) / 1e2, -1);
    return cleaned;
  });

  if (client !== "*") {
    GpScoreMetrics = GpScoreMetrics?.filter(r => r.client === client);
  }

  if (product !== "*") {
    GpScoreMetrics = GpScoreMetrics?.filter(r => r.prod_type === product);
  }

  const aggregatedStats = _.chain(GpScoreMetrics)
    .groupBy("timestamp")
    .map((objs) => ({
      ...objs[0],
      r2: null,
      mean_r2: Math.round(_.meanBy(objs, "r2") * 1e2) / 1e2,
      max_r2: _.maxBy(objs, "r2").r2,
      min_r2: _.minBy(objs, "r2").r2,

    }))
    .value();

  const tooltipMap = {};
  _.forEach(aggregatedStats, (v) => {
    let tooltip = "";
    tooltip += `<br/><b>Run On</b>: ${v.run_on}<br/>`;
    tooltip += `<b>Training Quarters</b>: ${v.training_quarters}<br/>`;
    tooltip += `<b>Prediction Quarters</b>: ${v.prediction_quarters}<br/>`;
    tooltip += `<b>Train Samples</b>: ${v.train_samples}<br/>`;
    tooltip += `<b>Prediction Samples</b>: ${v.prediction_samples}<br/>`;
    tooltip += `<b>Learning Rate</b>: ${v.learning_rate}<br/>`;
    tooltip += `<b>Max Depth</b>: ${v.max_depth}<br/>`;
    tooltip += `<b>Num Estimators</b>: ${v.n_estimators}<br/>`;
    tooltip += `<b>Num Quarters in Window</b>: ${v.num_qrt_in_window}<br/>`;
    tooltip += `<b>Num Windows to Train On</b>: ${v.num_windows_to_train_on}<br/>`;
    tooltip += `<b>Outlier Mads</b>: ${v.outlier_mads}<br/>`;
    tooltipMap[v.timestamp] = tooltip;
  });

  return (
    <Grid container>
      <Grid item xs={4}>
        <OptionSelect
          label="Client"
          value={client}
          style={{ width: "100%", marginLeft: "10px" }}
          onChange={(c) => setClient(c)}
          options={allClients}
        />
      </Grid>
      <Grid item xs={4}>
        <OptionSelect
          label="Products"
          value={product}
          style={{ width: "100%", marginLeft: "50px" }}
          onChange={(c) => setProduct(c)}
          options={allProducts}
        />
      </Grid>
      <GpsStats clients={allClients} products={allProducts} data={GpScoreMetrics} />
      <GpsR2Trend data={aggregatedStats} tooltipMap={tooltipMap} />
      <GpsR2Distribution data={GpScoreMetrics} tooltipMap={tooltipMap} />

    </Grid>
  );
}
