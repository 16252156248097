import React, { useEffect, useState } from "react";
import { Divider, IconButton, makeStyles, Menu, Typography } from "@material-ui/core";

import MoreVertIcon from "@material-ui/icons/MoreVert";
import Skeleton from "@material-ui/lab/Skeleton";
import { API } from "../../../utils/api";
import TaskClone from "./TaskClone";
import TaskDownloads from "./TaskDownloads";

const useStyles = makeStyles((theme) => ({
  menuIcon: {
    color: theme.palette.text.link,
  },
}));

export default function QuickActionsPopup({ rowData }) {
  const classes = useStyles();

  const [taskAttachments, setTaskAttachments] = useState([]);
  const [taskData, setTaskData] = useState({});
  const [loading, setLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    let isMounted = true;
    if (anchorEl) {
      setLoading(true);
      const url = `/tasks/${rowData.taskId}`;
      API.request({ url, method: "GET" })
        .then((response) => {
          if (!isMounted) return;
          setTaskData(response.data.task);
          setTaskAttachments(response.data.attachments);
          setLoading(false);
        }, (error) => (console.log(error)));
    }
    return () => { isMounted = false; };
  }, [rowData, anchorEl]);

  return (
    <div>
      <IconButton
        aria-controls="task-row-menu"
        aria-haspopup="true"
        className={classes.menuIcon}
        onClick={(event) => { setAnchorEl(event.currentTarget); }}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="task-row-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => { setAnchorEl(null); }}
      >
        <Typography variant="caption" display="block" align="center">Quick Actions</Typography>
        <Divider />
        {!loading && Boolean(anchorEl)
          ? (
            <div>
              <TaskClone taskId={rowData.taskId} taskData={taskData} />
              <Divider />
              <TaskDownloads taskId={rowData.taskId} attachments={taskAttachments} display="list" />
            </div>
          )
          : (
            <div style={{ width: 200 }}>
              <Skeleton style={{ margin: "0.5em" }} />
              <Divider />
              <Skeleton style={{ margin: "0.5em" }} />
            </div>
          )}
      </Menu>
    </div>
  );
}
