import React, { useEffect, useState } from "react";
import { TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { nanoid } from "nanoid";
import useSWR from "swr";

import CircularProgress from "../../../components/Spinner";
import { API } from "../../../utils/api";
import { roles } from "./Roles";
import TerritoryChip from "./TerritoryChip";

const fetcher = url => API.request({ url, method: "GET" }).then(res => res.data);

export default function TerritoryDropDown({ multiple, study, clientId, userRole, value, onChange, disabled, label }) {
  const [territoryData, setTerritoryData] = useState([]);
  const [territoryOptions, setTerritoryOptions] = useState([]);
  const [defaultTerrIndex, setDefaultTerrIndex] = useState(0);

  const territoriesKey = roles.find(r => r.value === userRole)?.territoriesKey;

  // SWR is awesome here since we need to render 100s of those and don't want to constantly pull data
  const { data, error } = useSWR(`/territories/${study}/${clientId}/${territoriesKey}`, fetcher);
  const isLoading = !error && !data;

  useEffect(() => {
    if (!data) return;

    if (territoriesKey === "wholesalers") {
      setTerritoryOptions(data[territoriesKey]);
    } else {
      setTerritoryData(data[territoriesKey]);
      setTerritoryOptions(Object.keys(data[territoriesKey]));
    }
  }, [data, territoriesKey]);

  function handleChipClick(index) {
    setDefaultTerrIndex(index);
  }

  if (!study || !clientId || !territoriesKey) {
    return null;
  }

  return (
    <Autocomplete
      multiple={multiple}
      id={nanoid()}
      options={territoryOptions}
      renderInput={(params) => (
        <TextField
          fullWidth
          {...params}
          label={label}
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
      renderTags={(tagValue, getTagProps) => tagValue.map((option, index) => (
        <TerritoryChip
          label={option}
          index={index}
          getTagProps={getTagProps}
          defaultTerr={defaultTerrIndex}
          handleChipClick={handleChipClick}
        />
      ))}
      value={value}
      onChange={(e, selection) => { onChange(selection, territoryData, defaultTerrIndex); }}
      disabled={!territoriesKey || disabled}
      loading={isLoading}
    />
  );
}
