/* eslint-disable react/no-unstable-nested-components */

import React, { useEffect, useState } from "react";
import { Button, Card, CardContent, InputAdornment, TextField, useTheme } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";

// For fuzzy matching
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import { matchSorter } from "match-sorter";
// For highlighting search
import MaterialTable from "material-table";
import moment from "moment-timezone";
import { nanoid } from "nanoid";
import { Link } from "react-router-dom";

import tableIcons from "../../../../components/MaterialTableIcons";
import TableLoadingOverlay from "../../../../components/MaterialTableLoadingOverlay";
import CircularProgress from "../../../../components/Spinner";
import UserStatusChip from "../UserStatusChip";
import ActivationDialog from "./ActivationDialog";

function getUniqueValues(data, key) {
  const uniqueValues = [...new Set(data?.map(item => item[key]))];
  const lookups = {};
  uniqueValues.forEach(item => {
    lookups[item] = item;
  });
  return lookups;
}

export default function UsersTable({ users, refreshUsers, loading, title }) {
  const theme = useTheme();

  // Search value
  const [searchInput, setSearchInput] = useState("");

  // Filtered down list based on search input
  const [filteredUsersList, setFilteredUsersList] = useState([]);

  // Users Table state
  const [selectedRows, setSelectedRows] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);

  // --- Filtering and Pagination
  useEffect(() => {
    if (users?.length) {
      const filteredList = matchSorter(users, searchInput, { keys: ["name", "email"] });
      setFilteredUsersList(filteredList);
    }
  }, [users, searchInput]);

  const columns = [
    {
      title: "Name",
      field: "name",
      filtering: false,
      render: rowData => (
        <Button variant="outlined" component={Link} target="_blank" to={`/user/${rowData.id}`}>
          {
            parse(rowData.name, match(rowData.name, searchInput)).map((part) => (
              <span key={nanoid()} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                {part.text}
              </span>
            ))
          }
        </Button>
      ),
    },
    {
      title: "Email",
      field: "email",
      filtering: false,
      render: rowData => (
        <p>
          {parse(rowData.email, match(rowData.email, searchInput)).map((part) => (
            <span key={nanoid()} style={{ fontWeight: part.highlight ? 700 : 400 }}>
              {part.text}
            </span>
          ))}
        </p>
      ),
    },
    { title: "Client", field: "client", lookup: getUniqueValues(users, "client") },
    { title: "Status", field: "status", lookup: getUniqueValues(users, "status"), render: rowData => <UserStatusChip status={rowData.status} /> },
    { title: "Last Login", field: "last_login", filtering: false, render: rowData => <p>{rowData.last_login ? moment(rowData.last_login).format("MMMM Do YYYY, h:mm:ss a") : <span style={{ color: theme.palette.error.main }}>Never logged in</span>}</p> },
  ];

  const searchLabel = users?.length ? `Search Users: ${users?.length} Available` : "Search Users: No Users Available";

  return (
    <>
      <Card>
        <CardContent>
          <TextField
            id="user-search"
            label={searchLabel}
            type="search"
            variant="outlined"
            onChange={(e) => setSearchInput(e.target.value)}
            style={{ width: "100%", height: "50" }}
            InputProps={{
              startAdornment: <InputAdornment position="start">{loading ? <CircularProgress color="inherit" size={20} /> : <SearchIcon />}</InputAdornment>,
            }}
            autoFocus={!loading}
          />
        </CardContent>
      </Card>
      <MaterialTable
        style={{ marginTop: "1.5em" }}
        title={title}
        columns={columns}
        data={filteredUsersList.map(rowData => ({
          ...rowData,
          tableData: { ...rowData.tableData, disabled: rowData.status === "ACTIVE" },
        }))}
        icons={tableIcons}
        isLoading={loading}
        options={{
          pageSize: 20,
          pageSizeOptions: [20],
          headerStyle: {
            backgroundColor: theme.palette.background.nav,
            color: theme.palette.text.shaded,
          },
          toolbar: true,
          search: false,
          filtering: true,
          selection: true,
          selectionProps: rowData => ({ disabled: rowData.tableData.disabled }),
          exportButton: true,
          exportAllData: true,
        }}
        actions={[
          { icon: "add",
            onClick: (e, data) => {
              setDialogOpen(true);
              setSelectedRows(data);
            } },
        ]}
        components={{
          OverlayLoading: () => (<TableLoadingOverlay />),
          //          Toolbar: () => (TableHeader),
          Action:
            ({ action, data }) => {
              // This seems to be the only way to override the icon to a button unfortunately
              if (action.icon === "add") {
                return (
                  <Button
                    color="primary"
                    variant="outlined"
                    onClick={(event) => action.onClick(event, data)}
                  >
                    Activate Users
                  </Button>
                );
              }
              return action.icon;
            },
        }}
      />
      <ActivationDialog
        open={dialogOpen}
        setOpen={setDialogOpen}
        users={selectedRows}
        refreshUsersFunction={refreshUsers}
      />
    </>
  );
}
