import React from "react";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import { taskTypes } from "../create_wizard/TaskMapping";

export default function DetailsColumn({ rowData }) {
  const taskMatch = taskTypes.find(task => task.id === rowData.taskType && task.label === rowData.title);
  const study = taskMatch?.study || "MISIGHT";
  const annMfStudy = "ANN-MF";

  const pattern = new RegExp(`\\b(${annMfStudy}|${study})\\b`, "gi");
  const title = rowData?.title?.replace(pattern, "");

  return (
    <Button
      variant="outlined"
      component={Link}
      to={rowData.status === "DRAFT" ? `/edit/${rowData.taskId}` : `/details/${rowData.taskId}`}
      style={{ width: 400, textAlign: "center" }}
    >
      {title}
    </Button>
  );
}
