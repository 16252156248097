import React, { useEffect, useState } from "react";
import { Button } from "@material-ui/core";
import MaterialTable from "material-table";
import { Link } from "react-router-dom";
import useSWR from "swr";
import CircularProgress from "../../components/Spinner";
import { getTransformStudyFileType } from "../../utils/api";

export function CommonHeaderMapping() {
  const [data, setData] = useState([]);
  const { data: studyMapping, error: studyFileTypeError } = useSWR("/lms/api/v1/transform/study_file_type", getTransformStudyFileType);

  useEffect(() => {
    if (studyMapping) {
      studyMapping.sort((a, b) => (a.study > b.study ? 1 : -1));
      setData(studyMapping);
    }
  }, [studyMapping]);
  if (studyFileTypeError) return <div>Error loading study with file types...</div>;
  const columns = [
    { title: "Study", field: "study" },
    { title: "File Type", field: "file_type" },
    {
      title: "Link to Header Mapping",
      render: rowData => (
        <Button
          variant="outlined"
          component={Link}
          to={`/preprocessing-transform/common-header-mapping/studyFileType/${rowData.study}:${rowData.file_type}/id/${rowData.id}`}
          style={{ minWidth: 400 }}
        >
          {`${rowData.study}:${rowData.file_type}`}
        </Button>
      ),
    },
  ];

  return (
    <div>
      {data.length > 0
        ? (
          <MaterialTable
            style={{ alignItems: "center" }}
            columns={columns}
            data={data}
            title="Common Header Mapping Study-File"
            options={{
              paging: true,
              pageSize: 5,
              pageSizeOptions: [],
              emptyRowsWhenPaging: false,
              headerStyle: {
                backgroundColor: "#30455c",
                fontSize: "small",
                alignContent: "center",
              },
            }}
          />
        )
        : (
          <div style={{
            display: "flex", alignItems: "center", justifyContent: "center",
          }}
          >
            <CircularProgress />
          </div>
        )}

    </div>
  );
}
