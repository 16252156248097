import React from "react";
import { Chip, Typography } from "@material-ui/core";
import useFormData from "../../hooks/useFormData";
import BaseForm from "./common/BaseForm";

export default function AnnInternalTerrConflictFromMapForm({ children, setTaskConfig, submitHandler, formDataProp, displayHeader }) {
  const [formData, setFormData] = useFormData(setTaskConfig, formDataProp);

  const annInternalTerrConflictFromMapSchema = {
    title: "ANN Territory Conflicts - Territory Map",
    description: "",
    type: "object",
    required: [],
    properties: {
      ann_type: {
        type: "string",
        title: "Annuity Type",
        enum: ["va", "fia", "all"],
        enumNames: ["VA", "FIA", "ALL"],
        default: "va",
      },
    },
  };

  return (
    <div>
      <div style={{ display: "flex", alignItems: "center" }}>
        <Typography variant="subtitle2" style={{ marginRight: "8px", marginBottom: "16px" }}>Task Name :</Typography>
        <Chip style={{ marginBottom: "16px" }} variant="outlined" label={displayHeader(["ann_type"])} />
      </div>
      <BaseForm
        schema={annInternalTerrConflictFromMapSchema}
        formData={formData}
        submitHandler={submitHandler}
        setFormData={setFormData}
      >
        {children}
      </BaseForm>
    </div>
  );
}
