import React, { useEffect, useState } from "react";
import { Typography } from "@material-ui/core";

export default function CountDown({ duration, onFinish, text }) {
  const [countDown, setCountDown] = useState(duration);

  useEffect(() => {
    let count = duration;
    const countdownTimer = setInterval(() => {
      if (count === 0) {
        onFinish();
        count = duration;
        setCountDown(count);
      } else {
        count -= 1;
        setCountDown(count);
      }
    }, 1000);
    return () => {
      clearInterval(countdownTimer);
    };
  }, [onFinish, duration]);

  return (
    <Typography color="textSecondary">
      {`${text} ${countDown}s`}
    </Typography>
  );
}
