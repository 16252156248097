import React, { createContext, useCallback, useMemo, useState } from "react";
import { Snackbar } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";

export const ClientProfileContext = createContext();

export const ClientProfileContextProvider = ({ mutate, clientProfileID, clientMnemonic, study, children }) => {
  const [success, setSuccess] = useState(null);
  const [response, setResponse] = useState("");
  const apiCallback = useCallback((apiSuccess, result) => {
    setSuccess(apiSuccess);
    if (apiSuccess) {
      mutate();
      if (result?.response?.data) {
        setResponse(JSON.stringify(result.response.data));
      } else {
        setResponse("Saved Successfully");
      }
    } else {
      setResponse(JSON.stringify(result.response.data));
    }
  }, [mutate]);
  const value = useMemo(() => ({
    mutate, clientProfileID, clientMnemonic, study, apiCallback,
  }), [mutate, clientProfileID, clientMnemonic, study, apiCallback]);
  return (
    <ClientProfileContext.Provider
      value={value}
    >
      {children}
      <Snackbar
        open={success !== null}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert onClose={() => setSuccess(null)} severity={success ? "success" : "error"}>
          {response}
        </Alert>
      </Snackbar>
    </ClientProfileContext.Provider>
  );
};
