import React, { useCallback, useEffect, useState } from "react";
import { Container, Grid, makeStyles } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { API } from "../../../../utils/api";
import AccountDetails from "./AccountDetails";
import OktaLogs from "./OktaLogs";
import Profile from "./Profile";
import Resources from "./Resources";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

export default function AccountView() {
  const classes = useStyles();

  const userIdParam = useParams();
  const misightUsersList = useSelector(state => state.users.misightUsers);

  const [oktaUserData, setOktaUserData] = useState({});
  const [misightUserData, setMisightUserData] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(undefined);

  const misightUser = misightUsersList?.find(user => user.okta_uid === oktaUserData.uid);
  const isAPIUser = oktaUserData?.groups?.some(g => g?.includes("_api_"));

  const fetchUserData = useCallback(() => {
    API.request({ url: `users/${userIdParam.userId}`, method: "GET" })
      .then((response) => {
        setOktaUserData(response.data);
        setLoading(false);
      })
      .catch((e) => {
        console.error(e);
        setError(e);
        setLoading(false);
      });
  }, [userIdParam]);

  const fetchMisightUserData = useCallback(() => {
    API.request({ url: `misight/users/${misightUser.id}`, method: "GET" })
      .then((response) => {
        setMisightUserData(response.data);
        setLoading(false);
      })
      .catch((e) => {
        console.error(e);
        setError(e);
        setLoading(false);
      });
  }, [misightUser?.id]);

  useEffect(() => {
    if (userIdParam.userId) {
      setLoading(true);
      fetchUserData();
    }
  }, [userIdParam, fetchUserData]);

  useEffect(() => {
    if (misightUser) {
      setLoading(true);
      fetchMisightUserData();
    }
  }, [fetchMisightUserData, misightUser]);

  return (
    <div>
      {loading && <Alert severity="info">Loading User Data...</Alert>}
      {error && <Alert severity="error">{`${error}`}</Alert>}
      <div className={classes.root} title="Account">
        <Container maxWidth="xl">
          <Grid container spacing={3}>
            <Grid item lg={4} md={6} xs={12}>
              <Profile userData={oktaUserData} loading={loading} refreshUserFunction={fetchUserData} />
            </Grid>
            <Grid item lg={8} md={6} xs={12}>
              <AccountDetails userData={oktaUserData} setUserData={setOktaUserData} misightUserData={misightUserData} loading={loading} isAPIUser={isAPIUser} />
            </Grid>
            {!isAPIUser && (
              <Grid item xs={12}>
                <Resources userData={oktaUserData} resources={misightUserData.resources} misightUserId={misightUser?.id} fetchMisightUserData={fetchMisightUserData} />
              </Grid>
            )}
            <Grid item xs={12}>
              <OktaLogs userId={userIdParam.userId} />
            </Grid>
          </Grid>
        </Container>
      </div>
    </div>
  );
}
