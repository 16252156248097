import React, { useEffect, useCallback, useState } from "react";
import Alert from "@material-ui/lab/Alert";
import { useSelector } from "react-redux";

import TableLoadingOverlay from "../../../../components/MaterialTableLoadingOverlay";
import { API } from "../../../../utils/api";
import { camelCaseObject, combineArrays } from "../../../../utils/dataTransformations";
import MisightProfile, { baseURL as url } from "./MisightProfile";

export default function MisightProfileList({ study, search, page, setCount, setPage }) {
  const [profiles, setProfiles] = useState([]);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const pageSize = 8;
  // all clients
  const formOptions = useSelector((state) => state.formOptions);
  const clients = formOptions.clients?.length > 0 && formOptions.lms_backend_clients?.length > 0
    ? formOptions.clients.filter(client => client.type === study)
      .map(client => {
        const lmsBackendClient = formOptions.lms_backend_clients?.find((o) => (
          o.mnemonic.toUpperCase() === client.id.toUpperCase()
        ));
        return { client_id: client.id.toUpperCase(), lms_backend_client_id: lmsBackendClient?.id };
      }).filter(o => o.lms_backend_client_id !== undefined)
    : [];

  // all misight profiles
  const getData = useCallback(() => {
    setLoading(true);
    API.request({ url, method: "GET" })
      .then(
        (response) => {
          setProfiles(response.data.filter(profile => profile.study === study));
          setError("");
          setLoading(false);
        },
        (e) => {
          setProfiles([]);
          setError(e);
          setLoading(false);
        },
      );
  }, [study]);
  useEffect(() => {
    getData();
  }, [getData]);

  const data = combineArrays(clients, profiles, "client_id")
    .filter(row => !search
      || row.client_id?.toUpperCase().includes(search.toUpperCase()));

  useEffect(() => {
    setCount(data ? data.length : 0);
  }, [data, setCount, setPage]);

  useEffect(() => {
    setPage(0);
  }, [study, search, setCount, setPage]);
  if (loading) return <TableLoadingOverlay />;
  if (error) return <Alert severity="error">{String(error)}</Alert>;

  return (
    <div>
      {data.slice(page * pageSize, (page + 1) * pageSize)
        .map(row => (
          <div style={{ paddingTop: 30 }} key={row.client_id}>
            <MisightProfile {...camelCaseObject(row)} study={study} refreshData={getData} />
          </div>
        ))}
    </div>
  );
}
