import React, { forwardRef, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  Slide,
  Typography,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { nanoid } from "nanoid";
import { API } from "../../../../utils/api";

import GroupsChip from "../GroupsChip";

const useConfirmDialogStyles = makeStyles((theme) => ({
  title: {
    position: "center",
  },
  cancelButton: {
    marginRight: theme.spacing(1),
    color: theme.palette.text.onBackground,
    backgroundColor: theme.palette.primary.light,
  },
  confirmButton: {
    color: theme.palette.text.onBackground,
    backgroundColor: theme.palette.secondary.light,
  },
  addIcon: {
    color: theme.palette.success.main,
    marginRight: theme.spacing(1),
  },
  deleteIcon: {
    color: theme.palette.error.main,
    marginRight: theme.spacing(1),
  },
}));

const Transition = forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

export default function ActivationDialog({ open, setOpen, userData, isActivated, refreshUserFunction }) {
  const classes = useConfirmDialogStyles();

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  const handleClose = () => {
    setError(null);
    if (success) {
      refreshUserFunction();
    }
    setSuccess(null);
    setOpen(false);
  };

  function activateUser() {
    setLoading(true);
    return API.request({ url: `/users/${encodeURIComponent(userData.id)}/activate`, method: "POST" })
      .then(() => { setSuccess("Successfully Activated!"); setLoading(false); }, (e) => { setError(e); setLoading(false); });
  }

  function deactivateUser() {
    setLoading(true);
    return API.request({ url: `/users/${encodeURIComponent(userData.id)}`, method: "DELETE" })
      .then(() => { setSuccess("Successfully Deactivated!"); setLoading(false); }, (e) => { setError(e); setLoading(false); });
  }

  let dialogText;
  if (isActivated) {
    dialogText = (
      <>
        <Typography color="textSecondary">
          {"You are about to "}
          <b>Remove Misight Access</b>
          {" the user "}
          <b>{userData.email}</b>
          <br />
          <br />
          The user will still be able to login to other platforms they currently have access to, but will not have access to MISight.
          <br />
          <br />
          User Groups:
          {" "}
          {userData?.groups?.map(g => <GroupsChip key={nanoid()} label={g} group={g} />) || "Unknown"}
          <br />
          <br />
        </Typography>
        <Typography color="textPrimary"><b>Please confirm this action:</b></Typography>
      </>
    );
  } else {
    dialogText = (
      <>
        <Typography color="textSecondary">
          {"You are about to "}
          <b>activate</b>
          {" this user, this will send an email notification to "}
          <b>{userData.email}</b>
          <br />
          <br />
          Activating this user will allow them access to the groups they are a member of.
          <br />
          Please ensure the user is a member of the correct groups before activating.
          <br />
          <br />
          User Groups:
          {" "}
          {userData?.groups?.map(g => <GroupsChip key={nanoid()} label={g} group={g} />) || "Unknown"}
          <br />
          <br />
        </Typography>
        <Typography color="textPrimary"><b>Please confirm this action:</b></Typography>
      </>
    );
  }

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        maxWidth="md"
        fullWidth
      >
        <DialogTitle id="alert-dialog-slide-title" className={classes.title}>
          Confirm
        </DialogTitle>
        <DialogContent dividers>
          {dialogText}
          <br />
        </DialogContent>
        {loading && <Alert severity="info">Updating User...</Alert>}
        {error && <Alert severity="error">{`${error}`}</Alert>}
        {success
          ? (
            <div>
              <Alert severity="success">{`${success}`}</Alert>
              <DialogActions>
                <Button onClick={handleClose} className={classes.cancelButton} disabled={loading}>
                  Close
                </Button>
              </DialogActions>
            </div>
          )
          : (
            <DialogActions>
              <Button onClick={handleClose} className={classes.cancelButton} disabled={loading}>
                Cancel
              </Button>
              <Button onClick={isActivated ? deactivateUser : activateUser} className={classes.confirmButton} disabled={loading}>
                Confirm
              </Button>
            </DialogActions>
          )}
      </Dialog>
    </div>
  );
}
