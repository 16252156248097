import React, { useEffect, useMemo } from "react";
import { Grid } from "@material-ui/core";
import { useFormikContext } from "formik";
import { useSelector } from "react-redux";

import EndQuarter from "./EndQuarter";
import StartQuarter from "./StartQuarter";
import LMSSelect from "./base/LMSSelect";

const timePeriods = [
  { id: 4, label: "Rolling 4q" },
  { id: 8, label: "Rolling 8q" },
  { id: 24, label: "Rolling 24q" },
  { id: "custom", label: "Custom" },
];

export default function TimePeriod({ required }) {
  const formOptions = useSelector((state) => state.formOptions);
  const { values, setFieldValue } = useFormikContext();
  const quarters = useMemo(() => {
    if (formOptions?.quarters?.length > 0) {
      return formOptions.quarters.filter((q) => q !== "");
    }
    return null;
  }, [formOptions?.quarters]);
  const latestQuarter = useMemo(() => {
    if (quarters?.length > 0) {
      return quarters[0];
    }
    return null;
  }, [quarters]);

  useEffect(() => {
    const lockQuarterIdx = values?.lock && quarters && quarters.indexOf(values.lock.split("_")[0]);
    if (lockQuarterIdx > -1) {
      setFieldValue("start_quarter", quarters[lockQuarterIdx + values.time_period - 1]);
      setFieldValue("end_quarter", quarters[lockQuarterIdx]);
    } else if (values?.time_period && latestQuarter) {
      setFieldValue("start_quarter", quarters[values.time_period - 1]);
      setFieldValue("end_quarter", latestQuarter);
    }
  }, [values?.time_period, values?.lock, latestQuarter, quarters, setFieldValue]);

  const label = required ? "Time Period *" : "Time Period";
  return (
    <>
      <LMSSelect
        name="time_period"
        label={label}
        options={timePeriods}
      />
      {values?.time_period === "custom"
        ? (
          <Grid container>
            <Grid item xs={12} md={6}>
              <StartQuarter required={required} />
            </Grid>
            <Grid item xs={12} md={6}>
              <EndQuarter required={required} />
            </Grid>
          </Grid>
        )
        : null}
    </>
  );
}
