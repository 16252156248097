/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { ToolbarButton } from "@graphiql/react";
import { Box, CircularProgress, Link, Modal, Typography } from "@material-ui/core";
import SaveAlt from "@material-ui/icons/SaveAlt";
import _ from "lodash";
import lightPalette from "../../../theme/lightPalette";
import { graphQlQuery } from "../../../utils/api";

const styles = {
  modalBox: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    backgroundColor: "#eef3f7",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    borderRadius: 8,
    padding: 20,
  },
};

function buildFileIdList(obj) {
  let ids = [];
  _.forEach(obj, (value) => {
    if (value?.queryInfo?.id) {
      ids = [...ids, value.queryInfo.id];
    } else if (_.isObject(value)) {
      ids = [...ids, ...buildFileIdList(value)];
    }
  });
  return ids;
}

export default function FileDownloader({ lastResult }) {
  const [downloadFilesIsOpen, setDownloadFilesIsOpen] = useState(false);
  const [downloadList, setDownloadList] = useState([]);
  const [fileIdList, setFileIdList] = useState([]);

  const fileErrorMessages = {
    QUEUED: <CircularProgress color="inherit" size={15} />,
    RUNNING: <CircularProgress color="inherit" size={15} />,
    FAILED: <Typography component="span" style={{ color: lightPalette.error.main }} mb={2}>There was an error accessing this file</Typography>,
    CANCELED: <Typography component="span" style={{ color: lightPalette.error.main }} mb={2}>The file has been canceled</Typography>,
    TIMEOUT: <Typography component="span" style={{ color: lightPalette.error.main }} mb={2}>Maximum retry attempts reached</Typography>,
  };

  let downloadRetryCounter = 0;
  const maximumDownloadRetries = 30;

  function queryFiles(id) {
    return graphQlQuery({ query: `{file(executionId: "${id}") {status,url,}}` }, 2).then((result) => result.data.file);
  }

  function checkFileStatus() {
    const idList = fileIdList;
    const loopthroughIds = idList.map((id) => queryFiles(id)
      .catch(() => ({ status: "FAILED", url: null }))
      .then((value) => { if ((downloadRetryCounter === maximumDownloadRetries) && (value.status === "QUEUED" || value.status === "RUNNING")) { return { status: "TIMEOUT", url: null }; } return value; }));
    Promise.all(loopthroughIds).then((files) => {
      const statuses = files.map(file => file.status);
      if ((statuses.includes("RUNNING") || statuses.includes("QUEUED")) && (downloadRetryCounter <= maximumDownloadRetries)) {
        setTimeout(() => {
          downloadRetryCounter += 1;
          checkFileStatus();
        }, [10000]);
      }
      setDownloadList(files);
    });
  }
  function downloadFiles() {
    setDownloadList([]);
    checkFileStatus();
    setDownloadFilesIsOpen(true);
  }

  useEffect(() => {
    if (!lastResult?.data) return;
    const idList = buildFileIdList(lastResult?.data);
    setFileIdList(idList);
  }, [lastResult]);

  return (
    <>
      <ToolbarButton
        onClick={() => { downloadFiles(); }}
        label="Download Files"
      >
        <p className="graphiql-toolbar-icon"><SaveAlt /></p>
      </ToolbarButton>
      <Modal
        open={downloadFilesIsOpen}
        onClose={() => { setDownloadFilesIsOpen(false); }}
        aria-labelledby="Download Files"
        aria-describedby="Download Files"
      >
        <Box style={styles.modalBox}>
          <Typography color="textPrimary" variant="h6">Download: </Typography>
          {
            downloadList.length
              ? (
                _.map(downloadList, (file, i) => (
                  (file.status === "SUCCEEDED")
                    ? (
                      <Typography key={_.uniqueId("file")} color="textPrimary" mb={2}>
                        {`File ${i + 1}: `}
                        <Link component="button" onClick={() => { window.open(file.url); }} color="primary" variant="inherit">Download</Link>
                      </Typography>
                    )
                    : (
                      <Typography key={_.uniqueId("file")} component="div" color="textPrimary" mb={2}>
                        {`File ${i + 1}: `}
                        {fileErrorMessages[file.status]}
                      </Typography>
                    )
                ))
              )
              : (
                <Typography color="textPrimary" mb={2}>
                  Remember to select queryInfo.id in the query.
                  <br />
                  <br />
                  Looking for files...
                </Typography>
              )
          }
        </Box>
      </Modal>
    </>
  );
}
