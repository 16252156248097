import React, { useEffect, useState } from "react";
import DateFnsUtils from "@date-io/date-fns";
import { Button, Grid, Chip, FormControl, Typography, FormControlLabel, RadioGroup, Radio, FormLabel, Tooltip } from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { Close, CommentRounded, Done, Info } from "@material-ui/icons";
import { Alert } from "@material-ui/lab";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import Form from "@rjsf/material-ui";
import MaterialTable from "material-table";
import { useSelector } from "react-redux";
import useSWR from "swr";
import TableLoadingOverlay from "../../components/MaterialTableLoadingOverlay";
import CSVUpload from "../../components/input/CSVUpload";
import OptionSelect from "../../components/input/OptionSelect";
import { getAllHeaderTransform, getClients, getStandardHeaders } from "../../utils/api";
import UpdatePreprocessingHeaderMapping from "./UpdatePreprocessingHeaderMapping";

const fileTypes = [
  // MF
  { id: "bd_transactions", label: "BD Transactions", study: "mf" },
  { id: "bd_aum", label: "BD AUM", study: "mf" },
  { id: "ria_transactions", label: "RIA Transactions", study: "mf" },
  { id: "ria_aum", label: "RIA AUM", study: "mf" },
  { id: "bd_and_ria_transactions", label: "BD+RIA Transactions", study: "mf" },
  { id: "bd_and_ria_aum", label: "BD+RIA AUM", study: "mf" },
  { id: "bd_and_ria_transactions_and_aum", label: "BD+RIA Transactions+AUM", study: "mf" },
  { id: "first_clearing", label: "First Clearing", study: "mf" },
  { id: "office_map", label: "Office Map", study: "mf" },
  // ANN
  { id: "fia", label: "FIA", study: "ann" },
  { id: "va", label: "VA", study: "ann" },
  { id: "fia_and_va", label: "FIA+VA", study: "ann" },
  { id: "office_map", label: "Office Map", study: "ann" },
  // SMA
  { id: "bd_transactions", label: "BD Transactions", study: "sma" },
  { id: "ria_transactions", label: "RIA Transactions", study: "sma" },
  { id: "bd_and_ria_transactions", label: "BD and RIA Transactions", study: "sma" },
  { id: "strategy_map", label: "Strategy Map", study: "sma" },
];

const sortingMethod = {
  common_header_mapping: 1,
  client_specific_header_mapping: 2,
  direct_header_mapping: 3,
};

export default function PreprocessingForm({ children, taskConfig, setTaskConfig, csv, setCsv, submitHandler, studyType, client, displayHeader }) {
  // eslint-disable-next-line no-unused-vars
  const { data: clients, error: clientsError } = useSWR("/lms/api/v1/client/client", getClients);
  const preprocessingSchema = {};
  const headerLookup = [];
  const productionCycles = useSelector((state) => state.formOptions.productionCycle);
  const environment = useSelector((state) => state.application.environment);
  const [fileType, setFileType] = useState(taskConfig.file_type ?? "aum");
  const [productionCycle, setProductionCycle] = useState(taskConfig.production_cycle ?? "");
  const [startQuarter, setStartQuarter] = useState(taskConfig.start_quarter ?? "");
  const [endQuarter, setEndQuarter] = useState(taskConfig.end_quarter ?? "");
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const initialText = "CUSTOM";
  const [customButtonText, setCustomButtonText] = useState(initialText);
  const csvHeaders = useSelector(state => state.csvHeaders.data);
  const [headerMappings, setHeaderMappings] = useState({});
  const [requiredHeaders, setRequiredHeaders] = useState(new Set());
  const [finalMapping, setFinalMapping] = useState([]);
  const [missingHeaders, setMissingHeaders] = useState([]);
  const [duplicateHeaders, setDuplicateHeaders] = useState([]);
  const [displayMapping, setDisplayMapping] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);
  const [dropDownData, setDropdownData] = useState([]);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [preprocessingFileType, setPreprocessingFileType] = useState();
  const clientId = clients ? clients.filter((c) => c.mnemonic === client)[0]?.id : 0;
  // eslint-disable-next-line no-unused-vars
  const { data: apiHeaderMapping, error: headerMappingError } = useSWR(
    `/lms/api/v1/transform/all-header-transform/${studyType?.toUpperCase()}/${taskConfig.file_type?.toUpperCase()}/${clientId}`,
    () => getAllHeaderTransform(studyType?.toUpperCase(), taskConfig.file_type?.toUpperCase(), clientId),
    { revalidateOnFocus: false },
  );
  // eslint-disable-next-line no-unused-vars
  const { data: standardHeaders, error: standardHeadersError } = useSWR(`/lms/api/v1/transform/standard-header/${studyType?.toUpperCase()}`, () => getStandardHeaders(studyType?.toUpperCase()), { revalidateOnFocus: false });

  const renderIsPermanent = (rowData) => (
    // eslint-disable-next-line no-nested-ternary
    ((rowData.mapping_type !== "common_header_mapping")
      ? ((rowData.is_permanent === undefined || rowData.is_permanent === false) ? <Close /> : <Done />)
      : <Tooltip title="This is Common Header Mapping. Changes to this mapping are always temporary."><Info /></Tooltip>)
  );

  const checkRequiredMappingsPresent = (headerMapping) => {
    if ([...requiredHeaders].length > 0) {
      const missingRequired = [...requiredHeaders].filter(row => ![...headerMapping.map(innerRow => innerRow.header_transform)].includes(row));
      setMissingHeaders(missingRequired);
    }
  };

  const checkDuplicateMappingsPresent = (headerMapping) => {
    const duplicateHeaderSet = new Set();
    const duplicateHeader = [...headerMapping.map(row => row.header_transform).filter(row => {
      if (duplicateHeaderSet.has(row)) {
        return true;
      }
      duplicateHeaderSet.add(row);
      return false;
    })];
    setDuplicateHeaders(duplicateHeader);
    setFinalMapping([
      ...headerMapping.sort((a, b) => sortingMethod[a.mapping_type] - sortingMethod[b.mapping_type]).map(row => {
        if (duplicateHeader.includes(row.header_transform)) {
          return { ...row, isDuplicate: true };
        }
        return row;
      }),
    ]);
  };

  function dateToString(date) {
    return date ? date.toISOString().split("T")[0] : "";
  }

  function handleClick() {
    if (customButtonText === initialText) {
      setCustomButtonText("CANCEL");
      if (startDate && endDate) {
        setTaskConfig((prevTaskConfig) => ({
          ...prevTaskConfig,
          start_quarter: "",
          end_quarter: "",
          start_date: dateToString(startDate),
          end_date: dateToString(endDate),
        }));
      }
    } else {
      setCustomButtonText(initialText);
      setTaskConfig((prevTaskConfig) => ({
        ...prevTaskConfig,
        start_quarter: startQuarter,
        end_quarter: endQuarter,
        start_date: "",
        end_date: "",
      }));
    }
  }

  useEffect(() => {
    setDisplayMapping(false);
    const getApiHeaderMapping = new Promise((resolve, reject) => {
      if (apiHeaderMapping) {
        if (apiHeaderMapping.common_header_mapping.length > 0) {
          apiHeaderMapping.common_header_mapping.forEach((commonHeader) => {
            setHeaderMappings((prev) => ({ ...prev, [commonHeader.header_name]: { standard_header_name: commonHeader.standard_header_name, is_required: commonHeader.is_required, mapping_type: "common_header_mapping" } }));
            if (commonHeader.is_required) {
              setRequiredHeaders((prev) => new Set(prev).add(commonHeader.standard_header_name));
            }
          });
        }
        if (apiHeaderMapping.client_specific_header_mapping.length > 0) {
          setPreprocessingFileType(apiHeaderMapping.client_specific_header_mapping[0]?.preprocessing_file_type);
          apiHeaderMapping.client_specific_header_mapping.map((clientSpecificHeader) => {
            setHeaderMappings((prev) => ({ ...prev, [clientSpecificHeader.header_name]: { preprocessing_file_type: clientSpecificHeader.preprocessing_file_type, standard_header_name: clientSpecificHeader.standard_header_name, is_required: clientSpecificHeader.is_required, mapping_type: "client_specific_header_mapping" } }));
            if (clientSpecificHeader.is_required) {
              setRequiredHeaders((prev) => new Set(prev).add(clientSpecificHeader.standard_header_name));
            }
            return true;
          });
        }
        setTimeout(() => {
          resolve();
        }, 0);
      } else {
        setTimeout(() => {
          reject();
        }, 0);
      }
    });

    const getStandardHeaderList = new Promise((resolve, reject) => {
      if (standardHeaders) {
        standardHeaders.standard_headers.sort((a, b) => (a.standard_header_name > b.standard_header_name ? 1 : -1));
        setDropdownData(standardHeaders.standard_headers);
        setTimeout(() => {
          resolve();
        }, 0);
      } else {
        setTimeout(() => {
          reject();
        }, 0);
      }
    });

    // eslint-disable-next-line no-unused-vars
    Promise.all([getApiHeaderMapping, getStandardHeaderList]).then((values) => {
      setTimeout(setDisplayMapping(true), 1000);
    }).catch((error) => {
      console.error(error);
      setDisplayMapping(false);
    });
  }, [apiHeaderMapping, standardHeaders]);
  useEffect(() => {
    setTaskConfig((preConfig) => ({ ...preConfig, study: studyType }));
    const productionCycleIdx = productionCycle !== "" && productionCycles && productionCycles.indexOf(productionCycle);
    if (productionCycleIdx) {
      const startQtr = productionCycles[productionCycleIdx + 3];
      const startMonth = 1 + 3 * (parseInt(startQtr?.split("Q")[1], 10) - 1);
      const endMonth = 3 + 3 * (parseInt(productionCycle?.split("Q")[1], 10) - 1);
      const fullStartDate = new Date(parseInt(startQtr?.split("Q")[0], 10), startMonth - 1, 1);
      const fullEndDate = new Date(parseInt(productionCycle?.split("Q")[0], 10), endMonth, 0);
      setStartQuarter(startQtr);
      setEndQuarter(productionCycle);
      setStartDate(fullStartDate);
      setEndDate(fullEndDate);

      setTaskConfig((preConfig) => ({
        ...preConfig,
        start_quarter: startQtr,
        end_quarter: productionCycle,
      }));
    }
  }, [setTaskConfig, studyType, productionCycle, productionCycles, setStartQuarter, setEndQuarter, setStartDate, setEndDate]);

  useEffect(() => {
    setTimeout(() => {
      if (csvHeaders) {
        const savedTempMapping = {};
        if (taskConfig.temporary_mapping !== undefined) {
          taskConfig.temporary_mapping.forEach((element) => {
            savedTempMapping[element.header_name.toString()] = { ...element };
          });
        }
        const headerMapping = [...csvHeaders.header.map(csvHeader => {
          const csvHeaderUpper = csvHeader.toUpperCase();
          if (Object.prototype.hasOwnProperty.call(savedTempMapping, csvHeaderUpper)) {
            return {
              preview_header: csvHeader,
              header_transform: savedTempMapping[csvHeaderUpper].standard_header_name,
              mapping_type: savedTempMapping[csvHeaderUpper].mapping_type,
              is_required: savedTempMapping[csvHeaderUpper].is_required,
              preprocessing_file_type: preprocessingFileType,
              is_permanent: false,
            };
          } if (Object.prototype.hasOwnProperty.call(headerMappings, csvHeaderUpper)) {
            return {
              preview_header: csvHeader,
              header_transform: headerMappings[csvHeaderUpper].standard_header_name,
              mapping_type: headerMappings[csvHeaderUpper].mapping_type,
              is_required: headerMappings[csvHeaderUpper].is_required,
              preprocessing_file_type: preprocessingFileType,
              is_permanent: true,
            };
          }
          return {
            preview_header: csvHeader,
            header_transform: csvHeaderUpper,
            preprocessing_file_type: preprocessingFileType,
            mapping_type: "direct_header_mapping",
            is_permanent: false,
          };
        })];
        checkRequiredMappingsPresent(headerMapping);
        checkDuplicateMappingsPresent(headerMapping);
      }
    }, 0);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [csvHeaders, headerMappings, requiredHeaders]);

  const columnsHeaders = [
    { title: "Preview Header", field: "preview_header" },
    {
      title: "Header Transform",
      field: "header_transform",
      render:
        rowData => (rowData.isDuplicate ? <p style={{ color: "red" }}>{`${rowData.header_transform} (Duplicate header)`}</p> : <p>{rowData.header_transform}</p>),
    },
    {
      title: "Is Required",
      field: "is_required",
      // eslint-disable-next-line no-nested-ternary
      render: (rowData) => ((rowData.is_permanent === true) ? (rowData.is_required === true ? <Done /> : <Close />) : ""),
    },
    {
      title: "Permanent Mapping",
      field: "is_permanent",
      render: (rowData) => renderIsPermanent(rowData),
    },
  ];
  /* eslint-disable*/
  const DisplayTableWithChecks = () => {
    const checks = (arr, msg, status) => {
      if (arr.length > 0) {
        return (
          <Alert severity={status}>
            {`${msg} ➔ ${arr.join(",\n ")}`}
          </Alert>
        );
      }
    };
    const handleChange = e => {
      if (e.target.value === "Yes") {
        setTaskConfig((prevTaskConfig) => ({ ...prevTaskConfig, use_v2: true }));
        return;
      }
      setTaskConfig((prevTaskConfig) => ({ ...prevTaskConfig, use_v2: false }));
      return;
    }
    if (dropDownData.length > 0) {
      dropDownData.map((row) => headerLookup.push({label:row.standard_header_name?.toString(), id: row.standard_header_name}));
    }
    return (
      <div>
        <Chip
          variant="outlined"
          color="primary"
          size="medium"
          icon={<CommentRounded />}
          label="In case of matching headers between common-headers and client-specific headers, the later would be applied."
        />
        {checks(missingHeaders, "Missing Required Headers", "error")}
        {checks(duplicateHeaders, "Duplicate Headers", "warning")}
        {duplicateHeaders.length === 0 && missingHeaders.length === 0
          && (
            <Alert severity="success">
              Checks Passed: Required Headers present and 0 duplicate headers.
            </Alert>
          )}
          <MaterialTable
            title="Header Mapping to be applied"
            data={finalMapping}
            columns={columnsHeaders}
            isLoading={tableLoading}
            options={{
              tableLayout: "auto",
              pageSize: 5,
              pageSizeOptions: [],
              emptyRowsWhenPaging: true,
              maxBodyHeight: 500,
              headerStyle: {
                backgroundColor: "#30455c",
              },
            }}
            components={{
              OverlayLoading: () => (
                  <TableLoadingOverlay />
              ),
            }}
            actions={[
              {
                icon: "edit",
                tooltip: "Edit Header Mapping",
                isFreeAction: true,
                onClick: (props) => {
                  setOpenEditDialog(true);
                }
              }
            ]}
          />
          <br />
        <div>
          {
            environment.toLowerCase() !== "prod" && (
              <FormControl style={{float: "left"}}>
                <FormLabel id="demo-row-radio-buttons-group-label">Use Above Mapping</FormLabel>
                <RadioGroup
                  row
                  value={taskConfig.use_v2 ? "Yes" : "No"}
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  onChange={handleChange}
                >
                  <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                  <FormControlLabel value="No" control={<Radio />} label="No" />
                </RadioGroup>
              </FormControl>
            )
          }
        </div>
      </div>
    );
  };

  if (headerMappingError) return <div>Error loading header mapping...</div>;
  if (clientsError) return <div>Error while fetching mappings...</div>;
  return (
    <Form
      schema={preprocessingSchema}
      onSubmit={submitHandler}
    >
      <div style={{ display: "flex", alignItems: "center" }}>
        <Typography variant="subtitle2" style={{ marginRight: "8px", marginBottom: "16px" }}>Task Name :</Typography>
        <Chip style={{ marginBottom: "16px" }} variant="outlined" label={displayHeader(["file_type"])} />
      </div>
      <CSVUpload
        onChange={(update) => {
          setCsv({ attachment: update.csv });
          setDisplayMapping(false);
        }}
        value={{ csv, ...taskConfig }}
      />
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <FormControl fullWidth margin="normal">
            <OptionSelect
              style={{ width: 500 }}
              value={fileType}
              onChange={(type) => {
                setFileType(type);
                setTaskConfig((prevTaskConfig) => ({ ...prevTaskConfig, file_type: type }));
              }}
              options={fileTypes.filter((o) => o.study === studyType)}
              label="File Type"
            />
          </FormControl>
          <FormControl fullWidth variant="outlined">
            <InputLabel required htmlFor="inputLabel-production-cycle-select">Production Cycle</InputLabel>
            <Select
              required
              style={{ width: 500, marginBottom: 30 }}
              value={productionCycle}
              onChange={(e) => {
                setProductionCycle(e.target.value);
                setTaskConfig((prevTaskConfig) => ({ ...prevTaskConfig, production_cycle: e.target.value }));
              }}
              label="Production Cycle"
              inputProps={{
                name: "production cycle",
                id: "inputLabel-production-cycle-select",
              }}
            >
              {productionCycles.map((quarter) => (
                <MenuItem key={quarter} value={quarter}>
                  {quarter}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {customButtonText === initialText
            ? (
              <>
                <FormControl sx={{ m: 1 }} variant="outlined">
                  <InputLabel htmlFor="inputLabel-start-quarter-select">Start Quarter</InputLabel>
                  <Select
                    required
                    style={{ width: 240, marginBottom: 30, marginRight: 10 }}
                    value={startQuarter}
                    onChange={(e) => {
                      setStartQuarter(e.target.value);
                      setTaskConfig((prevTaskConfig) => ({ ...prevTaskConfig, start_quarter: e.target.value }));
                    }}
                    label="Start Quarter"
                    inputProps={{
                      name: "start quarter",
                      id: "inputLabel-start-quarter-select",
                    }}
                  >
                    {productionCycles.map((quarter) => (
                      <MenuItem key={quarter} value={quarter}>
                        {quarter}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl sx={{ m: 1 }} variant="outlined">
                  <InputLabel htmlFor="inputLabel-end-quarter-select">End Quarter</InputLabel>
                  <Select
                    required
                    style={{ width: 240, marginBottom: 30 }}
                    value={endQuarter}
                    onChange={(e) => {
                      setEndQuarter(e.target.value);
                      setTaskConfig((prevTaskConfig) => ({ ...prevTaskConfig, end_quarter: e.target.value }));
                    }}
                    label="End Quarter"
                    inputProps={{
                      name: "end quarter",
                      id: "inputLabel-end-quarter-select",
                    }}
                  >
                    {productionCycles.map((quarter) => (
                      <MenuItem key={quarter} value={quarter}>
                        {quarter}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </>
            )
            : (
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <FormControl sx={{ m: 1 }} variant="outlined">
                  <KeyboardDatePicker
                    required
                    disableToolbar
                    variant="inline"
                    format="MM/dd/yyyy"
                    style={{ width: 240, marginBottom: 30, marginRight: 10 }}
                    id="start-date-picker"
                    label="Start Date"
                    value={startDate}
                    onChange={(newDate) => {
                      setStartDate(newDate);
                      setTaskConfig((prevTaskConfig) => ({
                        ...prevTaskConfig,
                        start_date: dateToString(newDate),
                        start_quarter: "",
                        end_quarter: "",
                      }));
                    }}
                    animateYearScrolling
                  />
                </FormControl>
                <FormControl sx={{ m: 1 }} variant="outlined">
                  <KeyboardDatePicker
                    required
                    disableToolbar
                    variant="inline"
                    format="MM/dd/yyyy"
                    style={{ width: 240, marginBottom: 30 }}
                    id="end-date-picker"
                    label="End Date"
                    value={endDate}
                    onChange={(newDate) => {
                      setEndDate(newDate);
                      setTaskConfig((prevTaskConfig) => ({
                        ...prevTaskConfig,
                        end_date: dateToString(newDate),
                        start_quarter: "",
                        end_quarter: "",
                      }));
                    }}
                  />
                </FormControl>
              </MuiPickersUtilsProvider>
            )}
          <FormControl>
            <Button variant="text" onClick={handleClick}>
              {customButtonText}
            </Button>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          {environment.toLowerCase() !== "prod" && csv.attachment && taskConfig.file_type !== undefined &&
            <div>
              <>
                {displayMapping ? <>
                  <DisplayTableWithChecks />
                  <UpdatePreprocessingHeaderMapping
                    headerLookup={headerLookup}
                    openEditDialog={openEditDialog}
                    setOpenEditDialog={setOpenEditDialog}
                    finalMapping={finalMapping}
                    studyType={studyType}
                    taskConfig={taskConfig}
                    setTaskConfig={setTaskConfig}
                    preprocessingFileType={preprocessingFileType}
                    clientId={clientId}
                    setTableLoading={setTableLoading}
                    checkDuplicateMappingsPresent={checkDuplicateMappingsPresent}
                    checkRequiredMappingsPresent={checkRequiredMappingsPresent} /></>
                  : <div alignItems="center">
                      <TableLoadingOverlay />
                      &nbsp;
                      <Typography align="center" variant="h6"> Loading Header Mapping...</Typography>
                    </div>
                }
              </>
            </div>
          }
        </Grid>
      </Grid>
      {children}
    </Form>
  );
}
