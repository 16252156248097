import React from "react";
import { FormControlLabel, Checkbox, Tooltip } from "@material-ui/core";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import { useField } from "formik";

export default function LMSCheckbox({
  name,
  label,
  tooltipText,
  ...props
}) {
  const [field] = useField({ name, type: "checkbox" });

  return (
    <div style={{ width: "100%" }}>
      <FormControlLabel
        control={
          (
            <Checkbox
              {...field}
              {...props}
            />
          )
        }
        label={label}
      />
      {(tooltipText) ? <Tooltip title={tooltipText} placement="top" arrow><HelpOutlineIcon fontSize="small" /></Tooltip> : null }
    </div>
  );
}
