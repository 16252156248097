import React, { useEffect, useState } from "react";
import { TextField, Typography } from "@material-ui/core";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import WarningIcon from "@material-ui/icons/Warning";
import Alert from "@material-ui/lab/Alert";
import Autocomplete from "@material-ui/lab/Autocomplete";
import OptionSelect from "../../components/input/OptionSelect";
import useTerrClientDescriptions from "../../hooks/useTerrClientDescriptions";
import { API } from "../../utils/api";
import { mapStudyTypeOptions } from "./TerrServiceTerritoryMapsForm";

export default function TerrServiceTerritoryPublishMapForm({ client, children }) {
  // State
  const [territoryMap, setTerritoryMap] = useState({});
  const [territoryMapOptions, setTerritoryMapOptions] = useState([]);
  const [terrClientDescriptions, setTerrClientDescriptions] = useState({});
  const [mapStudyType, setMapStudyType] = useState("");
  // TODO: Refactor loading after redux PR
  const [loading, setLoading] = useState(false); // Need to refactor after Redux PR is merge
  const [published, setPublished] = useState(false);
  const [error, setError] = useState(false);

  useTerrClientDescriptions(setTerrClientDescriptions);

  async function submitForm() {
    setLoading(true);
    await API.request({
      url: `/di/territory/clients/${encodeURIComponent(client)}/maps/${encodeURIComponent(territoryMap.sk)}/publish`,
      method: "PUT",
    }).then(
      (r) => {
        if (r.data) {
          setPublished(r.data);
          setLoading(false);
        }
      },
      (e) => {
        setError(e);
        setLoading(false);
        return null;
      },
    );
  }

  async function handleSubmit(event) {
    event.preventDefault();
    await submitForm();
  }

  useEffect(() => {
    setLoading(true);
    setTerritoryMapOptions([]); // Reset value
    API.request({ url: `/di/territory/clients/${encodeURIComponent(client)}/maps`, method: "GET" }).then((response) => {
      const data = response.data.filter((d) => d.study === mapStudyType);
      setTerritoryMapOptions(data);
      setLoading(false);
    });
  }, [client, mapStudyType]);

  return (
    <form noValidate autoComplete="off" onSubmit={handleSubmit}>
      <Typography gutterBottom variant="h5" component="h2">
        Publish Territory Map Form
      </Typography>
      <Typography variant="body2" color="textSecondary" component="h3">
        {`Client: ${terrClientDescriptions[client]}`}
      </Typography>
      <OptionSelect
        required
        style={{ width: "100%", marginBottom: 10 }}
        value={mapStudyType}
        onChange={setMapStudyType}
        options={mapStudyTypeOptions}
        label="Map Study"
      />
      <Autocomplete
        autoComplete="off"
        style={{ width: "100%" }}
        value={territoryMap}
        onChange={(event, newValue) => {
          setTerritoryMap(newValue);
        }}
        options={territoryMapOptions}
        autoHighlight
        getOptionLabel={(option) => option.territory_map_name}
        renderOption={(option) => (
          <>
            <b>{option.territory_map_name}</b>
            {"  "}
            {option.published_time ? <CheckCircleOutlineIcon color="primary" /> : <WarningIcon color="secondary" />}
            {"  "}
            {option.published_time ? `Published: ${option.published_time}` : "Unpublished"}
          </>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Territory Maps"
            variant="outlined"
            inputProps={{
              ...params.inputProps,
              autoComplete: "new-password", // disable autocomplete and autofill from browser
            }}
          />
        )}
      />
      <br />
      {loading && <Alert severity="info">Loading...</Alert>}
      {published && <Alert severity="success">Your map was published!</Alert>}
      {error && <Alert severity="error">{`${error}`}</Alert>}
      <br />
      { children }
    </form>
  );
}
