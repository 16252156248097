import React, { useEffect, useState } from "react";
import { Divider, List, ListItem, ListItemText } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import ReactJson from "react-json-view";

import { API } from "../../../utils/api";
import TaskClientProfile from "./TaskClientProfile";

export default function TaskPayload({ taskId }) {
  const [taskPayload, setTaskPayload] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    const url = `/tasks/${taskId}/config`;
    API.request({ url, method: "GET" }).then(
      (response) => {
        setTaskPayload(response.data.payload);
        setLoading(false);
      },
      (error) => {
        console.error(error);
        setLoading(false);
      },
    );
  }, [taskId, setTaskPayload]);

  if (loading) {
    return <Skeleton style={{ margin: "0.5em" }} />;
  }

  if (taskPayload && Object.keys(taskPayload).length) {
    return (
      <div>
        <ReactJson
          src={taskPayload}
          name={false}
          groupArraysAfterLength={1000}
          displayDataTypes={false}
          displayObjectSize={false}
          displayArrayKey={false}
          shouldCollapse={(field) => field.type === "array" && field.src.length > 5}
        />
        {taskPayload?.client_profile_id && (
          <TaskClientProfile profileId={taskPayload?.client_profile_id} study={taskPayload?.study} />
        )}
      </div>
    );
  }

  return (
    <List>
      <Divider component="li" />
      <ListItem key="no-config">
        <ListItemText secondary="No Config" />
      </ListItem>
    </List>
  );
}
