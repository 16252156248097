import React from "react";
import { Button, useTheme } from "@material-ui/core";
import { Link } from "react-router-dom";

export default function WorkflowTaskChip({ WorkflowStatus, Taskstatus, label, workflowId, taskId }) {
  const theme = useTheme();

  function getStyle(statusString) {
    switch (statusString) {
    case "SUCCEEDED": {
      return theme.common.chip.successChip;
    }
    case "FAILED": {
      return theme.common.chip.errorChip;
    }
    case "ABORTED": {
      return theme.common.chip.abortedChip;
    }
    case "DRAFT": {
      return theme.common.chip.draftChip;
    }
    case "RUNNING": {
      return theme.common.chip.runningChip;
    }
    case "QUEUED": {
      return theme.common.chip.queuedChip;
    }
    case "RUNNING_QUEUE": {
      return theme.common.chip.runningQueueChip;
    }
    default:
      return theme.common.chip.baseChip;
    }
  }

  return (
    <Button
      component={Link}
      disabled={["RUNNING", "SUCCEEDED", "FAILED", "ABORTED"].includes(WorkflowStatus) && Taskstatus === "DRAFT"}
      to={Taskstatus === "DRAFT" ? `/edit/${workflowId}/${taskId}` : `/details/${workflowId}/${taskId}`}
      target="_blank"
      style={{ ...getStyle(Taskstatus), margin: 3, textAlign: "center" }}
    >
      {label}
    </Button>
  );
}
