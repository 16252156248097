import React, { useEffect } from "react";
import { Chip, FormControl, InputAdornment, TextField, Typography } from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Form from "@rjsf/material-ui";
import { useSelector } from "react-redux";

export default function DatasetLockingForm({ children, taskConfig, setTaskConfig, submitHandler, studyType, displayHeader, setIsRequired }) {
  const productionCycles = useSelector((state) => state.formOptions.productionCycle);
  useEffect(() => {
    setTaskConfig((preConfig) => ({ ...preConfig, study: studyType }));
    setIsRequired((prevState) => ([...prevState, "production_cycle", "lock"]));
  }, [setTaskConfig, studyType, setIsRequired]);

  return (
    <Form
      schema={{}}
      onSubmit={submitHandler}
    >
      <div style={{ display: "flex", alignItems: "center" }}>
        <Typography variant="subtitle2" style={{ marginRight: "8px", marginBottom: "16px" }}>Task Name :</Typography>
        <Chip style={{ marginBottom: "16px" }} variant="outlined" label={displayHeader(["file_type"])} />
      </div>
      <FormControl fullWidth variant="outlined">
        <InputLabel required htmlFor="inputLabel-production-cycle-select">Production Cycle</InputLabel>
        <Select
          required
          style={{ width: 500, marginBottom: 30 }}
          value={taskConfig.production_cycle || ""}
          onChange={(e) => {
            setTaskConfig((prevTaskConfig) => ({ ...prevTaskConfig, production_cycle: e.target.value }));
          }}
          label="Production Cycle"
          inputProps={{
            name: "production cycle",
            id: "inputLabel-production-cycle-select",
          }}
        >
          {productionCycles.map((quarter) => (
            <MenuItem key={quarter} value={quarter}>
              {quarter}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl fullWidth margin="normal">
        <TextField
          required
          style={{ width: 500, marginBottom: 0 }}
          label="Dataset name: lock (ADD suffix):"
          value={taskConfig.lock || ""}
          onChange={(e) => {
            setTaskConfig((prevTaskConfig) => ({ ...prevTaskConfig, lock: e.target.value }));
          }}
          InputProps={{
            startAdornment: <InputAdornment position="start">{(taskConfig.production_cycle || "").concat("_")}</InputAdornment>,
          }}
        />
      </FormControl>
      {children}
    </Form>
  );
}
