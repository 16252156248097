/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/no-named-as-default */
import React, { useMemo, useState } from "react";

import { Grid, Box, Snackbar, Tabs, Tab } from "@material-ui/core";
import {
  Gradient as PreWarnStatsIcon,
  PlaylistAdd as RunPreWarmIcon,
  ListAlt as PreWarmStatusIcon,
} from "@material-ui/icons";
import MuiAlert from "@material-ui/lab/Alert";

import { useSelector } from "react-redux";
import PrewarmStatsTab from "./PrewarmStatsTab";
import TaskCreateWizardPreWarm from "./tasks/create_wizard";
import TabSelectorViewPreWarm from "./tasks/status";

export function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function Prewarm() {
  const [selectedTab, setSelectedTab] = useState("pre-warm task logs");
  const [submitMessage, setSubmitMessage] = useState("");
  const [severity, setSeverity] = useState("info");
  const updateSubmitMessage = (message, sever = "info") => {
    setSubmitMessage(message);
    setSeverity(sever);
  };
  const isDeveloperAccount = useSelector(state => state.application.isDeveloperAccount);

  const tabContents = useMemo(
    () => {
      if (selectedTab === "pre-warm task logs") {
        return <TabSelectorViewPreWarm />;
      }
      if (selectedTab === "pre-warm stats") {
        return <PrewarmStatsTab />;
      }
      return (
        <TaskCreateWizardPreWarm setSubmitMessage={updateSubmitMessage} isDeveloperAccount={isDeveloperAccount} />
      );
    },
    [selectedTab],
  );

  return (
    <Grid container spacing={2}>
      <Box style={{ width: "100%" }}>
        <Tabs
          value={selectedTab}
          onChange={(e, tabIndex) => { setSelectedTab(tabIndex); }}
          textColor="secondary"
          indicatorColor="secondary"
          aria-label="secondary tabs example"
        >
          <Tab value="pre-warm task logs" label="Pre-Warm Status" icon={<PreWarmStatusIcon />} />
          <Tab value="pre-warm stats" label="Pre-Warm Stats" icon={<PreWarnStatsIcon />} />
          <Tab value="run pre-warm" label="Run Pre-Warm" icon={<RunPreWarmIcon />} />
        </Tabs>
      </Box>
      <Box style={{ width: "100%", marginLeft: 10 }}>
        {tabContents}
      </Box>
      <Snackbar
        open={Boolean(submitMessage && submitMessage.length > 0)}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert onClose={() => setSubmitMessage("")} severity={severity}>
          {submitMessage}
        </Alert>
      </Snackbar>
    </Grid>
  );
}
