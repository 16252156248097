import React, { useState } from "react";
import { Card, CardContent, Box, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { useSelector } from "react-redux";
import usePageTitle from "../../hooks/usePageTitle";
import { ClientSpecificHeaderMapping } from "./ClientSpecificHeaderMapping";
import { CommonHeaderMapping } from "./CommonHeaderMapping";
import { CommonTransform } from "./CommonTransform";
import TransformLog from "./TransformLog";

export default function PreprocessingTransform({ devOnly }) {
  usePageTitle("Preprocessing Transform");

  const isDeveloperAccount = useSelector(state => state.application.isDeveloperAccount);
  const [transform, setTransform] = useState("");
  const transformTypes = [
    { id: "commonTransform", label: "Common Transform", visible: isDeveloperAccount },
    { id: "commonHeaderMapping", label: "Common Header Mapping", visible: isDeveloperAccount },
    { id: "clientSpecificTransform", label: "Client Specific Header Mapping", visible: true },
    { id: "HeaderTransformLogs", label: "Header Transform Logs", visible: true },
  ];

  const showOptions = () => transformTypes
    .filter((option) => option.visible === true)
    .sort((a, b) => {
      if (a.label < b.label) return -1;
      if (a.label > b.label) return 1;
      return 0;
    });

  return (
    <div>
      <Card style={{ marginBottom: "1em" }}>
        <CardContent>
          <Box display="flex" justifyContent="space-between" p={2}>
            <Autocomplete
              disableClearable
              fullWidth
              options={showOptions()}
              disablePortal
              id="combo-box-demo"
              getOptionLabel={(option) => {
                if (option.label !== undefined) return option.label;
                return "";
              }}
              renderInput={(params) => <TextField {...params} label="Select Preprocessing Transform" />}
              onChange={(e, value) => setTransform(value)}
            />
          </Box>
        </CardContent>
      </Card>
      {transform !== "" && <ShowTransform transformTypes={transformTypes} transformType={transform} devOnly={devOnly} />}
    </div>
  );
}

const ShowTransform = ({ transformTypes, transformType, devOnly }) => {
  const currentTransform = transformTypes.filter((type) => type.label === transformType.label)[0];
  return (
    <div>
      {currentTransform.id === "commonTransform" && <CommonTransform />}
      {currentTransform.id === "commonHeaderMapping" && <CommonHeaderMapping />}
      {currentTransform.id === "clientSpecificTransform" && <ClientSpecificHeaderMapping devOnly={devOnly} />}
      {currentTransform.id === "HeaderTransformLogs" && <TransformLog />}
    </div>
  );
};
