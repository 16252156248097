import React, { useEffect } from "react";
import { Chip, FormControl, Typography } from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Form from "@rjsf/material-ui";
import { useSelector } from "react-redux";

export default function DDMissingBranches({ children, taskConfig, setTaskConfig, submitHandler, studyType, displayHeader, setIsRequired }) {
  const productionCycles = useSelector((state) => state.formOptions.productionCycle);
  useEffect(() => {
    setTaskConfig((preConfig) => ({ ...preConfig, study: studyType }));
    setIsRequired((prevState) => ([...prevState, "start_quarter", "end_quarter"]));
  }, [setTaskConfig, studyType, setIsRequired]);

  return (
    <Form
      schema={{}}
      onSubmit={submitHandler}
    >
      <div style={{ display: "flex", alignItems: "center" }}>
        <Typography variant="subtitle2" style={{ marginRight: "8px", marginBottom: "16px" }}>Task Name :</Typography>
        <Chip style={{ marginBottom: "16px" }} variant="outlined" label={displayHeader(["file_type"])} />
      </div>
      <Typography variant="h4" style={{ backgroundColor: "orange", marginBottom: "10px" }}>Discovery Data</Typography>
      <FormControl fullWidth variant="outlined">
        <InputLabel required htmlFor="inputLabel-start-quarter-select">Start Quarter</InputLabel>
        <Select
          required
          style={{ width: 500, marginBottom: 20 }}
          value={taskConfig.start_quarter || ""}
          onChange={(e) => {
            setTaskConfig((prevTaskConfig) => ({ ...prevTaskConfig, start_quarter: e.target.value }));
          }}
          label="Start Quarter"
          inputProps={{
            name: "start quarter",
            id: "inputLabel-start-quarter-select",
          }}
        >
          {productionCycles.map((quarter) => (
            <MenuItem key={quarter} value={quarter}>
              {quarter}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl fullWidth variant="outlined">
        <InputLabel required htmlFor="inputLabel-end-quarter-select">End Quarter</InputLabel>
        <Select
          required
          style={{ width: 500, marginBottom: 20 }}
          value={taskConfig.end_quarter || ""}
          onChange={(e) => {
            setTaskConfig((prevTaskConfig) => ({ ...prevTaskConfig, end_quarter: e.target.value }));
          }}
          label="End Quarter"
          inputProps={{
            name: "end quarter",
            id: "inputLabel-end-quarter-select",
          }}
        >
          {productionCycles.map((quarter) => (
            <MenuItem key={quarter} value={quarter}>
              {quarter}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {children}
    </Form>
  );
}
