/* eslint-disable consistent-return */
import React, { useMemo, useCallback, useState } from "react";
import { Dialog, DialogContent, Box, Tab, Tabs, Snackbar, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { PlaylistAdd as PlaylistAddIcon, AddCircleOutline as AddCircleIcon } from "@material-ui/icons";
import MuiAlert from "@material-ui/lab/Alert";
import { CreateWorkflow as CreateWorkflowAndAddTask, ShowWorkflowModal } from "../views/tasks/create_wizard/WorkflowModal";
import CircularProgress from "./Spinner";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function AddToWorkflow({ loading, isRequired, requiredInputAdded, submitHandler, taskConfig }) {
  const [selectedTab, setSelectedTab] = useState("addToWorkflow");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  const [snackbarMessage, setSnackbarMessage] = useState({ severity: "", message: "" });
  const [open, setOpen] = useState(false);

  function quarterToInt(Qstr) {
    return parseInt(Qstr.split("Q").join(""), 10);
  }
  function validateData(requiredFields, configFields) {
    //  For Time Period
    if (requiredFields.includes("time_period")) {
      if ("time_period" in configFields && configFields.time_period !== "") {
        if (configFields.time_period === "custom") {
          if ("start_quarter" in configFields && "end_quarter" in configFields && configFields.start_quarter !== "" && configFields.end_quarter !== "") {
            if (quarterToInt(configFields.start_quarter) > quarterToInt(configFields.end_quarter)) {
              setSnackbarMessage({
                severity: "error",
                message: "Start quarter cannot be higher than End quarter",
              });
              return false;
            }
          } else {
            setSnackbarMessage({
              severity: "error",
              message: "Start quarter & End quarter is Required ",
            });
            return false;
          }
        } else if (typeof (configFields.time_period) === "number") {
          console.log();
        }
      } else {
        setSnackbarMessage({
          severity: "error",
          message: "Time Period is Required ",
        });
        return false;
      }
    }

    //  For start and End Quarters
    if (!(requiredFields.includes("time_period"))
      && requiredFields.includes("start_quarter")
      && requiredFields.includes("end_quarter")
    ) {
      if ("start_quarter" in configFields && "end_quarter" in configFields) {
        if (quarterToInt(configFields.start_quarter) > quarterToInt(configFields.end_quarter)) {
          setSnackbarMessage({
            severity: "error",
            message: "Start quarter cannot be higher than End quarter",
          });
          return false;
        }
      } else {
        setSnackbarMessage({
          severity: "error",
          message: "Start quarter & End quarter is Required ",
        });
        return false;
      }
    }

    //  For Delete start and Delete End Quarters
    if (
      requiredFields.includes("delete_start_quarter")
      && requiredFields.includes("delete_end_quarter")
    ) {
      if (
        "delete_start_quarter" in configFields
        && "delete_start_quarter" in configFields
      ) {
        if (
          quarterToInt(configFields.delete_start_quarter)
          > quarterToInt(configFields.delete_end_quarter)
        ) {
          setSnackbarMessage({
            severity: "error",
            message: "Start quarter cannot be higher than End quarter",
          });
          return false;
        }
      } else {
        setSnackbarMessage({
          severity: "error",
          message: "Start quarter & End quarter is Required ",
        });
        return false;
      }
    }

    // For Territory Map
    if (requiredFields.includes("territory_settings_1")) {
      if (
        "territory_settings_1" in configFields
        && Object.keys(configFields.territory_settings_1).length === 0
      ) {
        setSnackbarMessage({
          severity: "error",
          message: "Territory Map in Territory Setting 1 is Required ",
        });
        return false;
      }
    }

    // production_cycle
    if (requiredFields.includes("production_cycle")) {
      if (!("production_cycle" in configFields)) {
        setSnackbarMessage({
          severity: "error",
          message: "Kindly select Production Cycle",
        });
        return false;
      }
    }

    // For Lock
    if (
      requiredFields.includes("lock")
      || requiredFields.includes("lock_required")
      || requiredFields.includes("lock_to_compare")
    ) {
      if (
        !("lock" in configFields)
        && !("lock_required" in configFields)
        && !("lock_to_compare" in configFields)
      ) {
        setSnackbarMessage({
          severity: "error",
          message: "Kindly select Lock",
        });
        return false;
      }
    }

    // sales_volume_table
    if (requiredFields.includes("sales_volume_table")) {
      if (!("sales_volume_table" in configFields)) {
        setSnackbarMessage({
          severity: "error",
          message: "Kindly select Sales Volumn Table",
        });
        return false;
      }
    }

    // For End Quarter
    if (!(requiredFields.includes("time_period")) && requiredFields.includes("end_quarter")) {
      if (!("end_quarter" in configFields)) {
        setSnackbarMessage({
          severity: "error",
          message: "Kindly select End Quarter",
        });
        return false;
      }
    }

    // For Data Revision ID
    if (requiredFields.includes("dataset_revision_id")) {
      if (!("dataset_revision_id" in configFields)) {
        setSnackbarMessage({
          severity: "error",
          message: "Kindly select Data Revision ID",
        });
        return false;
      }
    }

    // For Data Revision Name
    if (requiredFields.includes("dataset_revision_name")) {
      if (!("dataset_revision_name" in configFields)) {
        setSnackbarMessage({
          severity: "error",
          message: "Kindly select Data Revision Name",
        });
        return false;
      }
    }

    // Sync
    if (requiredFields.includes("sync")) {
      if (!("sync" in configFields)) {
        setSnackbarMessage({
          severity: "error",
          message: "Kindly select Sync",
        });
        return false;
      }
    }

    // New Client Id
    if (requiredFields.includes("new_client_id")) {
      if (!("new_client_id" in configFields)) {
        setSnackbarMessage({
          severity: "error",
          message: "New Client Id is Empty",
        });
        return false;
      }
    }

    // New Client Name
    if (requiredFields.includes("new_client_name")) {
      if (!("new_client_name" in configFields)) {
        setSnackbarMessage({
          severity: "error",
          message: "New Client Name is Empty",
        });
        return false;
      }
    }

    // New Client Abbreviation
    if (requiredFields.includes("new_client_abbreviation")) {
      if (!("new_client_abbreviation" in configFields)) {
        setSnackbarMessage({
          severity: "error",
          message: "New Client Abbreviation is Empty",
        });
        return false;
      }
    }

    // Table Name
    if (requiredFields.includes("table_name")) {
      if (!("table_name" in configFields)) {
        setSnackbarMessage({
          severity: "error",
          message: "Kindly select Table Name",
        });
        return false;
      }
    }

    // File Options
    if (requiredFields.includes("file_options")) {
      if (!("file_options" in configFields)) {
        setSnackbarMessage({
          severity: "error",
          message: "Kindly select File Options",
        });
        return false;
      }
    }

    // Multiple Client Ids
    if (
      requiredFields.includes("multiple_client_ids")
      && !configFields?.multiple_client_ids?.length
    ) {
      setSnackbarMessage({
        severity: "error",
        message: "Kindly select at least one Client",
      });
      return false;
    }

    // Aggregate Fields
    if (
      requiredFields.includes("aggregate_fields")
      && !configFields?.aggregate_fields?.length
    ) {
      setSnackbarMessage({
        severity: "error",
        message: "Kindly select at least one Aggregate Field",
      });
      return false;
    }

    // ANN Type
    if (requiredFields.includes("ann_type")) {
      if (!("ann_type" in configFields)) {
        setSnackbarMessage({
          severity: "error",
          message: "Kindly select ANN Type",
        });
        return false;
      }
    }

    // Annuity Type
    if (requiredFields.includes("annuity_type")) {
      if (!("annuity_type" in configFields)) {
        setSnackbarMessage({
          severity: "error",
          message: "Kindly select Annuity Type",
        });
        return false;
      }
    }

    // RIA Type
    if (requiredFields.includes("ria_type")) {
      if (!("ria_type" in configFields)) {
        setSnackbarMessage({
          severity: "error",
          message: "Kindly select RIA Type",
        });
        return false;
      }
    }

    // Insertion Type
    if (
      requiredFields.includes("append_replace")
      || requiredFields.includes("insertion_type")
    ) {
      if (
        (!("append_replace" in configFields)
          || configFields?.append_replace === "")
        && (!("insertion_type" in configFields) || configFields?.insertion_type === "")
      ) {
        setSnackbarMessage({
          severity: "error",
          message: "Kindly select Insertion Type",
        });
        return false;
      }
    }

    // Zone Map Name
    if (requiredFields.includes("zone_map_name")) {
      if (
        !("zone_map_name" in configFields)
        || configFields?.zone_map_name === ""
      ) {
        setSnackbarMessage({
          severity: "error",
          message: "Kindly select Zone Map Name",
        });
        return false;
      }
    }

    // Client Profile
    if (requiredFields.includes("client_profile_id") || requiredFields.includes("client")) {
      if (
        (!("client_profile_id" in configFields)
          || configFields?.client_profile_id === "") || (!("client" in configFields)
            || configFields?.client === "")
      ) {
        setSnackbarMessage({
          severity: "error",
          message: "Kindly select Client Profile",
        });
        return false;
      }
    }

    // OTT Type
    if (requiredFields.includes("ott_type_settings")) {
      if (
        !("ott_type_settings" in configFields)
      ) {
        setSnackbarMessage({
          severity: "error",
          message: "Kindly select OTT Type",
        });
        return false;
      }
    }

    // Number of past Quarter
    if (requiredFields.includes("num_past_quarters")) {
      if (
        !("num_past_quarters" in configFields)
        || configFields?.num_past_quarters === ""
      ) {
        setSnackbarMessage({
          severity: "error",
          message: "Kindly select Number of Past Quarter",
        });
        return false;
      }
    }

    // Number of Rolling Quarter
    if (requiredFields.includes("num_rolling_quarters")) {
      if (
        !("num_rolling_quarters" in configFields)
        || configFields?.num_rolling_quarters === ""
      ) {
        setSnackbarMessage({
          severity: "error",
          message: "Kindly select Number of Rolling Quarter",
        });
        return false;
      }
    }

    // Metric Display Type (Cell Content)
    if (requiredFields.includes("metric_display_type")) {
      if (
        !("metric_display_type" in configFields)
        || configFields?.metric_display_type === ""
      ) {
        setSnackbarMessage({
          severity: "error",
          message: "Kindly select Metric Display Type (Cell Content)",
        });
        return false;
      }
    }

    return true;
  }

  const useStyles = makeStyles({ dialogPaper: { overflowX: "hidden" } });

  const classes = useStyles();

  const handleOpen = () => {
    const dataValidation = validateData(isRequired, taskConfig);
    if (!dataValidation) {
      setOpenSnackbar(true);
    } else {
      setSelectedTab("addToWorkflow");
      setOpen(true);
    }
  };

  const handleClose = useCallback(() => {
    localStorage.removeItem("workflowName");
    localStorage.removeItem("workflowId");
    setOpen(false);
  }, []);

  const tabContents = useMemo(() => {
    if (selectedTab === "addToWorkflow") {
      return (
        <ShowWorkflowModal
          open={open}
          submitHandler={submitHandler}
          handleClose={handleClose}
          taskConfig={taskConfig}
        />
      );
    }

    return (
      <CreateWorkflowAndAddTask
        handleClose={handleClose}
        submitHandler={submitHandler}
        taskConfig={taskConfig}
        createWorkflowAndAddTask
      />
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTab, open, submitHandler, handleClose]);

  return (
    <>
      <Button onClick={handleOpen} disabled={!requiredInputAdded}>
        Add to Workflow
      </Button>
      {open && (
        <Box>
          <Dialog
            disableBackdropClick
            open={open}
            onClose={handleClose}
            fullWidth
            classes={{
              paper: classes.dialogPaper,
            }}
          >
            <Tabs
              variant="fullWidth"
              value={selectedTab}
              onChange={(e, tabIndex) => {
                setSelectedTab(tabIndex);
              }}
              textColor="primary"
              indicatorColor="primary"
              style={{
                padding: 0,
                width: "100%",
              }}
              TabIndicatorProps={{
                style: {
                  height: "4px",
                  borderTopLeftRadius: "4px",
                  borderTopRightRadius: "4px",
                },
              }}
            >
              <Tab
                style={{
                  borderRight: "1px solid grey",
                  borderBottom: "1px solid grey",
                }}
                value="addToWorkflow"
                label="Select and Add"
                icon={<PlaylistAddIcon />}
              />
              <Tab
                style={{
                  borderBottom: "1px solid grey",
                }}
                value="createWorkflowAndAddTask"
                label="Create and Add"
                icon={<AddCircleIcon />}
              />
            </Tabs>
            <Box style={{ width: "100%", margin: 8, height: "50%" }}>
              {tabContents}
              {loading && (
                <Dialog
                  disableBackdropClick
                  open={loading}
                  PaperProps={{
                    style: {
                      backgroundColor: "transparent",
                      boxShadow: "none",
                    },
                  }}
                  maxWidth="xs"
                >
                  <DialogContent>
                    <CircularProgress
                      style={{ width: "50px", height: "50px" }}
                    />
                  </DialogContent>
                </Dialog>
              )}
            </Box>
          </Dialog>
        </Box>
      )}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={4000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbarMessage.severity}
        >
          {snackbarMessage.message}
        </Alert>
      </Snackbar>
    </>
  );
}
