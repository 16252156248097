import React, { useEffect } from "react";
import { Typography } from "@material-ui/core";
import Form from "@rjsf/material-ui";
import CSVUpload from "../../components/input/CSVUpload";

import { fileType } from "./common/BaseForm";

export default function LoadVerifiedAddresses({ children, taskConfig, setTaskConfig, csv, setCsv, submitHandler, studyType }) {
  useEffect(() => setTaskConfig((preConfig) => ({ ...preConfig, study: studyType, file_type: fileType.SECONDARY_FILES })), [setTaskConfig, studyType]);

  return (
    <Form
      schema={{}}
      onSubmit={submitHandler}
    >
      <Typography variant="h6">
        {studyType.toUpperCase()}
        {" Load Verified Addresses"}
      </Typography>
      <CSVUpload
        onChange={(update) => {
          setCsv({ attachment: update.csv });
        }}
        value={{ csv, ...taskConfig }}
      />
      {children}
    </Form>
  );
}
