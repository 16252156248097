/* eslint-disable react/no-unstable-nested-components */
import React from "react";
import { Select, MenuItem } from "@material-ui/core";
import MaterialTable from "material-table";
import useSWR from "swr";
import { getDataRevisionsAlias, getDataRevisions, updateDataRevisionAlias } from "../../../utils/api";

export default function DataRevisionAlias() {
  const { data: datarevisionsAlias, error: datarevisionsAliasError, mutate } = useSWR("/datarevisions/alias", getDataRevisionsAlias);
  const { data: datarevisions, error: datarevisionsError } = useSWR("/datarevisions", getDataRevisions);

  if (datarevisionsAliasError || datarevisionsError) return <div>Error loading...</div>;

  const columns = [
    { title: "Alias",
      field: "display_alias",
      editable: "never",
    },
    { title: "Revision",
      field: "revision",
      editable: "onUpdate",
      editComponent: ({ value, onChange }) => (
        <Select
          value={value}
          onChange={(event) => onChange(event.target.value)}
        >
          {datarevisions.map(s => (
            <MenuItem
              key={s.id}
              value={s.revision}
            >
              {s.revision}
            </MenuItem>
          ))}
        </Select>
      ),
    },
    { title: "Notes",
      field: "notes",
      editable: "onUpdate",
    },
    { title: "Updated By",
      field: "updated_by",
      editable: "never",
    },
    { title: "Updated At",
      field: "updated_at",
      type: "datetime",
      editable: "never",
    },
  ];

  return (
    <div style={{ width: "100%" }}>
      <MaterialTable
        isLoading={!datarevisionsAlias || !datarevisions}
        columns={columns}
        data={datarevisionsAlias}
        title="Data Revision Alias"
        options={{
          search: false,
          paging: false,
        }}
        editable={{
          onRowUpdate: async (newData) => {
            const newObj = datarevisions.find((datarev) => newData.revision === datarev.revision);
            // update data revision alias
            await updateDataRevisionAlias(newData.id, { dataset_revision_id: newObj.id, notes: newData.notes });
            // refetch and update UI
            mutate();
          },
        }}
      />
    </div>
  );
}
