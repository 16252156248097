import React, { useState, useMemo } from "react";
import { Button, makeStyles, Tab, Tabs, Tooltip, Typography, useTheme } from "@material-ui/core";
import { CloudDownloadOutlined, Folder, QueryBuilderOutlined, ReportProblemOutlined } from "@material-ui/icons";
import useSWR from "swr";
import TableLoadingOverlay from "../../../components/MaterialTableLoadingOverlay";
import { getQaDashboardQuarters, getQaDashboardReports } from "../../../utils/api";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    height: 500,
    width: "100%",
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
    width: "15%",
  },
}));

function getStyle(statusString, theme) {
  switch (statusString) {
  case "SUCCEEDED": {
    return theme.common.chip.successChip.backgroundColor;
  }
  case "FAILED": {
    return theme.common.chip.errorChip.backgroundColor;
  }
  default:
    return undefined;
  }
}

const iconMap = {
  SUCCEEDED: CloudDownloadOutlined,
  FAILED: ReportProblemOutlined,
  RUNNING: QueryBuilderOutlined,
};

function ReportButtonIcons({ reportStatus }) {
  const IconComponent = iconMap[reportStatus];

  if (IconComponent) {
    return <IconComponent />;
  }

  return undefined;
}

export default function ReportDownloads() {
  const classes = useStyles();
  const theme = useTheme();
  const memoizedLoadingOverlay = useMemo(() => <TableLoadingOverlay />, []);
  const [reportsQuarter, setReportsQuarter] = useState("");
  const { data: quarters, error: quartersError } = useSWR("/lms/api/v1/qa/get_lms_qa_dashboard_quarters", url => getQaDashboardQuarters(url, setReportsQuarter), { revalidateOnFocus: false });
  const { data: reports, error: reportsError } = useSWR(`/lms/api/v1/qa/get_lms_qa_dashboard_reports?production_cycle=${reportsQuarter}`, getQaDashboardReports, { revalidateOnFocus: false });

  const tabContents = useMemo(
    () => (
      <div style={{ width: "100%" }}>
        {reports?.map((report) => (
          (
            <React.Fragment key={`${report.fileName}`}>
              <Tooltip
                title={`Status : ${report.reportStatus?.toUpperCase()}`}
              >
                <span>
                  <Button
                    variant="outlined"
                    href={(report.reportStatus !== "SUCCEEDED" && report.downloadAvailable !== true) ? "#" : report.downloadLink}
                    style={{
                      marginLeft: 30,
                      marginTop: 20,
                      width: "600px",
                      height: "50px",
                      borderColor: getStyle(report.reportStatus, theme),
                      color: getStyle(report.reportStatus, theme),
                    }}
                    disabled={report.reportStatus === "RUNNING"}
                    size="large"
                    startIcon={<ReportButtonIcons reportStatus={report.reportStatus?.toUpperCase()} />}
                  >
                    <Typography variant="subtitle1" style={{ wordBreak: "break-word", marginLeft: 10, marginRight: 10 }}>
                      {report.fileName}
                    </Typography>
                  </Button>
                </span>
              </Tooltip>
              <br />
            </React.Fragment>
          )
        ))}
      </div>
    ),
    [reports, theme],
  );

  const ReportsDownloadSection = useMemo(() => (
    (quarters) ? (
      <div className={classes.root}>
        <Tabs
          orientation="vertical"
          variant="scrollable"
          value={reportsQuarter}
          onChange={(e, value) => setReportsQuarter(value)}
          className={classes.tabs}
          size="large"
        >
          {quarters?.map((quarter) => <Tab icon={<Folder />} key={quarter} value={quarter} label={quarter} />)}
        </Tabs>
        {(!reports && !reportsError) ? memoizedLoadingOverlay : tabContents}
      </div>
    )
      : (
        <div style={{ textAlign: "center", marginTop: 50 }}>
          <ReportButtonIcons reportStatus="FAILED" />
          <Typography variant="h5" style={{ wordBreak: "break-word", marginLeft: 10, marginRight: 10 }}>
            No Reports found for any Quarter.
            Run LMS QA Dashboard Job.
          </Typography>
        </div>
      )
  ), [classes.root, classes.tabs, memoizedLoadingOverlay, quarters, reports, reportsError, reportsQuarter, tabContents]);

  return (
    ((!quarters && !quartersError) ? memoizedLoadingOverlay : ReportsDownloadSection)
  );
}
