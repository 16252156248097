import React, { useEffect } from "react";
import { Chip, Typography } from "@material-ui/core";
import Form from "@rjsf/material-ui";

export default function AthenaSyncForm({ children, setTaskConfig, submitHandler, studyType, displayHeader }) {
  useEffect(() => {
    setTaskConfig((prevTaskConfig) => ({ ...prevTaskConfig, study: studyType }));
  }, [setTaskConfig, studyType]);

  const deploymentEnv = process.env.REACT_APP_CUSTOM_NODE_ENV;

  return (
    <Form
      schema={{}}
      onSubmit={submitHandler}
    >
      <div style={{ display: "flex", alignItems: "center" }}>
        <Typography variant="subtitle2" style={{ marginRight: "8px", marginBottom: "16px" }}>Task Name :</Typography>
        <Chip style={{ marginBottom: "16px" }} variant="outlined" label={displayHeader(["file_type"])} />
      </div>
      <Typography variant="h6">Athena Data Sync</Typography>
      <Typography variant="subtitle1" style={{ margin: 15 }}>
        {"This job will sync Production Athena data to "}
        <b>{`${deploymentEnv}`}</b>
        <br />
      </Typography>
      {children}
    </Form>
  );
}
