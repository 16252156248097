import React from "react";
import { makeStyles } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";

const useStyles = makeStyles(() => ({
  alerts: {
    maxHeight: 100,
    maxWidth: "100%",
    overflowY: "auto",
    overflowX: "hidden",
    overflowWrap: "break-word",
  },
}));

export default function FormAlerts({
  saving,
  success,
  error,
  setSuccess,
  setError,
}) {
  const classes = useStyles();
  return (
    <>
      {saving && <Alert severity="info" className={classes.alerts}>Saving...</Alert>}
      {success && <Alert onClose={() => setSuccess(false)} severity="success" className={classes.alerts}>{`${success}`}</Alert>}
      {error && <Alert onClose={() => setError("")} severity="error" className={classes.alerts}>{`${error}`}</Alert>}
    </>
  );
}
