import React, { useEffect, useMemo, useState } from "react";
import { Button, Checkbox, Chip, Dialog, DialogActions, DialogContent, DialogTitle, Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import MaterialTable from "material-table";
import TableLoadingOverlay from "../../../components/MaterialTableLoadingOverlay";
import { API } from "../../../utils/api";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function SetFileStatusDialog({ closeDialog, refreshTable, openDialog, taskList, clientRowData, states }) {
  const [data, setData] = useState([]);
  const [client, setClient] = useState(null);
  const [loading, setLoading] = useState(false);
  const [successSnackbarOpen, setSuccessSnackbarOpen] = useState(false);
  const [errorSnackbarOpen, setErrorSnackbarOpen] = useState(false);
  const memoizedLoadingOverlay = useMemo(() => <TableLoadingOverlay />, []);

  const submitData = () => {
    setLoading(true);
    API.request({
      url: "/lms/api/v1/file_load_monitor/set_clients_data",
      method: "POST",
      data: { ...states, client, not_expected_types: data },
    }).then(response => {
      if (response.status === 204) {
        setLoading(false);
        setSuccessSnackbarOpen(true);
        closeDialog();
        refreshTable(!states.refresh_table);
      }
    }).catch((error) => {
      console.error(error);
      setLoading(false);
      setErrorSnackbarOpen(true);
    });
  };

  const handleCheckboxStatusChange = (rowData) => {
    const dataUpdate = [...data];
    dataUpdate[rowData.tableData.id] = { ...rowData, not_expected: !rowData.not_expected };
    setData([...dataUpdate]);
  };

  const columns = [
    {
      title: "Task Name",
      field: "label",
      headerStyle: {
        textAlign: "center",
      },
      cellStyle: {
        textAlign: "center",
      },
      render: (rowData) => (
        <Chip
          variant="outlined"
          style={{
            width: 200,
            textAlign: "center",
            fontSize: "15px",
            backgroundColor: "white",
            color: "black",
          }}
          label={rowData.label}
        />
      ),
    },
    {
      title: "Set NOT EXPECTED",
      field: "not_expected",
      render: rowData => <Checkbox onChange={() => { handleCheckboxStatusChange(rowData); }} checked={rowData?.not_expected || false} name={rowData?.task_type} />,
      headerStyle: {
        textAlign: "center",
      },
      cellStyle: {
        textAlign: "center",
      },
    },
  ];

  useEffect(() => {
    const tempData = [];
    Object.keys(clientRowData).filter(element => element !== "name" && element !== "tableData").forEach(element => {
      const taskMatch = taskList.find(elem => (elem.field === element));
      tempData.push({ label: taskMatch?.headerName || element, task_type: element, not_expected: (clientRowData[element].status === "NOT_EXPECTED") });
    });
    setData(tempData);
    setClient(clientRowData.name.toUpperCase());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openDialog]);

  return (
    <div>
      <Dialog onClose={closeDialog} open={openDialog}>
        <DialogTitle>{`Update ${clientRowData.name.toUpperCase()} File Status`}</DialogTitle>
        <DialogContent dividers>
          <MaterialTable
            style={{ marginTop: "1.5em" }}
            columns={columns}
            data={data}
            options={{
              paging: false,
              headerStyle: {
                backgroundColor: "#30455c",
                color: "#97a9bc",
                borderBottom: "1px solid #ddd",
                borderRight: "1px solid #ddd",
              },
              cellStyle: { borderBottom: "1px solid #ddd" },
              padding: "dense",
              toolbar: false,
              emptyRowsWhenPaging: false,
            }}
            isLoading={loading}
            components={{
              OverlayLoading: () => memoizedLoadingOverlay,
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog} style={{ color: "red" }}>
            Cancel
          </Button>
          <Button onClick={submitData} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={successSnackbarOpen}
        autoHideDuration={3000}
        onClose={() => { setSuccessSnackbarOpen(false); }}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert severity="success" sx={{ width: "100%" }}>
          {`Succesfully saved status for ${clientRowData.name.toUpperCase()}`}
        </Alert>
      </Snackbar>
      <Snackbar
        open={errorSnackbarOpen}
        autoHideDuration={3000}
        onClose={() => { setErrorSnackbarOpen(false); }}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert severity="error" sx={{ width: "100%" }}>
          {`Error while saving status for ${clientRowData.name.toUpperCase()}. Please retry!`}
        </Alert>
      </Snackbar>
    </div>
  );
}

function ClientButton({ rowData, refreshTable, taskList, states }) {
  const [openDialog, setOpenDialog] = useState(false);
  return (
    <div>
      <Button
        variant="outlined"
        label={rowData.name.toUpperCase()}
        style={{
          width: "200px",
          fontSize: "15px",
          textAlign: "center",
          boxShadow: "1px 1px 1px rgba(0, 0, 0, 0.75)",
          marginBottom: "10px",
          marginTop: "10px",
        }}
        onClick={() => { setOpenDialog(rowData.name.toUpperCase() !== "TOTAL"); }}
      >
        {rowData.name.toUpperCase().replace("_", " ")}
      </Button>
      <SetFileStatusDialog
        openDialog={openDialog}
        closeDialog={() => { setOpenDialog(false); }}
        taskList={taskList}
        clientRowData={rowData}
        states={states}
        refreshTable={refreshTable}
      />
    </div>
  );
}

export default ClientButton;
