import React, { useEffect, useMemo, useRef, useState } from "react";
import { Button, Grid, Typography } from "@material-ui/core";
import { Fullscreen, FullscreenExit } from "@material-ui/icons";
import { useSelector } from "react-redux";
import usePageTitle from "../../hooks/usePageTitle";
import { API } from "../../utils/api";
import ErrorFallback from "./components/ErrorFallback";
import JobStatus from "./components/JobStatus";
import Renderer from "./components/Renderer";
import SideBarView from "./components/SideBar";
import TableOfContents from "./components/TableOfContents";
import { reportMappings } from "./constants";

export default function LmsQaDashboard({ selectedTab }) {
  const productionCycle = useSelector((state) => state.formOptions.productionCycle);
  const [loading, setLoading] = useState(false);
  const [showFallback, setShowFallback] = useState(false);
  const [expandState, setExpandState] = useState(false);
  const [currentView, setCurrentView] = useState(reportMappings[selectedTab][0].id);
  const [currentSheet, setCurrentSheet] = useState(reportMappings[selectedTab][0].items[0].id);
  const [currentSheetItems, setCurrentSheetItems] = useState(reportMappings[selectedTab].filter(item => item.id === currentView)[0]?.items.filter(item => item.id === currentSheet)[0].items);
  const [currentSheetApi, setCurrentSheetApi] = useState(reportMappings[selectedTab].filter(item => item.id === currentView)[0]?.items.filter(item => item.id === currentSheet)[0].dataURL);
  const [currentSheetData, setCurrentSheetData] = useState({});
  const [currentSheetParams, setCurrentSheetParams] = useState(reportMappings[selectedTab].filter(item => item.id === currentView)[0]?.items.filter(item => item.id === currentSheet)[0]?.apiParams);
  const currentSheetRef = useRef(reportMappings[selectedTab][0].items[0].id);
  usePageTitle("LMS QA Dashboard");

  useEffect(() => {
    setCurrentSheetParams(reportMappings[selectedTab].filter(item => item.id === currentView)[0]?.items.filter(item => item.id === currentSheet)[0]?.apiParams);
    setCurrentSheetItems(reportMappings[selectedTab].filter(item => item.id === currentView)[0]?.items.filter(item => item.id === currentSheet)[0].items);
    setCurrentSheetApi(reportMappings[selectedTab].filter(item => item.id === currentView)[0]?.items.filter(item => item.id === currentSheet)[0].dataURL);
  }, [currentView, currentSheet, selectedTab, currentSheetParams]);

  useEffect(() => {
    setShowFallback(false);
    if (currentSheetApi) {
      setLoading(true);
      const url = currentSheetApi;
      API.request({ url, method: "GET", params: { productionCycle: productionCycle[1], ...currentSheetParams } }).then(
        (response) => {
          if (currentSheetApi.includes(currentSheetRef.current) || currentSheetRef.current === currentSheetParams?.fileType) {
            setCurrentSheetData(response.data);
            setLoading(false);
            setShowFallback(false);
          }
        },
      ).catch((error) => {
        setLoading(false);
        setShowFallback(true);
        console.error(error);
      });
    } else setLoading(false);
  }, [currentSheetApi, currentSheetParams, productionCycle]);

  const memoizedDashboard = useMemo(() => (
    <Grid container>
      <Grid item style={{ width: expandState ? "0%" : "12%" }}>
        <SideBarView
          selectedTab={selectedTab}
          currentView={currentView}
          setCurrentView={setCurrentView}
          currentSheet={currentSheet}
          setCurrentSheet={setCurrentSheet}
          currentSheetRef={currentSheetRef}
        />
      </Grid>
      <Grid item style={{ width: expandState ? "100%" : "76%" }}>
        { showFallback ? <ErrorFallback /> : <Renderer currentView={currentView} currentSheetItems={currentSheetItems} currentSheetData={currentSheetData} loading={loading} />}
      </Grid>
      <Grid item style={{ width: expandState ? "0%" : "12%", paddingLeft: "1px" }}>
        <TableOfContents currentSheetItems={currentSheetItems} currentSheetData={currentSheetData} />
      </Grid>
    </Grid>
  ), [currentView, selectedTab, showFallback, currentSheet, currentSheetItems, expandState, currentSheetData, loading]);

  return (
    <>
      <Grid container style={{ marginTop: 10, marginBottom: 25 }}>
        <Grid item xs={4}>
          <Button
            variant="contained"
            color="primary"
            startIcon={expandState ? <FullscreenExit /> : <Fullscreen />}
            onClick={() => setExpandState(!expandState)}
          >
            {expandState ? "Normal View" : "FullScreen View"}
          </Button>
        </Grid>
        <Grid style={{ display: "flex", justifyContent: "flex-end" }} item xs={8}>
          <JobStatus currentView={currentView} selectedTab="reporting_ann_lms_qa_dashboard" />
        </Grid>
        <Grid item xs={12} style={{ textAlign: "center" }}>
          {expandState
            ? (
              <Typography variant="h5">
                {`${currentView.replaceAll("_", " ").toUpperCase()}  ->  ${currentSheet.replaceAll("-", " ").toUpperCase()}`}
              </Typography>
            ) : null}
        </Grid>
      </Grid>
      {memoizedDashboard}
    </>
  );
}
