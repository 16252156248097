import React, { useEffect, useState } from "react";
import { Button, Card, CardContent } from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";
import { useSelector } from "react-redux";

import CircularProgress from "../../../components/Spinner";
import APISelect from "../../../components/input/APISelect";
import OptionSelect from "../../../components/input/OptionSelect";
import BaseForm from "../../../forms/JsonSchema/common/BaseForm";
import useClientConfiguration from "../../../hooks/useClientConfiguration";
import usePageTitle from "../../../hooks/usePageTitle";
import * as SharedDefinitions from "../../../utils/SharedDefinitions";
import { API } from "../../../utils/api";

const studyTypes = [
  { id: "ann", label: "ANN" },
  { id: "mf", label: "MF" },
  { id: "sma", label: "SMA" },
];

const ANNClientConfigurationSchema = {
  title: "ANN Client Configuration Form",
  description:
    "The configuration saved here will be applied as default to the ANN reports",
  type: "object",
  properties: {
    broker_zip_exclusions_settings: {
      type: "object",
      title: "Broker/Zip Exclusions Settings",
      properties: {
        fia_exclusion_settings: {
          type: "object",
          title: "FIA Broker/Zip Exclusions",
          properties: SharedDefinitions.brokerZipExclusions,
        },
        va_exclusion_settings: {
          type: "object",
          title: "VA Broker/Zip Exclusions",
          properties: SharedDefinitions.brokerZipExclusions,
        },
      },
    },
  },
};

const MFClientConfigurationSchema = {
  title: "MF Client Configuration Form",
  description:
    "The configuration saved here will be applied as default to the MF reports",
  type: "object",
  properties: {
    broker_zip_exclusions_settings: {
      type: "object",
      title: "Broker/Zip Exclusions Settings",
      properties: {
        mf_exclusion_settings: {
          type: "object",
          title: "MF Broker/Zip Exclusions",
          properties: SharedDefinitions.brokerZipExclusions,
        },
      },
    },
  },
};

const SMAClientConfigurationSchema = {
  title: "SMA Client Configuration Form",
  description:
    "The configuration saved here will be applied as default to the SMA reports",
  type: "object",
  properties: {
    broker_zip_exclusions_settings: {
      type: "object",
      title: "Broker/Zip Exclusions Settings",
      properties: {
        sma_exclusion_settings: {
          type: "object",
          title: "SMA Broker/Zip Exclusions",
          properties: SharedDefinitions.brokerZipExclusions,
        },
      },
    },
  },
};

const StudyToSchema = {
  ann: ANNClientConfigurationSchema,
  mf: MFClientConfigurationSchema,
  sma: SMAClientConfigurationSchema,
};

export default function ClientConfiguration() {
  const formIsLoading = useSelector(state => state.application.formIsLoading);

  const [study, setStudy] = useState("ann");
  const [client, setClient] = useState(null);
  const [formData, setFormData] = useState({});

  const [saving, setSaving] = useState(false);
  const [saved, setSaved] = useState(false);

  const ConfigurationSchema = StudyToSchema[study];

  // Discard form state when client changes
  useEffect(() => {
    setFormData({});
  }, [client]);

  async function saveConfig() {
    setSaving(true);
    const payload = { study, client, config: JSON.stringify(formData) };
    await API.request({
      url: `/clients/${client}/${study}/config`,
      method: "POST",
      data: payload,
    }).then(
      () => {
        setSaving(false);
        setSaved(true);
      },
      (e) => {
        console.error(e);
        setSaving(false);
        setSaved(false);
      },
    );
  }

  usePageTitle("Client Configuration");

  // Prepopulate form with saved config
  useClientConfiguration(setFormData, study, client);
  return (
    <div>
      <Card style={{ marginBottom: "1em" }}>
        <CardContent>
          <OptionSelect
            value={study}
            onChange={setStudy}
            options={studyTypes}
            label="Study Type"
            style={{ display: "flex" }}
          />
          <APISelect
            label="Client"
            url="/clients"
            value={client}
            optionKey="id"
            onChange={setClient}
            showError={false}
            filter="type" // Name for study in API
            filterValue={study}
            sort
          />
        </CardContent>
      </Card>
      <Card>
        <CardContent>
          {formIsLoading
            ? <CircularProgress />
            : (
              <BaseForm
                schema={ConfigurationSchema}
                formData={formData}
                submitHandler={saveConfig}
                setFormData={setFormData}
              >
                {saving
                  ? <CircularProgress />
                  : (
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      startIcon={<SaveIcon />}
                      onClick={saveConfig}
                    >
                      {saved ? "Saved!" : "Save"}
                    </Button>
                  )}
              </BaseForm>
            )}
        </CardContent>
      </Card>
    </div>
  );
}
