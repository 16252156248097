import React from "react";
import { Box, Button, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {},
  cancelButton: {
    color: theme.palette.text.onBackground,
    backgroundColor: theme.palette.primary.light,
  },
  confirmButton: {
    marginRight: theme.spacing(1),
    color: theme.palette.text.onBackground,
    backgroundColor: theme.palette.secondary.light,
  },
}));

export default function FormActions({
  editing,
  setEditing,
  handleSave,
  handleCancel,
  isValid,
}) {
  const classes = useStyles();
  return (
    <Box
      display="flex"
      justifyContent="flex-end"
      p={2}
    >
      {editing
        ? (
          <div>
            <Button
              color="secondary"
              variant="contained"
              onClick={handleSave}
              className={classes.confirmButton}
              disabled={!isValid}
            >
              Save
            </Button>
            <Button
              onClick={() => {
                if (handleCancel) {
                  handleCancel();
                }
                setEditing(false);
              }}
              className={classes.cancelButton}
              disabled={!editing}
            >
              Cancel
            </Button>
          </div>
        )
        : (
          <Button
            color="primary"
            variant="contained"
            onClick={() => setEditing(true)}
          >
            Edit
          </Button>
        )}
    </Box>
  );
}
