import ANNDashboardForm from "../../../forms/JsonSchema/ANNDashboard";
import AnnInternalMissingZipsFormDD from "../../../forms/JsonSchema/ANNInternalMissingZipsDD";
import AnnInternalTerrConflictFromMapForm from "../../../forms/JsonSchema/ANNInternalTerrConflictFromMap";
import AnnInternalTerrConflictFromMapFormDD from "../../../forms/JsonSchema/ANNInternalTerrConflictFromMapDD";
import AnnMisightQAReportForm from "../../../forms/JsonSchema/ANNMisightQAReport";
import AnnSiIdentifierMappingForm from "../../../forms/JsonSchema/ANNSiIdentifierMapping";
import ANNStandardFeedForm from "../../../forms/JsonSchema/ANNStandardFeed";
import ANNOTTForm from "../../../forms/JsonSchema/AnnOTT";
import AnomalyDetectionForm from "../../../forms/JsonSchema/AnomalyDetection";
import CreateAthenaLockPartitionForm from "../../../forms/JsonSchema/CreateAthenaLockPartition";
import AnnGPSForm from "../../../forms/JsonSchema/GrowthPotentialScore";
import HOATrainingForm from "../../../forms/JsonSchema/HOATraining";
import BasicReportForm from "../../../forms/JsonSchema/JSONSchemaExample";
import LMSVettingReportForm from "../../../forms/JsonSchema/LMSVettingReport";
import MFDataExportForm from "../../../forms/JsonSchema/MFDataExport";
import MFLiteStandardFeedForm from "../../../forms/JsonSchema/MFLiteStandardFeed";
import MfMisightQAReportForm from "../../../forms/JsonSchema/MFMisightQAReport";
import MFSalesRecords from "../../../forms/JsonSchema/MFSalesRecords";
import MFStandardFeedForm from "../../../forms/JsonSchema/MFStandardFeed";
import MISightPrewarmCacheForm from "../../../forms/JsonSchema/MISightPrewarmCache";
import MfAnalyticsFeedForm from "../../../forms/JsonSchema/MfAnalyticsFeed";
import MfCrossReferenceFeedForm from "../../../forms/JsonSchema/MfCrossReferenceFeed";
import MfExclusionsFeedForm from "../../../forms/JsonSchema/MfExclusionsFeed";
import PublishANNLegacyForm from "../../../forms/JsonSchema/PublishANNLegacy";
import PublishAthenaConsortiumForm from "../../../forms/JsonSchema/PublishAthenaConsortium";
import PublishAthenaConsortiumFormDD from "../../../forms/JsonSchema/PublishAthenaConsortiumDD";
import PublishAthenaTerritoryForm from "../../../forms/JsonSchema/PublishAthenaTerritory";
import PublishAthenaTerritoryFormDD from "../../../forms/JsonSchema/PublishAthenaTerritoryDD";
import RebalanceTaggerForm from "../../../forms/JsonSchema/RebalanceTagger";
import SmaCombinedFeedForm from "../../../forms/JsonSchema/SmaCombinedFeed";
import SmaDataDumpForm from "../../../forms/JsonSchema/SmaDataDump";
import SmaDataFeedForm from "../../../forms/JsonSchema/SmaDataFeed";
import SmaOTTForm from "../../../forms/JsonSchema/SmaOTT";

import ANNLMSQADashboardForm from "../../../forms/Regular/ANNLMSQADashboardForm";
import ANNLoadChannelsFormDD from "../../../forms/Regular/ANNLoadChannelsFormDD";
import ANNLoadOfficeMapFormDD from "../../../forms/Regular/ANNLoadOfficeMapFormDD";
import ANNLoadTerritoryMapsFormDD from "../../../forms/Regular/ANNLoadTerritoryMapsFormDD";
import ANNMisightQADashboardForm from "../../../forms/Regular/ANNMisightQADashboardForm";
import ANNProductNameBreakdownForm from "../../../forms/Regular/ANNProductNameBreakdown";
import ANNSalesTotalsSelectively from "../../../forms/Regular/ANNSalesTotalsSelectively";
import ANNSalesTotalsSelectivelyDD from "../../../forms/Regular/ANNSalesTotalsSelectivelyDD";
import AddNewClient from "../../../forms/Regular/AddNewClient";
import AnnGeneralDataDump from "../../../forms/Regular/AnnGeneralDataDump";
import AnnGeneralDataDumpDD from "../../../forms/Regular/AnnGeneralDataDumpDD";
import AnnLoadShareClassStandardsForm from "../../../forms/Regular/AnnLoadShareClassStandardsForm";
import AnnLoadVAProductTypes from "../../../forms/Regular/AnnLoadVAProductTypes";
import AnnMFClientMaterializedViewForm from "../../../forms/Regular/AnnMFClientMaterializedViewForm";
import AnnVABDChannelConflictsForm from "../../../forms/Regular/AnnVABDChannelConflictsForm";
import AnnVABDChannelConflictsFormDD from "../../../forms/Regular/AnnVABDChannelConflictsFormDD";
import ApplyCusipRecode from "../../../forms/Regular/ApplyCusipRecode";
import AthenaSyncForm from "../../../forms/Regular/AthenaSync";
import AuroraSyncForm from "../../../forms/Regular/AuroraSyncForm";

import ClientMaterializedViewForm from "../../../forms/Regular/ClientMaterializedViewForm";
import ClientMaterializedViewFormDD from "../../../forms/Regular/ClientMaterializedViewFormDD";
import CreateSalesVolumeTablesForm from "../../../forms/Regular/CreateSalesVolumeTablesForm";
import DDMissingBranches from "../../../forms/Regular/DDMissingBranches";
import DDSyncForm from "../../../forms/Regular/DDSyncForm";
import DatasetLockingForm from "../../../forms/Regular/DatasetLocking";
import DeleteSalesDataByRunDate from "../../../forms/Regular/DeleteSalesDataByRunDate";
import GenericExporterForm from "../../../forms/Regular/GenericExporterForm";
import GenericLoaderForm from "../../../forms/Regular/GenericLoadersForm";
import GenericLoaderFormDD from "../../../forms/Regular/GenericLoadersFormDD";
import HOAWrapperGenericLoaderForm from "../../../forms/Regular/HOAWrapperGenericLoadersForm";
import LMSDDCrossReference from "../../../forms/Regular/LMSDDCrossReference";
import LoadAnnSalesForm from "../../../forms/Regular/LoadAnnSalesForm";
import LoadBrokerStandardizationForm from "../../../forms/Regular/LoadBrokerStandardization";
import LoadChannelsForm from "../../../forms/Regular/LoadChannelsForm";
import LoadKeyBrokersForm from "../../../forms/Regular/LoadKeyBrokersForm";
import LoadKeyBrokersFormDD from "../../../forms/Regular/LoadKeyBrokersFormDD";
import LoadOfficeMapForm from "../../../forms/Regular/LoadOfficeMapsForm";
import LoadSalesFormMF from "../../../forms/Regular/LoadSalesFormMF";
import LoadSalesFormSMA from "../../../forms/Regular/LoadSalesFormSMA";
import LoadTerritoryGroupForm from "../../../forms/Regular/LoadTerritoryGroupForm";
import LoadTerritoryMapsForm from "../../../forms/Regular/LoadTerritoryMapsForm";
import LoadVerifiedAddresses from "../../../forms/Regular/LoadVerifiedAddresses";
import LoadZoneMapsForm from "../../../forms/Regular/LoadZoneMapsForm";
import MFLoadZipCodeDataForm from "../../../forms/Regular/MFLoadZipCodeDataForm";
import MFMorningstarFundsStage from "../../../forms/Regular/MFMorningstarFundsStage";
import MFSalesTotalsSelectively from "../../../forms/Regular/MFSalesTotalsSelectively";
import MFTerritoryExport from "../../../forms/Regular/MFTerritoryExport";
import PreprocessingForm from "../../../forms/Regular/PreprocessingForm";
import PreprocessingSecondaryFilesForm from "../../../forms/Regular/PreprocessingSecondaryFilesForm";
import ProductStandardsWrapperGenericLoadersForm from "../../../forms/Regular/ProductStandardsWrapperGenericLoadersForm";
import PublishClientExclusionsForm from "../../../forms/Regular/PublishAthenaClientExclusionsForm";
import PublishClientExclusionsFormDD from "../../../forms/Regular/PublishAthenaClientExclusionsFormDD";
import PublishAthenaTransportDataForm from "../../../forms/Regular/PublishAthenaTransportDataForm";
import PublishToRedshift from "../../../forms/Regular/PublishToRedshift";
import SISSyncForm from "../../../forms/Regular/SISSyncForm";
import SMAWrapperGenericLoaderForm from "../../../forms/Regular/SMAWrapperGenericLoadersForm";
import TagSalesForm from "../../../forms/Regular/TagSalesForm";
import TerrServiceTerritoryAssignmentForm from "../../../forms/Regular/TerrServiceTerritoryAssignmentForm";
import TerrServiceTerritoryExportForm from "../../../forms/Regular/TerrServiceTerritoryExportForm";
import TerrServiceTerritoryMapsForm from "../../../forms/Regular/TerrServiceTerritoryMapsForm";
import TerrServiceTerritoryPublishMapForm from "../../../forms/Regular/TerrServiceTerritoryPublishMapForm";
import TerrServiceTerritoryQAImpactReportForm from "../../../forms/Regular/TerrServiceTerritoryQAImpactReportForm";
import UpdateBadAddressBDZips from "../../../forms/Regular/UpdateBadAddressBDZips";
import UpdateCusipWarningForm from "../../../forms/Regular/UpdateCusipWarningForm";
import UpdatePhantomZipExclusions from "../../../forms/Regular/UpdatePhantomZipExclusions";
import UpdateWarningCodes from "../../../forms/Regular/UpdateWarningCodes";
import WrapperGenericLoaderForm from "../../../forms/Regular/WrapperGenericLoadersForm";

import AnnDashboardFormV2 from "../../../forms/v2/ann/AnnDashBoard";
import AnnDashboardFormV2DD from "../../../forms/v2/ann/AnnDashBoardDD";
import AnnMissingZipsFormV2 from "../../../forms/v2/ann/AnnMissingZips";
import AnnOTTFormV2 from "../../../forms/v2/ann/AnnOTT";
import AnnOTTFormV2DD from "../../../forms/v2/ann/AnnOTTDD";
import AnnSiIdentifierMappingFormV2 from "../../../forms/v2/ann/AnnSIIdentifierMapping";
import ANNSIIdentifierMappingDD from "../../../forms/v2/ann/AnnSIIdentifierMappingDD";
import AnnStandardFeedFormV2 from "../../../forms/v2/ann/AnnStandardFeed";
import AnnStandardFeedFormV2DD from "../../../forms/v2/ann/AnnStandardFeedDD";
import AnnStandardReportFormV2 from "../../../forms/v2/ann/AnnStandardReport";
import AnnStandardReportFormV2DD from "../../../forms/v2/ann/AnnStandardReportDD";
import AnnUnclassifiedBDsFormV2 from "../../../forms/v2/ann/AnnUnclassifiedBDs";
import AnnUnclassifiedBDsDDForm from "../../../forms/v2/ann/AnnUnclassifiedBDsDD";
import AnnVettingReportFormV2 from "../../../forms/v2/ann/AnnVettingReport";
import AnnWarningBreakdownFormV2 from "../../../forms/v2/ann/AnnWarningBreakdown";
import { backendType } from "../../../state/application";

// Discovery Data

// Selection flow: Action (category) -> Study -> Task Type

const lmsActions = [
  { id: "preprocessing", label: "Pre-processing" },
  { id: "reporting", label: "Reporting" },
  { id: "loading", label: "Loading" },
  { id: "dataset_curation", label: "Dataset Curation" },
];

const diActions = [
  { id: "reporting", label: "Reporting" },
  { id: "loading", label: "Loading" },
];

export const actions = (backendType === "LMS") ? lmsActions : diActions;

export const reportTypes = [{ id: "internal_reports", label: "INTERNAL" }, { id: "external_reports", label: "EXTERNAL" }, { id: "misight_reports", label: "MISIGHT" }];
export const preWarmreportType = [{ id: "misight_reports", label: "MISIGHT" }];
const lmsStudyTypes = [
  { id: "mf", label: "MF", hasClientProfiles: true },
  { id: "ann", label: "ANN", hasClientProfiles: true, fileLoadMonitor: true },
  { id: "ann_mf", label: "ANN + MF" },
  { id: "sma", label: "SMA", hasClientProfiles: true },
  { id: "all", label: "ALL", internalOnly: true },
];

export const lmsPreWarmStudyTypes = [
  { id: "ann", label: "ANN", hasClientProfiles: true, fileLoadMonitor: true },
];

const diStudyTypes = [
  { id: "territory_service", label: "Territory Service", internalOnly: true },
];

export const studyTypes = (backendType === "LMS") ? lmsStudyTypes : diStudyTypes;

export const lmsTaskTypes = [
  // --- Preprocessing ---
  { action: "preprocessing", study: "mf", id: "preprocessing_mf", label: "MF Preprocessing" },
  { action: "preprocessing", study: "ann", id: "preprocessing_ann", label: "ANN Preprocessing" },
  { action: "preprocessing", study: "ann", id: "preprocessing_v2", dev_only_task: true, label: "ANN Preprocessing V2" },
  { action: "preprocessing", study: "sma", id: "preprocessing_sma", label: "SMA Preprocessing" },
  { action: "preprocessing", study: "all", id: "preprocessing_secondary_files", label: "Preprocessing Secondary files", all_clients_only: true },
  // --- Reporting ---
  // MF
  { action: "reporting", study: "all", id: "preprocessing_lms_dd_cross_ref", label: "Generate LMS-DD Cross Reference File", all_clients_only: true, reportType: "external_reports" },
  { action: "reporting", study: "mf", id: "reporting_mf_standard_feed", label: "MF Standard Feed", reportType: "external_reports" },
  { action: "reporting", study: "mf", id: "reporting_mf_ott", label: "MF OTT", reportType: "external_reports" },
  { action: "reporting", study: "mf", id: "reporting_mf_dashboard", label: "MF Dashboard", reportType: "external_reports" },
  { action: "reporting", study: "mf", id: "reporting_vetting", label: "MF Vetting", reportType: "internal_reports" },
  { action: "reporting", study: "mf", id: "reporting_mf_exclusions_feed", label: "MF Exclusions Feed", reportType: "external_reports" },
  { action: "reporting", study: "mf", id: "training_hoa", label: "MF HOA Training", reportType: "internal_reports" },
  { action: "reporting", study: "mf", id: "reporting_anomaly_detection", label: "MF Anomaly Detection", reportType: "external_reports" },
  { action: "reporting", study: "mf", id: "reporting_cross_reference", label: "MF Cross Reference Feed", all_clients_only: true, reportType: "external_reports" },
  { action: "reporting", study: "mf", id: "reporting_mf_analytics_feed", label: "MF Analytics Feed", all_clients_only: true, reportType: "external_reports" },
  { action: "reporting", study: "mf", id: "reporting_mf_misight_qa", label: "MF Misight QA Report", reportType: "misight_reports" },
  { action: "reporting", study: "mf", id: "reporting_mf_data_export", label: "MF Data Export", all_clients_only: true, reportType: "external_reports" },
  { action: "reporting", study: "mf", id: "reporting_mf_territory_export", label: "MF Territory Export - Client Specific", multiple_clients_selection: true, reportType: "external_reports" },
  { action: "reporting", study: "mf", id: "reporting_mf_territory_export_all_clients", label: "MF Territory Export - All Clients", all_clients_only: true, reportType: "external_reports" },
  { action: "reporting", study: "mf", id: "preprocessing_statham_mf_territory", label: "MF Publish territory table", all_clients_only: true, reportType: "misight_reports" },
  { action: "reporting", study: "mf", id: "preprocessing_statham_mf_exclusions", label: "MF Publish exclusions tables", all_clients_only: true, reportType: "misight_reports" },
  { action: "reporting", study: "mf", id: "preprocessing_statham_mf_client_view", label: "MF Create Materialized Client View", reportType: "misight_reports" },
  { action: "reporting", study: "mf", id: "preprocessing_statham_mf", label: "MF Publish consortium table", all_clients_only: true, reportType: "misight_reports" },
  { action: "reporting", study: "mf", id: "reporting_sales_records_report", label: "MF Sales and Records QA Report - Client Specific", reportType: "internal_reports" },
  { action: "reporting", study: "mf", id: "reporting_sales_records_report_all_clients", label: "MF Sales and Records QA Report - All Clients", all_clients_only: true, reportType: "internal_reports" },
  { action: "reporting", study: "mf", id: "reporting_generic_exporter", label: "MF Generic table exporter", all_clients_only: true },
  // ANN
  { action: "reporting", study: "ann", id: "reporting_ann_standard_feed_v2", label: "ANN Standard Feed", reportType: "external_reports" },
  { action: "reporting", study: "ann", id: "reporting_ann_standard_report", label: "ANN Standard Report", all_clients_only: true, reportType: "internal_reports" },
  { action: "reporting", study: "ann", id: "reporting_ann_standard_report_client_specific", label: "ANN Standard Report - Client Specific", reportType: "internal_reports" },
  { action: "reporting", study: "ann", id: "reporting_ann_ott_v2", label: "ANN OTT", reportType: "external_reports" },
  { action: "reporting", study: "ann", id: "reporting_ann_dashboard_v2", label: "ANN Dashboard", reportType: "external_reports" },
  { action: "reporting", study: "ann", id: "reporting_ann_si_identifier_mapping", label: "ANN SI Identifier Mapping", reportType: "external_reports" },
  { action: "reporting", study: "ann", id: "reporting_ann_misight_qa", label: "ANN Misight QA Report", reportType: "misight_reports" },
  { action: "reporting", study: "ann", id: "reporting_mf_lite_standard_feed", label: "ANN-MF Lite Standard Feed", reportType: "external_reports" },
  { action: "reporting", study: "ann", id: "reporting_ann_internal_terr_map_conflicts", label: "ANN Territory Conflicts - Territory Map", reportType: "internal_reports" },
  { action: "reporting", study: "ann", id: "reporting_ann_internal_terr_map_missing_zips_sales", label: "ANN Missing Zips", reportType: "internal_reports" },
  { action: "reporting", study: "ann", id: "preprocessing_statham_ann_client_view", label: "ANN Create Materialized Client View", reportType: "misight_reports" },
  { action: "reporting", study: "ann", id: "preprocessing_statham_ann_mf_client_view", label: "ANN-MF Create Materialized Client View", reportType: "misight_reports" },
  { action: "reporting", study: "ann", id: "preprocessing_statham_ann", label: "ANN Publish consortium table", all_clients_only: true, reportType: "misight_reports" },
  { action: "reporting", study: "ann", id: "preprocessing_statham_ann_mf", label: "ANN-MF Publish consortium table", all_clients_only: true, reportType: "misight_reports" },
  { action: "reporting", study: "ann", id: "preprocessing_statham_ann_legacy", label: "ANN Publish legacy tables", all_clients_only: true, dev_only_task: true, reportType: "misight_reports" },
  { action: "reporting", study: "ann", id: "preprocessing_statham_ann_territory", label: "ANN Publish territory table", all_clients_only: true, reportType: "misight_reports" },
  { action: "reporting", study: "ann", id: "preprocessing_statham_ann_exclusions", label: "ANN Publish exclusions tables", all_clients_only: true, reportType: "misight_reports" },
  { action: "reporting", study: "ann", id: "reporting_ann_vetting_report", label: "ANN LMS Vetting Report", reportType: "internal_reports" },
  { action: "reporting", study: "ann", id: "reporting_ann_warning_breakdown", label: "ANN Warning Breakdown", multiple_clients_selection: true, reportType: "internal_reports" },
  { action: "reporting", study: "ann", id: "reporting_ann_internal_channel_map_conflicts", label: "ANN VA BD Channel Conflicts", multiple_clients_selection: true, reportType: "internal_reports" },
  { action: "reporting", study: "ann", id: "reporting_ann_unclassified_bds", label: "ANN Unchannelized Brokers", reportType: "internal_reports" },
  { action: "reporting", study: "ann", id: "reporting_ann_general_data_dump", label: "ANN General Data Dump", all_clients_only: true, reportType: "internal_reports" },
  { action: "reporting", study: "ann", id: "reporting_ann_product_name_breakdown", label: "ANN Product Name Breakdown", multiple_clients_selection: true, reportType: "internal_reports" },
  { action: "reporting", study: "ann", id: "reporting_generic_exporter", label: "ANN Generic table exporter", all_clients_only: true },
  // TODO: Uncomment Below Dashboard Jobs once new updates are released
  // { action: "reporting", study: "ann", id: "reporting_ann_lms_qa_dashboard", label: "ANN LMS QA Dashboard", reportType: "internal_reports", all_clients_only: true },
  { action: "reporting", study: "ann", id: "reporting_ann_misight_qa_dashboard", label: "ANN Misight QA Dashboard", reportType: "misight_reports", all_clients_only: true },
  // SMA
  { action: "reporting", study: "sma", id: "reporting_sma_ott", label: "SMA OTT", reportType: "external_reports" },
  { action: "reporting", study: "sma", id: "reporting_sma_data_dump", label: "SMA Data Dump", all_clients_only: true, reportType: "internal_reports" },
  { action: "reporting", study: "sma", id: "preprocessing_statham_sma_client_view", label: "SMA Create Materialized Client View", reportType: "misight_reports" },
  { action: "reporting", study: "sma", id: "preprocessing_statham_sma", label: "SMA Publish consortium table", all_clients_only: true, reportType: "misight_reports" },
  { action: "reporting", study: "sma", id: "reporting_sma_data_feed", label: "SMA Data Feed", reportType: "external_reports" },
  { action: "reporting", study: "sma", id: "reporting_sma_combined_feed", label: "SMA Combined Feed", reportType: "external_reports" },
  { action: "reporting", study: "sma", id: "reporting_generic_exporter", label: "SMA Generic table exporter", all_clients_only: true },
  // --- Loading ---
  // TODO: Change backend to be loading type instead of preprocessing
  // ALL
  { action: "loading", study: "all", id: "preprocessing_statham_create_lock_partition", label: "Create Athena Lock Partition", all_clients_only: true },
  // MF
  { action: "loading", study: "mf", id: "preprocessing_generic_loader", label: "MF Generic table loaders", all_clients_only: true },
  { action: "loading", study: "mf", id: "preprocessing_load_key_broker", label: "MF Load Key Brokers" },
  { action: "loading", study: "mf", id: "preprocessing_load_key_funds", label: "MF Load Key Funds" },
  { action: "loading", study: "mf", id: "preprocessing_load_sales_mf", label: "MF Sales Loader" },
  { action: "loading", study: "mf", id: "preprocessing_load_territory_groups", label: "MF Territory Group Loader" },
  { action: "loading", study: "mf", id: "preprocessing_channel_loader", label: "MF Channel Loader" },
  { action: "loading", study: "mf", id: "preprocessing_load_territory_map", label: "MF Territory Map Loader" },
  { action: "loading", study: "mf", id: "preprocessing_load_office_map", label: "MF Office Maps" },
  { action: "loading", study: "mf", id: "preprocessing_historical_home_office_activity_loader", label: "MF Load Historical Home Office Activity" },
  { action: "loading", study: "mf", id: "preprocessing_client_terr_group_assign_loader", label: "MF Load Client Territory Grouping Assignment" },
  { action: "loading", study: "mf", id: "preprocessing_default_channels_loader", label: "MF Load Default Channels" },
  { action: "loading", study: "mf", id: "preprocessing_mf_delete_sales_data_by_run_date", label: "MF Delete Sales Data by Run Date" },

  // ANN
  { action: "loading", study: "ann", id: "preprocessing_generic_loader", label: "ANN Generic table loaders", all_clients_only: true },
  { action: "loading", study: "ann", id: "preprocessing_load_office_map", label: "ANN Office Maps" },
  { action: "loading", study: "ann", id: "preprocessing_load_key_broker", label: "ANN Load Key Brokers" },
  { action: "loading", study: "ann", id: "preprocessing_load_territory_groups", label: "ANN Territory Group Loader" },
  { action: "loading", study: "ann", id: "preprocessing_channel_loader", label: "ANN Channel Loader" },
  { action: "loading", study: "ann", id: "preprocessing_load_territory_map", label: "ANN Territory Map Loader" },
  { action: "loading", study: "ann", id: "preprocessing_load_zone_map", label: "ANN Load Zone Map" },
  { action: "loading", study: "ann", id: "preprocessing_load_sales_ann", label: "ANN Sales Loader" },
  // SMA
  { action: "loading", study: "sma", id: "preprocessing_sma_delete_sales_data_by_run_date", label: "SMA Delete Sales Data by Run Date" },
  { action: "loading", study: "sma", id: "preprocessing_sma_strategy_map_loader", label: "SMA Load Strategy Map" },
  { action: "loading", study: "sma", id: "preprocessing_sma_sales_loader", label: "SMA Sales Loader" },
  // ANN+MF
  { action: "bulk_loading", study: "ann_mf", id: "preprocessing_bulk_upload_client_profile_exclusions", label: "Bulk Upload Client Profile Exclusions" },
  { action: "bulk_loading", study: "ann_mf", id: "preprocessing_dd_bulk_upload_client_profile_exclusions", label: "Bulk Upload Client Profile Exclusions (DD)" },
  // ALL
  { action: "loading", study: "mf", id: "preprocessing_add_new_client", label: "MF Add/Rename Client", dev_only_task: true, all_clients_only: true },
  { action: "loading", study: "ann", id: "preprocessing_add_new_client", label: "ANN Add/Rename Client", dev_only_task: true, all_clients_only: true },

  // dataset_curation
  { action: "dataset_curation", study: "mf", id: "preprocessing_load_verified_address", label: "MF Load Verified Addresses", all_clients_only: true },
  { action: "dataset_curation", study: "mf", id: "preprocessing_populate_sales_totals", label: "MF Populate Sales Totals - Client Specific" },
  { action: "dataset_curation", study: "mf", id: "preprocessing_populate_sales_totals_all_clients", label: "MF Populate Sales Totals - All Clients", all_clients_only: true },
  { action: "dataset_curation", study: "mf", id: "preprocessing_cusip_recode", label: "MF Apply Cusip Recode", all_clients_only: true },
  { action: "dataset_curation", study: "mf", id: "preprocessing_morningstar_funds_stage_loader", label: "MF Morningstar Funds Stage loader", all_clients_only: true },
  { action: "dataset_curation", study: "mf", id: "preprocessing_update_phantom_zip_exclusions", label: "MF Update Phantom Zip Exclusions", all_clients_only: true },
  { action: "dataset_curation", study: "mf", id: "preprocessing_create_sales_volume_tables", label: "MF Create Sales Volume Tables", all_clients_only: true },
  { action: "dataset_curation", study: "mf", id: "preprocessing_publish_to_redshift", label: "MF Publish to Redshift", all_clients_only: true },
  { action: "dataset_curation", study: "mf", id: "preprocessing_update_cusip_warnings", label: "MF Update Cusip Warning From Morning Funds", all_clients_only: true },
  { action: "dataset_curation", study: "mf", id: "preprocessing_mf_update_bad_address_bd_zips", label: "Update BAD_ADDRESS_BD_ZIPS", all_clients_only: true },
  { action: "dataset_curation", study: "mf", id: "preprocessing_update_warning_codes", label: "MF Update Warning Codes", all_clients_only: true },
  { action: "dataset_curation", study: "mf", id: "preprocessing_broker_standardization", label: "MF Load FDS_BROKER_IDS", all_clients_only: true },
  { action: "dataset_curation", study: "mf", id: "preprocessing_load_mf_zip_code_data", label: "MF Load Zip Code Data", all_clients_only: true },
  { action: "dataset_curation", study: "mf", id: "preprocessing_tag_large_negative_sales", label: "MF Tag Large Negative Sales", all_clients_only: true },
  { action: "dataset_curation", study: "mf", id: "preprocessing_tag_manual_warnings", label: "MF Tag Manual Warnings", all_clients_only: true },
  { action: "dataset_curation", study: "mf", id: "preprocessing_rebalance_tagger", label: "MF Tag Rebalances", all_clients_only: true },
  { action: "dataset_curation", study: "mf", id: "preprocessing_rebalance_tagger_client_specific", label: "MF Tag Rebalances - Client Specific" },
  { action: "dataset_curation", study: "ann", id: "preprocessing_broker_standardization", label: "ANN Load VA_FDS_BROKER_IDS", all_clients_only: true },
  { action: "dataset_curation", study: "ann", id: "preprocessing_ann_broker_standardization_client", label: "ANN Load VA_CLIENT_SPECIFIC_IDS", all_clients_only: true },
  { action: "dataset_curation", study: "ann", id: "preprocessing_ann_populate_sales_totals", label: "ANN Populate Sales Totals - Client Specific" },
  { action: "dataset_curation", study: "ann", id: "preprocessing_ann_populate_sales_totals_all_clients", label: "ANN Populate Sales Totals - All Clients", all_clients_only: true },
  { action: "dataset_curation", study: "ann", id: "preprocessing_ann_load_va_product_types", label: "ANN Load VA Product Types", all_clients_only: true },
  { action: "dataset_curation", study: "ann", id: "preprocessing_load_verified_address", label: "ANN Load Verified Addresses", all_clients_only: true },
  { action: "dataset_curation", study: "ann", id: "preprocessing_share_class", label: "ANN Share Class Standardization", all_clients_only: true },
  { action: "dataset_curation", study: "ann", id: "preprocessing_ann_product_standards_loader", label: "ANN Product Standards loaders", allow_all_clients: true },
  { action: "dataset_curation", study: "ann_mf", id: "preprocessing_locked_dataset", label: "Dataset Locking", all_clients_only: true },
  { action: "dataset_curation", study: "all", id: "preprocessing_sis_sync", label: "SIS Sync", all_clients_only: true },
  { action: "dataset_curation", study: "all", id: "preprocessing_dd_sync", label: "Discovery Data Sync", all_clients_only: true },
  { action: "dataset_curation", study: "all", id: "sync_aurora_sync", label: "Aurora Data Sync", all_clients_only: true, dev_only_task: true },
  { action: "dataset_curation", study: "all", id: "sync_athena_sync", label: "Athena Data Sync", all_clients_only: true, dev_only_task: true },
  { action: "dataset_curation", study: "all", id: "preprocessing_statham_transport_discovery_data", label: "MISight Discovery Periodic Data Loader", reportType: "misight_reports", one_client_only: "DISCOVERYDATA" },
  { action: "dataset_curation", study: "all", id: "preprocessing_statham_bs_historical_plan_data", label: "MISight Brightscope Periodic Data Loader", reportType: "misight_reports", one_client_only: "BRIGHTSCOPE" },
  // Analytics
  { action: "loading", study: "ann", id: "analytics_ann_gp_score_predict", label: "ANN Growth Potential Score Prediction", all_clients_only: true },
];

export const lmsPreWarmTaskTypes = [
  { action: "reporting", study: "mf", id: "misight_prewarm_cache", label: "MF MISight Prewarm Cache", reportType: "misight_reports" },
  { action: "reporting", study: "ann", id: "misight_prewarm_cache", label: "ANN MISight Prewarm Cache", reportType: "misight_reports" },
  { action: "reporting", study: "sma", id: "misight_prewarm_cache", label: "SMA MISight Prewarm Cache", reportType: "misight_reports" },
];

const diTaskTypes = [
  { action: "reporting", study: "territory_service", id: "territory_assignment", label: "Territory Assignment" },
  { action: "loading", study: "territory_service", id: "territory_rule_generation", label: "Territory Maps" },
  { action: "loading", study: "territory_service", id: "territory_qa_impact_report", label: "Territory QA Impact Report" },
  { action: "loading", study: "territory_service", id: "territory_rule_publishing", label: "Territory Maps Publishing" },
  { action: "loading", study: "territory_service", id: "territory_lms_territory_export", label: "LMS Territory Export Job", multiple_clients: true },

];

export const ddLmsTaskTypes = [
  // ANN Loading
  { action: "loading", study: "ann", id: "preprocessing_load_office_map_discovery", label: "ANN Office Maps (DD)" },
  { action: "loading", study: "ann", id: "preprocessing_channel_loader_discovery", label: "ANN Channel Loader (DD) " },
  { action: "loading", study: "ann", id: "preprocessing_load_territory_map_discovery", label: "ANN Territory Map Loader (DD)" },
  { action: "loading", study: "ann", id: "preprocessing_load_territory_groups", label: "ANN Territory Group Loader (DD)" },
  { action: "loading", study: "ann", id: "preprocessing_load_zone_map", label: "ANN Load Zone Map (DD)" },
  { action: "loading", study: "ann", id: "preprocessing_generic_loader_discovery", label: "ANN Generic Loader (DD)", all_clients_only: true },
  { action: "loading", study: "ann", id: "preprocessing_load_key_broker_discovery", label: "ANN Load Key Brokers (DD)" },
  // ANN Reporting
  { action: "reporting", study: "ann", id: "reporting_ann_dd_general_data_dump", label: "ANN General Data Dump (DD)", all_clients_only: true, reportType: "internal_reports" },
  { action: "reporting", study: "ann", id: "reporting_ann_standard_feed_v2_discovery", label: "ANN Standard Feed (DD)", reportType: "external_reports" },
  { action: "reporting", study: "ann", id: "reporting_dd_ann_dashboard_v2", label: "ANN Dashboard (DD)", reportType: "external_reports" },
  { action: "reporting", study: "ann", id: "reporting_ann_dd_identifier_mapping", label: "ANN Discovery Identifier Mapping", reportType: "external_reports" },
  { action: "reporting", study: "ann", id: "preprocessing_statham_ann_discovery", label: "ANN Publish consortium table (DD)", all_clients_only: true, reportType: "misight_reports" },
  { action: "reporting", study: "ann", id: "preprocessing_statham_ann_territory_discovery", label: "ANN Publish territory table (DD)", all_clients_only: true, reportType: "misight_reports" },
  { action: "reporting", study: "ann", id: "preprocessing_statham_ann_exclusions_discovery", label: "ANN Publish exclusions tables (DD)", all_clients_only: true, reportType: "misight_reports" },
  { action: "reporting", study: "ann", id: "preprocessing_statham_ann_client_view_discovery", label: "ANN Create Materialized Client View (DD)", reportType: "misight_reports" },
  { action: "reporting", study: "ann", id: "reporting_dd_ann_internal_terr_map_missing_zips_sales", label: "ANN Missing Zips (DD)", reportType: "internal_reports" },
  { action: "reporting", study: "ann", id: "reporting_dd_ann_unclassified_bds", label: "ANN Unchannelized Brokers (DD)", reportType: "internal_reports" },
  { action: "reporting", study: "ann", id: "reporting_ann_ott_v2_discovery", label: "ANN OTT (DD)", reportType: "external_reports" },
  { action: "reporting", study: "ann", id: "reporting_ann_standard_report_discovery", label: "ANN Standard Report (v2) (DD)", all_clients_only: true, reportType: "internal_reports" },
  { action: "reporting", study: "ann", id: "reporting_ann_standard_report_client_specific_discovery", label: "ANN Standard Report - Client Specific (v2) (DD)", reportType: "internal_reports" },
  { action: "reporting", study: "ann", id: "reporting_ann_internal_terr_map_conflicts_discovery", label: "ANN Territory Conflicts - Territory Map (DD)", reportType: "internal_reports" },
  { action: "reporting", study: "ann", id: "reporting_ann_internal_channel_map_conflicts_discovery", label: "ANN VA BD Channel Conflicts (DD)", multiple_clients_selection: true, reportType: "internal_reports" },
  { action: "reporting", study: "ann", id: "reporting_ann_missing_branches_discovery", label: "ANN Missing Branches (DD)", all_clients_only: true, reportType: "internal_reports" },
  // ANN dataset_curation
  { action: "dataset_curation", study: "ann", id: "preprocessing_ann_populate_sales_totals_discovery", label: "ANN Populate Sales Totals - Client Specific (DD)" },
  { action: "dataset_curation", study: "ann", id: "preprocessing_ann_populate_sales_totals_all_clients_discovery", label: "ANN Populate Sales Totals - All Clients (DD)", all_clients_only: true },
];

export const taskTypes = (backendType === "LMS") ? lmsTaskTypes : diTaskTypes;

export default {
  // --- PREPROCESSING ---
  // MF Studies
  preprocessing_tag_large_negative_sales: TagSalesForm,
  preprocessing_tag_manual_warnings: TagSalesForm,
  preprocessing_rebalance_tagger: RebalanceTaggerForm,
  preprocessing_rebalance_tagger_client_specific: RebalanceTaggerForm,

  // ALL STUDIES
  preprocessing: PreprocessingForm,
  preprocessing_mf: PreprocessingForm,
  preprocessing_ann: PreprocessingForm,
  preprocessing_sma: PreprocessingForm,
  preprocessing_v2: PreprocessingForm,
  preprocessing_secondary_files: PreprocessingSecondaryFilesForm,
  preprocessing_statham_ann_client_view: ClientMaterializedViewForm,
  preprocessing_statham_ann_client_view_discovery: ClientMaterializedViewFormDD,
  preprocessing_statham_ann_mf_client_view: AnnMFClientMaterializedViewForm,
  preprocessing_statham_mf_client_view: ClientMaterializedViewForm,
  preprocessing_statham_sma_client_view: ClientMaterializedViewForm,
  preprocessing_statham_ann: PublishAthenaConsortiumForm,
  preprocessing_statham_ann_discovery: PublishAthenaConsortiumFormDD,
  preprocessing_statham_ann_legacy: PublishANNLegacyForm,
  preprocessing_statham_mf: PublishAthenaConsortiumForm,
  preprocessing_statham_sma: PublishAthenaConsortiumForm,
  preprocessing_statham_ann_mf: PublishAthenaConsortiumForm,
  preprocessing_statham_mf_territory: PublishAthenaTerritoryForm,
  preprocessing_statham_ann_territory: PublishAthenaTerritoryForm,
  preprocessing_statham_ann_territory_discovery: PublishAthenaTerritoryFormDD,
  preprocessing_statham_mf_exclusions: PublishClientExclusionsForm,
  preprocessing_statham_ann_exclusions: PublishClientExclusionsForm,
  preprocessing_statham_ann_exclusions_discovery: PublishClientExclusionsFormDD,
  preprocessing_statham_transport_discovery_data: PublishAthenaTransportDataForm,
  preprocessing_statham_bs_historical_plan_data: PublishAthenaTransportDataForm,
  preprocessing_statham_create_lock_partition: CreateAthenaLockPartitionForm,
  misight_prewarm_cache: MISightPrewarmCacheForm,
  preprocessing_sis_sync: SISSyncForm,
  preprocessing_dd_sync: DDSyncForm,
  sync_athena_sync: AthenaSyncForm,
  preprocessing_lms_dd_cross_ref: LMSDDCrossReference,

  // --- REPORTING ---
  // MF
  reporting_mf_standard_feed: MFStandardFeedForm,
  reporting_mf_ott: BasicReportForm,
  reporting_mf_dashboard: BasicReportForm,
  reporting_vetting: LMSVettingReportForm,
  reporting_mf_exclusions_feed: MfExclusionsFeedForm,
  training_hoa: HOATrainingForm,
  reporting_anomaly_detection: AnomalyDetectionForm,
  reporting_cross_reference: MfCrossReferenceFeedForm,
  reporting_mf_analytics_feed: MfAnalyticsFeedForm,
  reporting_mf_misight_qa: MfMisightQAReportForm,
  reporting_mf_data_export: MFDataExportForm,
  reporting_mf_territory_export_all_clients: MFTerritoryExport,
  reporting_mf_territory_export: MFTerritoryExport,
  reporting_sales_records_report: MFSalesRecords,
  reporting_sales_records_report_all_clients: MFSalesRecords,
  // ANN
  reporting_ann_standard_feed: ANNStandardFeedForm,
  reporting_ann_standard_feed_v2: AnnStandardFeedFormV2,
  reporting_ann_standard_feed_v2_discovery: AnnStandardFeedFormV2DD,
  reporting_ann_standard_report: AnnStandardReportFormV2,
  reporting_ann_standard_report_client_specific: AnnStandardReportFormV2,
  reporting_ann_standard_report_discovery: AnnStandardReportFormV2DD,
  reporting_ann_standard_report_client_specific_discovery: AnnStandardReportFormV2DD,

  reporting_ann_ott: ANNOTTForm,
  reporting_ann_ott_v2: AnnOTTFormV2,
  reporting_ann_ott_v2_discovery: AnnOTTFormV2DD,
  reporting_ann_dashboard: ANNDashboardForm,
  reporting_ann_dashboard_v2: AnnDashboardFormV2,
  reporting_dd_ann_dashboard_v2: AnnDashboardFormV2DD,
  reporting_ann_dd_identifier_mapping: ANNSIIdentifierMappingDD,
  reporting_ann_si_identifier_mapping: AnnSiIdentifierMappingFormV2,
  v2_reporting_ann_si_identifier_mapping: AnnSiIdentifierMappingForm,
  reporting_ann_misight_qa: AnnMisightQAReportForm,
  reporting_mf_lite_standard_feed: MFLiteStandardFeedForm,
  reporting_ann_internal_terr_map_conflicts: AnnInternalTerrConflictFromMapForm,
  reporting_ann_internal_terr_map_conflicts_discovery: AnnInternalTerrConflictFromMapFormDD,
  reporting_ann_internal_terr_map_missing_zips_sales: AnnMissingZipsFormV2,
  reporting_dd_ann_internal_terr_map_missing_zips_sales: AnnInternalMissingZipsFormDD,
  reporting_ann_vetting_report: AnnVettingReportFormV2,
  reporting_ann_warning_breakdown: AnnWarningBreakdownFormV2,
  reporting_ann_internal_channel_map_conflicts: AnnVABDChannelConflictsForm,
  reporting_ann_internal_channel_map_conflicts_discovery: AnnVABDChannelConflictsFormDD,
  reporting_ann_unclassified_bds: AnnUnclassifiedBDsFormV2,
  reporting_dd_ann_unclassified_bds: AnnUnclassifiedBDsDDForm,
  reporting_ann_general_data_dump: AnnGeneralDataDump,
  reporting_ann_dd_general_data_dump: AnnGeneralDataDumpDD,
  reporting_ann_product_name_breakdown: ANNProductNameBreakdownForm,
  reporting_ann_lms_qa_dashboard: ANNLMSQADashboardForm,
  reporting_ann_misight_qa_dashboard: ANNMisightQADashboardForm,
  reporting_ann_missing_branches_discovery: DDMissingBranches,
  // SMA
  reporting_sma_ott: SmaOTTForm,
  reporting_sma_data_dump: SmaDataDumpForm,
  reporting_sma_data_feed: SmaDataFeedForm,
  reporting_sma_combined_feed: SmaCombinedFeedForm,

  // TERRITORIES
  territory_assignment: TerrServiceTerritoryAssignmentForm,

  // --- LOADING --
  // MF or ANN
  preprocessing_load_key_broker: LoadKeyBrokersForm,
  preprocessing_load_territory_groups: LoadTerritoryGroupForm,
  preprocessing_channel_loader: LoadChannelsForm,
  preprocessing_load_territory_map: LoadTerritoryMapsForm,
  preprocessing_load_office_map: LoadOfficeMapForm,
  preprocessing_load_verified_address: LoadVerifiedAddresses,
  preprocessing_broker_standardization: LoadBrokerStandardizationForm,
  preprocessing_add_new_client: AddNewClient,
  // MF
  preprocessing_load_key_funds: LoadKeyBrokersForm,
  preprocessing_load_mf_zip_code_data: MFLoadZipCodeDataForm,
  preprocessing_historical_home_office_activity_loader: HOAWrapperGenericLoaderForm,
  preprocessing_client_terr_group_assign_loader: WrapperGenericLoaderForm,
  preprocessing_default_channels_loader: WrapperGenericLoaderForm,
  preprocessing_load_sales_mf: LoadSalesFormMF,
  preprocessing_load_sales_ann: LoadAnnSalesForm,
  preprocessing_sma_sales_loader: LoadSalesFormSMA,
  preprocessing_mf_delete_sales_data_by_run_date: DeleteSalesDataByRunDate,
  preprocessing_populate_sales_totals: MFSalesTotalsSelectively,
  preprocessing_populate_sales_totals_all_clients: MFSalesTotalsSelectively,
  preprocessing_cusip_recode: ApplyCusipRecode,
  preprocessing_morningstar_funds_stage_loader: MFMorningstarFundsStage,
  preprocessing_update_phantom_zip_exclusions: UpdatePhantomZipExclusions,
  preprocessing_create_sales_volume_tables: CreateSalesVolumeTablesForm,
  preprocessing_publish_to_redshift: PublishToRedshift,
  preprocessing_mf_update_bad_address_bd_zips: UpdateBadAddressBDZips,

  preprocessing_update_cusip_warnings: UpdateCusipWarningForm,
  preprocessing_update_warning_codes: UpdateWarningCodes,
  // ANN
  preprocessing_ann_load_va_product_types: AnnLoadVAProductTypes,
  preprocessing_share_class: AnnLoadShareClassStandardsForm,
  preprocessing_ann_broker_standardization_client: LoadBrokerStandardizationForm,
  preprocessing_ann_product_standards_loader: ProductStandardsWrapperGenericLoadersForm,
  preprocessing_load_zone_map: LoadZoneMapsForm,
  preprocessing_ann_populate_sales_totals: ANNSalesTotalsSelectively,
  preprocessing_ann_populate_sales_totals_all_clients: ANNSalesTotalsSelectively,
  preprocessing_ann_populate_sales_totals_discovery: ANNSalesTotalsSelectivelyDD,
  preprocessing_ann_populate_sales_totals_all_clients_discovery: ANNSalesTotalsSelectivelyDD,
  preprocessing_channel_loader_discovery: ANNLoadChannelsFormDD,
  preprocessing_load_office_map_discovery: ANNLoadOfficeMapFormDD,
  preprocessing_load_territory_map_discovery: ANNLoadTerritoryMapsFormDD,
  preprocessing_generic_loader_discovery: GenericLoaderFormDD,
  preprocessing_load_key_broker_discovery: LoadKeyBrokersFormDD,

  // ANN+MF
  preprocessing_locked_dataset: DatasetLockingForm,
  // SMA
  preprocessing_sma_strategy_map_loader: SMAWrapperGenericLoaderForm,
  preprocessing_sma_delete_sales_data_by_run_date: DeleteSalesDataByRunDate,
  // Generic loaders
  preprocessing_generic_loader_client: GenericLoaderForm,
  // TERRITORIES
  territory_rule_generation: TerrServiceTerritoryMapsForm,
  territory_qa_impact_report: TerrServiceTerritoryQAImpactReportForm,
  territory_rule_publishing: TerrServiceTerritoryPublishMapForm,
  territory_lms_territory_export: TerrServiceTerritoryExportForm,
  // ALL
  preprocessing_generic_loader: GenericLoaderForm,
  sync_aurora_sync: AuroraSyncForm,
  // Analytics
  analytics_ann_gp_score_predict: AnnGPSForm,
  reporting_generic_exporter: GenericExporterForm,
};
