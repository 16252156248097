import React from "react";
import { Chip, Typography } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";

import useClientConfiguration from "../../hooks/useClientConfiguration";
import useFormData from "../../hooks/useFormData";
import BaseForm from "./common/BaseForm";

const uiSchema = {
  lock: {
    "ui:help": "Leave unselected for Live",
  },
};

export default function MFDataExportForm({ children, studyType, client, setTaskConfig, submitHandler, formDataProp, displayHeader }) {
  const dispatch = useDispatch();
  const formOptions = useSelector((state) => state.formOptions);
  const [formData, setFormData] = useFormData(setTaskConfig, formDataProp);

  useClientConfiguration(
    setFormData,
    studyType,
    client,
    dispatch,
  );

  const MFDataExportSchema = {
    title: "Publish Consortium",
    description: "Create or update consortium data to athena",
    type: "object",
    properties: {
      lock: {
        type: "string",
        title: "Lock (Data Set)",
        enum: formOptions.locksANN,
      },
    },
  };

  return (
    <div>
      <div style={{ display: "flex", alignItems: "center" }}>
        <Typography variant="subtitle2" style={{ marginRight: "8px", marginBottom: "16px" }}>Task Name :</Typography>
        <Chip style={{ marginBottom: "16px" }} variant="outlined" label={displayHeader(["lock"])} />
      </div>
      <BaseForm
        schema={MFDataExportSchema}
        uiSchema={uiSchema}
        formData={formData}
        submitHandler={submitHandler}
        setFormData={setFormData}
      >
        {children}
      </BaseForm>
    </div>
  );
}
