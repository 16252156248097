import React, { useEffect } from "react";
import { FormControl } from "@material-ui/core";
import Form from "@rjsf/material-ui";

import CSVUpload from "../../components/input/CSVUpload";
import OptionSelect from "../../components/input/OptionSelect";
import { fileType } from "./common/BaseForm";

const FileOptions = [
  { id: "industry", label: "Industry Standardization File" },
  { id: "client_specific", label: "Client-Specific Standardization File" },
];

export default function AnnLoadShareClassStandardsForm({ children, taskConfig, setTaskConfig, csv, setCsv, submitHandler, studyType, displayHeader, setIsRequired }) {
  useEffect(() => {
    setTaskConfig((preConfig) => ({ ...preConfig, study: studyType, file_type: fileType.SECONDARY_FILES }));
    setIsRequired((prevState) => ([...prevState, "file_options"]));
  }, [setTaskConfig, studyType, setIsRequired]);
  return (
    <Form
      schema={{}}
      onSubmit={submitHandler}
      displayHeader={displayHeader}
    >
      <CSVUpload
        onChange={(update) => {
          setCsv({ attachment: update.csv });
        }}
        value={{ csv, ...taskConfig }}
      />
      <FormControl fullWidth margin="normal">
        <OptionSelect
          required
          style={{ width: 500, marginBottom: 20 }}
          value={taskConfig.file_options || ""}
          onChange={(fo) => {
            setTaskConfig((prevTaskConfig) => ({ ...prevTaskConfig, file_options: fo }));
          }}
          options={FileOptions}
          label="File Load Options"
        />
      </FormControl>
      {children}
    </Form>
  );
}
