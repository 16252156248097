import React, { useEffect, useState } from "react";
import DateFnsUtils from "@date-io/date-fns";
import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Divider,
  Grid,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { useDispatch, useSelector } from "react-redux";

import OptionSelect from "../../../components/input/OptionSelect";
import { setFilters } from "../../../state";
import { API, CognitoAPI } from "../../../utils/api";
import { taskTypes, ddLmsTaskTypes } from "../create_wizard/TaskMapping";
import Chips from "./FilterChip";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginBottom: theme.spacing(1),
  },
  column: {
    flexBasis: "50%",
    margin: theme.spacing(1),
  },
  action: {
    justifyContent: "left",
  },
}));

const StatusOptions = [
  { id: "SUCCEEDED", label: "SUCCEEDED" },
  { id: "DRAFT", label: "DRAFT" },
  { id: "FAILED", label: "FAILED" },
  { id: "RUNNING", label: "RUNNING" },
  { id: "ABORTED", label: "ABORTED" },
  { id: "QUEUED", label: "QUEUED" },
  { id: "RUNNING_QUEUE", label: "RUNNING_QUEUE" },
];

const OrderOptions = [
  { id: "desc", label: "Descending" },
  { id: "asc", label: "Ascending" },
];

const twoWeeksAgo = new Date();
twoWeeksAgo.setDate(twoWeeksAgo.getDate() - 14);

export default function Filters({ children, ddTaskOnly = false, preWarmOnly = false }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const backendType = useSelector(state => state.application.backendType);
  const filters = useSelector(state => state.filters.available);

  const [clientOptions, setClientOptions] = useState([]);
  const [clientOptionsLoading, setClientLoading] = useState(false);

  const [userOptions, setUserOptions] = useState([]);
  const [userOptionsLoading, setUserOptionsLoading] = useState(false);

  const [accordionExpanded, setAccordionExpanded] = useState(false);

  useEffect(() => {
    // Recursively fetch users to create the drop down options
    function getUsers(data = [], nextToken = null) {
      return CognitoAPI.request({ url: "/users", method: "GET", params: { next_token: nextToken } })
        .then((response) => {
          const standardizedResponse = response.data.users.map((obj) => ({ ...obj, label: obj.name }));
          const appendedData = data.concat(standardizedResponse);
          setUserOptions(appendedData);
          if (response.data.next_token) {
            getUsers(appendedData, response.data.users.next_token);
          } else {
            setUserOptionsLoading(false);
          }
        })
        .catch((error) => {
          console.error(error);
          setUserOptionsLoading(false);
        });
    }
    setUserOptionsLoading(true);
    getUsers();
  }, []);

  // Clients
  useEffect(() => {
    setClientLoading(true);
    function getClients() {
      return API.request({ url: "/clients", method: "GET" }).then((response) => {
        const standardizedResponse = response.data.map((obj) => ({ ...obj, label: obj.id }));
        setClientOptions((prevOptions) => prevOptions.concat(standardizedResponse));
      });
    }
    function getTerrClients() {
      return API.request({ url: "/di/territory/clients", method: "GET" }).then((response) => {
        const standardizedResponse = response.data.map((obj) => ({ id: obj.pk, value: obj.client_description, label: obj.client_description, type: "territory_service" }));
        setClientOptions((prevOptions) => prevOptions.concat(standardizedResponse));
      });
    }
    const requestPromiseArray = (backendType === "LMS") ? [getClients()] : [getTerrClients()];
    Promise.all(requestPromiseArray).catch(() => requestPromiseArray)
      .then(() => {
        setClientLoading(false);
      }, () => setClientLoading(false));
  }, [backendType]);

  return (
    <div className={classes.root}>
      <Accordion expanded={accordionExpanded} onChange={() => setAccordionExpanded(!accordionExpanded)}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1c-content"
          id="panel1c-header"
        >
          <div className={classes.column}>
            <Typography className={classes.heading}>Search Filters</Typography>
          </div>
        </AccordionSummary>
        <AccordionDetails className={classes.details}>
          <Grid container spacing={3}>

            {!preWarmOnly
            && (
              <Grid item xs={4}>
                <Autocomplete
                  value={filters.taskType}
                  onChange={(e, taskList) => dispatch(setFilters({ value: taskList, key: "taskType" }))}
                  options={ddTaskOnly ? ddLmsTaskTypes : taskTypes}
                  groupBy={(option) => option.action}
                  getOptionLabel={(option) => option.label}
                  style={{ width: "100%" }}
                  renderInput={(params) => <TextField {...params} label="Task Types" variant="outlined" margin="normal" />}
                  getOptionSelected={(val, option) => option.id === val.id}
                  multiple
                />
              </Grid>
            )}
            <Grid item xs={3}>
              <Autocomplete
                value={filters.client}
                onChange={(e, clientList) => dispatch(setFilters({ value: clientList, key: "client" }))}
                options={clientOptions}
                groupBy={(option) => option.type}
                getOptionLabel={(option) => option.label}
                style={{ width: "100%" }}
                renderInput={(params) => <TextField {...params} label="Clients" variant="outlined" margin="normal" />}
                getOptionSelected={(val, option) => option.id === val.id}
                multiple
                loading={clientOptionsLoading}
              />
            </Grid>
            <Grid item xs={2}>
              <OptionSelect
                value={filters.status || ""}
                onChange={(status) => dispatch(setFilters({ value: status, key: "status" }))}
                options={StatusOptions}
                label="Status"
                style={{ width: "100%" }}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={3}>
              <OptionSelect
                value={filters.orderBy || ""}
                onChange={(orderBy) => dispatch(setFilters({ value: orderBy, key: "orderBy" }))}
                options={OrderOptions}
                label="Order by (Descending by default)"
                style={{ width: "100%" }}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={4}>
              <Autocomplete
                value={filters.username}
                onChange={(e, users) => dispatch(setFilters({ value: users, key: "username" }))}
                options={userOptions}
                getOptionLabel={(option) => option.name}
                style={{ width: "100%" }}
                renderInput={(params) => <TextField {...params} label="Usernames" variant="outlined" margin="normal" />}
                getOptionSelected={(val, option) => option.name === val.name}
                multiple
                loading={userOptionsLoading}
              />
            </Grid>
            <Grid item xs={6}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="MM/dd/yyyy"
                  margin="normal"
                  id="start-date-picker-inline"
                  label="Start Date"
                  value={filters.startDate || twoWeeksAgo}
                  onChange={(date) => dispatch(setFilters({ value: date.toISOString().replace("Z", "+00:00"), key: "startDate" }))}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                  animateYearScrolling
                />
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="MM/dd/yyyy"
                  margin="normal"
                  id="end-date-picker-inline"
                  label="End Date"
                  value={filters.endDate || new Date()}
                  onChange={(date) => dispatch(setFilters({ value: date.toISOString().replace("Z", "+00:00"), key: "endDate" }))}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </MuiPickersUtilsProvider>
            </Grid>
          </Grid>
        </AccordionDetails>
        <Divider />
        <AccordionActions className={classes.action}>
          {children}
        </AccordionActions>
      </Accordion>
      <Grid display="row">
        <Chips chipData={filters} setChipData={setFilters} />
      </Grid>
    </div>
  );
}
