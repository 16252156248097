import React from "react";
import * as Yup from "yup";

import Lock from "../../../components/input/Formik/Lock";
import TimePeriod from "../../../components/input/Formik/TimePeriod";
import LMSSelect from "../../../components/input/Formik/base/LMSSelect";
import { LMSForm } from "../common/LMSForm";

export const AnnMissingZipsSchema = Yup.object().shape({
  time_period: Yup.string().required("Required"),
  start_quarter: Yup.string().required("Required"),
  end_quarter: Yup.string().required("Required"),
});

export default function AnnMissingZipsForm({
  title,
  client,
  studyType,
  step,
  setStep,
  submitHandler,
  taskConfig,
}) {
  return (
    <LMSForm
      client={client}
      studyType={studyType}
      step={step}
      setStep={setStep}
      submitHandler={submitHandler}
      taskConfig={taskConfig}
      validationSchema={AnnMissingZipsSchema}
      initialValues={{ ann_type: "va" }}
      title={title}
      requiredInputAdded
    >
      <TimePeriod required />
      <Lock studyType="ann" helperText="Leave unselected for Live" />
      <LMSSelect
        label="Annuity Type"
        name="ann_type"
        options={[
          { id: "va", label: "VA" },
          { id: "fia", label: "FIA" },
          { id: "all", label: "ALL" },
        ]}
      />
    </LMSForm>
  );
}
