import React from "react";
import { Typography } from "@material-ui/core";
import Form from "@rjsf/material-ui";

export default function UpdatePhantomZipExclusions({ children, submitHandler }) {
  return (
    <Form
      schema={{}}
      onSubmit={submitHandler}
    >
      <Typography variant="h5" style={{ marginBottom: 20 }}>
        Update Phantom Zip Exclusions
      </Typography>
      {children}
    </Form>
  );
}
