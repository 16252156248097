import React, { forwardRef, useCallback, useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, makeStyles, Slide } from "@material-ui/core";
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";

import Fab from "../../../components/Fab";
import FormAlerts from "../../../components/FormAlerts";
import LMSRadioGroup from "../../../components/input/Formik/base/LMSRadioGroup";
import LMSSelect from "../../../components/input/Formik/base/LMSSelect";
import {
  fetching,
  fetchingDone,
  setOptions,
} from "../../../state/index";
import { API } from "../../../utils/api";
import { studyTypes } from "../../tasks/create_wizard/TaskMapping";
import PrimaryInfo, { PrimaryInfoSchema } from "./PrimaryInfo";

export const Schema = Yup.object().shape({
  client_id: Yup.number().required(),
  ...PrimaryInfoSchema,
});

const useStyles = makeStyles((theme) => ({
  title: {
    position: "center",
  },
  cancelButton: {
    marginRight: theme.spacing(1),
    color: theme.palette.text.onBackground,
    backgroundColor: theme.palette.primary.light,
  },
  confirmButton: {
    color: theme.palette.text.onBackground,
    backgroundColor: theme.palette.secondary.light,
  },
  addIcon: {
    color: theme.palette.success.main,
    marginRight: theme.spacing(1),
  },
  deleteIcon: {
    color: theme.palette.error.main,
    marginRight: theme.spacing(1),
  },
}));

const Transition = forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

export default function CreateProfile() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const classes = useStyles();
  const formOptions = useSelector((state) => state.formOptions);

  const [dialogOpen, setDialogOpen] = useState(false);

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [createProfileSessionNum, setCreateProfileSessionNum] = useState(0);

  const handleClose = useCallback(() => {
    setError(null);
    setSuccess(null);
    setDialogOpen(false);
    setCreateProfileSessionNum((prevState) => prevState + 1);
  }, [setDialogOpen]);

  const createProfile = useCallback((profileData) => {
    setLoading(true);
    setError(null);
    setSuccess(null);
    const url = `/lms/api/v1/${profileData.study}/client/${profileData.study}_clientprofile`;
    return API.request({ url, method: "POST", data: profileData })
      .then(
        (response) => {
          setSuccess("-- Profile Successfully Created! --");
          const profileOptionsKey = `clientProfiles${profileData.study.toUpperCase()}`;
          dispatch(fetching(profileOptionsKey));
          API.request({ url, method: "GET" }).then((refreshResponse) => {
            dispatch(setOptions({ option: profileOptionsKey, value: refreshResponse.data }));
            dispatch(fetchingDone(profileOptionsKey));
          });
          navigate(`/client-profile/details/${response.data.id}/study/${profileData.study}`);
        },
        (e) => {
          setError(e.response.data);
          setError(JSON.stringify(e.response.data));
          setLoading(false);
        },
      );
  }, [dispatch, navigate]);

  const clients = formOptions.lms_backend_clients?.map((client) => (
    { ...client, label: client.mnemonic.toUpperCase() }
  ));

  return (
    <>
      <Fab onClick={() => { setDialogOpen(true); }} />
      <Formik
        key={createProfileSessionNum}
        initialValues={{
          study: "ann",
          use_consortium_exclusions: false,
          use_client_exclusions: false,
          use_allowlist_only: false,
          use_fia_allowlist_only: false,
          use_va_allowlist_only: false,
          include_assets_bd: false,
          include_assets_ria: false,
          include_ria_fidelity: false,
        }}
        validationSchema={Schema}
        enableReinitialize
      >
        {
          (formik) => (
            <Dialog
              open={dialogOpen}
              TransitionComponent={Transition}
              keepMounted
              onClose={handleClose}
              aria-labelledby="alert-dialog-slide-title"
              aria-describedby="alert-dialog-slide-description"
              maxWidth="lg"
              fullWidth
            >
              <DialogTitle id="alert-dialog-slide-title" className={classes.title}>
                Create Profile
              </DialogTitle>
              <DialogContent dividers>
                <LMSRadioGroup
                  label="Study"
                  name="study"
                  options={studyTypes.filter(o => o.hasClientProfiles)}
                />
                <PrimaryInfo
                  clientMnemonic={clients?.find(option => option.id === Number(formik.values?.client_id))?.mnemonic}
                  disabled={false}
                  study={formik.values.study}
                >
                  <LMSSelect
                    label="Client *"
                    name="client_id"
                    options={clients?.sort((c1, c2) => c1?.mnemonic.localeCompare(c2.mnemonic))}
                    formControlProps={{
                      style: { width: "20%" },
                    }}
                  />
                </PrimaryInfo>
              </DialogContent>
              <FormAlerts
                saving={loading}
                success={success}
                error={error}
                setSuccess={setSuccess}
                setError={setError}
              />
              {success
                ? (
                  <div>
                    <DialogActions>
                      <Button onClick={handleClose} className={classes.cancelButton} disabled={loading}>
                        Close
                      </Button>
                    </DialogActions>
                  </div>
                )
                : (
                  <DialogActions>
                    <Button onClick={handleClose} className={classes.cancelButton}>
                      Cancel
                    </Button>
                    <Button
                      onClick={() => createProfile(formik.values)}
                      className={classes.confirmButton}
                      disabled={!formik.touched || Object.keys(formik.errors).length > 0}
                    >
                      Create
                    </Button>
                  </DialogActions>
                )}
            </Dialog>
          )
        }
      </Formik>
    </>
  );
}
