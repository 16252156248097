import React, { useMemo, useState } from "react";
import { Box, Card, CardContent, TextField, CardActionArea, CardActions, Typography, Button, Tabs, Tab, Grid } from "@material-ui/core";
import DescriptionIcon from "@material-ui/icons/Description";
import Autocomplete from "@material-ui/lab/Autocomplete";
import MaterialTable from "material-table";
import useSWR from "swr";

import FormAlerts from "../../../components/FormAlerts";
import TableLoadingOverlay from "../../../components/MaterialTableLoadingOverlay";
import OptionSelect from "../../../components/input/OptionSelect";
import usePageTitle from "../../../hooks/usePageTitle";
import { getClientTemplates, getClients, updateClientTemplates, createClientTemplates, deleteClientTemplates } from "../../../utils/api";

export default function ClientProfilesPage() {
  const [client, setClient] = useState("");
  const [study, setStudy] = useState("ann");
  const { data: clients, error: clientsError } = useSWR("/lms/api/v1/client/client", getClients);
  const { data: clientTemplates, error: clientTemplatesError, mutate } = useSWR(`/lms/api/v1/reportinventory/${client}/${study}/templates/`, () => getClientTemplates(client, study));

  const [tabNum, setTabNum] = useState(0);

  usePageTitle("Report Templates");

  if (clientsError) return <div>Error loading...</div>;

  return (
    <div>
      <Card style={{ marginBottom: "1em" }}>
        <CardContent>
          <Box display="flex" alignItems="center" p={2}>
            {clients
              ? (
                <>
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    onChange={(e, value) => value && setClient(clients.find((clientOption) => clientOption.mnemonic === value)?.id)}
                    options={(clients && clients.map((clientOption) => clientOption.mnemonic)) || []}
                    style={{ width: 300, paddingRight: 20 }}
                    renderInput={(params) => <TextField {...params} label="Client Select" />}
                  />
                  <OptionSelect
                    value={study}
                    onChange={setStudy}
                    options={[
                      { id: "ann", label: "ANN" },
                      { id: "mf", label: "MF" },
                      { id: "sma", label: "SMA" },
                    ]}
                    label="Study"
                  />
                </>
              )
              : <TableLoadingOverlay />}
          </Box>
        </CardContent>
      </Card>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs value={tabNum} onChange={(e, v) => setTabNum(v)} aria-label="Templates tabs">
            <Tab label="Templates Table" id={0} />
            <Tab label="Templates Cards" id={1} />
          </Tabs>
        </Box>
        {tabNum === 0
          ? (
            <TemplatesTable clientId={client} study={study} templates={clientTemplates} error={clientTemplatesError?.response?.data?.detail || clientTemplatesError} mutate={mutate} />
          )
          : (
            <TemplatesList clientId={client} study={study} templates={clientTemplates} error={clientTemplatesError?.response?.data?.detail || clientTemplatesError} mutate={mutate} />
          )}
      </Box>
    </div>
  );
}

const TemplatesTable = ({ clientId, study, templates, error, mutate }) => {
  const columns = [
    { title: "client", field: "client", editable: "never" },
    { title: "id", field: "id", editable: "never" },
    { title: "created_at", field: "created_at", type: "datetime", editable: "never" },
    { title: "created_by", field: "created_by", type: "datetime", editable: "never" },
    { title: "updated_at", field: "updated_at", type: "datetime", editable: "never" },
    { title: "updated_by", field: "updated_by", type: "datetime", editable: "never" },
    { title: "notes", field: "notes" },
    { title: "study", field: "study", editable: "never" },
    { title: "task_type", field: "task_type" },
    { title: "version", field: "version" },
    { title: "time_period", field: "time_period" },
    { title: "payload", field: "payload", editable: "never", render: () => <Button>Edit</Button> },
  ];

  const memoizedLoadingOverlay = useMemo(() => <TableLoadingOverlay />, []);

  return (
    <>
      <FormAlerts error={error} />
      <MaterialTable
        isLoading={!templates && !error}
        columns={columns}
        data={templates}
        title="Report Templates"
        options={{
          pageSize: 20,
          pageSizeOptions: [20],
          headerStyle: {
            backgroundColor: "#30455c",
            color: "#97a9bc",
          },
        }}
        components={{
          OverlayLoading: () => memoizedLoadingOverlay,
        }}
        editable={{
          onRowUpdate: async (newData) => {
            const newObj = templates.find((datarev) => newData.id === datarev.id);
            await updateClientTemplates(clientId, study, newData.id, { id: newObj.id, notes: newData.notes });
            // refetch and update UI
            mutate();
          },
          onRowDelete: async (oldData) => {
            await deleteClientTemplates(clientId, study, oldData.id);
            mutate();
          },
          onRowAdd: async (newData) => {
            await createClientTemplates(clientId, study, { client: clientId, notes: newData.notes });
            mutate();
          },
        }}
      />
    </>
  );
};

const TemplatesList = ({ templates, error }) => (
  <>
    <FormAlerts error={error} />
    <Grid container spacing={3}>
      {templates?.map(template => (
        <Grid item xs={3}>
          <Card>
            <CardActionArea>
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  {template.version}
                </Typography>
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                  <DescriptionIcon style={{ width: 200, height: 200 }} />
                </div>
                <Typography variant="body2" color="text.secondary">
                  {template.task_type}
                </Typography>
              </CardContent>
            </CardActionArea>
            <CardActions>
              <Button size="small" color="primary">
                Run
              </Button>
              <Button size="small" color="primary">
                Edit
              </Button>
            </CardActions>
          </Card>
        </Grid>
      ))}
    </Grid>
  </>
);
