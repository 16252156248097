/* eslint-disable react/no-unstable-nested-components */
import React from "react";
import { Select, MenuItem } from "@material-ui/core";
import MaterialTable from "material-table";
import useSWR from "swr";
import { getDataRevisionsAliasOverride, getDataRevisions, updateDataRevisionAliasOverride, deleteDataRevisionAliasOverride, createDataRevisionAliasOverride, getMisightClients, getDataRevisionsAlias } from "../../../utils/api";

export default function DataRevisionAliasOverrides() {
  const { data, error, mutate } = useSWR("/datarevisions/aliasoverride", getDataRevisionsAliasOverride);
  const { data: datarevisions, error: datarevisionsError } = useSWR("/datarevisions", getDataRevisions);
  const { data: clients, error: clientsError } = useSWR("/misight/clients", getMisightClients);
  const { data: datarevisionsAlias, error: datarevisionsAliasError } = useSWR("/datarevisions/alias", getDataRevisionsAlias);

  if (error || datarevisionsError || clientsError || datarevisionsAliasError) return <div>Error loading...</div>;

  const columns = [
    { title: "Client",
      field: "client_mnemonic",
      editable: "onAdd",
      editComponent: ({ value, onChange }) => (
        <Select
          defaultValue=""
          value={value || ""}
          onChange={(event) => onChange(event.target.value)}
        >
          {clients.map(s => (
            <MenuItem
              key={s.id}
              value={s.mnemonic}
            >
              {s.mnemonic}
            </MenuItem>
          ))}
        </Select>
      ),
    },
    { title: "Alias",
      field: "alias",
      editable: "onAdd",
      editComponent: ({ value, onChange }) => (
        <Select
          defaultValue=""
          value={value || ""}
          onChange={(event) => onChange(event.target.value)}
        >
          {datarevisionsAlias.map(s => (
            <MenuItem
              key={s.id}
              value={s.alias}
            >
              {s.display_alias}
            </MenuItem>
          ))}
        </Select>
      ),
    },
    { title: "Revision",
      field: "revision",
      editable: "always",
      editComponent: ({ value, onChange }) => (
        <Select
          defaultValue=""
          value={value || ""}
          onChange={(event) => onChange(event.target.value)}
        >
          {datarevisions.map(s => (
            <MenuItem
              key={s.id}
              value={s.revision}
            >
              {s.revision}
            </MenuItem>
          ))}
        </Select>
      ),
    },
    { title: "Notes",
      field: "notes",
      editable: "always",
    },
    { title: "Updated By",
      field: "updated_by",
      editable: "never",
    },
    { title: "Updated At",
      field: "updated_at",
      type: "datetime",
      editable: "never",
    },
  ];

  return (
    <div style={{ width: "100%" }}>
      <MaterialTable
        isLoading={!(data && datarevisions && clients && datarevisionsAlias)}
        columns={columns}
        data={data}
        title="Client Overrides"
        editable={{
          onRowUpdate: async (newData) => {
            const newObj = datarevisions.find((datarev) => newData.revision === datarev.revision);
            await updateDataRevisionAliasOverride(newData.id, newData.client_id, { dataset_revision_id: newObj.id, notes: newData.notes });
            mutate();
          },
          onRowDelete: async (oldData) => {
            await deleteDataRevisionAliasOverride(oldData.id, oldData.client_id);
            mutate();
          },
          onRowAdd: async (newData) => {
            const selectedClient = clients.find((client) => newData.client_mnemonic === client.mnemonic);
            const selectedAlias = datarevisionsAlias.find((dra) => newData.alias === dra.alias);
            const selectedDataRevision = datarevisions.find((dr) => newData.revision === dr.revision);
            await createDataRevisionAliasOverride({ client_id: selectedClient.id, alias: selectedAlias.alias, dataset_revision_id: selectedDataRevision.id, notes: newData.notes });
            mutate();
          },
        }}
      />
    </div>
  );
}
