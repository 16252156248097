/* eslint-disable react/no-unstable-nested-components */
import React, { useEffect, useState } from "react";
import DateFnsUtils from "@date-io/date-fns";
import { Accordion, AccordionActions, AccordionSummary, Button, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MaterialTable from "material-table";
import TableLoadingOverlay from "../../../../components/MaterialTableLoadingOverlay";
import { API } from "../../../../utils/api";

const oneWeekAgo = new Date();
oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);

const useStyles = makeStyles(() => ({
  accordianSummary: {
    borderBottom: "1px solid #0000001F",
  },
  accordianActions: {
    padding: 0,
  },
  gridContainer: {
    "& .MuiGrid-item": {
      display: "flex",
      alignItems: "center",
      "&:first-child": {
        padding: 10,
      },
      "&:last-child": {
        justifyContent: "end",
        padding: 10,
      },
    },
  },
}));

function LogsTable({ userId, params }) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [logs, setLogs] = useState([]);

  useEffect(() => {
    // Recursively fetch logs if next_token is returned
    function getLogs(data = [], nextToken = null) {
      return API.request({ url: `/users/${userId}/logs`, method: "GET", params: { ...params, next_token: nextToken } })
        .then((response) => {
          const standardizedResponse = response.data.events;
          const appendedData = data.concat(standardizedResponse);
          if (response.data.next_token) {
            getLogs(appendedData, response.data.next_token);
          } else {
            setLogs(appendedData);
            setLoading(false);
          }
        })
        .catch((e) => {
          setError(e);
          setLoading(false);
        });
    }

    setLoading(true);
    getLogs();
  }, [userId, params]);

  const resultColors = {
    SUCCESS: "green",
    FAILURE: "red",
  };

  const renderResult = (rowData) => {
    const { result } = rowData;
    return <font color={resultColors[result]}>{result}</font>;
  };

  if (error) return <div style={{ width: "100%", paddingLeft: 10 }}> Error loading... </div>;

  return (
    <div style={{ width: "100%" }}>
      <MaterialTable
        isLoading={loading}
        columns={[
          { title: "Device", field: "device" },
          { title: "Event Type", field: "event_type" },
          { title: "Message", field: "message" },
          { title: "Result", field: "result", render: renderResult },
          { title: "Result Reason", field: "result_reason" },
          { title: "Time", field: "time", type: "datetime" },
        ]}
        data={logs}
        title=""
        style={{ borderTopLeftRadius: 0, borderTopRightRadius: 0, boxShadow: "none" }}
        components={{
          OverlayLoading: () => <TableLoadingOverlay />,
        }}
      />
    </div>
  );
}

export default function OktaLogs({ userId }) {
  const classes = useStyles();

  const [expanded, setExpanded] = useState(false);
  const [start, setStart] = useState(oneWeekAgo);
  const [end, setEnd] = useState(new Date());
  const [params, setParams] = useState({});

  return (
    <div style={{ width: "100%" }}>
      <Accordion expanded={expanded} style={{ margin: 0 }}>
        <AccordionSummary
          className={classes.accordianSummary}
          expandIcon={null}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Grid container className={classes.gridContainer}>
            <Grid item xs={12}>
              <Typography variant="h5">
                Okta Logs
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="MM/dd/yyyy"
                  margin="normal"
                  id="start-date-picker-inline"
                  label="Start Date"
                  value={start || oneWeekAgo}
                  onChange={(date) => setStart(date.toISOString().replace("Z", "+00:00"))}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                  animateYearScrolling
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={4}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="MM/dd/yyyy"
                  margin="normal"
                  id="end-date-picker-inline"
                  label="End Date"
                  value={end || new Date()}
                  onChange={(date) => setEnd(date.toISOString().replace("Z", "+00:00"))}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={4}>
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  setParams({ start, end });
                  setExpanded(true);
                }}
              >
                Get Logs
              </Button>
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionActions className={classes.accordianActions}>
          {expanded ? <LogsTable userId={userId} params={params} /> : null}
        </AccordionActions>
      </Accordion>
    </div>
  );
}
