import React from "react";
import { Typography } from "@material-ui/core";
import * as Yup from "yup";

import LMSCheckbox from "../../../components/input/Formik/base/LMSCheckBox";
import { LMSForm } from "../common/LMSForm";
import AnnReportSection, { AnnReportSchema, AnnReportInitialValues } from "../common/ann/AnnReportSection";

export const AnnStandardFeedSchema = Yup.object().shape({
  ...AnnReportSchema,
});

const AnnStandardFeedInitialValues = {
  time_period: 8,
  ...AnnReportInitialValues,
};

export default function AnnStandardFeedFormDD({
  title,
  client,
  studyType,
  step,
  setStep,
  submitHandler,
  taskConfig,
}) {
  return (
    <LMSForm
      client={client}
      studyType={studyType}
      step={step}
      setStep={setStep}
      submitHandler={submitHandler}
      taskConfig={taskConfig}
      validationSchema={AnnStandardFeedSchema}
      initialValues={AnnStandardFeedInitialValues}
      title={title}
    >
      <Typography variant="h4" style={{ backgroundColor: "orange" }}>Discovery Data</Typography>
      <AnnReportSection />
      <LMSCheckbox
        name="include_indirect_transactions"
        label="Include Indirect Transactions (Previously misnamed Sales)"
      />
    </LMSForm>
  );
}
