import React, { useEffect, useRef, useState } from "react";
import { Box } from "@material-ui/core";
import { Close, Done } from "@material-ui/icons";
import { AgGridReact } from "ag-grid-react";
import moment from "moment-timezone";
import useSWR from "swr";
import "../../theme/agGridThemes/statusTableStyle.css";
import {
  getAllHeaderTransformLogs,
  getAllRequiredHeaderTransformLogs,
} from "../../utils/api";

export default function HeaderTransformLogs(props) {
  const { required, api, resetFilter, setResetFilter } = props;
  const gridRef = useRef();
  const [headerLogsData, setHeaderLogsData] = useState([]);
  useEffect(() => {
    if (resetFilter) {
      gridRef.current.api.setFilterModel(null);
      setResetFilter(false);
    }
  }, [resetFilter, setResetFilter]);
  const defaultColDef = {
    sortable: true,
    editable: false,
    flex: 1,
    filter: true,
    floatingFilter: true,
  };
  const { data: headerLogs, error: logsError } = useSWR(
    api,
    required ? getAllRequiredHeaderTransformLogs : getAllHeaderTransformLogs,
  );
  const headerLogscolumns = [
    { headerName: "Client", field: "client" },
    { headerName: "Study Type", field: "study_type" },
    { headerName: "File Type", field: "file_type" },
    { headerName: "Header Name", field: "header_name" },

    {
      headerName: "Previous Header Name",
      field: "previous_standard_header_name",
    },
    {
      headerName: "Current Header Name",
      field: "current_standard_header_name",
    },
    { headerName: "Action Page", field: "action_page" },
    { headerName: "Updated By", field: "updated_by" },
    {
      headerName: "Updated At",
      field: "updated_at",
      valueFormatter: (params) => moment(params.value).format("D MMM YYYY HH:mm:ss"),
      sortable: true,
      sort: "desc",
    },
  ];

  const isRequiredCellRenderer = (data) => (data.data.current_is_required === true ? <Done /> : <Close />);

  const headerLogscolumnsRequired = [
    { headerName: "Client", field: "client" },
    { headerName: "Study Type", field: "study_type" },
    { headerName: "File Type", field: "file_type" },
    { headerName: "Header Name", field: "header_name" },
    { headerName: "Standard Header Name", field: "standard_header_name" },
    {
      headerName: "Is Required",
      field: "current_is_required",
      cellRenderer: (data) => isRequiredCellRenderer(data),
    },
    { headerName: "Action Page", field: "action_page" },
    { headerName: "Updated By", field: "updated_by" },
    {
      headerName: "Updated At",
      field: "updated_at",
      valueFormatter: (params) => moment(params.value).format("D MMM YYYY HH:mm:ss"),
      sortable: true,
      sort: "desc",
    },
  ];
  useEffect(() => {
    if (headerLogs) {
      setHeaderLogsData(headerLogs);
    }
  }, [headerLogs]);
  if (logsError) return <div>Error loading Logs ...</div>;
  return (
    <div>
      <Box style={{ width: "100%", marginLeft: 10 }}>
        <div className="ag-theme-alpine ag-theme-status-table" style={{ height: "600px" }}>
          <AgGridReact
            ref={gridRef}
            columnDefs={required ? headerLogscolumnsRequired : headerLogscolumns}
            gridOptions={{ rowHeight: 80 }}
            rowData={headerLogsData}
            defaultColDef={defaultColDef}
            enableCellEditing
            suppressClickEdit
          />
        </div>
      </Box>
    </div>
  );
}
