import { useEffect } from "react";
import { Auth } from "aws-amplify";
import { useDispatch } from "react-redux";
import { setPrivilege } from "../state";

export default function usePrivilege() {
  const dispatch = useDispatch();

  useEffect(() => {
    async function loadPrivilege() {
      try {
        const session = await Auth.currentSession();
        const isDeveloperAccount = session?.accessToken?.payload["cognito:groups"].includes("lms-devs");
        const isDbRefreshControlAccount = session?.accessToken?.payload["cognito:groups"].includes("lms-db-refresh-control");
        const isClientDomainControlAccount = session?.accessToken?.payload["cognito:groups"].includes("lms-client-domain-control");

        dispatch(setPrivilege({
          isDeveloperAccount,
          isDbRefreshControlAccount,
          isClientDomainControlAccount,
        }));
      } catch (e) {
        console.log(e);
      }
    }
    loadPrivilege();
  }, [dispatch]);
}
