import React from "react";
import { Chip, Typography } from "@material-ui/core";
import { useSelector } from "react-redux";
import useFormData from "../../hooks/useFormData";
import BaseForm from "./common/BaseForm";

const uiSchema = {
  scope: {
    "ui:widget": "radio",
  },
  invalidate: {
    "ui:help": "Completely invalidate the user's cache before starting to prewarm with new data (recommended)",
  },
};

export default function MISightPrewarmCacheForm({ children, client, setTaskConfig, submitHandler, formDataProp, displayHeader }) {
  const [formData, setFormData] = useFormData(setTaskConfig, formDataProp);

  function cleanFormData(orgData) {
    const clnData = {};
    if ("scope" in orgData) {
      clnData.scope = orgData.scope;
    }
    if ("value" in orgData) {
      if (orgData.scope === "client") {
        clnData.value = client;
      } else if (orgData.value?.includes("@")) {
        clnData.value = orgData.value;
      }
    }
    if ("invalidate" in orgData) {
      clnData.invalidate = orgData.invalidate;
    }
    return setFormData(clnData);
  }

  let users = useSelector(state => state.users.misightUsers);
  users = users.filter(user => user.client_mnemonic === client).map(user => (
    {
      const: user.username,
      title: `${user.okta_first_name} ${user.okta_last_name} - ${user.okta_role}`,
    }
  ));
  if (!users?.length) users.push({ const: null, title: "No users available for client" });

  const misightPrewarmCacheSchema = {
    title: "MISight Prewarm Cache",
    description: "Prewarm MISight user's cache by individual user or whole client",
    type: "object",
    required: [
      "scope",
      "value",
      "invalidate",
    ],
    definitions: {
      client: {
        type: "string",
        title: "Client",
        readOnly: true,
        default: client,
      },
      invalidate: {
        type: "boolean",
        title: "Invalidate Previous Cache",
        description: "Completely invalidate the user's cache before starting to prewarm with new data (recommended)",
        default: true,
      },
    },
    properties: {
      scope: {
        type: "string",
        title: "Prewarm Scope",
        description: "Prewarm the cache for an individual user or all of a client's users",
        enum: [
          "client",
          "user",
        ],
        enumNames: ["All Users in Client", "Single User"],
        default: "client",
      },
    },
    dependencies: {
      scope: {
        oneOf: [
          {
            properties: {
              scope: {
                enum: ["client"],
              },
              value: {
                description: "Client to prewarm",
                $ref: "#/definitions/client",
              },
              invalidate: {
                $ref: "#/definitions/invalidate",
              },
            },
            required: ["value"],
          },
          {
            properties: {
              scope: {
                enum: ["user"],
              },
              client: {
                $ref: "#/definitions/client",
              },
              value: {
                type: "string",
                title: "User",
                oneOf: users,
                description: "User to prewarm",
              },
              invalidate: {
                $ref: "#/definitions/invalidate",
              },
            },
            required: ["value"],
          },
        ],
      },
    },
  };

  return (
    <div>
      <div style={{ display: "flex", alignItems: "center" }}>
        <Typography variant="subtitle2" style={{ marginRight: "8px", marginBottom: "16px" }}>Task Name :</Typography>
        <Chip style={{ marginBottom: "16px" }} variant="outlined" label={displayHeader(["study", "scope"])} />
      </div>

      <BaseForm
        schema={misightPrewarmCacheSchema}
        uiSchema={uiSchema}
        formData={formData}
        submitHandler={submitHandler}
        setFormData={cleanFormData}
      >
        {children}
      </BaseForm>
    </div>
  );
}
