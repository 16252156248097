import React, { useCallback, useEffect, useState } from "react";
import { Checkbox, FormControlLabel, Typography } from "@material-ui/core";
import useTerrClientDescriptions from "../../hooks/useTerrClientDescriptions";

export default function TerrServiceTerritoryExportForm({ client, setTaskConfig, submitHandler, setClient, children }) {
  // State
  // Keeping this as a work around until we do need more jobs with multiple clients
  // since the rest a braavos/hodor expects a string for client
  const [multiClients] = useState(client);
  const [excludeSalesStructure, setExcludeSalesStructure] = useState(false);
  const [terrClientDescriptions, setTerrClientDescriptions] = useState({});

  const handleFormChange = useCallback(() => {
    setTaskConfig((prevTaskConfig) => ({
      ...prevTaskConfig,
      exclude_sales_structure: excludeSalesStructure,
      clients: multiClients,
    }));
  }, [excludeSalesStructure, multiClients, setTaskConfig]);

  useTerrClientDescriptions(setTerrClientDescriptions);

  useEffect(() => {
    handleFormChange();
  }, [excludeSalesStructure, multiClients, handleFormChange]);

  useEffect(() => {
    handleFormChange();
    setClient("MULTIPLE_CLIENTS");
  }, [handleFormChange, setClient]);

  return (
    <form schema={{}} onSubmit={(e) => { e.preventDefault(); submitHandler(true, e); }} noValidate autoComplete="off" onChange={handleFormChange}>
      <Typography gutterBottom variant="h5" component="h2">
        Run LMS Territory Export Job
      </Typography>
      <Typography variant="body2" color="textSecondary" component="h3">
        {`Running for Clients: ${multiClients.map((clientId) => terrClientDescriptions[clientId])}`}
      </Typography>
      <br />
      <br />
      <FormControlLabel
        control={(
          <Checkbox
            checked={excludeSalesStructure}
            onChange={(e) => setExcludeSalesStructure(e.target.checked)}
            inputProps={{ "aria-label": "primary checkbox" }}
          />
        )}
        label="Exclude client sales structure territory files from export"
      />
      {children}
    </form>
  );
}
