import React, { useEffect } from "react";
import { FormControl } from "@material-ui/core";
import Form from "@rjsf/material-ui";
import CSVUpload from "../../components/input/CSVUpload";
import OptionSelect from "../../components/input/OptionSelect";

const fileTypes = [
  { id: "secondary_files", label: "Secondary Files", study: "all" },
];

export default function PreprocessingSecondaryFilesForm({ children, taskConfig, setTaskConfig, csv, setCsv, submitHandler, studyType }) {
  useEffect(() => {
    setTaskConfig((preConfig) => ({ ...preConfig, study: studyType, file_type: "secondary_files" }));
  }, [setTaskConfig, studyType]);

  return (
    <Form
      schema={{}}
      onSubmit={submitHandler}
    >
      <CSVUpload
        onChange={(update) => {
          setCsv({ attachment: update.csv });
        }}
        value={{ csv, ...taskConfig }}
      />
      <FormControl fullWidth margin="normal">
        <OptionSelect
          disabled
          required
          style={{ width: 500 }}
          value={taskConfig.file_type || ""}
          onChange={(type) => {
            setTaskConfig((prevTaskConfig) => ({ ...prevTaskConfig, file_type: type }));
          }}
          options={fileTypes.filter((o) => o.study === studyType)}
          label="File Type"
        />
      </FormControl>
      {children}
    </Form>
  );
}
