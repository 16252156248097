import React, { useEffect, useMemo, useState } from "react";
import { Box, Card, CardContent, TextField, Button, Dialog, DialogActions, DialogContent, DialogContentText } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import MaterialTable from "material-table";
import { useLocation } from "react-router-dom";
import useSWR from "swr";

import TableLoadingOverlay from "../../components/MaterialTableLoadingOverlay";
import usePageTitle from "../../hooks/usePageTitle";
import { addAnnFirm, deleteMultipleAnnFirm, getClients, getFilteredAnnFirmNames, getFinalFirmNames, updateAnnFirmNames } from "../../utils/api";
import { ANNUITY_TYPES } from "./constant";

export default function AnnuityFirmNameChangeProfilePage() {
  const location = useLocation();
  const { clientId } = location.state || "";
  const [client, setClient] = useState(clientId);
  const { data: clients, error: clientsError } = useSWR("/lms/api/v1/client/client", getClients);

  usePageTitle("Annuity Firm Name Change");

  if (clientsError) return <div>Error loading...</div>;
  return (
    <div>
      <Card style={{ marginBottom: "1em" }}>
        <CardContent>
          <Box display="flex" justifyContent="space-between" p={2}>
            {clients
              ? (
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  onChange={(e, value) => value && setClient(clients.find((clientOption) => clientOption.mnemonic.toUpperCase() === value)?.id)}
                  options={(clients && clients.map((clientOption) => clientOption.mnemonic.toUpperCase())) || []}
                  value={clients.find((clientOption) => clientOption.id === clientId)?.mnemonic}
                  style={{ width: 300, paddingRight: 20 }}
                  renderInput={(params) => <TextField {...params} label="Annuity Client Select" />}
                />
              )
              : <TableLoadingOverlay />}
            <Card />
          </Box>
        </CardContent>
      </Card>
      {client ? <FirmNamesTable clientId={client} clients={clients} /> : null}
    </div>
  );
}

const FirmNamesTable = ({ clientId, clients }) => {
  const { data: annuityFirms, error: annuityFirmsError } = useSWR(`lms/api/v1/firmnames/${clientId}`, () => getFilteredAnnFirmNames(clientId));
  const { data: finalFirmNames, error: finalFirmnamesError } = useSWR("/lms/api/v1/firmnames/final_firmnames", getFinalFirmNames);
  const [displayedRows, setDisplayedRows] = useState([]);
  const [currentClient, setCurrentClient] = useState("");
  const [open, setOpen] = useState(false);
  const [description, setDescription] = useState("");
  const [isDelete, setIsDelete] = useState(false);
  const [deleteData, setDeleteData] = useState([]);

  const finalFirmNamesMapping = {};
  function convertToObject(firmName) {
    finalFirmNamesMapping[firmName] = firmName;
  }

  if (finalFirmNames) {
    finalFirmNames.map(convertToObject); // Converting the api response to object type for column lookup
  }

  function setDialogValues(message) {
    setOpen(true);
    setDescription(message);
  }

  useEffect(() => {
    setCurrentClient(clients.find((clientOption) => clientOption.id === clientId)?.mnemonic);
    if (annuityFirms) {
      Object.entries(annuityFirms).forEach((firm) => {
        /* eslint-disable no-param-reassign */
        firm[1].client = currentClient;
        /* eslint-disable no-param-reassign */
      });
      setDisplayedRows(annuityFirms);
    }
  }, [annuityFirms, clientId, clients, currentClient]);

  function rowUpdate(newData, oldData) {
    const response = updateAnnFirmNames(newData.id, newData);
    response.then(res => {
      if (res.status === 200) {
        const updatedData = res.data;
        updatedData.client = currentClient;
        const dataUpdate = [...displayedRows];
        const index = oldData.tableData.id;
        dataUpdate[index] = updatedData;
        setDisplayedRows([...dataUpdate]);
        setDialogValues("Updated the row");
      } else {
        setDialogValues(`Error = ${res.request.responseText}.`);
      }
    });
  }

  function rowAdd(newData) {
    if (!newData.original_firm_name || !newData.final_firm_name || !newData.annuity_type) {
      setDialogValues("Please fill all the mandatory fields --> Original Firm Name, Final Firm Name, Annuity Types");
      return true;
    }
    const response = addAnnFirm(newData);
    response.then(res => {
      if (res.status === 200) {
        const newAddedData = res.data;
        newAddedData.client = currentClient;
        setDisplayedRows([...displayedRows, newAddedData]);
        setDialogValues("Added one new row");
      } else {
        setDialogValues(`Error = ${res.request.responseText}.`);
      }
    });
    return false;
  }

  function deleteMultiple(deleteMultipleData) {
    setDeleteData(deleteMultipleData);
    setIsDelete(true);
    setDescription("Are you sure you want to delete the selected rows?");
  }
  const columns = [
    { title: "Client", field: "client", editable: "never" },
    { title: "Broker Dealer Id (Begins with)", field: "broker_dealer_id_begins_with" },
    { title: "Original Firm Name*", field: "original_firm_name", validate: rowData => rowData.original_firm_name !== "" },
    { title: "Final Firm Name*", field: "final_firm_name", lookup: finalFirmNamesMapping },
    { title: "Coverage", field: "coverage" },
    { title: "Channel", field: "channel" },
    { title: "Annuity*", field: "annuity_type", lookup: ANNUITY_TYPES },
  ];

  const memoizedLoadingOverlay = useMemo(() => <TableLoadingOverlay />, []);

  if (annuityFirmsError) return <div>Error loading Firmname records for the selected client...</div>;
  if (finalFirmnamesError) return <div>Error loading Final Firm Names...</div>;

  return (
    <div>
      <MaterialTable
        isLoading={!annuityFirms}
        columns={columns}
        data={displayedRows}
        title="Annuity Firms"
        options={{
          pageSize: 20,
          pageSizeOptions: [20],
          headerStyle: {
            backgroundColor: "#30455c",
            color: "#97a9bc",
          },
          selection: true,
        }}
        actions={[
          {
            tooltip: "Remove All Selected Users",
            icon: "delete",
            onClick: (evt, data) => {
              deleteMultiple(data);
            },
          },
        ]}
        components={{
          OverlayLoading: () => memoizedLoadingOverlay,
        }}
        editable={{
          onRowAdd: newData => new Promise((resolve, reject) => {
            newData.client_id = clientId;
            const isReject = rowAdd(newData);
            if (isReject) {
              setTimeout(() => {
                reject();
              }, 1000);
            }
            setTimeout(() => {
              resolve();
            }, 1000);
          }),
          onRowUpdate: (newData, oldData) => new Promise((resolve) => {
            rowUpdate(newData, oldData);
            setTimeout(() => {
              resolve();
            }, 1000);
          }),
        }}
      />
      {open ? <AlertDialog setOpen={setOpen} description={description} /> : null}
      {isDelete ? <ActionDialog setDisplayedRows={setDisplayedRows} setIsDelete={setIsDelete} setDialogValues={setDialogValues} displayedRows={displayedRows} deleteData={deleteData} description={description} /> : null}
    </div>
  );
};

function AlertDialog({ setOpen, description }) {
  const [dialogOpen, setDialogOpen] = React.useState(true);

  const handleClose = () => {
    setOpen(false);
    setDialogOpen(false);
  };

  return (
    <div>
      <Dialog
        open={dialogOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {description}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Ok</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

function ActionDialog({ setDisplayedRows, setIsDelete, setDialogValues, displayedRows, deleteData, description }) {
  const [dialogOpen, setDialogOpen] = React.useState(true);

  const handleDelete = () => {
    const payload = { id: deleteData.map(({ id }) => id) };
    const response = deleteMultipleAnnFirm(payload);
    response.then(res => {
      if (res.status === 200) {
        const currentDisplayedRows = [...displayedRows];
        const indexes = deleteData.map((data) => data.tableData.id);
        indexes.sort((a, b) => b - a);
        indexes.map((index) => (
          currentDisplayedRows.splice(index, 1)
        ));
        setDisplayedRows(currentDisplayedRows);
        setIsDelete(false);
        setDialogOpen(false);
      } else {
        setDialogValues(res.error);
        setDialogOpen(false);
      }
    });
  };

  const handleCancel = () => {
    setIsDelete(false);
    setDialogOpen(false);
  };

  return (
    <div>
      <Dialog
        open={dialogOpen}
        onClose={handleCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {description}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button style={{ backgroundColor: "#ff5c5c" }} onClick={handleDelete}>Delete</Button>
          <Button onClick={handleCancel}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
