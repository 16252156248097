/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/no-unstable-nested-components */
import React, { useCallback, useEffect, useState } from "react";
import { Box, Button, Chip, FormControlLabel, FormGroup, Switch, Typography } from "@material-ui/core";
import MaterialTable from "material-table";
import { useDispatch, useSelector } from "react-redux";
import CountDown from "../../../components/CountDown";
import tableIcons from "../../../components/MaterialTableIcons";
import TableLoadingOverlay from "../../../components/MaterialTableLoadingOverlay";
import usePageTitle from "../../../hooks/usePageTitle";
import useTerrClientDescriptions from "../../../hooks/useTerrClientDescriptions";
import { resetFilters } from "../../../state";
import { API } from "../../../utils/api";
import { taskTypes } from "../create_wizard/TaskMapping";
import DetailsColumn from "./DetailsColumn";
import Filters from "./Filters";
import QuickActionsPopup from "./QuickActionsPopup";
import StatusChip from "./StatusChip";
import TimeColumn from "./TimeColumn";

const refreshTime = 30;

export default function StatusTable({ isDeveloperAccount, ddTaskOnly = false, pageTitle = "Task Status", preWarmOnly = false }) {
  const dispatch = useDispatch();
  const filters = useSelector(state => state.filters.available);
  const [appliedFilters, setAppliedFilters] = useState({});
  const [terrClientDescriptions, setTerrClientDescriptions] = useState({});
  const [timestampIconDisplay, setTimestampIconDisplay] = useState(true);
  const [onQueuePage, setOnQueuePage] = useState(false);
  useTerrClientDescriptions(setTerrClientDescriptions);
  usePageTitle(pageTitle);

  const DisplayStudy = ({ rowData }) => {
    const taskMatch = taskTypes.find(task => task.id === rowData.taskType && task.label === rowData.title);
    const study = taskMatch && taskMatch.study ? taskMatch.study : "NONE";

    return <Chip variant="outlined" label={study.toUpperCase()} style={{ width: 100 }} />;
  };

  const columns = [
    { title: "Study", sorting: true, field: "study", render: (rowData) => <DisplayStudy rowData={rowData} /> },
    { title: "Task", field: "details", render: rowData => <DetailsColumn rowData={rowData} /> },
    { title: "Actions", field: "actions", sorting: false, render: rowData => <QuickActionsPopup isDeveloperAccount={isDeveloperAccount} rowData={rowData} /> },
    { title: "Client", field: "client", render: rowData => (rowData.client in terrClientDescriptions ? terrClientDescriptions[rowData.client] : rowData.client) },
    { title: "Status", field: "status", render: rowData => <StatusChip status={rowData.status} /> },
    {
      title: "Timestamp",
      field: "createdDate",
      type: "datetime",
      render: rowData => (
        <TimeColumn
          createdTime={rowData.createdDate}
          history={rowData.statusHistory}
          timestampIconDisplay={timestampIconDisplay}
        />
      ),
    },
    {
      title: "User",
      field: "username",
      render: rowData => {
        const capitalize = (name) => name.replace(/(\b[a-z](?!\s))/g, (x) => x.toUpperCase());
        if (!rowData.username.includes("_")) {
          let [first, last = ""] = rowData.username.split(".");
          first = capitalize(first.split("@")[0]);
          last = capitalize(last.split("@")[0]);
          return `${first} ${last}`;
        }
        return `${rowData.username}`;
      },
    },
  ];

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [nextPaginationKey, setNextPaginationKey] = useState(false);
  const [loadedPaginationKeys, setLoadedPaginationKeys] = useState([]);
  const [pages, setPages] = useState([]);

  const url = "/tasks";

  const fetchData = useCallback((page, paginationKeyParam) => {
    if (!pages.includes(page)) {
      setLoading(true);
      setLoadedPaginationKeys(prevKeys => prevKeys.concat(paginationKeyParam));
      const params = paginationKeyParam ? { ...appliedFilters, limit: 21, paginationKey: paginationKeyParam } : { ...appliedFilters, limit: 21 };
      if (preWarmOnly) { params.preWarmOnly = preWarmOnly; }
      API.request({ url, method: "GET", params }).then(
        (response) => {
          response.data.results.sort((a, b) => (a.createdDate > b.createdDate ? -1 : 1));
          setData((PrevData) => (PrevData.concat(response.data.results)));
          setNextPaginationKey(response.data.paginationKey);
          setPages(prevPages => prevPages.concat(page));
          setLoading(false);
        },
        () => {
          setLoading(false);
          return null;
        },
      );
    }
  }, [pages, appliedFilters]);

  // Refreshing all seen pagination keys because the user might be on another page already
  const refreshData = useCallback(() => {
    setLoading(true);
    setOnQueuePage(false);
    let newData = [];
    const refreshRequests = [];

    loadedPaginationKeys.forEach((paginationKey) => {
      const params = paginationKey ? { ...appliedFilters, limit: 21, paginationKey } : { ...appliedFilters, limit: 21 };
      if (preWarmOnly) { params.preWarmOnly = preWarmOnly; }
      refreshRequests.push(
        API.request({ url, method: "GET", params }).then((response) => {
          newData = newData.concat(response.data.results);
        }),
      );
    });
    Promise.all(refreshRequests).then(() => {
      newData.sort((a, b) => (a.createdDate > b.createdDate ? -1 : 1));
      setData(newData);
      setLoading(false);
    });
  }, [appliedFilters, loadedPaginationKeys]);

  const tableRef = React.useRef();
  const fetchNoFilteredData = useCallback(() => {
    tableRef.current.onChangePage({}, 0);
    const params = { limit: 21 };
    params.preWarmOnly = preWarmOnly;
    API.request({ url, method: "GET", params }).then(
      (response) => {
        // Reset all the data with the new filters
        if (response.data.results.length > 0) {
          response.data.results.sort((a, b) => (a.createdDate > b.createdDate ? -1 : 1));
          if (params.orderBy === "asc") {
            response.data.results.sort((a, b) => (a.createdDate < b.createdDate ? -1 : 1));
          }
          setData(response.data.results);
          setNextPaginationKey(response.data.paginationKey);
          setLoadedPaginationKeys([""]);
          setPages([0]);
        } else {
          setData([]);
        }
        setLoading(false);
      },
      () => {
        setLoading(false);
        return null;
      },
    );
  }, []);

  const applyFilterAndFetchData = useCallback(() => {
    setLoading(true);
    // Clean up state before sending
    const flattenedFilters = {
      ...filters,
      taskType: filters.taskType.map((task) => task.id),
      client: filters.client.map((clt) => clt.id),
      username: filters.username.map((user) => user.email),
    };
    const noBlankFilters = {};
    Object.keys(flattenedFilters).forEach((key) => {
      if (flattenedFilters[key] && flattenedFilters[key] !== "" && Object.keys(flattenedFilters[key]).length !== 0) {
        noBlankFilters[key] = flattenedFilters[key];
      }
    });
    setAppliedFilters(noBlankFilters);
    // Fetching all items to correctly sort because paginations relies on sorting in the
    // backend which is significantly more complex to change.
    const params = { ...noBlankFilters, limit: 10000 };
    if (preWarmOnly) { params.preWarmOnly = preWarmOnly; }
    API.request({ url, method: "GET", params }).then(
      (response) => {
        // Reset all the data with the new filters
        if (response.data.results.length > 0) {
          response.data.results.sort((a, b) => (a.createdDate > b.createdDate ? -1 : 1));
          if (params.orderBy === "asc") {
            response.data.results.sort((a, b) => (a.createdDate < b.createdDate ? -1 : 1));
          }
          setData(response.data.results);
        } else {
          setData([]);
        }
        setLoading(false);
      },
      () => {
        setLoading(false);
        return null;
      },
    );
  }, [filters]);

  const fetchQueuedData = useCallback(() => {
    setLoading(true);
    let fetchedData = [];
    const promisedRequest = [];
    const queuedStatus = ["RUNNING_QUEUE", "QUEUED"];
    queuedStatus.forEach((status) => {
      const params = { ...appliedFilters, status };
      promisedRequest.push(
        API.request({ url, method: "GET", params }).then((response) => {
          fetchedData = fetchedData.concat(response.data.results);
        }),
      );
    });
    Promise.all(promisedRequest).then(() => {
      let finalFetch = [];
      fetchedData = fetchedData.sort((a, b) => b.createdDate < a.createdDate);
      const fetchRunnning = fetchedData.filter(a => a.status === "RUNNING_QUEUE");
      finalFetch = finalFetch.concat(fetchRunnning);
      let fetchQueued = fetchedData.filter(a => a.status === "QUEUED");
      /* eslint-disable*/
      fetchQueued = fetchQueued.sort((a, b) => ((a.createdDate > b.createdDate) ? 1 : ((b.createdDate > a.createdDate) ? -1 : 0)));
      finalFetch = finalFetch.concat(fetchQueued);
      if (!fetchedData.length) setOnQueuePage(true);
      setLoading(false);
      finalFetch.sort((a, b) => a.createdDate > b.createdDate ? -1 : 1);
      setData(finalFetch);
    });
  }, [appliedFilters]);
  const handleToggle = (e) => {
    setOnQueuePage(e.target.checked);
    if (e.target.checked) {
      fetchQueuedData()
    } else {
      refreshData()
    }
  }

  useEffect(() => {
    dispatch(resetFilters())
    fetchData(0, null)
  }, []);

  useEffect(() => {
    const flattenedFilters = {
      ...filters,
      taskType: filters.taskType.map((task) => task.id),
      client: filters.client.map((clt) => clt.id),
      username: filters.username.map((user) => user.email),
    };
    const noBlankFilters = {};
    Object.keys(flattenedFilters).forEach((key) => {
      if (flattenedFilters[key] && flattenedFilters[key] !== "" && Object.keys(flattenedFilters[key]).length !== 0) {
        noBlankFilters[key] = flattenedFilters[key];
      }
    });
    setAppliedFilters(noBlankFilters)
  }, [filters]);

  return (
    <div>
      <Filters ddTaskOnly={ddTaskOnly} preWarmOnly={preWarmOnly}>
        <Button variant="outlined" color="secondary" onClick={applyFilterAndFetchData}>Apply Filter</Button>
        <Button variant="outlined" color="primary" onClick={() => { dispatch(resetFilters()); setAppliedFilters({}); setOnQueuePage(false); fetchNoFilteredData() }}>Reset Filter</Button>
        <Button variant="outlined" color="primary" onClick={() => { setTimestampIconDisplay(!timestampIconDisplay) }}>Toggle Timestamp Display</Button>
        {!preWarmOnly && (
        <FormGroup>
          <FormControlLabel
            control={<Switch color="primary" checked={onQueuePage} onChange={(e) => { handleToggle(e) }} />}
            label="Display Queued Jobs"
          />
        </FormGroup>
        )
        }
      </Filters>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="right"
        style={{ marginLeft: "1em", marginRight: "1em" }}
      >
        <CountDown duration={refreshTime} onFinish={onQueuePage ? fetchQueuedData : refreshData} text="Refreshing in" />
      </Box>
      {data.length > 0 ?
        <MaterialTable
          tableRef={tableRef}
          style={{ marginTop: "1.5em" }}
          title="Status Table"
          columns={columns}
          data={data}
          icons={tableIcons}
          isLoading={loading}
          onChangePage={(page) => (fetchData(page, nextPaginationKey))}
          options={{
            pageSize: 20,
            pageSizeOptions: [20],
            // TODO: Figure out how to theme this properly
            headerStyle: {
              backgroundColor: "#30455c",
              color: "#97a9bc",
            },
            toolbar: false,
            emptyRowsWhenPaging: false,
          }}
          components={{
            OverlayLoading: () => (
              <TableLoadingOverlay />
            ),
          }}
        /> :
         onQueuePage ?
          <Typography variant="h3" align="center" component="h2">
            Empty Queue 🛒
          </Typography> : loading ? <TableLoadingOverlay /> :

            <Typography variant="h4" align="center" component="h4">
              No data for selected filter
            </Typography>
      }
    </div>
  );
}