import React, { useContext } from "react";
import { Divider, Typography } from "@material-ui/core";
import { useSelector } from "react-redux";

import LMSSelect from "../../../../components/input/Formik/base/LMSSelect";
import { getOptionsByClient } from "../../../../utils/SharedDefinitions";
import { convertArrayToOptions } from "../../../../utils/dataTransformations";
import { FormContext } from "../FormContext";

export default function TerritorySection({
  territorySettingsName,
  title = "Territories Configurations",
  alternateTerrLabel1 = "Alternate Territory Name 1",
  alternateTerrLabel2 = "Alternate Territory Name 2",
  alternateTerrGroupingLabel = "Alternate Territory Grouping",
}) {
  const { clientMnemonic, studyType } = useContext(FormContext);
  const formOptions = useSelector((state) => state.formOptions);

  const legacyClient = formOptions.clients?.find((o) => o.id.toUpperCase() === clientMnemonic?.toUpperCase());
  const territoryGroups = convertArrayToOptions(getOptionsByClient(formOptions[`territoryGroups${studyType.toUpperCase()}`], legacyClient?.id));
  const terrAlts = convertArrayToOptions(getOptionsByClient(formOptions[`terrAltLabels${studyType.toUpperCase()}`], legacyClient?.id));
  return (
    <>
      <Typography variant="h5">
        {title}
      </Typography>
      <Divider />
      <LMSSelect
        name={`${territorySettingsName}.territory_map_as_group`}
        options={territoryGroups}
        label="Territory Group Map as Territory"
      />
      <LMSSelect
        name={`${territorySettingsName}.alternate_territory_grouping`}
        options={territoryGroups}
        label={alternateTerrGroupingLabel}
      />
      <LMSSelect
        name={`${territorySettingsName}.alternate_territory_name_1`}
        options={terrAlts}
        label={alternateTerrLabel1}
      />
      <LMSSelect
        name={`${territorySettingsName}.alternate_territory_name_2`}
        options={terrAlts}
        label={alternateTerrLabel2}
      />
    </>
  );
}
