import React from "react";
import { makeStyles } from "@material-ui/core";
import Form from "@rjsf/material-ui";

import CustomArray from "../../../components/input/CustomArray";
import FilingTypeInput from "../../../components/input/FilingTypeInput";

// Shrink hitbox for CheckBox Fields
const useStyles = makeStyles(() => ({
  form: {
    "&&&&& label": {
      width: "fit-content",
    },
  },
}));

function quarterToInt(Qstr) {
  return parseInt(Qstr.split("Q").join(""), 10);
}

export default function BaseForm({ children, schema, uiSchema, formData, submitHandler, setFormData }) {
  const classes = useStyles();

  const formUISchema = {
    ...uiSchema,
    "ui:order": [
      "start_quarter",
      "end_quarter",
      "lock",
      "production_cycle",
      "*",
      "territory_settings",
      "territory_settings_1",
      "territory_settings_2",
      "broker_zip_exclusions_settings",
      "custom_inclusions_settings",
    ],
    lock: {
      "ui:help": "Leave unselected for Live",
    },
    annuity_types: {
      "ui:widget": "checkboxes",
    },
    ria_inclusions: {
      "ui:field": "FilingTypeInputField",
    },
    broker_zip_exclusions_settings: {
      fia_exclusion_settings: {
        broker_exclusions: {
          "ui:field": "CustomArrayField",
        },
        broker_limitation: {
          "ui:field": "CustomArrayField",
        },
        broker_zip_exclusion_overrides: {
          "ui:field": "CustomArrayField",
        },
        broker_zip_exclusions: {
          "ui:field": "CustomArrayField",
        },
        broker_zip_limitation: {
          "ui:field": "CustomArrayField",
        },
        client_broker_zip_exclusion_overrides: {
          "ui:field": "CustomArrayField",
        },
        client_broker_zip_exclusions: {
          "ui:field": "CustomArrayField",
        },
      },
      va_exclusion_settings: {
        broker_exclusions: {
          "ui:field": "CustomArrayField",
        },
        broker_limitation: {
          "ui:field": "CustomArrayField",
        },
        broker_zip_exclusion_overrides: {
          "ui:field": "CustomArrayField",
        },
        broker_zip_exclusions: {
          "ui:field": "CustomArrayField",
        },
        broker_zip_limitation: {
          "ui:field": "CustomArrayField",
        },
        client_broker_zip_exclusion_overrides: {
          "ui:field": "CustomArrayField",
        },
        client_broker_zip_exclusions: {
          "ui:field": "CustomArrayField",
        },
      },
      sma_exclusion_settings: {
        broker_exclusions: {
          "ui:field": "CustomArrayField",
        },
        broker_limitation: {
          "ui:field": "CustomArrayField",
        },
        broker_zip_exclusion_overrides: {
          "ui:field": "CustomArrayField",
        },
        broker_zip_exclusions: {
          "ui:field": "CustomArrayField",
        },
        broker_zip_limitation: {
          "ui:field": "CustomArrayField",
        },
        client_broker_zip_exclusion_overrides: {
          "ui:field": "CustomArrayField",
        },
        client_broker_zip_exclusions: {
          "ui:field": "CustomArrayField",
        },
      },
      mf_exclusion_settings: {
        broker_exclusions: {
          "ui:field": "CustomArrayField",
        },
        broker_limitation: {
          "ui:field": "CustomArrayField",
        },
        broker_zip_exclusion_overrides: {
          "ui:field": "CustomArrayField",
        },
        broker_zip_exclusions: {
          "ui:field": "CustomArrayField",
        },
        broker_zip_limitation: {
          "ui:field": "CustomArrayField",
        },
        client_broker_zip_exclusion_overrides: {
          "ui:field": "CustomArrayField",
        },
        client_broker_zip_exclusions: {
          "ui:field": "CustomArrayField",
        },
      },
    },
  };
  const fields = {
    CustomArrayField: CustomArray,
    FilingTypeInputField: FilingTypeInput,
  };

  // Add any general validation rules here
  function validate(data, errors) {
    if (data.start_quarter && data.end_quarter) {
      if (quarterToInt(data.start_quarter) > quarterToInt(data.end_quarter)) errors.start_quarter.addError("Start quarter cannot be higher than end quarter");
    }
    if (data.include_all_mstar_categories && data.include_top_categories) {
      errors.include_top_categories.addError("Please select one option from Include Top Category Sections and Include All Category Sections from Morningstar");
    }
    return errors;
  }

  return (
    <Form
      className={classes.form}
      schema={schema}
      fields={fields}
      uiSchema={formUISchema}
      formData={formData}
      onChange={(e) => setFormData(e.formData)}
      onSubmit={submitHandler}
      validate={validate}
    >
      {children}
    </Form>
  );
}
