import React from "react";
import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";

export default function OptionSelect({
  onChange,
  value,
  options,
  required,
  disabled,
  error,
  label,
  inputProps,
  style,
  valueKey = "id",
  optionKey = "label",
  children,
  multiple = false,
  variant = "outlined",
}) {
  const labelId = label && `inputLabel-${label.replace(" ", "")}`;

  return (
    <FormControl style={style} error={error} disabled={disabled} required={required} variant={variant} margin="normal">
      {labelId && <InputLabel id={`inputLabel-${label.replace(" ", "")}`}>{label}</InputLabel>}
      <Select
        label={label}
        labelId={labelId}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        inputProps={inputProps}
        multiple={multiple}
      >
        {options.map((o) => (
          <MenuItem key={o[valueKey]} value={o[valueKey]}>
            {o[optionKey]}
          </MenuItem>
        ))}
      </Select>
      {children}
    </FormControl>
  );
}
