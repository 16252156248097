import React, { useEffect, useState, useMemo } from "react";
import { Button, Card, CardContent, Snackbar, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import MuiAlert from "@material-ui/lab/Alert";
import MaterialTable from "material-table";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, Link } from "react-router-dom";
import useSWR from "swr";
import TableLoadingOverlay from "../../components/MaterialTableLoadingOverlay";
import OptionSelect from "../../components/input/OptionSelect";
import { fetching, fetchingDone } from "../../state";
import { API, getClients, getPreprocessListById, postPreprocessingFiles, deletePreprocessingFiles } from "../../utils/api";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
export function ClientSpecificHeaderMapping({ devOnly }) {
  const location = useLocation();
  const dispatch = useDispatch();
  const selectAPIisLoading = useSelector(
    (state) => state.application.fetching.preprocessingTransformLoadingAPI,
  );
  const { data: clients, error: clientsError } = useSWR("/lms/api/v1/client/client", getClients);
  const { clientId } = location.state || "";
  const [clientMapping, setClientMapping] = useState({});
  const [preprocessedData, setPreprocessedData] = useState([]);
  const { data: preprocessList, error: preprocessListError } = useSWR(
    `/lms/api/v1/transform/preprocessing-files-list/${clientMapping.id}`,
    async () => {
      dispatch(fetching({ element: "preprocessingTransformLoadingAPI" }));
      const result = await getPreprocessListById(clientMapping.id);
      dispatch(fetchingDone({ element: "preprocessingTransformLoadingAPI" }));
      return result.data;
    },
    { revalidateOnFocus: false },
  );
  const memoizedLoadingOverlay = useMemo(() => <TableLoadingOverlay />, []);
  const [fileType, setFileType] = useState("");
  const [fileTypeOptions, setFileTypeOptions] = useState([]);
  const [studyType, setStudyType] = useState("MF");
  const [studyOptions, setStudyOptions] = useState([]);
  const [studyToFileTypeMapping] = useState({});
  const tempArray = [];
  const [isOpen, setIsOpen] = useState({ status: false, message: "", severity: "" });
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setIsOpen((prev) => ({ ...prev, status: false, message: "", severity: "" }));
  };

  const getDistinctFileTypeMappings = (mappingArray) => (mappingArray.reduce((acc, dict) => {
    if (!acc.some(item => JSON.stringify(item) === JSON.stringify(dict))) {
      acc.push(dict);
    }
    return acc;
  }, []));

  /* eslint-disable*/
  useEffect(() => {
    setPreprocessedData([]);
    if (preprocessList && preprocessList.length) {
      preprocessList.map((row) =>
        setPreprocessedData(prev => ([...prev, { study: row.study_file_type.study, file_type: row.study_file_type.file_type, preprocessed_id: row.preprocessing_files.id }])));
    }
  }, [preprocessList]);


  if (clientsError) return <div>Error loading...</div>;
  if (preprocessListError) return <div>Error loading preprocessedData for {clientMapping.name}...</div>;

  useEffect(() => {
    API.request({ url: "lms/api/v1/transform/study_file_type", method: "GET" }).then(
      response => {
        const study_file_type_mapping = response.data;
        study_file_type_mapping?.map(row => {
          if (row.study in studyToFileTypeMapping) {
            studyToFileTypeMapping[row.study].push(row);
          }
          else {
            studyToFileTypeMapping[row.study] = [];
            studyToFileTypeMapping[row.study].push(row);
          }
        });
        const studyOptionsVar = [];
        Object.keys(studyToFileTypeMapping)?.forEach((element) => {
          studyOptionsVar.push({ id: element, label: element })
        });
        setStudyOptions(studyOptionsVar);
        studyToFileTypeMapping[studyType]?.map((row) => { tempArray.push({ "label": row.file_type, "id": row.id }) });
        setFileTypeOptions(getDistinctFileTypeMappings(tempArray));
      });
  }, []);

  useEffect(() => {
    studyToFileTypeMapping[studyType]?.map((row) => { tempArray.push({ "label": row.file_type, "id": row.id }) });
    setFileType({});
    setFileTypeOptions(getDistinctFileTypeMappings(tempArray));
  }, [studyType]
  );

  const columns = [
    {
      title: "Study", field: "study", editComponent: () =>
        <OptionSelect value={studyType} onChange={setStudyType} options={studyOptions} />
    },
    {
      title: "File Type", field: "file_type", editComponent: () =>
        <OptionSelect value={fileType?.fileTypeId} onChange={(val) => {
          const temp = fileTypeOptions.find((element) => {
            return val === element.id
          }).label
          setFileType({
            fileTypeId: val, fileTypeName: temp
          })
        }} options={fileTypeOptions} />
    },
    {
      title: "Link to Client Header Mapping",
      render: rowData => (
        <Button
          variant="outlined"
          component={Link}
          to={`/preprocessing-transform/client-specific-transform/client/${clientMapping.name}/studyFileType/${rowData.study}:${rowData.file_type}/id/${rowData.preprocessed_id}`}
          style={{ minWidth: 400 }}
        >
          {`${rowData.study}:${rowData.file_type}`}
        </Button>
      ),
    },
  ];

  return (
    <div>
      <Card>
        <CardContent>
          {clients
            ? (
              <Autocomplete
                disablePortal
                disableClearable
                id="combo-box-demo"
                onChange={(e, value) => {
                  setClientMapping(prevState => ({
                    ...prevState,
                    id: clients.find((clientOption) => clientOption.mnemonic.toUpperCase() === value)?.id,
                    name: value,
                  }
                  ))
                }}
                options={(clients && clients.map((clientOption) => clientOption.mnemonic.toUpperCase())) || []}
                value={clients.find((clientOption) => clientOption.id === clientId)?.mnemonic}
                style={{ width: 300, paddingRight: 20 }}
                renderInput={(params) => <TextField {...params} label="Client Select" />}
              />
            )
            : <TableLoadingOverlay />}
        </CardContent>
      </Card>


      {clientMapping.name && (<Card style={{ marginTop: "1%" }}>
        <MaterialTable
          title={`Preprocessing Files - ${clientMapping.name}`}
          isLoading={selectAPIisLoading}
          data={preprocessedData}
          columns={columns}
          options={{
            headerStyle: {
              backgroundColor: "#30455c",
            },
          }}
          components={{
            OverlayLoading: () => memoizedLoadingOverlay,
          }}
          editable={{
            onRowAdd: async () => {
              const rowToAdd = {
                study_file_type: fileType.fileTypeId,
                client_id: clientMapping.id,
              };
              if (preprocessedData.filter(row => ((row.file_type === fileType.fileTypeName) && (studyType === row.study))).length > 0) {
                setIsOpen((prev) => ({
                  ...prev, status: true, message: "Preprocessing File Mapping already added !", severity: "error",
                }));
              } else {
                if (rowToAdd.study_file_type === undefined) {
                  setIsOpen((prev) => ({
                    ...prev, status: true, message: "Select all input fileds for adding a new record !", severity: "warning",
                  }));
                } else {
                  const response = await postPreprocessingFiles(rowToAdd);
                  setIsOpen((prev) => ({
                    ...prev, status: true, message: "Preprocessing File Mapping added !", severity: "info",
                  }));
                  setTimeout(() => setPreprocessedData([...preprocessedData, { study: studyType, preprocessed_id: response.id, file_type: fileType.fileTypeName }]), 0)
                };
              }
            },
            onRowDelete: async (oldData) => {
              if (devOnly) {
                await deletePreprocessingFiles(oldData.preprocessed_id);
                setPreprocessedData([...preprocessedData.filter(row => row.preprocessed_id !== oldData.preprocessed_id)]);
                setIsOpen((prev) => ({
                  ...prev, status: true, message: "Preprocessing File Mapping deleted successfully !", severity: "error",
                }));
              }
              else {
                setIsOpen((prev) => ({
                  ...prev, status: true, message: "Deletion is dev only feature", severity: "info",
                }));
              }
            },
          }
          }
        />

      </Card>
      )
      }
      {isOpen.status && (
        <Snackbar
          open={isOpen}
          onClose={() => setIsOpen((prev) => ({ ...prev, status: false }))}
          autoHideDuration={4000}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        >
          <Alert onClose={handleClose} severity={isOpen.severity}>
            {isOpen.message}
          </Alert>
        </Snackbar>
      )}
    </div>
  );
}