import React, { useEffect, useState } from "react";
import { FormHelperText, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { useDispatch, useSelector } from "react-redux";

import { fetching, fetchingDone } from "../../state";
import { API } from "../../utils/api";
import OptionSelect from "./OptionSelect";

export default function APISelect({
  value,
  onChange,
  inputValueClient,
  setInputValueClient,
  label,
  url,
  required,
  showError,
  multiple,
  valueKey = "id",
  optionKey = "value",
  errorText = "Required",
  filter = false,
  filterValue = null,
  sort = false,
  sortValue = "value",
  forClients = false,
  additionalOptions = [],
}) {
  const dispatch = useDispatch();
  const selectAPIisLoading = useSelector(
    (state) => state.application.fetching.selectAPI,
  );
  const [fullData, setFullData] = useState([]);
  const [options, setOptions] = useState([]);

  const error = Boolean(!value && showError);

  useEffect(() => {
    dispatch(fetching({ element: "selectAPI" }));
    API.request({ url, method: "GET" }).then(
      (response) => {
        const { data } = response;
        // Sort Alphabetically
        if (sort) {
          data.sort((a, b) => (a[sortValue] > b[sortValue] ? 1 : -1));
        }
        // Setting full base data
        setFullData(data);
        dispatch(fetchingDone({ element: "selectAPI" }));
      },
      (e) => {
        console.error(e);
        dispatch(fetchingDone({ element: "selectAPI" }));
        return null;
      },
    );
  }, [url, sort, sortValue, dispatch]);

  useEffect(() => {
    const filteredData = filter
      ? fullData.filter((d) => d[filter] === filterValue)
      : fullData;
    setOptions(filteredData);
    // Rerendering this based on full dependencies array causes render-loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fullData, filter, filterValue]);

  const handleChange = (e, val) => {
    if (val === null) {
      onChange("");
      setInputValueClient("");
    } else {
      onChange(val.id);
      setInputValueClient(val.id);
    }
  };
  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {forClients
        ? (
          <Autocomplete
            clearOnBlur={false}
            getOptionSelected={(option, val) => option.id === val.id}
            inputValue={inputValueClient}
            onInputChange={(event, newInputValue) => {
              if (event?.type === "change") {
                setInputValueClient(newInputValue);
              }
            }}
            onChange={handleChange}
            options={options.length > 0 ? [...additionalOptions, ...options] : [{ id: "" }]}
            renderInput={(params) => (
              <TextField
                {...params}
                label={options.length > 0 ? "Client" : "Loading Options..."}
                variant="outlined"
                margin="normal"
              />
            )}
            getOptionLabel={(option) => {
              if (option.id !== undefined) return option.id;
              return "";
            }}
            value={value !== null ? value : ""}
          >
            {selectAPIisLoading && (
              <FormHelperText>Loading Options...</FormHelperText>
            )}
            {error && <FormHelperText>{errorText}</FormHelperText>}
          </Autocomplete>
        )
        : (
          <OptionSelect
            style={{ width: "100%" }}
            error={error}
            disabled={selectAPIisLoading}
            label={label}
            required={required}
            onChange={onChange}
            inputProps={{
              "data-testid": "api-select-id",
            }}
            options={options}
            value={value && options.length ? value : ""}
            valueKey={valueKey}
            optionKey={optionKey}
            multiple={multiple}
          >
            {selectAPIisLoading && (
              <FormHelperText>Loading Options...</FormHelperText>
            )}
            {error && <FormHelperText>{errorText}</FormHelperText>}
          </OptionSelect>
        )}
    </>
  );
}
