import React from "react";

import { fade } from "@material-ui/core";

import CircularProgress from "./Spinner";

export default function TableLoadingOverlay() {
  return (
    <div
      style={{
        display: "table",
        width: "100%",
        height: "100%",
        backgroundColor: fade("#fff", 0.7),
      }}
    >
      <div
        style={{
          display: "table-cell",
          width: "100%",
          height: "100%",
          verticalAlign: "middle",
          textAlign: "center",
        }}
      >
        <CircularProgress style={{ width: "100px", height: "100px" }} />
      </div>
    </div>
  );
}
