import React, { useEffect } from "react";
import {
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import Form from "@rjsf/core";
import { useSelector } from "react-redux";
import CSVUpload from "../../components/input/CSVUpload";

export default function TagSalesForm({ children, taskConfig, setTaskConfig, csv, setCsv, submitHandler, studyType, displayHeader }) {
  const endQuarters = useSelector((state) => state.formOptions.productionCycle);

  useEffect(() => {
    setTaskConfig((prevTaskConfig) => ({ ...prevTaskConfig,
      study: studyType,
      reverse: taskConfig.reverse ?? false }));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setTaskConfig, studyType]);

  return (
    <Form
      schema={{}}
      onSubmit={submitHandler}
    >
      <div style={{ display: "flex", alignItems: "center" }}>
        <Typography variant="subtitle2" style={{ marginRight: "8px", marginBottom: "16px" }}>Task Name :</Typography>
        <Chip style={{ marginBottom: "16px" }} variant="outlined" label={displayHeader(["reverse"])} />
      </div>
      <CSVUpload
        required
        onChange={(update) => {
          setCsv({ attachment: update.csv });
        }}
        value={{ csv, ...taskConfig }}
      />
      <FormControl component="fieldset" fullWidth margin="normal">
        <FormControlLabel
          value={taskConfig.reverse || false}
          control={<Checkbox checked={taskConfig.reverse} />}
          label="Remove the tags instead"
          onChange={(e) => setTaskConfig((prevTaskConfig) => ({ ...prevTaskConfig, reverse: e.target.checked }))}
        />
      </FormControl>
      <FormControl fullWidth variant="outlined">
        <InputLabel htmlFor="inputLabel-production-cycle-select">End Quarter</InputLabel>
        <Select
          style={{ width: 500 }}
          value={taskConfig.end_quarter || ""}
          onChange={(e) => {
            setTaskConfig((prevTaskConfig) => ({ ...prevTaskConfig, end_quarter: e.target.value }));
          }}
          label="End Quarter"
          inputProps={{
            name: "production cycle",
            id: "inputLabel-production-cycle-select",
          }}
        >
          {endQuarters.map((quarter) => (
            <MenuItem key={quarter} value={quarter}>
              {quarter}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText style={{ marginBottom: 30 }}>Leave blank if file contains transaction dates</FormHelperText>
      </FormControl>
      {children}
    </Form>
  );
}
