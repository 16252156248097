import React from "react";
import {
  FormControl,
  Grid,
  makeStyles,
  TextField,
  Button, Card, CardActions, CardContent,
} from "@material-ui/core";

import BulkUploadButton from "../../components/buttons/BulkUploadButton";
import ExportButton from "../../components/buttons/ExportButton";
import OptionSelect from "../../components/input/OptionSelect";
import { channels } from "../../utils/SharedDefinitions";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginBottom: theme.spacing(1),
  },
  column: {
    flexBasis: "50%",
    margin: theme.spacing(1),
  },
  action: {
    justifyContent: "left",
  },
}));

export default function BranchChannelFilters({ filters, setFilters, getData, handleResetFilters }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Card sx={{ minWidth: 275 }}>
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={4}>
              <TextField
                fullWidth
                label="DD Branch ID"
                name="dd_physical_branch_id"
                onChange={(event) => {
                  setFilters((prevFilters) => ({
                    ...prevFilters,
                    dd_physical_branch_id: event.target.value,
                  }));
                }}
                value={filters?.dd_physical_branch_id}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth margin="none">
                <OptionSelect
                  style={{ marginTop: 0 }}
                  value={filters?.channel || ""}
                  onChange={(channel) => {
                    setFilters((prevFilters) => ({
                      ...prevFilters,
                      channel,
                    }));
                  }}
                  options={channels}
                  label="Channel"
                />
              </FormControl>
            </Grid>
          </Grid>
        </CardContent>
        <CardActions>
          <Button variant="outlined" color="secondary" onClick={getData}>Apply Filter</Button>
          <Button
            variant="outlined"
            color="primary"
            onClick={handleResetFilters}
          >
            Reset Filter
          </Button>
          <BulkUploadButton
            tableName="DD_BRANCH_ID_CHANNEL"
            studyType="ann"
          />
          <ExportButton
            tableName="dd_branch_id_channel"
            tableSet="ANNTableSet"
            studyType="ann"
          />
        </CardActions>
      </Card>
    </div>
  );
}
