import React, { useEffect } from "react";
import { FormHelperText, FormControl } from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Form from "@rjsf/material-ui";
import { useSelector } from "react-redux";
import OptionSelect from "../../components/input/OptionSelect";

const salesVolumetableNames = [
  { id: "sales_volume_by_firm_office", label: "SALES_VOLUME_BY_FIRM_OFFICE" },
  { id: "sales_volume_by_si_office", label: "SALES_VOLUME_BY_SI_OFFICE" },
];

export default function CreateSalesVolumeTablesForm({ children, taskConfig, setTaskConfig, submitHandler, studyType, setIsRequired }) {
  const quarters = useSelector((state) => state.formOptions.quarters);
  const locksMF = useSelector((state) => state.formOptions.locksMF);

  useEffect(() => {
    setTaskConfig((preConfig) => ({ ...preConfig, study: studyType }));
    setIsRequired((prevState) => ([...prevState, "sales_volume_table", "end_quarter"]));
  }, [setTaskConfig, studyType, setIsRequired]);
  return (
    <Form
      schema={{}}
      onSubmit={submitHandler}
    >
      <FormControl fullWidth variant="outlined" margin="normal">
        <OptionSelect
          required
          style={{ width: 500, marginBottom: 0 }}
          value={taskConfig.sales_volume_table || ""}
          onChange={(tableName) => {
            setTaskConfig((prevTaskConfig) => ({ ...prevTaskConfig, sales_volume_table: tableName }));
          }}
          label="Sales Volume Table to Create"
          options={salesVolumetableNames}
        />
      </FormControl>
      <FormControl fullWidth variant="outlined" margin="normal">
        <InputLabel required htmlFor="inputLabel-end-quarter-select">Ending quarter of the rolling 12-quarter period</InputLabel>
        <Select
          required
          style={{ width: 500, marginBottom: 0 }}
          value={taskConfig.end_quarter || ""}
          onChange={(e) => {
            setTaskConfig((prevTaskConfig) => ({ ...prevTaskConfig, end_quarter: e.target.value }));
          }}
          label="Ending quarter of the rolling 12-quarter period"
          inputProps={{
            name: "Ending quarter of the rolling 12-quarter period",
            id: "inputLabel-end-quarter-select",
          }}
        >
          {quarters.map((quarter) => (
            <MenuItem key={quarter} value={quarter}>
              {quarter}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl fullWidth variant="outlined" margin="normal">
        <InputLabel htmlFor="inputLabel-lock-select">Lock(Data Set)</InputLabel>
        <Select
          style={{ width: 500, marginBottom: 0 }}
          value={taskConfig.lock || ""}
          onChange={(e) => {
            setTaskConfig((prevTaskConfig) => ({ ...prevTaskConfig, lock: e.target.value }));
          }}
          label="Lock(Data Set)"
          inputProps={{
            name: "Lock(Data Set)",
            id: "inputLabel-lock-select",
          }}
        >
          {locksMF.map((quarter) => (
            <MenuItem key={quarter} value={quarter}>
              {quarter}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText>Leave unselected for Live</FormHelperText>
      </FormControl>
      {children}
    </Form>
  );
}
