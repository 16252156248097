import React from "react";
import { Button, Tooltip, Zoom } from "@material-ui/core";

export default function WizardButtons({
  step,
  hasNext,
  hasPrev = true,
  onSave,
  onChange,
  children,
  validated = true,
  isWorkflowTask,
}) {
  const handleClick = () => {
    window.location.reload();
    // TODO: Figure out better way to handle this
    // If we go to Previous page by setting "step -1" it carries the config of selected
    // form to the next form if both forms has common field.
  };
  return (
    <Tooltip TransitionComponent={Zoom} placement="bottom-start" title={validated ? "" : "Required field missing"}>
      <div>
        {!isWorkflowTask && step !== 1 && hasPrev && <Button onClick={() => handleClick()}>Previous</Button>}
        <Button
          onClick={() => {
            if (hasNext) onChange(step + 1);
            else onSave();
          }}
          disabled={!validated}
        >
          {hasNext ? "Next" : "Save"}
        </Button>
        {children}
      </div>
    </Tooltip>
  );
}
