import React from "react";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";

export default function WorkflowNameColumn({ rowData }) {
  return (
    <Button
      variant="outlined"
      component={Link}
      to={rowData.status === "CREATED" || rowData.status === "DRAFT" ? `/workflows/edit/${rowData.workflowName}` : `/workflows/details/${rowData.workflowName}`}
      style={{ width: 400, textAlign: "center" }}
    >
      <span style={{ maxWidth: "400px", wordWrap: "break-word", textAlign: "center", padding: "5px 15px" }}>
        {rowData?.workflowName}
      </span>
    </Button>
  );
}
