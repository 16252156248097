import React, { useRef, useState } from "react";
import { Button, Snackbar, Grid, Typography } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { API } from "../../../utils/api";
import { reportMappings } from "../constants";
import Table from "./Table";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
function SalesVettingTable({ id, label, currentView, columnHeader, inputData, formattingOptions }) {
  const [isEditable, setIsEditable] = useState(false);
  const [inputDataCopy, setInputDataCopy] = useState(inputData ? JSON.parse(JSON.stringify(inputData)) : {});
  const [updatedNotes, setUpdatedNotes] = useState({});
  const [notification, setNotification] = useState({ info: false, success: false, error: false, noData: false });
  const gridRef = useRef();
  const handleCancel = () => {
    gridRef.current?.api?.stopEditing(true);
    const oldCopyInputData = inputDataCopy.data;
    oldCopyInputData.forEach(row => {
      if (updatedNotes[row.id]) {
        // eslint-disable-next-line no-param-reassign
        row.notes = updatedNotes[row.id].oldNote;
      }
    });
    setIsEditable(!isEditable);
    setInputDataCopy((prev) => ({ ...prev, data: oldCopyInputData }));
    setUpdatedNotes({});
    gridRef.current?.api?.refreshCells();
  };
  const handleCellEdit = (event) => {
    const updatedValue = {};
    updatedValue.dataId = event.data?.id;
    updatedValue.oldNote = event.oldValue;
    updatedValue.newNote = event.newValue;
    setUpdatedNotes((prev) => ({ ...prev, [updatedValue.dataId]: { oldNote: updatedValue.oldNote, newNote: updatedValue.newNote } }));
  };
  const saveData = () => {
    setIsEditable(!isEditable);
    gridRef.current.api.stopEditing();
    setNotification({ info: true, success: false, error: false, noData: false });
    const dataToBeUpdated = Object.keys(updatedNotes).map(dataId => ({ id: dataId, notes: updatedNotes[dataId].newNote }));
    const urlOfCurrentView = reportMappings.reporting_ann_lms_qa_dashboard.filter((report) => report.id === currentView)?.[0]?.items[0]?.dataURL;
    const url = `${urlOfCurrentView}/ann_${columnHeader}`;
    if (dataToBeUpdated.length > 0) {
      API.request({ url, method: "PUT", data: { payload: dataToBeUpdated } }).then(
        () => {
          setNotification({ success: true, info: false, error: false, noData: false });
          setUpdatedNotes({});
        },
        () => {
          setNotification({ error: true, info: false, success: false, noData: false });
        },
      );
    } else {
      setNotification({ info: false, success: false, error: false, noData: true });
    }
  };
  return (

    <>
      <Grid container alignItems="flex-end" style={{ display: "flex" }}>
        <Grid style={{ paddingLeft: "20%" }} item xs={9}>
          <Typography id={id} variant="h3" data-section style={{ textAlign: "center", fontSize: "22px", fontWeight: "500", fontFamily: "IBM Plex Sans,sans-serif", color: "#2f2b38", margin: "0.5em  " }}>
            {label}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <div>
            {isEditable
              ? (
                <div className="cover" style={{ display: "flex", float: "right" }}>
                  <Button style={{ color: "red" }} onClick={() => handleCancel()}>Cancel</Button>
                  <Button color="secondary" className="submission" onClick={() => saveData()}>Save</Button>
                </div>
              )
              : (
                <div style={{ float: "right" }}>
                  <Button disabled={!inputData?.data?.length} className="editBtn" color="primary" onClick={() => setIsEditable(!isEditable)}>Edit</Button>
                </div>
              )}
          </div>
        </Grid>
      </Grid>
      <Table isEditable={isEditable} inputData={inputDataCopy} formattingOptions={formattingOptions} gridRef={gridRef} cellEditCallback={handleCellEdit} />
      {
        notification.error && (
          <Snackbar
            open={notification.error}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            autoHideDuration={3000}
            onClose={() => {
              setNotification({ success: false, deleted: false, info: false, noData: false });
            }}
          >
            <Alert severity="error">
              Error while saving the Comments
            </Alert>
          </Snackbar>
        )
      }
      {
        notification.success && (
          <Snackbar
            open={notification.success}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            autoHideDuration={3000}
            onClose={() => {
              setNotification({ success: false, deleted: false, info: false, noData: false });
            }}
          >
            <Alert severity="success">Comments Updated Successfully</Alert>
          </Snackbar>
        )
      }
      {
        notification.info && (
          <Snackbar
            open={notification.success}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            autoHideDuration={3000}
            onClose={() => {
              setNotification({ success: false, deleted: false, info: false, noData: false });
            }}
          >
            <Alert severity="info">Submitting</Alert>
          </Snackbar>
        )
      }
      {
        notification.noData && (
          <Snackbar
            open={notification.noData}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            autoHideDuration={3000}
            onClose={() => {
              setNotification({ success: false, deleted: false, info: false, noData: false });
            }}
          >
            <Alert severity="error">Comments not updated not saving</Alert>
          </Snackbar>
        )
      }
    </>
  );
}

export default SalesVettingTable;
