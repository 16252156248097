import { Auth } from "aws-amplify";

export const authConfig = {
  region: process.env.REACT_APP_AWS_REGION,
  userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
  userPoolWebClientId: process.env.REACT_APP_COGNITO_WEB_CLIENT_ID,
  mandatorySignIn: true,
  authenticationFlowType: process.env.REACT_APP_COGNITO_AUTHENTICATION_FLOW_TYPE,
  oauth: {
    identityProvider: process.env.REACT_APP_COGNITO_OAUTH_IDENTITY_PROVIDER,
    domain: process.env.REACT_APP_COGNITO_OAUTH_DOMAIN,
    scope: ["openid", "profile", "email"],
    redirectSignIn: process.env.REACT_APP_COGNITO_OAUTH_REDIRECT_SIGNIN,
    redirectSignOut: process.env.REACT_APP_COGNITO_OAUTH_REDIRECT_SIGNOUT,
    responseType: "code",
  },
};

export const oktaSignInUrl = `https://${authConfig.oauth.domain}/oauth2/authorize`
  + `?response_type=${authConfig.oauth.responseType}`
  + `&identity_provider=${authConfig.oauth.identityProvider}`
  + `&client_id=${authConfig.userPoolWebClientId}`
  + `&redirect_uri=${authConfig.oauth.redirectSignIn}`
  + "&scope=email+openid+profile";

export async function getCurrentSession() {
  return Auth.currentSession();
}
