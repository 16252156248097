import React, { useEffect, useState } from "react";
import {
  Card,
  CardActions,
  CardContent,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Typography,
} from "@material-ui/core";
import moment from "moment-timezone";
import { nanoid } from "nanoid";
import { useParams } from "react-router-dom";
import usePageTitle from "../../hooks/usePageTitle";
import useTerrClientDescriptions from "../../hooks/useTerrClientDescriptions";
import { API } from "../../utils/api";
import TaskClone from "./status/TaskClone";
import TaskDownloads from "./status/TaskDownloads";
import TaskLogs from "./status/TaskLogs";
import TaskPayload from "./status/TaskPayload";

const tz = moment().tz(moment.tz.guess()).format("z");

const useStyles = makeStyles((theme) => ({
  cloneButton: {
    width: "fit-content",
    margin: "1.5em",
    backgroundColor: theme.palette.secondary.main,
  },
  root: {
    minWidth: 275,
    margin: "1.5em",
  },
  title: {
    fontSize: 14,
  },
}));

export default function TaskDetails() {
  const classes = useStyles();

  const [taskData, setTaskData] = useState(null);
  const [taskAttachments, setTaskAttachments] = useState([]);
  const [terrClientDescriptions, setTerrClientDescriptions] = useState({});
  const [errorMessage, setErrorMessage] = useState(null);

  usePageTitle("Task Details");

  useTerrClientDescriptions(setTerrClientDescriptions);

  const { taskIdParam } = useParams();
  const { workflowIdParam } = useParams();

  useEffect(() => {
    const url = workflowIdParam ? `/workflow-task/${workflowIdParam}/${taskIdParam}` : `/tasks/${taskIdParam}`;
    API.request({ url, method: "GET" }).then(
      (response) => {
        setTaskData(response.data);
        setTaskAttachments(response.data.attachments);
      },
      (error) => {
        console.error(error);
        setErrorMessage(error.response.data);
      },
    );
  }, [taskIdParam, workflowIdParam]);

  if (taskData) {
    return (
      <Grid container>
        <Grid item xs={12}>
          {/* CLONE TASK */}
          { !workflowIdParam && (
            <Card className={classes.cloneButton}>
              <TaskClone taskId={taskIdParam} taskData={taskData.task} />
            </Card>
          )}
          {/* ATTACHMENTS */}
          <Card className={classes.root}>
            <CardContent>
              <Typography
                className={classes.title}
                color="textSecondary"
                gutterBottom
              >
                Attachments
              </Typography>
            </CardContent>
            <Divider />
            <CardActions>
              <TaskDownloads
                taskId={taskIdParam}
                attachments={taskAttachments}
                display="grid"
              />
            </CardActions>
          </Card>
          {/* LOGS */}
          <TaskLogs classes={classes} taskId={taskIdParam} workflowId={workflowIdParam} />
        </Grid>
        <Grid item xs={12} md={6}>
          {/* SUMMARY */}
          <Card className={classes.root}>
            <CardContent>
              <Typography
                className={classes.title}
                color="textSecondary"
                gutterBottom
              >
                Summary
              </Typography>
            </CardContent>
            <List>
              { workflowIdParam && (
                <>
                  <Divider component="li" />
                  <ListItem key="workflowname">
                    <ListItemText primary="Workflow Name" secondary={taskData.task.workflowName} />
                  </ListItem>
                  <Divider component="li" />
                  <ListItem key="workflowid">
                    <ListItemText primary="Workflow ID" secondary={taskData.task.workflowId} />
                  </ListItem>
                </>
              )}
              <Divider component="li" />
              <ListItem key="title">
                <ListItemText primary="Title" secondary={taskData.task.title} />
              </ListItem>
              <Divider component="li" />
              <ListItem key="taskid">
                <ListItemText primary="TaskId" secondary={taskData.task.taskId} />
              </ListItem>
              <Divider component="li" />
              <ListItem key="status">
                <ListItemText primary="Status" secondary={taskData.task.status} />
              </ListItem>
              <Divider component="li" />
              <ListItem key="created">
                <ListItemText
                  primary="Created"
                  secondary={`${moment(moment.parseZone(taskData?.task?.createdDate).local()).format("MMMM Do YYYY, h:mm:ss:SSSS a")} ${tz}`}
                />
              </ListItem>
              <Divider component="li" />
              <ListItem key="username">
                <ListItemText
                  primary="Username"
                  secondary={taskData.task.username}
                />
              </ListItem>
              <Divider component="li" />
              <ListItem key="tasktype">
                <ListItemText
                  primary="TaskType"
                  secondary={taskData.task.taskType}
                />
              </ListItem>
              <Divider component="li" />
              <ListItem key="client">
                <ListItemText primary="Client" secondary={taskData.task.client} />
              </ListItem>
              {taskData.task.client in terrClientDescriptions && (
                <>
                  <Divider component="li" />
                  <ListItem key="client_description">
                    <ListItemText primary="Client Description" secondary={terrClientDescriptions[taskData.task.client]} />
                  </ListItem>
                </>
              )}
            </List>
          </Card>
          {/* CONFIG */}
          <Card className={classes.root}>
            <CardContent>
              <Typography
                className={classes.title}
                color="textSecondary"
                gutterBottom
              >
                Config
              </Typography>
            </CardContent>
            <TaskPayload taskId={taskIdParam} />
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          {/* HISTORY */}
          <Card className={classes.root}>
            <CardContent>
              <Typography
                className={classes.title}
                color="textSecondary"
                gutterBottom
              >
                Status History
              </Typography>
            </CardContent>
            <List>
              {taskData.task.statusHistory.map((statusHistory) => (
                <div key={statusHistory.date}>
                  <Divider component="li" />
                  <ListItem key={nanoid()}>
                    <ListItemText
                      primary={statusHistory.status}
                      secondary={`${moment(moment.parseZone(statusHistory?.date).local()).format("MMMM Do YYYY, h:mm:ss:SSSS a")} ${tz}`}
                    />
                  </ListItem>
                </div>
              ))}
            </List>
          </Card>
        </Grid>
      </Grid>
    );
  }

  return (
    <Card>
      <CardContent>
        <Typography
          className={classes.title}
          color="textSecondary"
          gutterBottom
        >
          { errorMessage || "Loading..."}
        </Typography>
      </CardContent>
    </Card>
  );
}
