import { API } from "../utils/api";
import { processFlattenedData } from "../utils/dataTransformations";
import { getFromStorage, saveToStorage } from "../utils/localStorage";
import {
  fetching,
  fetchingDone,
  fetchingBackground,
  fetchingBackgroundDone,
  fetchingLMS,
  fetchingLMSDone,
  setMisightUsers,
  setOptions,
} from "./index";

export function getOptionsData() {
  return (dispatch) => {
    // --- QUARTERS ---
    dispatch(fetching("quarters"));
    API.request({ url: "/quarters", method: "GET" }).then((response) => {
      const QuartersArr = response.data.map(d => d.value);
      const QuartersArrPlusEmpty = [""].concat(QuartersArr);
      dispatch(setOptions({ option: "quarters", value: QuartersArrPlusEmpty }));
      dispatch(setOptions({ option: "productionCycle", value: QuartersArrPlusEmpty }));
      dispatch(fetchingDone("quarters"));
    });

    // --- LOCKS ---
    dispatch(fetching("locksMF"));
    API.request({ url: "/locks/mf", method: "GET" }).then((response) => {
      const LocksArr = response.data.map(d => d.value);
      const LocksPlusLive = [""].concat(LocksArr);
      dispatch(setOptions({ option: "locksMF", value: LocksPlusLive }));
      dispatch(fetchingDone("locksMF"));
    });

    dispatch(fetching("locksANN"));
    API.request({ url: "/locks/ann", method: "GET" }).then((response) => {
      const LocksArr = response.data.map(d => d.value);
      const LocksPlusLive = [""].concat(LocksArr);
      dispatch(setOptions({ option: "locksANN", value: LocksPlusLive }));
      dispatch(fetchingDone("locksANN"));
    });

    dispatch(fetching("locksSMA"));
    API.request({ url: "/locks/sma", method: "GET" }).then((response) => {
      const LocksArr = response.data.map(d => d.value);
      const LocksPlusLive = [""].concat(LocksArr);
      dispatch(setOptions({ option: "locksSMA", value: LocksPlusLive }));
      dispatch(fetchingDone("locksSMA"));
    });

    // --- TERRITORIES ---
    dispatch(fetching({ element: "territoriesVA" }));
    API.request({ url: "/territories/va", method: "GET" }).then((response) => {
      const territoriesArr = response.data.map((d) => d.value);
      const territoriesPlusEmpty = [""].concat(territoriesArr);
      dispatch(setOptions({ option: "territoriesVA", value: territoriesPlusEmpty }));
      dispatch(fetchingDone({ element: "territoriesVA" }));
    });

    dispatch(fetching("territoriesFIA"));
    API.request({ url: "/territories/fia", method: "GET" }).then((response) => {
      const territoriesArr = response.data.map((d) => d.value);
      const territoriesPlusEmpty = [""].concat(territoriesArr);
      dispatch(setOptions({ option: "territoriesFIA", value: territoriesPlusEmpty }));
      dispatch(fetchingDone("territoriesFIA"));
    });

    dispatch(fetching({ element: "ddTerritoriesVA" }));
    API.request({ url: "/dd-territories/va", method: "GET" }).then((response) => {
      const territoriesArr = response.data.map((d) => d.value);
      const territoriesPlusEmpty = [""].concat(territoriesArr);
      dispatch(setOptions({ option: "ddTerritoriesVA", value: territoriesPlusEmpty }));
      dispatch(fetchingDone({ element: "ddTerritoriesVA" }));
    });

    dispatch(fetching("ddTerritoriesFIA"));
    API.request({ url: "/dd-territories/fia", method: "GET" }).then((response) => {
      const territoriesArr = response.data.map((d) => d.value);
      const territoriesPlusEmpty = [""].concat(territoriesArr);
      dispatch(setOptions({ option: "ddTerritoriesFIA", value: territoriesPlusEmpty }));
      dispatch(fetchingDone("ddTerritoriesFIA"));
    });

    dispatch(fetching("territoryGroupsANN"));
    API.request({ url: "/territory-groups/ann", method: "GET" }).then((response) => {
      const terrGroupsArr = response.data.map((d) => d.value);
      const terrGroupsPlusEmpty = [""].concat(terrGroupsArr);
      const terrAltLabels = ["", "TERRITORY_ID"].concat(terrGroupsArr);
      dispatch(setOptions({ option: "territoryGroupsANN", value: terrGroupsPlusEmpty }));
      dispatch(setOptions({ option: "terrAltLabelsANN", value: terrAltLabels }));
      dispatch(fetchingDone("territoryGroupsANN"));
    });

    dispatch(fetching({ element: "channelsANN" }));
    API.request({ url: "/channels/ann", method: "GET" }).then((response) => {
      const channelsArr = response.data.map((d) => d.value);
      const channelsPlusEmpty = [""].concat(channelsArr);
      dispatch(setOptions({ option: "channelsANN", value: channelsPlusEmpty }));
      dispatch(fetchingDone({ element: "channelsANN" }));
    });

    dispatch(fetching({ element: "ddChannelsANN" }));
    API.request({ url: "/dd-channels/ann", method: "GET" }).then((response) => {
      const channelsArr = response.data.map((d) => d.value);
      const channelsPlusEmpty = [""].concat(channelsArr);
      dispatch(setOptions({ option: "ddChannelsANN", value: channelsPlusEmpty }));
      dispatch(fetchingDone({ element: "ddChannelsANN" }));
    });

    dispatch(fetching("OTTTerrGroupsANN"));
    API.request({ url: "/territory-groups/ann", method: "GET" }).then((response) => {
      const terrGroupsArr = response.data.map((d) => d.value);
      const terrGroupsPlusEmpty = [""].concat(terrGroupsArr);
      dispatch(setOptions({ option: "OTTTerrGroupsANN", value: terrGroupsPlusEmpty }));
      dispatch(fetchingDone("OTTTerrGroupsANN"));
    });

    dispatch(fetching("territoriesSMA"));
    API.request({ url: "/territories/sma", method: "GET" }).then((response) => {
      const territoriesArr = response.data.map((d) => d.value);
      const territoriesPlusEmpty = ["", ...territoriesArr];
      dispatch(setOptions({ option: "territoriesSMA", value: territoriesPlusEmpty }));
      dispatch(fetchingDone("territoriesSMA"));
    });

    dispatch(fetching("territoriesMF"));
    API.request({ url: "/territories/mf", method: "GET" }).then((response) => {
      const territoriesArr = response.data.map((d) => d.value);
      const territoriesPlusEmpty = ["", ...territoriesArr];
      dispatch(setOptions({ option: "territoriesMF", value: territoriesPlusEmpty }));
      dispatch(fetchingDone("territoriesMF"));
    });

    dispatch(fetching("ddTerritoriesSMA"));
    API.request({ url: "/dd-territories/sma", method: "GET" }).then((response) => {
      const territoriesArr = response.data.map((d) => d.value);
      const territoriesPlusEmpty = ["", ...territoriesArr];
      dispatch(setOptions({ option: "ddTerritoriesSMA", value: territoriesPlusEmpty }));
      dispatch(fetchingDone("ddTerritoriesSMA"));
    });

    dispatch(fetching("ddTerritoriesMF"));
    API.request({ url: "/dd-territories/mf", method: "GET" }).then((response) => {
      const territoriesArr = response.data.map((d) => d.value);
      const territoriesPlusEmpty = ["", ...territoriesArr];
      dispatch(setOptions({ option: "ddTerritoriesMF", value: territoriesPlusEmpty }));
      dispatch(fetchingDone("ddTerritoriesMF"));
    });

    dispatch(fetching("territoryGroupsSMA"));
    API.request({ url: "/territory-groups/sma", method: "GET" }).then((response) => {
      const terrGroupsArr = response.data.map((d) => d.value);
      const terrGroupsPlusEmpty = ["", ...terrGroupsArr];
      const terrAltLabels = ["", "TERRITORY_ID", ...terrGroupsArr];
      dispatch(setOptions({ option: "territoryGroupsSMA", value: terrGroupsPlusEmpty }));
      dispatch(setOptions({ option: "terrAltLabelsSMA", value: terrAltLabels }));
      dispatch(fetchingDone("territoryGroupsSMA"));
    });

    dispatch(fetching("territoryGroupsMF"));
    API.request({ url: "/territory-groups/mf", method: "GET" }).then((response) => {
      const terrGroupsArr = response.data.map((d) => d.value);
      const terrGroupsPlusEmpty = ["", ...terrGroupsArr];
      const terrAltLabels = ["", "TERRITORY_ID", ...terrGroupsArr];
      dispatch(setOptions({ option: "territoryGroupsMF", value: terrGroupsPlusEmpty }));
      dispatch(setOptions({ option: "terrAltLabelsMF", value: terrAltLabels }));
      dispatch(fetchingDone("territoryGroupsMF"));
    });

    dispatch(fetching("channelsSMA"));
    API.request({ url: "/channels/sma", method: "GET" }).then((response) => {
      const channelsArr = response.data.map((d) => d.value);
      const channelsPlusEmpty = ["", ...channelsArr];
      dispatch(setOptions({ option: "channelsSMA", value: channelsPlusEmpty }));
      dispatch(fetchingDone("channelsSMA"));
    });

    dispatch(fetching("channelsMF"));
    API.request({ url: "/channels/mf", method: "GET" }).then((response) => {
      const channelsArr = response.data.map((d) => d.value);
      const channelsPlusEmpty = ["", ...channelsArr];
      dispatch(setOptions({ option: "channelsMF", value: channelsPlusEmpty }));
      dispatch(fetchingDone("channelsMF"));
    });

    dispatch(fetching("ddChannelsSMA"));
    API.request({ url: "/dd-channels/sma", method: "GET" }).then((response) => {
      const channelsArr = response.data.map((d) => d.value);
      const channelsPlusEmpty = ["", ...channelsArr];
      dispatch(setOptions({ option: "ddChannelsSMA", value: channelsPlusEmpty }));
      dispatch(fetchingDone("ddChannelsSMA"));
    });

    dispatch(fetching("ddChannelsMF"));
    API.request({ url: "/dd-channels/mf", method: "GET" }).then((response) => {
      const channelsArr = response.data.map((d) => d.value);
      const channelsPlusEmpty = ["", ...channelsArr];
      dispatch(setOptions({ option: "ddChannelsMF", value: channelsPlusEmpty }));
      dispatch(fetchingDone("ddChannelsMF"));
    });

    // --- Dataset Revisions ---
    dispatch(fetching("datasetRevisions"));
    API.request({ url: "/datarevisions", method: "GET" }).then((response) => {
      const dataRevisionIDToNameArr = response.data.map((d) => [d.id, d.revision_name]);
      dataRevisionIDToNameArr.sort((a, b) => a[0] - b[0]);
      const dataRevisionIDArr = dataRevisionIDToNameArr.map((d) => d[0]);
      const dataRevisionNameArr = dataRevisionIDToNameArr.map((d) => `${d[0]}: ${d[1]}`);
      dispatch(setOptions({ option: "dataRevisionID", value: dataRevisionIDArr }));
      dispatch(setOptions({ option: "dataRevisionName", value: dataRevisionNameArr }));
      dispatch(fetchingDone("datasetRevisions"));
    });

    dispatch(fetching("clients"));
    API.request({ url: "/clients", method: "GET" }).then((response) => {
      dispatch(setOptions({ option: "clients", value: response.data }));
      dispatch(fetchingDone("clients"));
    });

    dispatch(fetching("misightUsers"));
    API.request({ url: "/misight/users", method: "GET" }).then((response) => {
      dispatch(setMisightUsers({ users: response.data }));
      dispatch(fetchingDone("misightUsers"));
    });

    dispatch(fetching("clientProfilesANN"));
    API.request({ url: "/lms/api/v1/ann/client/ann_clientprofile", method: "GET" }).then((response) => {
      dispatch(setOptions({ option: "clientProfilesANN", value: response.data }));
      dispatch(fetchingDone("clientProfilesANN"));
    });

    dispatch(fetchingLMS("clientProfilesMF"));
    API.request({ url: "/lms/api/v1/mf/client/mf_clientprofile", method: "GET" }).then((response) => {
      dispatch(setOptions({ option: "clientProfilesMF", value: response.data }));
      dispatch(fetchingLMSDone("clientProfilesMF"));
    });

    dispatch(fetchingLMS({ element: "clients" }));
    API.request({ url: "/lms/api/v1/client/client", method: "GET" }).then((response) => {
      dispatch(setOptions({ option: "lms_backend_clients", value: response.data }));
      dispatch(fetchingLMSDone({ element: "clients" }));
    });

    let firmNames = getFromStorage("firmNames");
    if (firmNames?.length) {
      dispatch(setOptions({ option: "firmNames", value: firmNames }));
      dispatch(fetchingBackgroundDone({ element: "firmNames" }));
    } else {
      firmNames = [];
      const getFirmNames = (pageIdx) => {
        API.request({ url: "/si-firm-id", method: "GET", params: { page_idx: pageIdx, limit: 10000 } }).then((response) => {
          if (response?.data?.results && response.data.results.length > 0) {
            const asDictList = processFlattenedData(response.data);
            firmNames = firmNames.concat(asDictList);
            getFirmNames(pageIdx + 1);
          } else {
            // no more results, so done fetching
            dispatch(setOptions({ option: "firmNames", value: firmNames }));
            dispatch(fetchingBackgroundDone({ element: "firmNames" }));
            saveToStorage("firmNames", firmNames, 86400000); // 24Hrs
          }
        });
      };
      dispatch(fetchingBackground({ element: "firmNames" }));
      getFirmNames(0);
    }

    let firmNamesDD = getFromStorage("firmNamesDD");
    if (firmNamesDD?.length) {
      dispatch(setOptions({ option: "firmNamesDD", value: firmNamesDD }));
      dispatch(fetchingBackgroundDone({ element: "firmNamesDD" }));
    } else {
      firmNamesDD = [];
      const getFirmNamesDD = (queryId = null, nextToken = null) => {
        let query = "{\n  firms(pageSize: 999, filterBy: {isActive: {neq: null}, name: {neq: null}, crd: {neq: null}}";
        if (queryId && nextToken) {
          query += `\n nextPage: {queryId: "${queryId}", token: "${nextToken}"}\n`;
        }
        if (queryId && !nextToken) {
          // no more results, so done fetching
          for (let i = 0; i < firmNamesDD.length; i += 1) {
            firmNamesDD[i].firm_crd = parseInt(firmNamesDD[i].crd, 10);
          }
          dispatch(setOptions({ option: "firmNamesDD", value: firmNamesDD }));
          dispatch(fetchingBackgroundDone({ element: "firmNamesDD" }));
          saveToStorage("firmNamesDD", firmNamesDD, 86400000); // 24Hrs
          return;
        }
        query += ") {\n    queryInfo {\n      id\n      nextToken\n    }\n    list {\n      crd\n      name\n    isActive\n    }\n  }\n}";
        API.request({ url: "/graphql", method: "POST", data: { query } }).then((response) => {
          firmNamesDD = firmNamesDD.concat(response.data.data.firms.list);
          getFirmNamesDD(response.data.data.firms.queryInfo.id, response.data.data.firms.queryInfo.nextToken);
        });
      };
      dispatch(fetchingBackground({ element: "firmNamesDD" }));
      getFirmNamesDD();
    }
  };
}
