import React, { useEffect, useState } from "react";
import {
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import Form from "@rjsf/material-ui";
import { useSelector } from "react-redux";

export default function ANNLMSQADashboardForm({
  children,
  taskConfig,
  setTaskConfig,
  submitHandler,
  studyType,
  displayHeader,
  setIsRequired,
}) {
  const productionCycles = useSelector((state) => state.formOptions.productionCycle);
  const locksANN = useSelector((state) => state.formOptions.locksANN);
  const [disableLock, setDisableLock] = useState(false);

  const onCheckHandler = (e) => {
    setTaskConfig((prevTaskConfig) => ({
      ...prevTaskConfig,
      [e.target.value]: e.target.checked,
    }));
  };

  const onCycleLockSelect = (e) => {
    setTaskConfig((prevTaskConfig) => ({
      ...prevTaskConfig,
      [e.target.name]: e.target.value,
    }));
  };

  useEffect(() => {
    // If none of the options are selected then reset production_cycle and lock.
    if (
      taskConfig.file_load_monitor_drill_down === false
      && taskConfig.quality_control_audit === false
      && taskConfig.sales_file_vetting === false
    ) {
      setTaskConfig((prevTaskConfig) => {
        // eslint-disable-next-line camelcase
        const { production_cycle, lock, ...newTaskConfig } = prevTaskConfig;
        return newTaskConfig;
      });
    }
    // If file_load_monitor_drill_down is the only option selected then disable lock.
    if (
      taskConfig.file_load_monitor_drill_down === true
      && taskConfig.quality_control_audit === false
      && taskConfig.sales_file_vetting === false
    ) {
      setDisableLock(true);
      setTaskConfig((prevTaskConfig) => ({
        ...prevTaskConfig,
        lock: "",
      }));
    } else {
      setDisableLock(false);
    }
  }, [
    taskConfig.file_load_monitor_drill_down,
    taskConfig.quality_control_audit,
    taskConfig.sales_file_vetting,
    setTaskConfig,
  ]);

  useEffect(() => {
    setTaskConfig((prevConfig) => ({
      ...prevConfig,
      study: studyType,
      production_cycle: taskConfig.production_cycle ?? productionCycles[1],
      lock: taskConfig.lock ?? locksANN[1],
      quality_control_audit: taskConfig.quality_control_audit ?? true,
      file_load_monitor_drill_down: taskConfig.file_load_monitor_drill_down ?? true,
      sales_file_vetting: taskConfig.sales_file_vetting ?? true,
    }));
    setIsRequired((prevState) => [...prevState, "production_cycle"]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Form schema={{}} onSubmit={submitHandler}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <Typography
          variant="subtitle2"
          style={{ marginRight: "8px", marginBottom: "16px" }}
        >
          Task Name :
        </Typography>
        <Chip
          style={{ marginBottom: "16px" }}
          variant="outlined"
          label={displayHeader(["file_type"])}
        />
      </div>
      <Grid container spacing={2}>
        <Grid item>
          <FormControl fullWidth variant="outlined">
            <InputLabel required htmlFor="inputLabel-production-cycle-select">
              Production Cycle
            </InputLabel>
            <Select
              required
              style={{ width: 250 }}
              value={taskConfig.production_cycle || ""}
              onChange={onCycleLockSelect}
              label="Production Cycle"
              inputProps={{
                name: "production_cycle",
                id: "inputLabel-production-cycle-select",
              }}
              disabled={
                !taskConfig.quality_control_audit
                && !taskConfig.file_load_monitor_drill_down
                && !taskConfig.sales_file_vetting
              }
            >
              {productionCycles.map((quarter) => (
                <MenuItem key={quarter} value={quarter}>
                  {quarter}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item>
          <FormControl fullWidth variant="outlined">
            <InputLabel required htmlFor="inputLabel-lock-select">
              Lock
            </InputLabel>
            <Select
              required={!disableLock}
              style={{ width: 250 }}
              value={taskConfig.lock || ""}
              onChange={onCycleLockSelect}
              label="Lock"
              inputProps={{
                name: "lock",
                id: "inputLabel-lock-select",
              }}
              disabled={
                disableLock
                || (!taskConfig.quality_control_audit
                  && !taskConfig.file_load_monitor_drill_down
                  && !taskConfig.sales_file_vetting)
              }
            >
              {locksANN.map((lock) => (
                <MenuItem key={lock} value={lock}>
                  {lock}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item>
          {(taskConfig.production_cycle && taskConfig.production_cycle !== productionCycles[1]) ? (
            <Alert severity="warning">
              Selected Production Cycle is not the current cycle. Running this
              job will overwrite the data for
              {" "}
              {taskConfig.production_cycle}
              {" "}
              cycle.
            </Alert>
          ) : null}
        </Grid>
      </Grid>
      {/* Quality Control Audit */}
      <div style={{ height: 50 }}>
        <FormControl margin="normal" variant="outlined">
          <Tooltip title="Quality Control Audit" placement="right-end">
            <FormControlLabel
              control={(
                <Checkbox
                  value="quality_control_audit"
                  onChange={onCheckHandler}
                  checked={taskConfig.quality_control_audit ?? true}
                />
              )}
              label="Quality Control Audit"
            />
          </Tooltip>
        </FormControl>
      </div>
      {/* File Load Monitor */}
      <div style={{ height: 50 }}>
        <div style={{ display: "flex" }}>
          <FormControl margin="normal" variant="outlined">
            <Tooltip title="File Load Monitor Drill Down" placement="right-end">
              <FormControlLabel
                control={(
                  <Checkbox
                    value="file_load_monitor_drill_down"
                    onChange={onCheckHandler}
                    checked={taskConfig.file_load_monitor_drill_down ?? true}
                  />
                )}
                label="File Load Monitor Drill Down"
              />
            </Tooltip>
          </FormControl>
          {taskConfig.file_load_monitor_drill_down ? (
            <Alert
              severity="info"
              style={{ marginTop: "auto", marginBottom: "auto" }}
            >
              File Load Monitor Drill Down does not require a lock.
            </Alert>
          ) : null}
        </div>
      </div>
      {/* Sales File Vetting */}
      <div style={{ height: 50, marginBottom: 25 }}>
        <FormControl margin="normal" variant="outlined">
          <Tooltip title="Sales File Vetting" placement="right-end">
            <FormControlLabel
              control={(
                <Checkbox
                  value="sales_file_vetting"
                  onChange={onCheckHandler}
                  checked={taskConfig.sales_file_vetting ?? true}
                />
              )}
              label="Sales File Vetting"
            />
          </Tooltip>
        </FormControl>
      </div>
      {children}
    </Form>
  );
}
