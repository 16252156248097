import React from "react";
import { MenuItem, Select } from "@material-ui/core";

import FirmNameColumn from "../../../../components/FirmNameColumn";
import FirmNameAutoComplete from "../../../../components/input/FirmNameAutoComplete";
import { channels } from "../../../../utils/SharedDefinitions";
import { API } from "../../../../utils/api";

export const generateSIFirmColumns = () => (
  [
    {
      title: "Firm Name",
      field: "firm_name",
      editable: "always",
      render: rowData => <FirmNameColumn value={rowData.firm_name} />,
      editComponent: ({ value, onChange, ...other }) => (
        <FirmNameAutoComplete
          onChange={(e, selectedValue) => {
            if (selectedValue) {
              other.onRowDataChange({
                ...other.rowData,
                ...selectedValue,
              });
            }
          }}
        />
      ),
    },
    { title: "Firm CRD", field: "firm_crd", render: rowData => <FirmNameColumn value={typeof rowData === "string" ? rowData : rowData.firm_crd} />, editable: "never" },
    { title: "SI Firm ID", field: "si_firm_id", editable: "never" },
  ]
);

export const generateCommonColumns = () => (
  [
    { title: "Created At", field: "created_at", editable: "never", type: "datetime" },
    { title: "Created By", field: "created_by", editable: "never" },
    { title: "Updated By", field: "updated_by", editable: "never" },
    { title: "Updated At", field: "updated_at", editable: "never", type: "datetime" },
  ]
);

export const generateCommonExclusionColumns = () => (
  [
    ...generateSIFirmColumns(),
    { title: "Zip Code", field: "zip_code", editable: "always" },
  ]
);

export const generateStudySpecificColumns = (study) => {
  const columnMap = {
    ann: [
      {
        title: "Channel",
        field: "channel",
        editable: "always",
        editComponent: ({ value, onChange }) => (
          <Select
            defaultValue=""
            value={value || ""}
            onChange={(event) => onChange(event.target.value)}
          >
            {channels.map(o => (
              <MenuItem
                key={o.id}
                value={o.id}
              >
                {o.label}
              </MenuItem>
            ))}
          </Select>
        ),
      },
      { title: "VA", field: "va", type: "boolean", editable: "always" },
      { title: "FIA", field: "fia", type: "boolean", editable: "always", initialEditValue: true },
    ],
    mf: [],
    sma: [],
  };
  return columnMap[study];
};

const mapPayloadToUrl = (payload, direction, study) => {
  const hasAnnuityType = payload.fia || payload.va;

  if (payload.si_firm_id && !payload.zip_code && !payload.channel && !hasAnnuityType) {
    return `/lms/api/v1/${study}/exclusion/${study}_exclusion_clientprofile${direction}firm`;
  }
  if (payload.si_firm_id && !payload.zip_code && payload.channel && !hasAnnuityType) {
    return `/lms/api/v1/${study}/exclusion/${study}_exclusion_clientprofile${direction}firmchannel`;
  }
  if (payload.si_firm_id && !payload.zip_code && hasAnnuityType) {
    return `/lms/api/v1/ann/exclusion/ann_exclusion_clientprofile${direction}firmannuitytype`;
  }
  if (payload.si_firm_id && payload.zip_code && !payload.channel && !hasAnnuityType) {
    return `/lms/api/v1/${study}/exclusion/${study}_exclusion_clientprofile${direction}firmzip`;
  }
  if (payload.si_firm_id && payload.zip_code && payload.channel && !hasAnnuityType) {
    return `/lms/api/v1/${study}/exclusion/${study}_exclusion_clientprofile${direction}firmzipchannel`;
  }
  if (payload.si_firm_id && payload.zip_code && hasAnnuityType) {
    return `/lms/api/v1/ann/exclusion/ann_exclusion_clientprofile${direction}firmzipannuitytype`;
  }
  return null;
};

const checks = (data, study) => {
  const hasAnnuityType = data.fia || data.va;
  if (study === "ann") {
    if (!data.channel && !hasAnnuityType) {
      return "Channel or FIA or VA is a required field";
    }
    if (data.channel && hasAnnuityType) {
      return "Channel and Annuity Type are mutually exclusive";
    }
  }
  return null;
};

export const generateExclusionTableCallbacks = (
  url,
  callBack,
  extraCallBack,
  direction,
  study,
  tableData,
) => {
  const actions = {};
  actions.onRowAdd = async (data) => {
    const annReponse = checks(data, study);
    if (annReponse) {
      callBack(false, { response: { data: annReponse } });
      return;
    }
    API.request({ url, method: "POST", data })
      .then((response) => {
        callBack(true, response);
        extraCallBack();
      })
      .catch((e) => {
        callBack(false, e);
      });
  };

  actions.onRowUpdate = async (newData, data) => {
    let dupsPresent = false;
    const compareValues = (preValue, newValue) => ((preValue === null && newValue === "") || (preValue === newValue));
    const baseURL = mapPayloadToUrl(data, direction, study);
    const annReponse = checks(newData, study);
    if (annReponse) {
      callBack(false, { response: { data: annReponse } });
      return;
    }
    tableData.forEach((prevData) => {
      if (prevData.si_firm_id === newData.si_firm_id
        && compareValues(prevData.zip_code, newData.zip_code)
        && compareValues(prevData.channel, newData.channel)
        && compareValues(prevData.fia, newData.fia)
        && compareValues(prevData.va, newData.va)) {
        dupsPresent = true;
        callBack(false, { response: { data: "Duplicate data: Another row with same values existing" } });
      }
    });
    if (!dupsPresent) {
      data.ids.forEach((id) => {
        API.request({ url: `${baseURL}/${id}`, method: "DELETE" })
          .then((response) => {
            callBack(true, response);
            extraCallBack();
          })
          .catch((e) => {
            callBack(false, e);
          });
      });
      API.request({ url, method: "POST", data: newData })
        .then((response) => {
          callBack(true, response);
          extraCallBack();
        })
        .catch((e) => {
          callBack(false, e);
        });
    }
  };
  actions.onRowDelete = async (data) => {
    const baseURL = mapPayloadToUrl(data, direction, study);
    data.ids.forEach((id) => {
      API.request({ url: `${baseURL}/${id}`, method: "DELETE" })
        .then((response) => {
          callBack(true, response);
          extraCallBack();
        })
        .catch((e) => {
          callBack(false, e);
        });
    });
  };
  return actions;
};
