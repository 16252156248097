import React, { useContext, useEffect, useState } from "react";
import MaterialTable from "material-table";
import { useSelector } from "react-redux";
import useSWR from "swr";

import { getSIClientProfileExclusionsAggregated } from "../../../../utils/api";
import { combineArrays } from "../../../../utils/dataTransformations";
import { ClientProfileContext } from "../ClientProfileContext";
import BulkUploadDialog from "./BulkUploadDialog";
import { generateCommonExclusionColumns, generateExclusionTableCallbacks, generateStudySpecificColumns } from "./Columns";

export default function ExclusionsTable() {
  const { clientProfileID, study, apiCallback } = useContext(ClientProfileContext);
  const url = `lms/api/v1/si_clientprofile/${clientProfileID}/study/${study}/exclusions`;
  const { data, error, mutate } = useSWR(url, () => getSIClientProfileExclusionsAggregated(clientProfileID, study));
  const fetchingFirmNames = useSelector((state) => state.application.fetchingBackground.firmNames);
  const firmNames = useSelector((state) => state.formOptions.firmNames);
  const [dataWithFirmNames, setDataWithFirmNames] = useState([]);
  const [bulkUploadDialogOpen, setBulkUploadDialogOpen] = useState(false);

  useEffect(() => {
    if (!fetchingFirmNames && data && !error) {
      setDataWithFirmNames(combineArrays(data, firmNames, "si_firm_id"));
    }
  }, [firmNames, fetchingFirmNames, data, error]);

  return (
    <div style={{ width: "100%" }}>
      <MaterialTable
        isLoading={!data}
        columns={[
          ...generateCommonExclusionColumns(),
          ...generateStudySpecificColumns(study),
        ]}
        data={fetchingFirmNames ? data : dataWithFirmNames}
        title="Broker Exclusions"
        editable={
          generateExclusionTableCallbacks(url, apiCallback, mutate, "deny", study, data)
        }
        localization={{
          body: {
            editRow: {
              saveTooltip: "Yes",
            },
          },
        }}
        actions={[
          {
            icon: "upload",
            tooltip: "Bulk Upload",
            isFreeAction: true,
            onClick: () => setBulkUploadDialogOpen(true),
          },
        ]}
      />
      <BulkUploadDialog open={bulkUploadDialogOpen} setOpen={setBulkUploadDialogOpen} direction="exclusions" />
    </div>
  );
}
