import { createSlice } from "@reduxjs/toolkit";

export const BACKEND_TYPE = Object.freeze({
  ALL: "ALL",
  DI: "DI",
  LMS: "LMS",
});

export const backendType = process.env.REACT_APP_BACKEND_API.includes("marketmetrics") ? BACKEND_TYPE.LMS : BACKEND_TYPE.DI;

export default createSlice({
  name: "filters",
  initialState: {
    backendType,
    environment: "",
    pageTitle: "",
    formIsLoading: false,
    isDeveloperAccount: false,
    isDbRefreshControlAccount: false,
    isClientDomainControlAccount: false,
    fetching: {
      selectAPI: false,
      taskAPI: false,
      locks: false,
      quarters: false,
      territoriesVA: false,
      territoriesFIA: false,
      territoriesSMA: false,
      territoriesMF: false,
      territoryGroupsANN: false,
      territoryGroupsSMA: false,
      territoryGroupsMF: false,
      channelsANN: false,
      channelsSMA: false,
      channelsMF: false,
      clientConfig: false,
      OTTTerrGroupsANN: false,
    },
    fetchingBackground: {
      firmNames: false,
    },
    LMSIsLoading: false,
    fetchingLMS: {
      clients: false,
      clientProfilesMF: false,
    },
  },
  reducers: {
    setEnvironment: (state, action) => {
      const { environment } = action.payload;
      state.environment = environment;
    },

    setPageTitle: (state, action) => {
      const { title } = action.payload;
      state.pageTitle = title;
    },
    setPrivilege: (state, action) => {
      const { isDeveloperAccount, isDbRefreshControlAccount, isClientDomainControlAccount } = action.payload;
      state.isDeveloperAccount = isDeveloperAccount;
      state.isDbRefreshControlAccount = isDbRefreshControlAccount;
      state.isClientDomainControlAccount = isClientDomainControlAccount;
    },

    fetching: (state, action) => {
      const { element } = action.payload;
      state.fetching[element] = true;
      state.formIsLoading = true;
    },

    fetchingDone: (state, action) => {
      const { element } = action.payload;
      state.fetching[element] = false;
      state.formIsLoading = Object.values(state.fetching).some((e) => e === true);
    },

    fetchingBackground: (state, action) => {
      const { element } = action.payload;
      state.fetchingBackground[element] = true;
    },

    fetchingBackgroundDone: (state, action) => {
      const { element } = action.payload;
      state.fetchingBackground[element] = false;
    },

    fetchingLMS: (state, action) => {
      const { element } = action.payload;
      state.fetchingLMS[element] = true;
      state.LMSIsLoading = true;
    },

    fetchingLMSDone: (state, action) => {
      const { element } = action.payload;
      state.fetchingLMS[element] = false;
      state.LMSIsLoading = Object.values(state.fetchingLMS).some((e) => e === true);
    },
  },
});
