import React, { useEffect } from "react";
import { Chip, Typography } from "@material-ui/core";
import { useSelector } from "react-redux";

import useFormData from "../../hooks/useFormData";
import * as SharedDefinitions from "../../utils/SharedDefinitions";
import BaseForm from "./common/BaseForm";

const BrokerCategory = {
  fds_broker_id: SharedDefinitions.FDSBrokerId,
  fund_category: {
    type: "string",
    title: "Fund Category",
    description: "Morningstar fund category",
  },
};

const uiSchema = {
  exclude_client_firm_id: {
    "ui:help": "This is a report customization used for American Century",
  },
};

const riaInclusionsTooltip = (
  <div>
    Brokerage/Hybrid will pull a Standard Feed with Brokerage and hybrid firms only (no RIA), and includes data from both non fidelity and fidelity custodial files.
    <br />
    <br />
    RIA will pull a Standard Feed with RIA firms only (no Brokerage/Hybrid), and will include data from both non fidelity and fidelity custodial files.
    <br />
    <br />
    Brokerage/Hybrid + RIA will pull a Standard Feed with all firm types, and will include data from non fidelity and fidelity custodial files.
    <br />
    <br />
    Brokerage/Hybrid + RIA (Ex-Fidelity) will pull a Standard feed with all firm types, including data from non-fidelity custodial files and EXCLUDING data originating from Fidelity custodial files.
    <br />
    <br />
    RIA (Ex-Fidelity) will pull a Standard feed with RIA firms only, and will include data from non-fidelity custodial files while EXCLUDING data originating from Fidelity custodial Files.
  </div>
);

export default function MFStandardFeedForm({ children, client, setTaskConfig, submitHandler, formDataProp, displayHeader, setIsRequired }) {
  const formOptions = useSelector((state) => state.formOptions);
  const firmInclusions = useSelector((state) => state.firmInclusions);
  const [formData, setFormData] = useFormData(setTaskConfig, formDataProp);

  useEffect(() => {
    setFormData((prevFormData) => ({ ...(prevFormData), ...firmInclusions }));
  }, [firmInclusions, setFormData]);

  const StandardFeedTerritoryConfiguration = {
    territory_name: {
      type: "string",
      title: "Territory Map",
      description: "The name of the territory map to be used for this report",
      enum: SharedDefinitions.getOptionsByClient(formOptions.territoriesMF, client),
    },
    channel_map: {
      type: "string",
      title: "Channel Map",
      description: "The name of the channel map to be used for this report",
      enum: SharedDefinitions.getOptionsByClient(formOptions.channelsMF, client),
    },
    territory_map_as_group: {
      type: "string",
      title: "Alternate Territory Grouping",
      description: "Group the territories by another group other than the channel",
      enum: SharedDefinitions.getOptionsByClient(formOptions.territoryGroupsMF, client),
    },
  };

  const MFStandardFeedSchema = {
    title: "MF Standard Feed",
    type: "object",
    required: [
      "start_quarter",
      "end_quarter",
      "territory_settings_1",
    ],
    properties: {
      start_quarter: {
        type: "string",
        title: "Start Quarter",
        enum: formOptions.quarters,
      },
      end_quarter: {
        type: "string",
        title: "End Quarter",
        enum: formOptions.quarters,
      },
      lock: {
        type: "string",
        title: "Lock",
        enum: formOptions.locksMF,
      },
      territory_settings_1: {
        type: "object",
        required: ["territory_name"],
        properties: StandardFeedTerritoryConfiguration,
        title: "Territory Settings 1",
        description: "The territories to be outputed for this report",
      },
      territory_settings_2: {
        properties: StandardFeedTerritoryConfiguration,
        title: "Territory Settings 2 (Optional)",
        description: "The territories to be outputed for this report",
      },
      exclude_client_firm_id: {
        type: "boolean",
        title: "Exclude Broker Identifiers",
        description: "Exclude client broker identifiers",
      },
      exclude_client_office_id: {
        type: "boolean",
        title: "Exclude Office Identifiers",
        description: "Exclude client office identifiers",
      },
      exclude_sales_fields: {
        type: "boolean",
        title: "Exclude Sales Fields",
        description: "Blank out sales fields",
      },
      exclude_redemptions_fields: {
        type: "boolean",
        title: "Exclude Redemptions Fields",
        description: "Blank out redemptions fields",
      },
      exclude_aum_fields: {
        type: "boolean",
        title: "Exclude AUM Fields",
        description: "Blank out AUM fields",
      },
      custom_inclusions_settings: {
        type: "object",
        title: "Custom Limitations",
        properties: {
          broker_limitation: SharedDefinitions.brokerZipExclusions.broker_limitation,
          broker_zip_limitation: SharedDefinitions.brokerZipExclusions.broker_zip_limitation,
          cusip_limitation: {
            type: "array",
            items: {
              type: "string",
            },
            title: "Cusip Limitation",
            description: "Limit the feed to only these cusips",
          },
          broker_category_limitation: {
            type: "array",
            required: [
              "fds_broker_id",
              "fund_category",
            ],
            items: {
              properties: BrokerCategory,
            },
            title: "Broker Category Limitation",
            description: "Limit  the feed to only these broker and category combinations",
          },
        },
      },
      filter_code_override_settings: {
        type: "object",
        title: "Filter code overrides",
        properties: {
          broker_overrides: {
            type: "array",
            items: SharedDefinitions.FDSBrokerId,
            title: "Broker Filter Code Override",
            description: "Removes 'LMS_EXCLUSION' label from brokers tied to Phantom logic",
          },
          client_specific_broker_overrides: {
            type: "array",
            items: SharedDefinitions.FDSBrokerId,
            title: "Client Specific Broker Filter Code Override",
            description: "Removes 'LMS_EXCLUSION' label from brokers tied to Phantom logic for this client",
          },
          client_data_exclusions: {
            type: "array",
            items: {
              type: "string",
            },
            title: "Client Data Exclusions",
            description: "Apply 'LMS_EXCLUSION' filter code to the selected client",
          },
        },
      },
      premium_analytics: {
        type: "boolean",
        title: "Generate Premium Analytics Feed",
        description: "Generate LMS Premium Feed with LMS Standard Feed",
      },
      ria_inclusions: {
        type: "array",
        minItems: 1,
        items: SharedDefinitions.FilingType,
        title: "Firm Type Inclusions",
        description: "Finra filing types and RIA custodian types inclusions",
        headerTooltipText: riaInclusionsTooltip,
      },
    },
  };

  useEffect(() => {
    if (MFStandardFeedSchema.required) {
      setIsRequired((prevState) => ([...prevState, ...MFStandardFeedSchema.required]));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setIsRequired]);

  return (
    <div>
      <div style={{ display: "flex", alignItems: "center" }}>
        <Typography variant="subtitle2" style={{ marginRight: "8px", marginBottom: "16px" }}>Task Name :</Typography>
        <Chip style={{ marginBottom: "16px" }} variant="outlined" label={displayHeader(["start_quarter", "end_quarter", "lock"])} />
      </div>
      <BaseForm
        schema={MFStandardFeedSchema}
        uiSchema={uiSchema}
        formData={formData}
        submitHandler={submitHandler}
        setFormData={setFormData}
      >
        {children}
      </BaseForm>
    </div>
  );
}
