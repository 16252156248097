import React, { useState } from "react";
import { Box, Button, makeStyles } from "@material-ui/core";
import PasswordResetDialog from "./PasswordResetDialog";

const useStyles = makeStyles((theme) => ({
  root: {},
  deactivateButton: {
    marginRight: theme.spacing(1),
    color: theme.palette.text.onBackground,
    backgroundColor: theme.palette.error.light,
  },
  activateButton: {
    color: theme.palette.text.onBackground,
    backgroundColor: theme.palette.secondary.light,
  },
}));

export default function PasswordReset({ userData, loading, refreshUserFunction }) {
  const classes = useStyles();

  const isIssUser = userData.client === "ISS";

  const [dialogOpen, setDialogOpen] = useState(false);

  return (
    <Box
      alignItems="center"
      display="flex"
      flexDirection="column"
    >
      <Button
        color="secondary"
        variant="contained"
        onClick={() => { setDialogOpen(true); }}
        className={classes.activateButton}
        disabled={loading || isIssUser}
      >
        Reset Password
      </Button>
      <PasswordResetDialog
        open={dialogOpen}
        setOpen={setDialogOpen}
        userData={userData}
        refreshUserFunction={refreshUserFunction}
      />
    </Box>
  );
}
