import React, { useEffect } from "react";
import Form from "@rjsf/material-ui";

import CSVUpload from "../../components/input/CSVUpload";
import { fileType } from "./common/BaseForm";

export default function AnnLoadVAProductTypes({ children, taskConfig, setTaskConfig, csv, setCsv, submitHandler, studyType }) {
  useEffect(
    () => {
      setTaskConfig((preConfig) => ({ ...preConfig, study: studyType, file_type: fileType.SECONDARY_FILES }));
    },
    [setTaskConfig, studyType],
  );
  return (
    <Form
      schema={{}}
      onSubmit={submitHandler}
    >
      <CSVUpload
        onChange={(update) => {
          setCsv({ attachment: update.csv });
        }}
        value={{ csv, ...taskConfig }}
      />
      {children}
    </Form>
  );
}
