import React, { useContext, useEffect } from "react";
import { Typography, Divider } from "@material-ui/core";
import { useFormikContext } from "formik";
import { useSelector } from "react-redux";
import * as Yup from "yup";

import LMSCheckbox from "../../../../components/input/Formik/base/LMSCheckBox";
import LMSSelect from "../../../../components/input/Formik/base/LMSSelect";
import { getOptionsByClient } from "../../../../utils/SharedDefinitions";
import { convertArrayToOptions } from "../../../../utils/dataTransformations";

import { FormContext } from "../FormContext";

export const OTTTypeSchema = {
  ott_type_settings: Yup.object().shape({
    ott_type: Yup.string().required("Required"),
  }),
};

const ottTypeOptions = [
  {
    id: "bd",
    label: "BD",
  },
  {
    id: "bd_territory",
    label: "BD/Territory",
  },
  {
    id: "bd_territory_per_file",
    label: "BD/Territory per File",
  },
  {
    id: "territory",
    label: "Territory",
  },
  {
    id: "territory_per_file",
    label: "Territory per File",
  },
];

export default function OTTTypeSection({ isDDJob }) {
  const formOptions = useSelector((state) => state.formOptions);
  const { clientMnemonic, studyType } = useContext(FormContext);
  const { values, setFieldValue } = useFormikContext();
  const territoryGroups = convertArrayToOptions(getOptionsByClient(formOptions[`territoryGroups${studyType.toUpperCase()}`], clientMnemonic));

  const isPerFile = values?.ott_type_settings?.ott_type?.includes("per_file");
  const isTerritory = values?.ott_type_settings?.ott_type === "territory";
  useEffect(() => {
    if (!isPerFile) {
      setFieldValue("ott_type_settings.display_territory_per_territory_file", undefined);
      setFieldValue("ott_type_settings.per_file_terr_map_as_group", undefined);
    }
    if (!isTerritory) {
      setFieldValue("ott_type_settings.include_in_misight_qa", undefined);
    }
  }, [isPerFile, isTerritory, setFieldValue]);

  return (
    <>
      <Typography variant="h5"> OTT Type Settings </Typography>
      <Divider />
      <LMSSelect
        name="ott_type_settings.ott_type"
        options={ottTypeOptions}
        label="OTT Type *"
      />
      <div style={{ paddingLeft: 30 }}>
        {(isDDJob && isTerritory) && (
          <LMSCheckbox
            name="ott_type_settings.include_in_misight_qa"
            label="Include in MISIGHT QA"
            disabled={!isTerritory}
          />
        )}
        <LMSCheckbox
          name="ott_type_settings.display_territory_per_territory_file"
          label="Include Territory Column in Each Per Territory File"
          disabled={!isPerFile}
        />
        <LMSSelect
          name="ott_type_settings.per_file_terr_map_as_group"
          options={territoryGroups}
          label="Change Per Territory File Grouping & File Name"
          disabled={!isPerFile}
        />
      </div>
    </>
  );
}
