import React, { useRef } from "react";
import { Card, CardContent, Grid } from "@material-ui/core";
import * as Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

export default function ChartCard({ options, span = 12 }) {
  const chartComponentRef = useRef();

  Highcharts.setOptions({
    colors: [
      "#1ABFA3", "#50759B", "#DEBE52", "#EF8C57", "#9CAEC1", "#3A94CA", "#9BE3F9", "#736DBF", "#D45FDB", "#7A828B"],
  });

  return (
    <Grid item xs={span} style={{ marginBottom: 10 }}>
      <Card>
        <CardContent>
          <HighchartsReact
            highcharts={Highcharts}
            options={options}
            ref={chartComponentRef}
          />
        </CardContent>
      </Card>
    </Grid>
  );
}
