import React from "react";
import { CircularProgress, Grid, Typography } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import useSWR from "swr";
import usePageTitle from "../../hooks/usePageTitle";
import { API } from "../../utils/api";
import DataRefreshStatusCard from "./DataRefreshStatusCard";

const fetcher = url => API.request({ url, method: "GET" }).then(res => res.data);

export default function LmsDbRefreshStatus() {
  usePageTitle("Data Refresh Control");

  const { data, error, mutate } = useSWR("/datarefresh", fetcher);

  if (error) return <Alert severity="error">Failed to load Data Refresh Control status.</Alert>;
  if (!data) return <CircularProgress size="2em" />;

  const headers = Object.keys(data);

  return (
    <Grid container spacing={3}>
      {headers?.map((h) => (
        <Grid item key={h} xs={12}>
          <Typography variant="h4">{h.toUpperCase()}</Typography>
          <Grid container spacing={2}>
            {data[h].map((s) => (
              <Grid item xs={12} key={s.rule}>
                <DataRefreshStatusCard name={s.name} rule={s.rule} schedule={s.schedule} enabled={s.enabled} mutate={mutate} />
              </Grid>
            ))}
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
}
