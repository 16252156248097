import React, { useEffect, useState, useMemo } from "react";
import {
  AppBar,
  Box,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Snackbar,
  Toolbar,
  Tooltip,
  Typography,
} from "@material-ui/core";
import {
  ContactMail as ClientProfileIcon,
  Business as BusinessIcon,
  Check as CheckIcon,
  ChevronLeft as ChevronLeftIcon,
  Menu as MenuIcon,
  NotInterested as NotInterestedIcon,
  ListAlt as StatusTableIcon,
  PersonPinCircle as PersonPinCircleIcon,
  VpnKey as VpnKeyIcon,
  AddCircle as AddTaskIcon,
} from "@material-ui/icons";

import MuiAlert from "@material-ui/lab/Alert";
import { Auth } from "aws-amplify";
import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";
import { Link, Navigate, Route, Routes } from "react-router-dom";
import usePrivilege from "../hooks/usePrivilege";
import { setEnvironment } from "../state";
import { getOptionsData } from "../state/getOptions";
import PageNotFound from "./404";
import ClientProfileDetailsDD from "./clients/profiles/ProfileDetailsDD";
import MisightClientProfileGroupingConfig from "./clients/profiles/athena";
import LmsClientsDD from "./clients/profiles/indexDD";
import ConsortiumExclusions from "./consortium";
import { DisplayClientSpecificMapping } from "./preprocessing_transforms/DisplayClientSpecificMapping";
import { DisplayCommonHeaderMapping } from "./preprocessing_transforms/DisplayCommonHeaderMapping";
import { DisplayCommonTransforms } from "./preprocessing_transforms/DisplayCommonTransforms";
import SplitBrokers from "./split_brokers";
import TaskDetails from "./tasks/TaskDetails";
import TaskCreateWizard from "./tasks/create_wizard/index";
import StatusTable from "./tasks/status";

const drawerWidth = 200;
const deploymentEnv = process.env.REACT_APP_CUSTOM_NODE_ENV;

const envColors = Object.freeze({
  local: "white",
  dev: "#debe52",
  stage: "#736dbf",
  prod: "#d85f5f",
});

const envDisplayName = Object.freeze({
  local: "Local",
  dev: "Development",
  stage: "Staging",
  prod: "Production",
});

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    background: "linear-gradient(157deg, rgba(255,255,255,1) 0%, rgba(230,230,230,1) 100%)",
    backgroundImage: "url('/abstract-data.svg')",
  },
  text: {
    padding: theme.spacing(2, 2, 0),
  },
  paper: {
    paddingBottom: 50,
  },
  list: {
    marginBottom: theme.spacing(2),
  },
  subheader: {
    backgroundColor: theme.palette.background.paper,
  },
  taskPopover: {
    minWidth: "90em",
    minHeight: "80em",
  },
  appBottomBar: {
    top: "auto",
    bottom: 0,
  },
  grow: {
    flexGrow: 1,
  },
  fabButton: {
    position: "absolute",
    zIndex: 1,
    top: -30,
    left: 0,
    right: 0,
    margin: "0 auto",
  },
  appTopBar: {
    backgroundColor: theme.palette.background.nav,
    zIndex: theme.zIndex.drawer + 1,
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    minHeight: "100vh",
    overflow: "hidden",
    margin: "1.5em",
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: "none",
  },
  drawerOpen: {
    width: drawerWidth,
  },
  drawerClose: {
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  appName: {
    fontWeight: 300,
    fontSize: "1.5em",
  },
  appNameColor: {
    fontSize: "1.5em",
    color: theme.palette.text.link,
  },
  deploymentEnv: {
    color: envColors[deploymentEnv],
    textTransform: "capitalize",
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function MainViewDD() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const pageTitle = useSelector(state => state.application.pageTitle);
  const backendType = useSelector(state => state.application.backendType);

  const isDeveloperAccount = useSelector(state => state.application.isDeveloperAccount);
  const isDbRefreshControlAccount = useSelector(state => state.application.isDbRefreshControlAccount);

  const [submitMessage, setSubmitMessage] = useState("");
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [severity, setSeverity] = useState("info");
  // Refresh Create Task Component on clicking Plus Button
  const [copiedJWT, setCopiedJWT] = useState(false);

  const updateSubmitMessage = (message, sever = "info") => {
    setSubmitMessage(message);
    setSeverity(sever);
  };

  usePrivilege();

  useEffect(() => {
    if (backendType === "LMS") {
      dispatch(getOptionsData());
      const env = process.env.REACT_APP_BACKEND_API.split(".")[1];
      dispatch(setEnvironment({ environment: env }));
    }
  }, [backendType, dispatch]);

  useEffect(() => {
    document.title = `Braavos ${backendType} Discovery Data | ${pageTitle}`;
  }, [backendType, pageTitle]);

  // Items can be available for LMS, DI or ALL
  const drawerItems = [
    { label: "Status Table", to: "/dd/status", icon: <StatusTableIcon />, backendType: "ALL" },
    { label: "Create Discovery Data Task", to: "/dd/create", icon: <AddTaskIcon />, backendType: "LMS" },
    { label: "Client Profiles", to: "/dd/client-profiles", icon: <ClientProfileIcon />, backendType: "LMS" },
    { label: "Consortium Exclusions", to: "/dd/consortium-exclusions", icon: <NotInterestedIcon />, backendType: "LMS" },
    { label: "Misight Client Config", to: "/dd/misight-client-config", icon: <PersonPinCircleIcon />, backendType: "LMS" },
    { label: "Split Brokers", to: "/dd/split-brokers", icon: <BusinessIcon />, backendType: "LMS" },
    // Immediately filter for respective services
  ].filter(item => item.backendType === backendType || item.backendType === "ALL");

  const contentRoutes = [
    { path: "/", element: <Navigate replace to={{ pathname: "/dd/status" }} /> },
    { path: "/status", element: <StatusTable ddTaskOnly /> },
    { path: "/create", element: <TaskCreateWizard setSubmitMessage={updateSubmitMessage} isDDTask /> },
    { path: "/edit/:taskIdParam", element: <TaskCreateWizard setSubmitMessage={updateSubmitMessage} /> },
    { path: "/details/:taskIdParam", element: <TaskDetails /> },
    { path: "/client-profiles", element: <LmsClientsDD devOnly={isDeveloperAccount} /> },
    { path: "/consortium-exclusions", element: <ConsortiumExclusions /> },
    { path: "/preprocessing-transform/common-transform/studyFileType/:studyFileType/id/:id", element: <DisplayCommonTransforms /> },
    { path: "/preprocessing-transform/common-header-mapping/studyFileType/:studyFileType/id/:id", element: <DisplayCommonHeaderMapping /> },
    { path: "/preprocessing-transform/client-specific-transform/client/:client/studyFileType/:studyFileType/id/:id", element: <DisplayClientSpecificMapping /> },
    { path: "/client-profile/details/:profileId/study/:study", element: <ClientProfileDetailsDD /> },
    { path: "/misight-client-config", element: <MisightClientProfileGroupingConfig drawerOpen={drawerOpen} /> },
    { path: "/split-brokers", element: <SplitBrokers /> },
    { path: "*", element: <PageNotFound /> },
  ];

  function filteredToolbarItems(items) {
    return (
      items?.length > 0 && (
        <>
          <Divider />
          <List>
            {items?.map(({ label, to, icon, otherProps = {} }) => (
              <ListItem
                button
                key={label}
                component={Link}
                to={to}
                {...otherProps}
                style={{ height: 80 }}
              >
                <ListItemIcon>
                  {icon}
                </ListItemIcon>
                <ListItemText primary={label} />
              </ListItem>
            ))}
          </List>
        </>
      )
    );
  }

  const drawerItemsComponent = useMemo(() => (
    <>
      {filteredToolbarItems(drawerItems.filter(item => !item.isDeveloperItem && !item.isDbRefreshControlItem))}
      {filteredToolbarItems(drawerItems.filter(item => isDeveloperAccount && item.isDeveloperItem))}
      {filteredToolbarItems(drawerItems.filter(item => isDbRefreshControlAccount && item.isDbRefreshControlItem))}
    </>
  ), [isDeveloperAccount, isDbRefreshControlAccount, drawerItems]);

  return (
    <div className={classes.root}>
      {/* --- TOP BAR --- */}
      <AppBar
        position="fixed"
        className={clsx(classes.appTopBar, {
          [classes.appBarShift]: drawerOpen,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={() => { setDrawerOpen(true); }}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: drawerOpen,
            })}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap>
            <b className={classes.appNameColor}>B</b>
            <b className={classes.appName}>{`raavos ${backendType} Discovery Data`}</b>
            <b className={classes.appName}>{` | ${pageTitle}`}</b>
          </Typography>
          <Box style={{ flexGrow: 1 }} />
          <Tooltip title={copiedJWT ? "Copied!" : "Copy JSON Web Token"}>
            <IconButton
              className={classes.appNameColor}
              onClick={() => {
                Auth.currentSession().then((response) => {
                  if (response && response.idToken) {
                    navigator.clipboard.writeText(response.idToken.jwtToken);
                    setCopiedJWT(true);
                    setTimeout(() => setCopiedJWT(false), 3000);
                  }
                });
              }}
            >
              {copiedJWT ? <CheckIcon /> : <VpnKeyIcon />}
            </IconButton>
          </Tooltip>
          <Typography>
            <b className={classes.appName}>{" | "}</b>
            <b className={`${classes.appNameColor} ${classes.deploymentEnv}`}>{`${envDisplayName[deploymentEnv]}`}</b>
          </Typography>
        </Toolbar>
      </AppBar>
      {/*  --- SIDE DRAWER ---  */}
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: drawerOpen,
          [classes.drawerClose]: !drawerOpen,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: drawerOpen,
            [classes.drawerClose]: !drawerOpen,
          }),
        }}
      >
        <div className={classes.toolbar}>
          <IconButton onClick={() => { setDrawerOpen(false); }}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <div className={classes.drawerContainer}>
          {drawerItemsComponent}
        </div>
      </Drawer>
      {/* ---CONTENT ---  */}
      <main className={classes.content}>
        <Toolbar />
        <Routes>
          {contentRoutes.map(({ path, element }) => (
            <Route key={path} path={path} element={element} />
          ))}
        </Routes>
      </main>
      {/* MAIN FEEDBACK MESSAGES */}
      <Snackbar
        open={Boolean(submitMessage && submitMessage.length > 0)}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert onClose={() => setSubmitMessage("")} severity={severity}>
          {submitMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}
