import React, { useEffect, useState } from "react";
import { FormControl, Button } from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Form from "@rjsf/material-ui";
import MaterialTable from "material-table";
import { useSelector, useDispatch } from "react-redux";
import CircularProgress from "../../components/Spinner";
import { fetching, fetchingDone } from "../../state";
import { API } from "../../utils/api";

const columns = [
  { title: "Client", field: "client_id" },
  { title: "Quarter", field: "quarter" },
  { title: "Run Date", field: "run_date" },
  { title: "File Name", field: "filename" },
  { title: "RIA Type", field: "ria_type" },
];

export default function DeleteSalesDataByRunDate({ children, taskConfig, setTaskConfig, submitHandler, studyType, client }) {
  const productionCycles = useSelector((state) => state.formOptions.productionCycle);
  const [clientFiles, setClientFiles] = useState([]);
  const [quarters, setQuarters] = useState([]);
  const [showSelectedClientFiles, setShowSelectedClientFiles] = useState(false);
  const formIsLoading = useSelector(state => state.application.formIsLoading);
  const dispatch = useDispatch();
  useEffect(() => {
    setTaskConfig((preConfig) => ({ ...preConfig, study: studyType, file_type: "secondary_files" }));
  }, [setTaskConfig, studyType]);

  const getClientFiles = () => {
    dispatch(fetching({ element: "clientFiles" }));
    setClientFiles([]);
    const result = API.request({
      url: `/clients/sales_files/${studyType}`,
      method: "GET",
      params: { quarters: quarters.join(","), client_id: client },
    });
    result.then((response) => {
      setClientFiles(response.data.client_sales_runs);
      dispatch(fetchingDone({ element: "clientFiles" }));
    }).catch(e => {
      console.error(e.message);
      dispatch(fetchingDone({ element: "clientFiles" }));
    });
  };

  return (
    <Form
      schema={{}}
      onSubmit={submitHandler}
    >
      {
        !showSelectedClientFiles
        && (
          <>
            <FormControl fullWidth variant="outlined" margin="normal">
              <InputLabel required htmlFor="inputLabel-quarters">Select Quarters</InputLabel>
              <Select
                required
                style={{ width: 500 }}
                value={quarters || []}
                onChange={(e) => setQuarters(e.target.value)}
                label="Select Quarters"
                inputProps={{
                  name: "quarters",
                  id: "inputLabel-quarters",
                }}
                multiple
                MenuProps={{ variant: "menu" }}
              >
                {productionCycles.map((quarter) => (
                  <MenuItem key={quarter} value={quarter}>
                    {quarter}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth variant="outlined" margin="normal">
              <Button
                variant="contained"
                color="primary"
                style={{ width: 175 }}
                onClick={getClientFiles}
              >
                View Client Files
              </Button>
            </FormControl>
          </>
        )
      }
      {clientFiles.length !== 0
        && (
          <FormControl fullWidth variant="outlined" margin="normal">
            <MaterialTable
              title={showSelectedClientFiles ? "Are you sure you want to delete the following files?" : `Delete Sales Data for ${client}`}
              columns={columns}
              data={
                (showSelectedClientFiles ? taskConfig.run_date_quarter_pairs : clientFiles)
              }
              options={{
                selection: !showSelectedClientFiles,
                search: false,
                paging: false,
                showTextRowsSelected: false,
              }}
              onSelectionChange={(rows) => {
                setTaskConfig((prevTaskConfig) => ({ ...prevTaskConfig, run_date_quarter_pairs: [...rows] }));
              }}
            />
          </FormControl>
        )}
      {taskConfig.run_date_quarter_pairs?.length > 0
        && (
          <FormControl fullWidth variant="outlined" margin="normal">
            <Button
              margin="normal"
              variant="contained"
              color="primary"
              onClick={() => setShowSelectedClientFiles(!showSelectedClientFiles)}
              style={{ width: 175 }}
            >
              {showSelectedClientFiles ? "Go Back" : "Delete Files"}
            </Button>
          </FormControl>
        )}
      {(formIsLoading && !showSelectedClientFiles) && <CircularProgress size="2em" />}
      {showSelectedClientFiles && children}
    </Form>
  );
}
