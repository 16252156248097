import React, { useEffect, useState } from "react";
import { Divider, Paper, TextField, Typography, Tooltip } from "@material-ui/core";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import Autocomplete from "@material-ui/lab/Autocomplete";
import _ from "lodash";
import { useDispatch } from "react-redux";
import { setExcludeBD, setExcludeFidelity, setExcludeRIA } from "../../state";

/*
 BROKERAGE/HYBRID
 This means we want to output a report with BD/HYBRID firm types only

 RIA
 This means we want to output a report with RIA firm types only

 BROKERAGE/HYBRID+RIA
 This means we want to output a report with all possible firm types

 BROKERAGE/HYBRID + RIA (Ex-Fidelity)
 This means we want to output a report with all possible firm types with FIDELITY custodian excluded

 RIA (Ex-Fidelity)
 This means we want to output a report with RIA firm types only, with FIDELITY custodian excluded
*/
const options = [
  {
    label: "BROKERAGE/HYBRID",
    exclude_bd: false,
    exclude_ria: true,
    exclude_fidelity: false,
    value: [
      {
        finra_filing_type: "BROKERAGE",
        ria_custodian: "NONE",
      },
      {
        finra_filing_type: "HYBRID",
        ria_custodian: "NONE",
      },
      {
        finra_filing_type: "UNKNOWN",
        ria_custodian: "NONE",
      },
    ],
  },
  {
    label: "RIA",
    exclude_bd: true,
    exclude_ria: false,
    exclude_fidelity: false,
    value: [
      {
        finra_filing_type: "RIA",
        ria_custodian: "GENERAL_RIA",
      },
      {
        finra_filing_type: "RIA",
        ria_custodian: "CHARLES_SCHWAB",
      },
      {
        finra_filing_type: "RIA",
        ria_custodian: "FIDELITY",
      },
      {
        finra_filing_type: "RIA",
        ria_custodian: "PERSHING",
      },
      {
        finra_filing_type: "RIA",
        ria_custodian: "TD_AMERITRADE",
      },
      {
        finra_filing_type: "RIA",
        ria_custodian: "UNKNOWN",
      },
    ],
  },
  {
    label: "BROKERAGE/HYBRID+RIA",
    exclude_bd: false,
    exclude_ria: false,
    exclude_fidelity: false,
    value: [
      {
        finra_filing_type: "BROKERAGE",
        ria_custodian: "NONE",
      },
      {
        finra_filing_type: "HYBRID",
        ria_custodian: "NONE",
      },
      {
        finra_filing_type: "RIA",
        ria_custodian: "GENERAL_RIA",
      },
      {
        finra_filing_type: "RIA",
        ria_custodian: "CHARLES_SCHWAB",
      },
      {
        finra_filing_type: "RIA",
        ria_custodian: "FIDELITY",
      },
      {
        finra_filing_type: "RIA",
        ria_custodian: "PERSHING",
      },
      {
        finra_filing_type: "RIA",
        ria_custodian: "TD_AMERITRADE",
      },
      {
        finra_filing_type: "RIA",
        ria_custodian: "UNKNOWN",
      },
    ],
  },
  {
    label: "BROKERAGE/HYBRID + RIA (Exclude Fidelity)",
    exclude_bd: false,
    exclude_ria: false,
    exclude_fidelity: true,
    value: [
      {
        finra_filing_type: "BROKERAGE",
        ria_custodian: "NONE",
      },
      {
        finra_filing_type: "HYBRID",
        ria_custodian: "NONE",
      },
      {
        finra_filing_type: "RIA",
        ria_custodian: "GENERAL_RIA",
      },
      {
        finra_filing_type: "RIA",
        ria_custodian: "CHARLES_SCHWAB",
      },
      {
        finra_filing_type: "RIA",
        ria_custodian: "PERSHING",
      },
      {
        finra_filing_type: "RIA",
        ria_custodian: "TD_AMERITRADE",
      },
      {
        finra_filing_type: "RIA",
        ria_custodian: "UNKNOWN",
      },
    ],
  },
  {
    label: "RIA (Exclude Fidelity)",
    exclude_bd: true,
    exclude_ria: false,
    exclude_fidelity: true,
    value: [
      {
        finra_filing_type: "RIA",
        ria_custodian: "GENERAL_RIA",
      },
      {
        finra_filing_type: "RIA",
        ria_custodian: "CHARLES_SCHWAB",
      },
      {
        finra_filing_type: "RIA",
        ria_custodian: "PERSHING",
      },
      {
        finra_filing_type: "RIA",
        ria_custodian: "TD_AMERITRADE",
      },
      {
        finra_filing_type: "RIA",
        ria_custodian: "UNKNOWN",
      },
    ],
  },
];

export default function FilingTypeInput({ schema, formData, name, onChange }) {
  const dispatch = useDispatch();
  const [filingType, setFilingType] = useState([]);

  useEffect(() => {
    const selected = options.filter(o => _.isEqual(o.value, formData))[0];
    setFilingType(selected);
    dispatch(setExcludeBD(selected?.exclude_bd));
    dispatch(setExcludeRIA(selected?.exclude_ria));
    dispatch(setExcludeFidelity(selected?.exclude_fidelity));
  }, [formData, dispatch]);

  return (
    <Paper component="div">
      <div style={{ display: "flex", flexDirection: "row", padding: 20 }}>
        <Typography component="h5" variant="h5" style={{ paddingRight: 5 }}>
          {schema.title}
        </Typography>
        {(schema.headerTooltipText) ? <Tooltip title={<div style={{ fontSize: 11 }}>{schema.headerTooltipText}</div>} placement="top" arrow><HelpOutlineIcon fontSize="small" /></Tooltip> : null }
      </div>
      <Divider />
      <div style={{ padding: 20 }}>
        <Autocomplete
          id={`filing-type-array-${name}`}
          options={options}
          getOptionLabel={(option) => (option.label)}
          getOptionSelected={(o, v) => _.isEqual(o, v)}
          value={filingType}
          onChange={(e, v) => {
            onChange(v.value);
          }}
          renderInput={(params) => (
            <TextField {...params} variant="outlined" label="Firm Types" placeholder="Add" />
          )}
          style={{ maxWidth: "75%", width: 500 }}
        />
      </div>
    </Paper>
  );
}
