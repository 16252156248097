import React, { useEffect } from "react";
import { Typography } from "@material-ui/core";
import Form from "@rjsf/material-ui";

export default function AuroraSyncForm({ children, setTaskConfig, submitHandler, studyType, setIsRequired }) {
  useEffect(() => {
    setTaskConfig((prevTaskConfig) => ({ ...prevTaskConfig, study: studyType }));
  }, [setTaskConfig, studyType, setIsRequired]);

  return (
    <Form
      schema={{}}
      onSubmit={submitHandler}
    >
      <Typography variant="subtitle1" style={{ margin: 15 }}>
        This Job makes the Target DB the same as the Prod DB. This job cannot be done in the Production environment.
      </Typography>
      {children}
    </Form>
  );
}
