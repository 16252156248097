import Amplify from "aws-amplify";
import { SignIn } from "aws-amplify-react";
import { authConfig, oktaSignInUrl } from "../constants";

Amplify.configure({ Auth: authConfig });

export default class CustomSignIn extends SignIn {
  constructor(props) {
    super(props);
    this._validAuthStates = ["signIn"];
  }

  // eslint-disable-next-line class-methods-use-this
  showComponent() {
    window.location.href = oktaSignInUrl;
    return "";
  }
}
