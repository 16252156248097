import React, { useEffect } from "react";
import { Chip, FormControl, Typography } from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Form from "@rjsf/material-ui";
import { useSelector } from "react-redux";

import CSVUpload from "../../components/input/CSVUpload";
import OptionSelect from "../../components/input/OptionSelect";
import { fileType } from "./common/BaseForm";

const tableNames = [
  // ANN
  { id: "ANN_PRODUCT_STANDARDS", label: "ANN DD PRODUCT STANDARDS", study: "ann" },
  { id: "DD_BRANCH_ID_CHANNEL", label: "DD BRANCH ID CHANNEL", study: "ann" },
];

const insertTypes = [
  { id: "append", label: "Append" },
  { id: "replace", label: "Replace" },
];

export default function GenericLoaderFormDD({ children, taskConfig, setTaskConfig, csv, setCsv, submitHandler, studyType, showTableDropDown = true, showProductionCycleDropDown = true, showInsertionTypeDropDown = true, displayHeader }) {
  const productionCycles = useSelector((state) => state.formOptions.productionCycle);

  useEffect(() => {
    setTaskConfig((preConfig) => ({ ...preConfig, study: studyType, file_type: fileType.SECONDARY_FILES }));
  }, [setTaskConfig, studyType]);

  return (
    <Form
      schema={{}}
      onSubmit={submitHandler}
    >
      <div style={{ display: "flex", alignItems: "center" }}>
        <Typography variant="subtitle2" style={{ marginRight: "8px", marginBottom: "16px" }}>Task Name :</Typography>
        <Chip style={{ marginBottom: "16px" }} variant="outlined" label={displayHeader(["table_name", "append_replace"])} />
      </div>
      <CSVUpload
        onChange={(update) => {
          setCsv({ attachment: update.csv });
        }}
        value={{ csv, ...taskConfig }}
      />
      {showTableDropDown && (
        <FormControl fullWidth margin="normal">
          <OptionSelect
            required
            style={{ width: 500, marginBottom: -10 }}
            value={taskConfig.table_name || ""}
            onChange={(name) => {
              setTaskConfig((prevTaskConfig) => ({ ...prevTaskConfig, table_name: name, append_replace: taskConfig.append_replace || "" }));
            }}
            options={tableNames.filter((o) => o.study === studyType)}
            label="Table Name"
          />
        </FormControl>
      )}
      {showInsertionTypeDropDown && (
        <FormControl fullWidth margin="normal">
          <OptionSelect
            required
            style={{ width: 500, marginBottom: 20 }}
            value={taskConfig.append_replace || ""}
            onChange={(insertionType) => {
              setTaskConfig((prevTaskConfig) => ({ ...prevTaskConfig, append_replace: insertionType }));
            }}
            options={insertTypes}
            label="Insertion Type"
          />
        </FormControl>
      )}
      {studyType === "mf" && showProductionCycleDropDown && (
        <FormControl fullWidth variant="outlined">
          <InputLabel required htmlFor="inputLabel-production-cycle-select">Production Cycle</InputLabel>
          <Select
            required
            style={{ width: 500, marginBottom: 30 }}
            value={taskConfig.production_cycle || ""}
            onChange={(e) => {
              setTaskConfig((prevTaskConfig) => ({ ...prevTaskConfig, production_cycle: e.target.value }));
            }}
            label="Production Cycle"
            inputProps={{
              name: "production cycle",
              id: "inputLabel-production-cycle-select",
            }}
          >
            {productionCycles.map((quarter) => (
              <MenuItem key={quarter} value={quarter}>
                {quarter}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
      {children}
    </Form>
  );
}
