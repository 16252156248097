import React, { useEffect, useState } from "react";
import { IconButton, TextField } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import Autocomplete, { createFilterOptions } from "@material-ui/lab/Autocomplete";
import { useDispatch, useSelector } from "react-redux";

import { fetching, fetchingDone } from "../../../state";
import { API } from "../../../utils/api";
import CreationDialog from "./CreationDialog";
import UpdateDialog from "./UpdateDialog";

const filterOptions = createFilterOptions();

/*
  A select with a create on the go functionality

  value: value state
  onChange: state hook function for value
  label: label description of the whole input
  url: url to be used for fetching the data
  valueKey: key for the value we need
  labelKey: key to choose how to display the option to the user
  filter: bool if the options need to be filtered out by a key
  filterValue: key to use to filter out options
  sort: bool to sort the options
  additionalOnChange: function to update any other option data that we might want to lift up
  dispatch: Redux dispatch function to track loading globally
 */
export default function TerrClientSelect({
  value,
  onChange,
  label,
  url,
  valueKey = "id",
  labelKey = "value",
  filter = false,
  filterValue = null,
  sort = false,
  additionalOnChange = () => {},
  variant = "outlined",
}) {
  const dispatch = useDispatch();
  const selectAPIisLoading = useSelector(state => state.application.fetching.selectAPI);
  const [refreshData, setRefreshData] = useState(false);

  // Internal state
  const [selectedOption, setSelectedOption] = useState({ [valueKey]: "", [labelKey]: "" });
  const [allOptions, setAllOptions] = useState([]);

  // Dialogs
  const [creationDialogOpen, setCreationDialogOpen] = useState(false);
  const [updateDialogOpen, setUpdateDialogOpen] = useState(false);

  // State of the create form when adding a brand new field
  const [dialogValue, setDialogValue] = useState({});

  useEffect(
    () => {
      function getData() {
        dispatch(fetching({ element: "selectAPI" }));
        onChange(""); // Reset value
        API.request({ url, method: "GET" }).then((response) => {
          // Filter by any arg passed through props
          const data = filter ? response.data.filter((d) => d[filter] === filterValue) : response.data;

          // Sort Alphabetically
          if (sort) {
            data.sort((a, b) => (a[labelKey] > b[labelKey] ? 1 : -1));
          }

          setAllOptions(data);
          if (!value && data.length) {
            setSelectedOption(data[0]);
            onChange(data[0][valueKey]);
            additionalOnChange(data[0][labelKey]);
          }
          dispatch(fetchingDone({ element: "selectAPI" }));
        }, (e) => {
          console.error(e);
          dispatch(fetchingDone({ element: "selectAPI" }));
          return null;
        });
      }
      getData();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [refreshData],
  );

  return (
    <>
      <Autocomplete
        value={selectedOption}
        onChange={(event, newValue) => {
          if (typeof newValue === "string") {
            // timeout to avoid instant validation of the dialog's form.
            setTimeout(() => {
              setCreationDialogOpen(true);
              setDialogValue({
                ...dialogValue,
                [labelKey]: newValue,
              });
            });
          } else if (newValue?.inputValue) {
            setCreationDialogOpen(true);
            setDialogValue({
              ...dialogValue,
              [labelKey]: newValue.inputValue,
            });
          } else {
            setSelectedOption(newValue);
            onChange(newValue ? newValue[valueKey] : {});
            additionalOnChange(newValue ? newValue[labelKey] : {});
          }
        }}
        filterOptions={(opts, params) => {
          const filtered = filterOptions(opts, params);
          if (params.inputValue !== "") {
            filtered.push({
              inputValue: params.inputValue,
              [labelKey]: `Add "${params.inputValue}"`,
            });
          }
          return filtered;
        }}
        id="free-solo-dialog-demo"
        options={allOptions}
        getOptionLabel={(o) => {
          // e.g value selected with enter, right from the input
          if (typeof o === "string") {
            return o;
          }
          if (o.inputValue) {
            return o.inputValue;
          }
          return o[labelKey];
        }}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        renderOption={(option) => (
          <div>
            {!option.inputValue && (
              <IconButton
                onClick={() => {
                  setSelectedOption(option);
                  setUpdateDialogOpen(true);
                }}
                aria-label="edit"
                style={{ marginRight: 20 }}
              >
                <EditIcon />
              </IconButton>
            )}
            <span>{option[labelKey]}</span>
          </div>
        )}
        style={{ width: "100%" }}
        freeSolo
        renderInput={(params) => (
          <TextField
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...params}
            label={label}
            variant={variant}
            margin="normal"
          />
        )}
        disabled={selectAPIisLoading}
      />
      <CreationDialog
        open={creationDialogOpen}
        handleClose={() => {
          setCreationDialogOpen(false);
        }}
        onChange={onChange}
        clientData={dialogValue}
        setClientData={setDialogValue}
        allOptions={allOptions}
        setAllOptions={setAllOptions}
        label={label}
        valueKey={valueKey}
      />
      <UpdateDialog
        open={updateDialogOpen}
        handleClose={() => {
          setUpdateDialogOpen(false);
        }}
        clientData={selectedOption}
        setClientData={setSelectedOption}
        reloadFunction={() => setRefreshData(!refreshData)}
      />
    </>
  );
}
