import React, { useContext, useEffect, useState } from "react";
import MaterialTable from "material-table";
import { useSelector } from "react-redux";
import useSWR from "swr";

import { getClientProfileExclusionsAggregated } from "../../../../utils/api";
import { combineArrays } from "../../../../utils/dataTransformations";
import { ClientProfileContext } from "../ClientProfileContext";
import BulkUploadDialog from "./BulkUploadDialog";
import { generateExclusionTableCallbacks, generateStudySpecificColumns } from "./ColumnsDD";

export default function ExclusionsTableDD() {
  const { clientProfileID, study, apiCallback } = useContext(ClientProfileContext);
  const url = `lms/api/v1/clientprofile/${clientProfileID}/study/${study}/exclusions`;
  const { data, error, mutate } = useSWR(url, () => getClientProfileExclusionsAggregated(clientProfileID, study));
  const fetchingFirmNames = useSelector((state) => state.application.fetchingBackground.firmNamesDD);
  const firmNames = useSelector((state) => state.formOptions.firmNamesDD);
  const [dataWithFirmNames, setDataWithFirmNames] = useState([]);
  const [bulkUploadDialogOpen, setBulkUploadDialogOpen] = useState(false);
  const useRawData = fetchingFirmNames;

  useEffect(() => {
    if (!fetchingFirmNames && data && !error) {
      setDataWithFirmNames(combineArrays(data, firmNames, "firm_crd"));
    }
  }, [firmNames, fetchingFirmNames, data, error]);

  return (
    <div style={{ width: "100%" }}>
      <MaterialTable
        isLoading={!data}
        columns={generateStudySpecificColumns(study)}
        data={useRawData ? data : dataWithFirmNames}
        title="Broker Exclusions"
        editable={
          generateExclusionTableCallbacks(url, apiCallback, mutate, "deny", study, data)
        }
        localization={{
          body: {
            editRow: {
              saveTooltip: "Yes",
            },
          },
        }}
        actions={[
          {
            icon: "upload",
            tooltip: "Bulk Upload",
            isFreeAction: true,
            onClick: () => setBulkUploadDialogOpen(true),
          },
        ]}
      />
      <BulkUploadDialog open={bulkUploadDialogOpen} setOpen={setBulkUploadDialogOpen} direction="exclusions" isDDTask />
    </div>
  );
}
