import React, { useState } from "react";
import {
  Grid,
  Tabs,
  Tab,
} from "@material-ui/core";
import useSWR from "swr";
import usePageTitle from "../../../hooks/usePageTitle";

import { fetcher } from "../../../utils/swr";
import Bundles from "./Bundles";
import ClientBundleAssignment from "./ClientBundleAssignment";

export default function ApiAccessControl() {
  usePageTitle("API Access Control");
  const [selectedTab, setSelectedTab] = useState("bundles");

  const { data: bundles, error } = useSWR(
    "lms/api/v1/misight/accessbundles",
    fetcher,
  );

  return (
    <Grid container spacing={3}>
      <Tabs
        value={selectedTab}
        onChange={(e, tabIndex) => { setSelectedTab(tabIndex); }}
        textColor="secondary"
        indicatorColor="secondary"
        aria-label="secondary tabs example"
      >
        <Tab value="bundles" label="Bundles" />
        <Tab value="clients" label="Client Bundle Assignment" />
      </Tabs>
      {selectedTab === "bundles" ? <Bundles bundles={bundles} error={error} /> : <ClientBundleAssignment bundles={bundles} error={error} />}
    </Grid>
  );
}
