import React, { useEffect, useState } from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  TextField,
} from "@material-ui/core";
import Autocomplete, { createFilterOptions } from "@material-ui/lab/Autocomplete";
import { useDispatch, useSelector } from "react-redux";

import { fetching, fetchingDone } from "../../state";
import { API } from "../../utils/api";
import OptionSelect from "./OptionSelect";

const filterOptions = createFilterOptions();

/*
  A select with a create on the go functionality

  value: value state
  onChange: state hook function for value
  label: label description of the whole input
  url: url to be used for fetching the data
  valueKey: key for the value we need
  labelKey: key to choose how to display the option to the user
  filter: bool if the options need to be filtered out by a key
  filterValue: key to use to filter out options
  sort: bool to sort the options
  createInputFields: fields to be part if the form when adding a new value
  createUrl: url to call when adding a new value
  createInitialState: initial state of the form to prepopulate it
  additionalOnChange: function to update any other option data that we might want to lift up
  dispatch: Redux dispatch function to track loading globally
*/

export default function APISelectCreatable({
  value,
  onChange,
  label,
  url,
  valueKey = "id",
  labelKey = "value",
  filter = false,
  filterValue = null,
  sort = false,
  createInputFields = [],
  createOptionSelectFields = [],
  createOptionSelectOptions = {},
  createUrl = "",
  createInitialState = {},
  additionalOnChange = () => {},
  variant = "outlined",
}) {
  const dispatch = useDispatch();
  const selectAPIisLoading = useSelector(state => state.application.fetching.selectAPI);

  // Internal state
  const [selectedOption, setSelectedOption] = useState({ [valueKey]: "", [labelKey]: "" });
  const [allOptions, setAllOptions] = useState([]);
  const [open, toggleOpen] = useState(false);

  // State of the create form when adding a brand new field
  const [dialogValue, setDialogValue] = useState(createInitialState);

  async function createNew() {
    dispatch(fetching({ element: "selectAPI" }));
    await API.request({
      url: createUrl,
      method: "POST",
      data: dialogValue,
    }).then(
      (r) => {
        if (r.data) {
          // Internal state
          setSelectedOption(r.data);
          setAllOptions(allOptions.concat(r.data));
          // Usefull value
          onChange(r.data[valueKey]);
          additionalOnChange(r.data[labelKey]);
          dispatch(fetchingDone({ element: "selectAPI" }));
        }
      },
      (e) => {
        console.error(e);
        dispatch(fetchingDone({ element: "selectAPI" }));
        return null;
      },
    );
  }

  useEffect(() => {
    dispatch(fetching({ element: "selectAPI" }));
    onChange(""); // Reset value
    API.request({ url, method: "GET" }).then((response) => {
      // Filter by any arg passed through props
      const data = filter ? response.data.filter((d) => d[filter] === filterValue) : response.data;

      // Sort Alphabetically
      if (sort) {
        data.sort((a, b) => (a.value > b.value ? 1 : -1));
      }
      setAllOptions(data);
      if ((!value || !data.some((d) => d[valueKey] === value)) && data.length) {
        setSelectedOption(data[0]);
        onChange(data[0][valueKey]);
        additionalOnChange(data[0][labelKey]);
      } else {
        setSelectedOption({ [valueKey]: "", [labelKey]: "" });
        onChange("");
        additionalOnChange("{}");
      }
      dispatch(fetchingDone({ element: "selectAPI" }));
    }, (e) => {
      console.error(e);
      dispatch(fetchingDone({ element: "selectAPI" }));
      return null;
    });
    // Rerendering this based on full dependencies array causes render-loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url, filter, filterValue]);

  const handleClose = () => {
    toggleOpen(false);
  };

  async function handleSubmit() {
    await createNew();
    handleClose();
  }

  return (
    <>
      <Autocomplete
        value={selectedOption}
        onChange={(event, newValue) => {
          if (typeof newValue === "string") {
            // timeout to avoid instant validation of the dialog's form.
            setTimeout(() => {
              toggleOpen(true);
              setDialogValue({
                ...dialogValue,
                [labelKey]: newValue,
              });
            });
          } else if (newValue?.inputValue) {
            toggleOpen(true);
            setDialogValue({
              ...dialogValue,
              [labelKey]: newValue.inputValue,
            });
          } else {
            setSelectedOption(newValue);
            onChange(newValue[valueKey]);
            additionalOnChange(newValue[labelKey]);
          }
        }}
        filterOptions={(opts, params) => {
          const filtered = filterOptions(opts, params);
          if (params.inputValue !== "") {
            filtered.push({
              inputValue: params.inputValue,
              [labelKey]: `Add "${params.inputValue}"`,
            });
          }
          return filtered;
        }}
        id="free-solo-dialog-demo"
        options={allOptions}
        getOptionLabel={(o) => {
          // e.g value selected with enter, right from the input
          if (typeof o === "string") return o;
          if (o.inputValue) return o.inputValue;
          return o[labelKey];
        }}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        renderOption={(o) => o[labelKey]}
        style={{ width: "100%" }}
        freeSolo
        renderInput={(params) => (
          <TextField
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...params}
            label={label}
            variant={variant}
            margin="normal"
          />
        )}
        disabled={selectAPIisLoading}
      />
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth="sm"
      >
        <form>
          <DialogTitle id="form-dialog-title">
            {`Add a new ${label}`}
          </DialogTitle>
          <Divider />
          <DialogContent>
            <FormControl fullWidth>
              {createInputFields.map((field) => (
                <FormControl margin="normal" key={[field]}>
                  <TextField
                    autoFocus
                    id={field}
                    value={dialogValue[[field]]}
                    onChange={(event) => setDialogValue({
                      ...dialogValue,
                      [field]: event.target.value,
                    })}
                    label={[field]}
                    type="text"
                    fullWidth
                  />
                </FormControl>
              ))}
              {createOptionSelectFields.map((field) => (
                <FormControl key={field}>
                  <OptionSelect
                    value={dialogValue[field]}
                    options={createOptionSelectOptions[field]}
                    onChange={(newValue) => {
                      setDialogValue((prevDialogValue) => ({ ...prevDialogValue, [field]: newValue }));
                    }}
                    label={field}
                  />
                </FormControl>
              ))}
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={handleSubmit} color="primary">
              Create
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
}
