import React, { Fragment, useCallback, useState } from "react";
import { Button, Card, CardActions, CardContent, Chip, Typography, useTheme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Sync as SyncIcon, SyncDisabled as SyncDisabledIcon } from "@material-ui/icons";
import awsCronParser from "aws-cron-parser";
import _ from "lodash";
import moment from "moment-timezone";
import FormAlerts from "../../components/FormAlerts";
import { API } from "../../utils/api";

const useStyles = makeStyles(theme => ({
  root: {
    minWidth: 275,
  },
  pos: {
    marginBottom: 12,
  },
  dev: {
    color: "#debe52",
    textTransform: "capitalize",
    fontWeight: "bold",
  },
  stage: {
    color: "#736dbf",
    textTransform: "capitalize",
    fontWeight: "bold",
  },
  prod: {
    color: "#d85f5f",
    textTransform: "capitalize",
    fontWeight: "bold",
  },
  buttonEnable: {
    color: theme.palette.text.onBackground,
    backgroundColor: theme.palette.secondary.light,
  },
  buttonDisable: {
    color: theme.palette.text.onBackground,
    backgroundColor: theme.palette.error.light,
  },
}));

export default function DataRefreshStatusCard({ name, rule, schedule, enabled, mutate }) {
  const theme = useTheme();
  const classes = useStyles(theme);

  const cron = awsCronParser.parse(schedule);
  const nextRun = awsCronParser.next(cron, new Date());

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  const updateEnabled = useCallback(() => {
    setLoading(true);
    setSuccess(false);
    setError(false);
    API.request(
      { url: `/datarefresh/${rule}`, method: "POST", data: { enabled: !enabled } },
    ).then(
      () => setSuccess("Saved!"),
      (e) => setError(e.response.data),
    ).then(async () => {
      await mutate();
      setLoading(false);
    });
  }, [rule, enabled, mutate]);

  const DisplayName = useCallback(() => {
    const envArray = ["dev", "stage", "prod"];
    const displayName = name.split(" ").map((part) => {
      if (envArray.includes(part.toLowerCase())) {
        return <span className={classes[part.toLowerCase()]}>{part}</span>;
      }
      return part;
    });

    return displayName.map((n) => (
      <Fragment key={_.uniqueId()}>
        {n}
        {" "}
      </Fragment>
    ));
  }, [classes, name]);

  const SyncStatusChip = useCallback(() => {
    const config = enabled
      ? {
        icon: <SyncIcon style={{ color: "white" }} />,
        label: "Enabled",
        style: theme.common.chip.successChip,
      }
      : {
        icon: <SyncDisabledIcon style={{ color: "white" }} />,
        label: "Disabled",
        style: theme.common.chip.errorChip,
      };

    return (
      <Chip
        icon={config.icon}
        label={config.label}
        size="small"
        style={{
          justify: "right",
          ...config.style,
        }}
      />
    );
  }, [theme, enabled]);

  const UpdateButton = useCallback(() => {
    const config = enabled
      ? {
        label: "Disable",
        className: classes.buttonDisable,
      }
      : {
        label: "Enable",
        className: classes.buttonEnable,
      };

    return (
      <Button
        variant="contained"
        size="small"
        className={config.className}
        disabled={loading}
        onClick={updateEnabled}
      >
        {config.label}
      </Button>
    );
  }, [classes, enabled, loading, updateEnabled]);

  return (
    <Card className={classes.root}>
      <CardContent>
        <Typography variant="h5" color="textSecondary" gutterBottom>
          <DisplayName />
        </Typography>
        <Typography component="div" variant="body2" color="textSecondary" gutterBottom>
          Status:
          {" "}
          <SyncStatusChip />
        </Typography>
        <Typography variant="body2" color="textSecondary">
          Next scheduled refresh:
        </Typography>
        <Typography color="textPrimary">{moment(nextRun).tz(moment.tz.guess()).format("MMMM Do YYYY, h:mm:ss a z")}</Typography>
      </CardContent>
      <CardActions style={{ justifyContent: "right" }}>
        <FormAlerts saving={loading} success={success} error={error} setSuccess={setSuccess} setError={setError} />
        <UpdateButton />
      </CardActions>
    </Card>
  );
}
