import React, { useCallback, useEffect, useState, useRef } from "react";
import { TablePagination } from "@material-ui/core";
import _ from "lodash";
import MaterialTable from "material-table";

import { API, generateTableCallbacks } from "../../../utils/api";
import { generateExclusionTableCallbacks } from "../../clients/profiles/exclusions/Columns";

export default function ExclusionsTable({ study, direction, url, editUrl, columns, title, apiCallback, filterPanel, genericEditActions = false }) {
  const [filters, setFilters] = useState({});
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const mountedRef = useRef(true);
  const getData = useCallback((params = null) => {
    setLoading(true);
    API.request({
      url,
      method: genericEditActions ? "GET" : "POST",
      data: {
        page_idx: params.page_idx,
        limit: params.limit,
        filter_by: {
          ..._.pickBy(params?.filter_by, (value) => value !== ""),
          firm_name: params?.filter_by?.firm_name ? `~${params.filter_by.firm_name}` : undefined,
        },
      },
      params: {
        page_idx: params.page_idx,
        limit: params.limit,
        filter_by: {
          ..._.pickBy(params?.filter_by, (value) => value !== ""),
          firm_name: params?.filter_by?.firm_name ? `~${params.filter_by.firm_name}` : undefined,
        },
      },
    })
      .then((response) => {
        setData(response.data);
        setLoading(false);
      })
      .catch(() => {
        if (!mountedRef.current) return;
        const rows = [];
        setData({ rows,
          total: 50 });

        setLoading(false);
      });
  }, [url, genericEditActions]);
  useEffect(() => {
    getData({ page_idx: 0, limit: 5 });
    return () => {
      mountedRef.current = false;
    };
  }, [getData]);
  const stableColumns = columns.map((column) => ({ ...column }));
  return (
    <div style={{ width: "100%" }}>
      {React.createElement(
        filterPanel,
        {
          filters,
          setFilters,
          getData: () => { setPage(0); getData({ page_idx: 0, limit: rowsPerPage, filter_by: filters }); },
          handleResetFilters: () => {
            setFilters((prevFilters) => _.mapValues(prevFilters, () => ""));
            setPage(0); getData({ page_idx: 0, limit: rowsPerPage });
          },
        },
      )}
      <MaterialTable
        isLoading={loading}
        columns={stableColumns}
        data={data?.rows || []}
        title={title}
        editable={
          genericEditActions
            ? generateTableCallbacks(
              editUrl || url,
              null,
              null,
              (success, response) => {
                apiCallback(success, response);
                setPage(0); getData({ page_idx: 0, limit: rowsPerPage, filter_by: filters });
              },
            )
            : generateExclusionTableCallbacks(
              editUrl || url,
              apiCallback,
              () => { setPage(0); getData({ page_idx: 0, limit: rowsPerPage, filter_by: filters }); },
              "consortium",
              direction,
              study,
              data?.rows,
            )
        }
        localization={{
          body: {
            editRow: {
              saveTooltip: "Yes",
            },
          },
        }}
        options={{
          search: false,
        }}
        components={{
          Pagination: props => (
            <TablePagination
              {...props}
              rowsPerPageOptions={[5, 10, 20, 30, 100]}
              rowsPerPage={rowsPerPage}
              count={data?.total || 0}
              page={
                page
              }
              onChangePage={(e, newPage) => {
                setPage(newPage);
                getData({ page_idx: newPage, limit: rowsPerPage, filter_by: filters });
              }}
              onChangeRowsPerPage={event => {
                const { onChangeRowsPerPage } = props;
                onChangeRowsPerPage(event);
                setRowsPerPage(event?.target?.value);
                getData({ page_idx: page, limit: event?.target?.value, filter_by: filters });
              }}
            />
          ),
        }}
      />
    </div>
  );
}
