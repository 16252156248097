import React, { useState, useEffect } from "react";
import { Button, ButtonGroup, Chip, CircularProgress, Dialog, DialogActions, DialogContent, IconButton } from "@material-ui/core";
import DialogTitle from "@material-ui/core/DialogTitle";
import { ArrowRightAlt, DeviceHubRounded, ZoomIn, ZoomOut } from "@material-ui/icons";
import { Alert } from "@material-ui/lab";
import { API } from "../utils/api";
import CountDown from "./CountDown";
import TableLoadingOverlay from "./MaterialTableLoadingOverlay";
import WorkflowTaskChip from "./WorkflowTaskChip";

const refreshTime = 10;

const Flow = ({ data, workflowStatus }) => {
  const stepNumbers = [...new Set(data.map((item) => item.stepNumber))];
  return (
    <div>
      {/* loop through each step number */}
      {data
        ? stepNumbers.map((stepNumber) => (
          <div style={{ display: "inline-flex", flexDirection: "row" }}>
            <div style={{ display: "inline-flex", flexDirection: "column" }}>
              {/* display step number */}
              <Chip
                variant="outlined"
                style={{
                  width: 100,
                  textAlign: "center",
                  fontSize: "15px",
                  backgroundColor: "white",
                  color: "black",
                  margin: "5px auto",
                }}
                label={`Step ${stepNumber}`}
              />
              <div className="steps" style={{ display: "inline-flex", flexDirection: "column", borderRadius: "8px", border: "1px solid #6A66A3", padding: "5px 8px" }}>
                {/* loop through all items with the current step number */}
                {data
                  .filter((item) => item.stepNumber === stepNumber)
                  .map((item) => (
                    <div>
                      <WorkflowTaskChip
                        WorkflowStatus={workflowStatus}
                        Taskstatus={item.status}
                        label={item.title}
                        workflowId={item.workflowId}
                        taskId={item.taskId}
                        variant="outlined"
                      />
                    </div>
                  ))}
              </div>
            </div>

            {/* display arrow if there's a next step */}
            {
              stepNumbers.indexOf(stepNumber) !== stepNumbers.length - 1
                ? (
                  <div>
                    <div className="arrow" style={{ display: "inline-block", margin: "auto 10px" }}>
                      <ArrowRightAlt fontSize="large" />
                    </div>
                  </div>
                ) : ""
            }

          </div>

        ))
        : <TableLoadingOverlay />}
    </div>
  );
};

export const WorkflowDiagram = ({ workflowName, refresh, setRefresh }) => {
  const [data, setData] = useState([]);
  const [workflowStatus, setWorkflowStatus] = useState();
  const [spinner, setSpinner] = useState(true);

  useEffect(() => {
    const url = `/workflow-tasks?workflow_name=${workflowName}&limit=100`;
    setSpinner(true);

    API.request({ url, method: "GET" }).then(
      (response) => {
        response.data.taskDetails.sort((a, b) => (a.stepNumber > b.stepNumber ? 1 : -1));
        setData(response.data.taskDetails);
        setWorkflowStatus(response.data.workflowDetails?.status);
        setSpinner(false);
      },
      (error) => console.error(error),
    );
    setRefresh(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workflowName, refresh]);

  const result = data.length > 0 ? <Flow data={data} workflowStatus={workflowStatus} />
    : <Alert severity="warning">No Tasks were Found!</Alert>;

  return (
    spinner ? <CircularProgress />
      : result
  );
};

export default function DisplayFlow({ rowData }) {
  const [workflowName, setWorkflowName] = useState("");
  const [open, setOpen] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [zoomLevel, setZoomLevel] = useState(100);

  function openWorkflowHandler() {
    setOpen(true);
    return workflowName === "" ? setWorkflowName(rowData.workflowName) : setWorkflowName(workflowName);
  }
  function closeWorkflowHandler() {
    setOpen(false);
  }

  const handleZoomIn = () => {
    setZoomLevel(zoomLevel + 10);
  };
  const handleZoomOut = () => {
    setZoomLevel(zoomLevel - 10);
  };
  const handleRefresh = () => {
    setRefresh(true);
  };

  return (
    <div>
      <IconButton disabled={rowData.status === "CREATED"} onClick={openWorkflowHandler}>
        <DeviceHubRounded />
      </IconButton>
      <Dialog
        onClose={closeWorkflowHandler}
        aria-labelledby="workflow-steps-dialog"
        open={open}
        maxWidth="false"
        fullWidth
      >
        <DialogTitle id="simple-dialog-title">
          {workflowName}
          {rowData.status === "RUNNING" && <CountDown duration={refreshTime} onFinish={handleRefresh} text="Refreshing in" />}
        </DialogTitle>
        <div style={{ height: "300px", overflow: "scroll" }}>
          <DialogContent style={{ width: "max-content", transform: `scale(${zoomLevel / 100})`, transformOrigin: "top left", margin: "5px auto" }}>
            <WorkflowDiagram workflowName={workflowName} refresh={refresh} setRefresh={setRefresh} />
          </DialogContent>
        </div>
        <div>
          <DialogActions>
            <ButtonGroup color="secondary" aria-label="outlined primary button group">
              <Button onClick={handleZoomIn}>
                <ZoomIn />
              </Button>
              <Button onClick={handleZoomOut}>
                <ZoomOut />
              </Button>
              <Button onClick={closeWorkflowHandler}>
                Close
              </Button>
            </ButtonGroup>
          </DialogActions>
        </div>

      </Dialog>
    </div>
  );
}
