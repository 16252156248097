import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { CloudUpload } from "@material-ui/icons";
import { nanoid } from "nanoid";
import Papa from "papaparse";
import { useDropzone } from "react-dropzone";
import { useDispatch } from "react-redux";
import * as XLSX from "xlsx";
import { resetHeaders, setCsvHeaders } from "../../state";

const delimitersLabel = {
  "\t": "Tab",
  ",": "Comma",
  "|": "Pipe",
};

function CSVPreview({ csv, keyName = "attachment", delimitersToGuess }) {
  const [csvData, setCsvData] = useState(undefined);
  const [error, setError] = useState(false);
  const fileExtention = csv[keyName]?.name.split(".").pop();
  const dispatch = useDispatch();

  const handleExcelUpload = (file) => {
    const reader = new FileReader();
    reader.readAsArrayBuffer(file);
    reader.onload = (event) => {
      const data = event.target.result;
      const workbook = XLSX.read(data, { sheetRows: 5 });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      let json = XLSX.utils.sheet_to_json(worksheet, { header: 1, defval: "", raw: false });
      if (json.length === 0) {
        json = [[""]];
      }
      setCsvData(json.slice(0, 5));
    };
  };

  useEffect(() => {
    if (csv[keyName] && ["xlsx", "xls"].includes(fileExtention)) {
      handleExcelUpload(csv.attachment);
    } else if (csv[keyName] && !["xlsx", "xls"].includes(fileExtention)) {
      let parsedData;

      const parseConfig = {
        preview: 10,
        chunk: (result) => {
          parsedData = result;
        },
        complete: () => {
          if (parsedData.data && Array.isArray(parsedData.data)) {
            setCsvData(parsedData.data);
            setError(false);
          } else {
            setError(true);
          }
        },
        error: () => setError(true),
        delimitersToGuess,
      };
      Papa.parse(csv[keyName], parseConfig);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [csv, keyName, fileExtention]);
  useEffect(() => {
    dispatch(resetHeaders());
    if (csvData) {
      dispatch(setCsvHeaders({ csvHeaders: [...csvData[0]] }));
    }
  }, [csvData, dispatch]);
  if (!csv[keyName]) return null;

  if (error) return <div>Preview Not Available</div>;

  if (!csvData) return <div>parsing...</div>;
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            {csvData[0].map((h) => (
              <TableCell key={nanoid()}>{h}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {csvData.slice(1, 5).map((row) => (
            <TableRow key={nanoid()}>
              {row.map((cell) => (
                <TableCell key={nanoid()}>{cell}</TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

function CustomDropZone({ onChange, delimitersToGuess }) {
  const onDrop = useCallback(acceptedFiles => {
    onChange({ csv: acceptedFiles[0] });
  }, [onChange]);

  const { getInputProps, getRootProps } = useDropzone({
    onDrop,
    accept: "text/plain, text/csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel",
    maxFiles: 1,
    maxSize: 1024 * 1024 * 1024 * 500, // 500GB
  });

  return (
    <Paper style={{ display: "block", width: "100%", padding: 30, height: 300 }}>
      <div {...getRootProps()} style={{ height: "100%", width: "100%" }}>
        <input {...getInputProps()} />
        <Typography variant="h5" style={{ textAlign: "center" }}>
          Click to select file or drag-and-drop the file here
          <br />
          <Typography variant="h6" component="b" style={{ fontSize: "0.5em" }}>
            Note: Only
            {` ${delimitersToGuess.map(delimiter => delimitersLabel[delimiter]).join(", ")} `}
            delimited txt files and xlsx are supported for preview
          </Typography>
        </Typography>
        <CloudUpload style={{ display: "block", margin: "auto", width: 50, height: 50 }} />
      </div>
    </Paper>
  );
}

export default function CSVUpload({ value, onChange, attachmentKey = "attachment", showAttachment = false, delimitersToGuess = ["\t", ",", "|"] }) {
  const inputRef = useRef();

  // Reset FileUpload on remove
  function removeFile() {
    onChange({});
    if (inputRef.current) inputRef.current.value = null;
  }

  return (
    <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
      {attachmentKey !== "attachment"
        ? (
          <>
            {(showAttachment && value.csv[attachmentKey]) && (
              <>
                <CSVPreview csv={value.csv} keyName={attachmentKey} delimitersToGuess={delimitersToGuess} />
                <Button varian="outlined" onClick={() => { removeFile(); }} style={{ width: "20%", marginTop: "10px" }}>Cancel</Button>
              </>
            )}
            {(!value.csv[attachmentKey]) && (
              <CustomDropZone onChange={onChange} delimitersToGuess={delimitersToGuess} />
            )}
          </>
        )
        : (
          <>
            {(value.csv.attachment) && (
              <>
                <CSVPreview csv={value.csv} delimitersToGuess={delimitersToGuess} />
                <Button varian="outlined" onClick={() => { removeFile(); }} style={{ width: "20%", marginTop: "10px" }}>Cancel</Button>
              </>
            )}
            {(!value.csv.attachment) && (
              <CustomDropZone onChange={onChange} delimitersToGuess={delimitersToGuess} />
            )}
          </>
        )}
    </div>
  );
}
