import React, { useMemo, useState } from "react";

import { Grid, Box, Tabs, Tab } from "@material-ui/core";
import {
  TrendingUp as GrowthIcon,
  GridOnSharp as QADashboardIcon,
  Assessment as MISightQaDashboardIcon,
} from "@material-ui/icons";

import { useSelector } from "react-redux";
import LmsQaDashboard from "../../dashboards/LMSQaDashboard";
import MISightQaDashboard from "../../dashboards/MISightQaDashboard";
import GrowthPotentialTab from "./GrowthPotentialTab";

export default function Performance() {
  const environment = useSelector((state) => state.application.environment);
  const [selectedTab, setSelectedTab] = useState("gps");

  const tabContents = useMemo(() => {
    if (selectedTab === "reporting_ann_lms_qa_dashboard") {
      return <LmsQaDashboard selectedTab={selectedTab} />;
    }
    if (selectedTab === "reporting_ann_misight_qa_dashboard") {
      return <MISightQaDashboard selectedTab={selectedTab} />;
    }
    return <GrowthPotentialTab />;
  }, [selectedTab]);

  return (
    <Grid container spacing={2}>
      <Box style={{ width: "100%" }}>
        <Tabs
          value={selectedTab}
          onChange={(e, tabIndex) => { setSelectedTab(tabIndex); }}
          textColor="secondary"
          indicatorColor="secondary"
          aria-label="secondary tabs example"
        >
          <Tab value="gps" label="Growth Potential" icon={<GrowthIcon />} />
          {environment.toLowerCase() !== "prod" && (<Tab value="reporting_ann_lms_qa_dashboard" label="LMS QA Dashboard" icon={<QADashboardIcon />} />)}
          <Tab value="reporting_ann_misight_qa_dashboard" label="MISight QA Dashboard" icon={<MISightQaDashboardIcon />} />
        </Tabs>
      </Box>
      <Box style={{ width: "100%", marginLeft: 10 }}>
        {tabContents}
      </Box>
    </Grid>
  );
}
