import React from "react";
import { CssBaseline, ThemeProvider } from "@material-ui/core";
import { configureStore } from "@reduxjs/toolkit";
import { withAuthenticator } from "aws-amplify-react";
import { createBrowserHistory } from "history";
import { createRoot } from "react-dom/client";
import { Provider as ReduxProvider } from "react-redux";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import CustomSignIn from "./lib/cognito/components/CognitoAuth";
import { getCurrentSession } from "./lib/cognito/constants";
import { heapInit, heapMiddleware, heapSetGlobals, heapSetUser } from "./lib/heap";
import { SentryErrorBoundary, sentryInit, SentryReduxEnhancer, sentrySetUsername } from "./lib/sentry";

import { rootReducer } from "./state/index";
import theme from "./theme/theme";
import MainView from "./views/MainView";
import MainViewDD from "./views/MainViewDD";

const history = createBrowserHistory();

// Initialize Analytics
sentryInit(history);
heapInit();

const store = configureStore({
  reducer: rootReducer,
  enhancers: [SentryReduxEnhancer],
  middleware: (getDefaultMiddleware) => [
    ...getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }),

    heapMiddleware,
  ],
});

const App = function App() {
  getCurrentSession().then((session) => {
    const userInfo = session?.idToken?.payload;
    if (!userInfo?.name) return;

    const user = {
      name: userInfo?.name,
      groups: userInfo?.["cognito:groups"],
      email: userInfo?.email,
    };
    heapSetUser(user.email, user);
    heapSetGlobals({});
    sentrySetUsername(user.email);
  });

  return (
    <SentryErrorBoundary>
      <ReduxProvider store={store}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Router history={history}>
            <Routes>
              <Route
                path="/*"
                element={(
                  <MainView />
                )}
              />
              <Route
                path="/dd/*"
                element={(
                  <MainViewDD />
                )}
              />
            </Routes>
          </Router>
        </ThemeProvider>
      </ReduxProvider>
    </SentryErrorBoundary>
  );
};

const AppWithAuth = withAuthenticator(App, false, [<CustomSignIn />]);

createRoot(document.querySelector("#root")).render(<AppWithAuth />);
