import React, { useState } from "react";
import {
  Card,
  CardContent,
  Chip,
  Divider,
  Grid,
  makeStyles,
  TextField,
  Typography,
  useTheme,
} from "@material-ui/core";
import ReportProblemIcon from "@material-ui/icons/ReportProblem";
import moment from "moment-timezone";
import { useSelector } from "react-redux";
import useSWR from "swr";
import usePageTitle from "../../../hooks/usePageTitle";
import { fetcher, customRetry } from "../../../utils/swr";
import ClientSelect from "../ClientSelect";
import HistoricalGraphs from "./HistoricalGraphs";
import JobProblemsTable from "./JobProblemsTable";
import JobSummaryClient from "./JobSummaryClient";
import JobSummaryUser from "./JobSummaryUser";
import { userDisplay } from "./utils";

const useStyles = makeStyles(() => ({
  title: {
    fontSize: 14,
  },
}));

function GraphChips({ compareTo, setCompareTo, showJustIssues, setShowJustIssues, useAverage, setUseAverage }) {
  const theme = useTheme();

  return (
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      <div style={{ display: "flex" }}>
        <Chip icon={<ReportProblemIcon />} label="Show Issues Only" onClick={() => setShowJustIssues(!showJustIssues)} style={showJustIssues ? theme.common.chip.errorChip : { color: "default" }} />
        <Chip label={`Displaying ${useAverage ? "Averages" : "Medians"}`} onClick={() => setUseAverage(!useAverage)} color={useAverage ? "primary" : "default"} />
      </div>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Chip label="Compare Oldest Job" onClick={() => setCompareTo("oldest")} color={compareTo === "oldest" ? "primary" : "default"} />
        <Chip label="Compare Previous Job" onClick={() => setCompareTo("older")} color={compareTo === "older" ? "primary" : "default"} />
      </div>
    </div>
  );
}

export default function PrewarmStatsTab() {
  const classes = useStyles();

  usePageTitle("Prewarm Performance");

  // --- SATE ---
  const [client, setClient] = useState();
  const [user, setUser] = useState("");
  const [compareTo, setCompareTo] = useState("oldest");
  const [showJustIssues, setShowJustIssues] = useState(false);
  const [useAverage, setUseAverage] = useState(false);

  // --- USERS ---
  const [usersSearch, setUsersSearch] = useState("");
  const users = useSelector(state => state.users.misightUsers);
  const usersSearchLower = usersSearch.toLowerCase();
  const clientUsers = users?.filter(u => u.client_mnemonic.toUpperCase() === client)?.filter(u => !usersSearchLower || (u?.id === usersSearch || u.username.toLowerCase()?.includes(usersSearchLower) || u.okta_first_name.toLowerCase()?.includes(usersSearchLower) || (u.okta_last_name.toLowerCase())?.includes(usersSearchLower)));

  // --- PREWARM DATA ---
  // - AVAILABLE -
  // const { data, error } = useSWR("lms/api/v1/misight/prewarmstats", fetcher);
  // const { data: prewarmTaskData, error: prewarmTaskError } = useSWR(`lms/api/v1/misight/prewarmstats/task/{TASK_ID}`, fetcher);
  // - USED -
  const { data: prewarmClientDataLatest } = useSWR(`lms/api/v1/misight/prewarmstats/client/${client}?latest=true`, fetcher, customRetry);
  const { data: prewarmClientDataAll } = useSWR(`lms/api/v1/misight/prewarmstats/client/${client}?latest=false`, fetcher, customRetry);
  const { data: prewarmUserDataLatest } = useSWR(`lms/api/v1/misight/prewarmstats/user/${user}?latest=true`, fetcher, customRetry);
  const { data: prewarmUserDataAll } = useSWR(`lms/api/v1/misight/prewarmstats/user/${user}?latest=false`, fetcher, customRetry);

  // CLIENT DATA
  const prewarmClientDataOldest = prewarmClientDataAll?.filter(job => job.task_id === prewarmClientDataAll[0]?.task_id);
  const prewarmClientDataOlder = prewarmClientDataAll?.filter(job => job.task_id === prewarmClientDataAll[prewarmClientDataAll.length - 2]?.task_id);
  const prewarmClientDataToCompare = compareTo === "oldest" ? prewarmClientDataOldest : prewarmClientDataOlder;
  const prewarmClientDataToCompareDate = prewarmClientDataToCompare?.find(x => x !== undefined)?.created_at;

  // USER DATA
  const prewarmUserDataOldest = prewarmUserDataAll?.filter(job => job.task_id === prewarmUserDataAll[0]?.task_id);
  const prewarmUserDataOlder = prewarmUserDataAll?.filter(job => job.task_id === prewarmUserDataAll[prewarmUserDataAll.length - 2]?.task_id);
  const prewarmUserDataToCompare = compareTo === "oldest" ? prewarmUserDataOldest : prewarmUserDataOlder;
  const prewarmUserDataToCompareDate = prewarmUserDataToCompare?.find(x => x !== undefined)?.created_at;

  return (
    <Grid container className={classes.root} spacing={2}>

      {/* --- CLIENT SELECTION ---- */}
      <Grid item xs={12}>
        <ClientSelect client={client} setClient={setClient} />
        <Divider style={{ marginBlock: 30 }} />
      </Grid>
      {prewarmClientDataLatest?.length
        ? (
          <>
            {/* --- LATEST JOB STATS ---- */}
            <Grid item xs={12}>
              <Card>
                <CardContent>
                  <JobSummaryClient client={client} data={prewarmClientDataLatest} users={users} user={user} setUser={setUser} />
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12}>
              <JobProblemsTable data={prewarmClientDataLatest} users={users} user={user} setUser={setUser} setUsersSearch={setUsersSearch} />
            </Grid>
            <Grid item xs={6}>
              <Card style={{ minHeight: 600 }}>
                <CardContent>
                  {prewarmClientDataLatest?.length && prewarmClientDataAll?.length
                    ? (
                      <>
                        <GraphChips compareTo={compareTo} setCompareTo={setCompareTo} showJustIssues={showJustIssues} setShowJustIssues={setShowJustIssues} useAverage={useAverage} setUseAverage={setUseAverage} />
                        <Divider style={{ marginBlock: 30 }} />
                        <Typography className={classes.title} color="textSecondary" gutterBottom>
                          {useAverage ? "Average" : "Medians"}
                          {" "}
                          user query run time now VS
                          {" "}
                          {moment(prewarmClientDataToCompareDate)?.format("MMMM Do YYYY, h:mm:ss a")}
                        </Typography>
                        <HistoricalGraphs graphKey="seconds" latestJobData={prewarmClientDataLatest} oldestJobData={prewarmClientDataToCompare} showJustIssues={showJustIssues} useAverage={useAverage} />
                      </>
                    )
                    : null}
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={6}>
              <Card>
                {prewarmClientDataLatest?.length && prewarmClientDataAll?.length
                  ? (
                    <CardContent>
                      <GraphChips compareTo={compareTo} setCompareTo={setCompareTo} showJustIssues={showJustIssues} setShowJustIssues={setShowJustIssues} useAverage={useAverage} setUseAverage={setUseAverage} />
                      <Divider style={{ marginBlock: 30 }} />
                      <Typography className={classes.title} color="textSecondary" gutterBottom>
                        {useAverage ? "Average" : "Medians"}
                        {" "}
                        user query run time now VS
                        {" "}
                        {moment(prewarmClientDataToCompareDate)?.format("MMMM Do YYYY, h:mm:ss a")}
                      </Typography>
                      <HistoricalGraphs graphKey="records" latestJobData={prewarmClientDataLatest} oldestJobData={prewarmClientDataToCompare} showJustIssues={showJustIssues} useAverage={useAverage} />
                    </CardContent>
                  )
                  : null}
              </Card>
            </Grid>
            {/* --- USER SELECTION ---- */}
            <Grid item xs={12}>
              <Divider style={{ marginBlock: 30 }} />
              <Typography variant="h3" style={{ display: "flex", alignItems: "flex-end" }}>
                Please select a
                {" "}
                <TextField
                  id="standard-full-width"
                  style={{ marginLeft: 20 }}
                  label={user ? "USER" : ""}
                  placeholder={usersSearch || "USER"}
                  variant="standard"
                  margin="normal"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={e => setUsersSearch(e.target.value)}
                />
              </Typography>
              {clientUsers?.slice(0, 6).map(c => (
                <Chip key={c?.id} label={userDisplay(c.id, users)} color={c.id === user ? "primary" : "default"} onClick={() => setUser(c.id)} />
              ))}
              {clientUsers?.length >= 6 && <Chip label="...and more" disabled />}
              <Divider style={{ marginBlock: 30 }} />
            </Grid>
          </>
        ) : null}
      {!prewarmClientDataLatest?.length && (
        <Card style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", height: 500, width: "100%" }}>
          <img alt="not found" src="/not-found.svg" style={{ width: 200, height: 200 }} />
          <Typography variant="h4">No Data found for this client</Typography>
        </Card>
      )}
      {/* --- USER STATS ---- */}
      {(user && prewarmClientDataLatest?.length) && (
        <>
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <JobSummaryUser userName={clientUsers.find(u => u.id === user)?.username} data={prewarmUserDataLatest} />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={6}>
            <Card style={{ minHeight: 600 }}>
              <CardContent>
                {prewarmUserDataLatest?.length && prewarmUserDataLatest?.length
                  ? (
                    <>
                      <GraphChips compareTo={compareTo} setCompareTo={setCompareTo} showJustIssues={showJustIssues} setShowJustIssues={setShowJustIssues} useAverage={useAverage} setUseAverage={setUseAverage} />
                      <Divider style={{ marginBlock: 30 }} />
                      <Typography className={classes.title} color="textSecondary" gutterBottom>
                        {useAverage ? "Average" : "Medians"}
                        {" "}
                        user query run time now VS
                        {" "}
                        {moment(prewarmUserDataToCompareDate)?.format("MMMM Do YYYY, h:mm:ss a")}
                      </Typography>
                      <HistoricalGraphs graphKey="seconds" latestJobData={prewarmUserDataLatest} oldestJobData={prewarmUserDataToCompare} showJustIssues={showJustIssues} useAverage={useAverage} />
                    </>
                  )
                  : null}
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={6}>
            <Card>
              {prewarmUserDataLatest?.length && prewarmUserDataLatest?.length
                ? (
                  <CardContent>
                    <GraphChips compareTo={compareTo} setCompareTo={setCompareTo} showJustIssues={showJustIssues} setShowJustIssues={setShowJustIssues} useAverage={useAverage} setUseAverage={setUseAverage} />
                    <Divider style={{ marginBlock: 30 }} />
                    <Typography className={classes.title} color="textSecondary" gutterBottom>
                      {useAverage ? "Average" : "Medians"}
                      {" "}
                      user query run time now VS
                      {" "}
                      {moment(prewarmUserDataToCompareDate)?.format("MMMM Do YYYY, h:mm:ss a")}
                    </Typography>
                    <HistoricalGraphs graphKey="records" latestJobData={prewarmUserDataLatest} oldestJobData={prewarmUserDataToCompare} showJustIssues={showJustIssues} useAverage={useAverage} />
                  </CardContent>
                )
                : null}
            </Card>
          </Grid>
        </>
      )}
    </Grid>
  );
}
