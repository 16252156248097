import React, { useEffect, useState } from "react";
import { Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import MaterialTable from "material-table";
import { useParams } from "react-router-dom";
import useSWR from "swr";
import TableLoadingOverlay from "../../components/MaterialTableLoadingOverlay";
import { deleteCommonTransFormById, getAllCommonTransform, postCommonTransform, getCommonTransformMapping } from "../../utils/api";

export function DisplayCommonTransforms() {
  const { studyFileType, id } = useParams();
  const transformLookup = {};
  const [data, setData] = useState([]);
  const [isOpen, setIsOpen] = useState({ status: false, message: "", severity: "" });
  const [dropDownData, setDropData] = useState([]);
  const { data: transformData, error: transformDataError, mutate } = useSWR(`/lms/api/v1/transform/common-transform/${id}`, () => getCommonTransformMapping(id));
  const { data: commonTransformData, error: commonTransformDataError } = useSWR("/lms/api/v1/transform/common_transform_file_mapping", getAllCommonTransform);
  useEffect(() => {
    if (transformData) {
      transformData.sort((a, b) => (a.transform_name > b.transform_name ? 1 : -1));
      setData(transformData);
    }
    if (commonTransformData) {
      commonTransformData.sort((a, b) => (a.transform_name > b.transform_name ? 1 : -1));
      setDropData(commonTransformData);
    }
  }, [transformData, commonTransformData]);
  /* eslint-disable*/
  if (dropDownData.length > 0) {
    dropDownData.map((row) => transformLookup[row.transform_name] = row.transform_name?.toString());
  }
  if (transformDataError) return <div>Error loading common transform data</div>;
  if (commonTransformDataError) return <div>Error loading file mapping for common transform</div>;
  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const columns = [
    { title: "Transform", field: "transform_name", lookup: transformLookup },
    { title: "Description", field: "description", editable: "never" },
  ];
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setIsOpen((prev) => ({ ...prev, status: false, message: "", severity: "" }));
  };
  return (
    <div>
      {transformData ? (
      <MaterialTable
        columns={columns}
        data={data}
        title={`Common Transform  - ${studyFileType}`}
        options={{
          headerStyle: {
            backgroundColor: "#30455c",
          },
          paging: true,
          pageSize: 20,
          pageSizeOptions: [],
          emptyRowsWhenPaging: false,
        }}
        editable={{
          onRowAdd: async (newData) => {
            const rowToAdd = {
              study_file_type: parseInt(id, 10),
              transform_name: newData.transform_name,
              description: dropDownData.filter(row => row.transform_name === newData.transform_name)[0].description,
            };

            if (data.filter(row => row.transform_name === rowToAdd.transform_name).length === 0) {
              await postCommonTransform(rowToAdd);
              mutate();
            } else {
              setIsOpen((prev) => ({
                ...prev, status: true, message: "Transform Type already added !", severity: "error",
              }));
            }
          },

          onRowDelete: async (oldData) => {
            await deleteCommonTransFormById(oldData.id);
            mutate();
            setIsOpen((prev) => ({
              ...prev, status: true, message: "Transform Type deleted successfully", severity: "success",
            }));
          },
        }}
      />
      ) :
        <TableLoadingOverlay />
      }
      {isOpen.status && (
        <Snackbar
          open={isOpen}
          onClose={() => setIsOpen((prev) => ({ ...prev, status: false }))}
          autoHideDuration={4000}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        >
          <Alert onClose={handleClose} severity={isOpen.severity}>
            {isOpen.message}
          </Alert>
        </Snackbar>
      )}
    </div>
  );
}
