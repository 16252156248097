import React from "react";
import {
  makeStyles,
  Button,
} from "@material-ui/core";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  button: {
    marginRight: theme.spacing(1),
  },
}));

export default function NavigationButton({ url, text, state = {} }) {
  const classes = useStyles();
  const navigate = useNavigate();
  return (
    <Button
      className={classes.button}
      variant="contained"
      color="primary"
      href={url}
      onClick={() => {
        navigate(
          url,
          {
            state,
          },
        );
      }}
    >
      {text}
    </Button>

  );
}
