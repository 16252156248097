import React, { useEffect, useMemo, useState } from "react";
import { Box, Card, CardContent, TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import MaterialTable from "material-table";
import { useLocation } from "react-router-dom";
import useSWR from "swr";

import TableLoadingOverlay from "../../../components/MaterialTableLoadingOverlay";
import usePageTitle from "../../../hooks/usePageTitle";
import { getClientProfiles, getClients, deleteClientProfile } from "../../../utils/api";

import CreateProfile from "./CreateProfile";
import ProfileNameColumn from "./ProfileNameColumn";

export default function ClientProfiles({ isDDTask = false }) {
  const location = useLocation();
  const { clientId } = location.state || "";
  const [client, setClient] = useState(clientId);
  const { data: clients, error: clientsError } = useSWR("/lms/api/v1/client/client", getClients);

  usePageTitle("Client Profiles");

  if (clientsError) return <div>Error loading...</div>;

  return (
    <div>
      <Card style={{ marginBottom: "1em" }}>
        <CardContent>
          <Box display="flex" justifyContent="space-between" p={2}>
            {clients
              ? (
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  onChange={(e, value) => value && setClient(clients.find((clientOption) => clientOption.mnemonic.toUpperCase() === value)?.id)}
                  options={(clients && clients.map((clientOption) => clientOption.mnemonic.toUpperCase())) || []}
                  value={clients.find((clientOption) => clientOption.id === clientId)?.mnemonic}
                  style={{ width: 300, paddingRight: 20 }}
                  renderInput={(params) => <TextField {...params} label="Client Select" />}
                />
              )
              : <TableLoadingOverlay />}
          </Box>
        </CardContent>
      </Card>
      {client ? <ProfilesTable clientId={client} isDDTask={isDDTask} /> : null}
      <CreateProfile />
    </div>
  );
}

const ProfilesTable = ({ clientId, isDDTask = false }) => {
  const { data: clientProfilesMap, error: clientProfilesMapError, mutate } = useSWR(`/lms/api/v1/client/${clientId}/profiles`, () => getClientProfiles(clientId));
  const [displayedRows, setDisplayedRows] = useState([]);

  useEffect(() => {
    if (clientProfilesMap) {
      let newRows = [];
      Object.entries(clientProfilesMap).forEach(([study, profiles]) => {
        newRows = newRows.concat(
          profiles.map((profile) => ({ ...(profile), study })),
        );
      });
      setDisplayedRows(newRows);
    }
  }, [clientProfilesMap]);

  const columns = [
    { title: "Study", field: "study" },
    { title: "Profile Full Name", field: "client_profile_full_name" },
    { title: "Profile Short Name", field: "client_profile_short_name", render: rowData => <ProfileNameColumn rowData={rowData} isDDTask={isDDTask} /> },
  ];

  const memoizedLoadingOverlay = useMemo(() => <TableLoadingOverlay />, []);

  if (clientProfilesMapError) return <div>Error loading...</div>;

  return (
    <div>
      <MaterialTable
        isLoading={!clientProfilesMap}
        columns={columns}
        data={displayedRows}
        title="Client Profiles"
        options={{
          pageSize: 20,
          pageSizeOptions: [20],
          headerStyle: {
            backgroundColor: "#30455c",
            color: "#97a9bc",
          },
        }}
        components={{
          OverlayLoading: () => memoizedLoadingOverlay,
        }}
        editable={{
          onRowDelete: async (profile) => {
            await deleteClientProfile(profile.client_profile_id, profile.study);
            mutate();
          },
        }}
      />
    </div>
  );
};
