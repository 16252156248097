import React, { useCallback, useState } from "react";
import { MenuItem, Select, Snackbar } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";

import usePageTitle from "../../hooks/usePageTitle";
import { channels } from "../../utils/SharedDefinitions";
import ExclusionsTable from "../consortium/exclusions/ExclusionsTable";
import BranchChannelFilters from "./BranchChannelFilters";

const commonColumns = () => [
  {
    title: "DD BRANCH ID",
    field: "dd_physical_branch_id",
    editable: "always",
  },
  {
    title: "Channel",
    field: "channel",
    editable: "always",
    render: rowData => <p>{channels.find(o => o.id === rowData.channel)?.label}</p>,
    editComponent: ({ value, onChange }) => (
      <Select
        defaultValue=""
        value={value || ""}
        onChange={(event) => onChange(event.target.value)}
      >
        {channels.map(o => (
          <MenuItem
            key={o.id}
            value={o.id}
          >
            {o?.label}
          </MenuItem>
        ))}
      </Select>
    ),
  },
];

export default function SplitBrokers() {
  usePageTitle("Split Brokers");
  const [success, setSuccess] = useState(null);
  const [response, setResponse] = useState("");
  const apiCallback = useCallback((apiSuccess, result) => {
    setSuccess(apiSuccess);
    if (apiSuccess) {
      if (result?.response?.data) {
        setResponse(JSON.stringify(result.response.data));
      } else {
        setResponse("Saved Successfully");
      }
    } else {
      setResponse(JSON.stringify(result.response.data));
    }
  }, []);
  return (
    <div>
      <div style={{ padding: 20 }}>
        <ExclusionsTable
          study={null}
          direction={null}
          title="Split Brokers"
          url="branch/channel"
          editUrl="branch/{ddPhysicalBranchId}/channel"
          columns={[
            ...commonColumns(),
            {
              title: "Notes",
              field: "notes",
              editable: "always",
            },
            {
              title: "Timestamp",
              field: "timestamp",
              editable: "never",
            },
          ]}
          apiCallback={apiCallback}
          filterPanel={BranchChannelFilters}
          genericEditActions
        />
      </div>
      <Snackbar
        open={success !== null}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert onClose={() => setSuccess(null)} severity={success ? "success" : "error"}>
          {response}
        </Alert>
      </Snackbar>
    </div>
  );
}
