import React, { useMemo, useState } from "react";
import { Box, Grid, Tab, Tabs } from "@material-ui/core";
import {
  Person as UsersIcon,
  Android as ApiIcon,
} from "@material-ui/icons";
import APITokens from "./apiTokens";
import UsersList from "./users";

export default function AccountsView() {
  const [selectedTab, setSelectedTab] = useState("users");

  const tabContents = useMemo(() => {
    if (selectedTab === "users") {
      return <UsersList />;
    }
    return <APITokens />;
  }, [selectedTab]);

  return (
    <Grid container spacing={2}>
      <Grid item sx={{ width: "100%" }}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Tabs
            value={selectedTab}
            onChange={(e, tabIndex) => { setSelectedTab(tabIndex); }}
            textColor="secondary"
            indicatorColor="secondary"
            aria-label="secondary tabs example"
          >
            <Tab value="users" label="MISight App Users" icon={<UsersIcon />} iconPosition="end" />
            <Tab value="api" label="Machine to Machine (API)" icon={<ApiIcon />} iconPosition="end" />
          </Tabs>
        </Box>
      </Grid>
      <Box style={{ width: "100%" }}>
        {tabContents}
      </Box>
    </Grid>
  );
}
